import React, { useState } from 'react';
import { CrossIcon, DownArrowIcon } from '../../../../assets/icons';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  Button,
  DialogFooter,
  Label,
  Slider,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from '../../../UI';
import { DatePicker, notify } from '../../../../components/common';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import {
  createNewSchedule,
  updateSchedule,
} from '../../../../services/api/endPoints/schedule';
import {
  addResourceSchedule,
  updateResourceSchedule,
} from '../../../../redux/slice/resourceScheduleSlice';
import { useDispatch } from 'react-redux';
import { dayNames, days } from '../../../../constants/constants';
import MultipleSelect from '../../../../components/common/MultipleSelet';

const AddEditWorkingHoursModal: React.FC<AddEditWorkingHoursModalProps> = ({
  open,
  onClose,
  selectedResourceId,
  selectedLocationId,
  selectedResourceName,
  ...props
}: AddEditWorkingHoursModalProps) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | undefined>(
    props.type === 'Edit' && props.selectedSchedule
      ? new Date(props.selectedSchedule.startDate)
      : new Date()
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    props.type === 'Edit' && props.selectedSchedule
      ? new Date(props.selectedSchedule.endDate)
      : new Date('2099-01-01')
  );
  const [selectedServiceIds, setSelectedServiceIds] = useState<string[]>(
    props.type === 'Edit' && props.selectedSchedule
      ? props.selectedSchedule.services.map(service => service.publicId)
      : []
  );
  const services = useSelector<RootState, Service[]>(
    state => state.services.services
  );
  const locations = useSelector<RootState, Location[]>(
    state => state.locations.locations
  );
  const [selectedDays, setSelectedDays] = useState<number[]>(
    props.type === 'Edit' && props.selectedSchedule
      ? props.selectedSchedule.days.map(day => dayNames.indexOf(day))
      : []
  );
  const [selectedToTime, setSelectedToTime] = useState<string>(
    props.type === 'Edit' && props.selectedSchedule
      ? props.selectedSchedule.timeEnd
      : '00:00:00'
  );
  const [selectedFromTime, setSelectedFromTime] = useState<string>(
    props.type === 'Edit' && props.selectedSchedule
      ? props.selectedSchedule.timeStart
      : '00:00:00'
  );
  const [repeatWeeks, setRepeatWeeks] = useState<number>(
    props.type === 'Edit' && props.selectedSchedule
      ? props.selectedSchedule.repeatWeeks
      : 1
  );
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleDateChange = (newDate: Date | null, type: 'start' | 'end') => {
    if (newDate) {
      if (type === 'start') {
        setStartDate(newDate);
      } else {
        setEndDate(newDate);
      }
    }
  };

  const handleTimeChange = (time: Date | null, type: 'from' | 'to') => {
    if (!time) return;
    const formattedTime = time.toTimeString().slice(0, 8); // "HH:MM:SS" format
    if (type === 'from') {
      setSelectedFromTime(formattedTime);
    } else {
      setSelectedToTime(formattedTime);
    }
  };

  const toggleDay = (index: number) => {
    setSelectedDays(prev =>
      prev.includes(index)
        ? prev.filter(day => day !== index)
        : [...prev, index]
    );
  };

  const mapServicesToRecord = (services: Service[]): Record<string, string> => {
    const record: Record<string, string> = {};
    services.forEach(service => {
      record[service.publicId] = service.name;
    });
    return record;
  };

  const handleSubmit = async () => {
    if (props.type === 'Create') {
      setIsLoading(true);
      if (
        !startDate ||
        !endDate ||
        !selectedResourceId ||
        selectedServiceIds.length === 0 ||
        selectedDays.length === 0
      ) {
        setErrorMessage('Please fill in all the required fields.');
        setIsLoading(false);
        return;
      }

      const scheduleData: CreateScheduleRequest = {
        startDate: startDate?.toISOString() || '',
        endDate: endDate?.toISOString() || '',
        resourcePublicId: selectedResourceId || '',
        staffPublicId: '',
        servicePublicIds: selectedServiceIds,
        days: selectedDays.map(day => dayNames[day]),
        repeatWeeks: repeatWeeks,
        timeStart: selectedFromTime,
        timeEnd: selectedToTime,
      };

      try {
        const [response, error] = await createNewSchedule(scheduleData);
        if (response) {
          dispatch(addResourceSchedule(response));
          notify.success({
            title: 'Working hours added successfully',
          });
          onClose();
        } else {
          setErrorMessage(error || 'An unknown error occurred.');
          setIsLoading(false);
        }
      } catch (error) {
        setErrorMessage(
          'Failed to create schedule. Try changing the location, services, days, or time'
        );
        notify.error({ title: error as string });
      }
    } else if (props.type == 'Edit') {
      setIsLoading(true);
      if (
        !startDate ||
        !selectedResourceId ||
        selectedServiceIds.length === 0 ||
        selectedDays.length === 0
      ) {
        setErrorMessage('Please fill in all the required fields.');
        return;
      }

      const scheduleData: CreateScheduleRequest = {
        publicId: props.selectedSchedule?.publicId,
        startDate: startDate?.toISOString() || '',
        endDate: endDate?.toISOString() || '',
        resourcePublicId: selectedResourceId || '',
        staffPublicId: '',
        servicePublicIds: selectedServiceIds,
        days: selectedDays.map(day => dayNames[day]),
        repeatWeeks: repeatWeeks,
        timeStart: selectedFromTime,
        timeEnd: selectedToTime,
      };

      try {
        const [response, error] = await updateSchedule(scheduleData);
        if (response) {
          dispatch(updateResourceSchedule(response));
          notify.success({
            title: 'Working hours updated successfully',
          });
          onClose();
        } else {
          setErrorMessage(error || 'An unknown error occurred.');
          setIsLoading(false);
        }
      } catch (error) {
        setErrorMessage(
          'Failed to create schedule. Try changing the location, services, days, or time'
        );
        setIsLoading(false);
        console.error(error);
      }
    }
  };
  const getDateFromTimeString = (timeString: string) => {
    const [hours, minutes, seconds] = timeString.split(':');
    const currentDate = new Date();
    currentDate.setHours(parseInt(hours, 10));
    currentDate.setMinutes(parseInt(minutes, 10));
    currentDate.setSeconds(parseInt(seconds, 10));
    return currentDate;
  };
  return (
    <div>
      <Dialog open={open} onOpenChange={onClose}>
        <DialogContent className="p-0  tablet:w-[40rem] md:w-[40rem] flex flex-col gap-0 border border-neutral-100 max-h-[80%]">
          <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
            <div className="p-1">
              <h1 className="text-[14px] text-neutral-900 font-semibold">
                {props.type === 'Edit'
                  ? 'Edit Working Hours'
                  : 'Add Working Hours'}
              </h1>
              <p className="text-[14px] text-neutral-900 font-sm">
                Set when {selectedResourceName} is available to take bookings.
              </p>
            </div>
            <Button variant={'link'} size={'xmall'} onClick={onClose}>
              <CrossIcon />
            </Button>
          </DialogHeader>
          <Slider className="flex-1" variant={'vertical'}>
            <div className="p-[1rem]">
              <div className="pb-2 flex flex-row gap-2">
                <div className="w-full">
                  <Label>Start Date</Label>
                  <DatePicker
                    variant="fullDate"
                    initialValue={startDate}
                    handleDateChange={date => handleDateChange(date, 'start')}
                  />
                </div>
                <div className="w-full">
                  <Label>End Date</Label>
                  <DatePicker
                    variant="fullDate"
                    initialValue={endDate}
                    handleDateChange={date => handleDateChange(date, 'end')}
                  />
                </div>
              </div>
              <div className="flex flex-row gap-2 items-center">
                <div className="w-full">
                  <Label>Days</Label>
                  <div className="flex space-x-2 mt-1">
                    {days.map((day, index) => (
                      <button
                        key={index}
                        className={`w-8 h-8 flex items-center justify-center rounded ${
                          selectedDays.includes(index)
                            ? 'bg-primary-500 text-white'
                            : 'bg-white text-black border border-neutral-200'
                        }`}
                        onClick={() => toggleDay(index)}
                      >
                        {day}
                      </button>
                    ))}
                  </div>
                </div>
                <div className="w-full">
                  <Label>Repeats</Label>
                  <Select
                    value={String(repeatWeeks)}
                    onValueChange={value => setRepeatWeeks(Number(value))}
                  >
                    <SelectTrigger
                      className="w-full"
                      selectIcon={<DownArrowIcon />}
                    >
                      <SelectValue placeholder="Weekly" />
                    </SelectTrigger>
                    <SelectContent className="bg-shades-0 z-[999999]">
                      <SelectItem value="1">Weekly</SelectItem>
                      <SelectItem value="2">Every 2 weeks</SelectItem>
                      <SelectItem value="3">Every 3 weeks</SelectItem>
                      <SelectItem value="4">Every 4 weeks</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </div>
              <div className="flex flex-row gap-3">
                <div className="pt-2">
                  <Label className="pb-1">From</Label>
                  <DatePicker
                    variant="time"
                    initialValue={getDateFromTimeString(selectedFromTime)}
                    handleDateChange={time => handleTimeChange(time, 'from')}
                  />
                </div>
                <div className="pt-2">
                  <Label className="pb-1">To</Label>
                  <DatePicker
                    variant="time"
                    initialValue={getDateFromTimeString(selectedToTime)}
                    handleDateChange={time => handleTimeChange(time, 'to')}
                  />
                </div>
              </div>
              <div className="pt-2">
                <Label className="pb-1">Location</Label>
                <div className="border border-neutral-100 p-1 rounded">
                  <h1 className="text-neutral-900 text-[14px]">
                    {locations.find(
                      location => location.publicId === selectedLocationId
                    )?.name || ''}
                  </h1>
                </div>
              </div>
              <div className="pt-2">
                <Label className="pb-1">Service</Label>
                <MultipleSelect
                  options={mapServicesToRecord(services)}
                  selectedValues={selectedServiceIds}
                  setSelectedValues={setSelectedServiceIds}
                />
              </div>
            </div>
          </Slider>
          {errorMessage && (
            <div className="text-red-600 text-center pb-2">{errorMessage}</div>
          )}
          <DialogFooter>
            <Button
              variant={'link'}
              size={'small'}
              className="w-full"
              onClick={onClose}
            >
              Close
            </Button>
            <Button
              size={'small'}
              onClick={handleSubmit}
              className="w-full"
              variant={'primary'}
              isLoading={isLoading}
              disabled={isLoading}
            >
              {props.type === 'Edit' ? 'Update' : 'Save'}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddEditWorkingHoursModal;
