import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  Button,
  Slider,
} from '../../../../UI';
import { CrossIcon } from '../../../../../assets/icons';

const ViewPathwayModal = ({
  open,
  onClose,
  selectedPathway,
}: ViewPathwayModalProps) => {
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="p-0  w-[400px] md:w-[533px] flex flex-col gap-0 border border-neutral-100 max-h-[70%]">
        <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
          <h1 className="text-[14px] text-neutral-900 font-semibold">
            Pathway details
          </h1>
          <Button variant={'link'} size={'xmall'} onClick={onClose}>
            <CrossIcon />
          </Button>
        </DialogHeader>
        <Slider className="flex-1" variant={'vertical'}>
          <div className="p-4 space-y-4">
            <div className="grid grid-cols-2">
              <p className="text-[14px] font-semibold text-neutral-500">Pathway</p>
              <p className="text-[14px] font-semibold text-neutral-900">
                {selectedPathway.name}
              </p>
            </div>
            <div className="grid grid-cols-2">
              <p className="text-[14px] font-semibold text-neutral-900">No. of stages</p>
              <p className="text-[14px] font-semibold text-neutral-900">
                {selectedPathway.pathwayStages.length}
              </p>
            </div>
            <div className="grid grid-cols-2">
              <p className="text-neutral-500 text-[14px]">Stages</p>
              <div className="space-y-1">
                {selectedPathway.pathwayStages.map(pathwayStage =>
                  !pathwayStage.finalStage ? (
                    <p
                      className="text-neutral-900 text-[14px]"
                      key={pathwayStage.publicId}
                    >
                      {pathwayStage.name}
                    </p>
                  ) : (
                    <div
                      className="flex justify-between items-center"
                      key={pathwayStage.publicId}
                    >
                      <p className="text-neutral-900 text-[14px]">
                        {pathwayStage.name}
                      </p>
                      <p className="border border-neutral-500 text-neutral-600 rounded-[4px] px-[10px] py-[2px] tex-[14px] height-[21px]">
                        Final
                      </p>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </Slider>
      </DialogContent>
    </Dialog>
  );
};

export default ViewPathwayModal;
