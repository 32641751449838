import { URL } from '../requestConstructor';

export const getAllSites = async () => {
  try {
    const response = await URL.get('/site/get/all');

    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const createNewSite = async (data: CreateSiteRequest) => {
  try {
    const response = await URL.post('/site/create', data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const updateSite = async (data: UpdateSiteRequest) => {
  try {
    const response = await URL.put('/site/update', data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const deleteSite = async (id: string) => {
  try {
    const response = await URL.delete(`/site/delete?publicId=${id}`);

    return [response, null];
  } catch (error: any) {
    return [null, error.response];
  }
};
export const getSite = async (publicId: string) => {
  try {
    const response = await URL.get(`/site/get?publicId=${publicId}`);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};
