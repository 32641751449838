import React, { useState } from 'react';
import {
  CrossIcon,
  PersonIcon,
  LocationIcon,
  ClockIcon,
  ConsultantIcon,
  InvoiceIcon,
  DeleteIcon,
} from '../../../assets/icons';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  Button,
  DialogFooter,
} from '../../UI';
import ReallocateResourceModal from './ReallocateResourceModal';
import { formatDateTime } from '../../../utils/date.utl';
import { THEME } from '../../../constants/ColorConstants';
import { updateResourceBooking } from '../../../redux/slice/resourceBookingSlice';
import { notify } from '../../../components/common';
import { updateBooking } from '../../../services/api/endPoints/bookings';
import { useDispatch } from 'react-redux';
import { PAYMENT_STATUS } from '../../../constants/constants';

const CalenderEventModal = ({
  event,
  open,
  onClose,
}: {
  event: any;
  open: boolean;
  onClose: () => void;
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openAddPatientModal, setOpenPatientModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleOnClose = () => {
    setErrorMessage(''); // Clear the error message
    onClose();
  };

  const cancelResourceBookings = async () => {
    setIsLoading(true);

    const bookingData = {
      publicId: event?.publicId,
      cancelled: true,
    };

    const [response, error] = await updateBooking(bookingData);
    setIsLoading(false);

    if (response) {
      dispatch(updateResourceBooking(response));
      notify.success({ title: 'Booking cancelled successfully' });
      handleOnClose();
    } else {
      setErrorMessage(error || 'An unknown error occurred.');
    }
  };
  const reinstateResourceBookings = async () => {
    setIsLoading(true);

    const bookingData = {
      publicId: event?.publicId,
      cancelled: false,
      forceBooking: true,
    };

    const [response, error] = await updateBooking(bookingData);
    setIsLoading(false);

    if (response) {
      dispatch(updateResourceBooking(response));
      notify.success({ title: 'Booking reinstated successfully' });
      handleOnClose();
    } else {
      setErrorMessage(error || 'An unknown error occurred.');
    }
  };

  const calculateDuration = (start: string, end: string) => {
    const startTime = new Date(start);
    const endTime = new Date(end);
    const duration = endTime.getTime() - startTime.getTime();

    const days = Math.floor(duration / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));

    return `${days > 0 ? days + ' day' + (days > 1 ? 's ' : ' ') : ''}${hours > 0 ? hours + ' hour' + (hours > 1 ? 's ' : ' ') : ''}${minutes > 0 ? minutes + ' min' + (minutes > 1 ? 's' : '') : ''}`;
  };

  return (
    <div>
      <Dialog open={open} onOpenChange={handleOnClose}>
        <DialogContent className="p-0 tablet:w-[25rem] md:w-[25rem] flex flex-col gap-0 border border-neutral-100 max-h-[80%]">
          <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
            <div>
              {event?.serviceName ? (
                <h1 className="text-[14px] text-neutral-900 font-semibold">
                  {event?.serviceName}
                </h1>
              ) : (
                <h1 className="text-[14px] text-neutral-900 font-semibold">
                  {event?.title}
                </h1>
              )}
              <h1 className="text-[14px] text-neutral-900 font-sm">
                {event?.start &&
                  event?.end &&
                  formatDateTime(event.start, event.end)}
              </h1>
            </div>
            <Button variant={'link'} size={'xmall'} onClick={handleOnClose}>
              <CrossIcon />
            </Button>
          </DialogHeader>
          <div className="p-[1rem]">
            {event?.patient && (
              <div className="flex flex-row gap-[0.75rem] items-center">
                <PersonIcon stroke={THEME.NEUTRAL_500} />
                <h1 className="text-[14px] text-neutral-900 font-sm">
                  {event?.patient}
                </h1>
              </div>
            )}

            <div className="flex flex-row gap-[0.75rem] items-center mt-[0.5rem]">
              <ConsultantIcon stroke={THEME.NEUTRAL_500} />
              <h1 className="text-[14px] text-neutral-900 font-sm">
                {event?.resource}
              </h1>
            </div>
            <div className="flex flex-row gap-[0.75rem] items-center mt-[0.5rem]">
              <LocationIcon />
              <h1 className="text-[14px] text-neutral-900 font-sm">
                {event?.location}
              </h1>
            </div>
            <div className="flex flex-row gap-[0.75rem] items-center mt-[0.5rem]">
              <ClockIcon />
              <h1 className="text-[14px] text-neutral-900 font-sm">
                {event?.start &&
                  event?.end &&
                  calculateDuration(event.start, event.end)}
              </h1>
            </div>
            {event?.description ? (
              <div className="flex flex-row gap-[0.75rem] items-center mt-[0.5rem]">
                <InvoiceIcon stroke={THEME.NEUTRAL_500} />
                <h1 className="text-[14px] text-neutral-900 font-sm">
                  {event?.description}
                </h1>
              </div>
            ) : (
              <div>
                {event?.paymnetStatus === PAYMENT_STATUS.PAID && (
                  <div className="flex flex-row gap-[0.75rem] items-center mt-[0.5rem]">
                    <InvoiceIcon stroke={THEME.NEUTRAL_500} />
                    <div className="border border-success-500 bg-success-50 px-2 py-1 rounded flex items-center gap-[0.25rem]">
                      <div className="h-2 w-2 rounded-full bg-green-500"></div>
                      <h1 className="text-[14px] text-neutral-600 font-sm">
                        Paid
                      </h1>
                    </div>
                  </div>
                )}
                {event?.paymnetStatus === PAYMENT_STATUS.NOTPAID && (
                  <div className="flex flex-row gap-[0.75rem] items-center mt-[0.5rem]">
                    <InvoiceIcon stroke={THEME.NEUTRAL_500} />
                    <div className="border border-destructive-500 bg-destructive-50 px-2 py-1 rounded flex items-center gap-[0.25rem]">
                      <div className="h-2 w-2 rounded-full bg-destructive-500"></div>
                      <h1 className="text-[14px] text-neutral-600 font-sm">
                        Not Paid
                      </h1>
                    </div>
                  </div>
                )}
                {!event?.paymnetStatus && (
                  <div className="flex flex-row gap-[0.75rem] items-center mt-[0.5rem]">
                    <InvoiceIcon stroke={THEME.NEUTRAL_500} />
                    <div className="border border-neutral-500 bg-neutral-50 px-2 py-1 rounded flex items-center gap-[0.25rem]">
                      <div className="h-2 w-2 rounded-full bg-neutral-500"></div>
                      <h1 className="text-[14px] text-neutral-600 font-sm">
                        Not Available
                      </h1>
                    </div>
                  </div>
                )}
              </div>
            )}

            {errorMessage && (
              <div className="text-red-600 text-center">{errorMessage}</div>
            )}
          </div>
          {!event?.cancelled ? (
            <DialogFooter>
              <Button
                variant={'link'}
                size={'small'}
                className="w-full flex items-center justify-center text-destructive-500"
                onClick={cancelResourceBookings}
                isLoading={isLoading}
              >
                <h1>Cancel Booking</h1>
                <div className="mt-[0.15rem]">
                  <DeleteIcon stroke={THEME.DESTRUCTIVE_500} />
                </div>
              </Button>
              <Button
                size={'small'}
                onClick={() => setOpenPatientModal(true)}
                className="w-full"
                variant={'secondary'}
              >
                Reschedule
                <div className="mt-[0.15rem]">
                  <ClockIcon stroke={THEME.PRIMARY_500} />
                </div>
              </Button>
            </DialogFooter>
          ) : (
            <DialogFooter>
              <Button
                size={'small'}
                onClick={reinstateResourceBookings}
                className="w-full"
                variant={'secondary'}
                isLoading={isLoading}
              >
                Reinstate Booking
                <div className="mt-[0.15rem]">
                  <ClockIcon stroke={THEME.PRIMARY_500} />
                </div>
              </Button>
            </DialogFooter>
          )}
        </DialogContent>
      </Dialog>
      <ReallocateResourceModal
        event={event}
        open={openAddPatientModal}
        onClose={() => {
          setOpenPatientModal(false);
          handleOnClose();
        }}
      />
    </div>
  );
};

export default CalenderEventModal;
