/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Input,
  Label,
} from '../../../../UI';
import { CrossIcon, OutlinedAddIcon } from '../../../../../assets/icons';
import { useParams } from 'react-router-dom';
import { createNote } from '../../../../../services/api';
import { notify } from '../../../../common/Alert';
import DebouncedSearchInput from '../../../../../components/UI/DebouncedSearchInput';
import { searchPatient } from '../../../../../services/api/endPoints/patients';
import { getFullName } from '../../../../../utils/Helper';
import { TextEditor } from '../../../../../components/common';
import { ERROR_MESSAGE, ERROR_TITLE, SUCCESS_TITLE } from '../../../../../constants/NotificationConstants';

const AddNoteModal = ({
  open,
  onClose,
  searchForPatient,
  refreshNotes,
}: {
  open: boolean;
  onClose: () => void;
  callbackFunction?: () => void;
  searchForPatient: boolean;
  refreshNotes?: () => void;
}) => {
  const { id } = useParams();
  const [selectedPatient, setSelectedPatient] = useState<PatientLite>();
  const [loading, setLoading] = useState<boolean>(false);
  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>('');

  const addNoteToPatient = async () => {
    setLoading(true);
    const patientPublicId = searchForPatient ? selectedPatient?.publicId : id;
    if (patientPublicId && title && description) {
      const noteCreateRequest: NoteCreateRequest = {
        patientPublicId: patientPublicId,
        title: title,
        note: description,
      };

      const [res, err] = await createNote(noteCreateRequest);
      if (err) {
        notify.error({
          title: ERROR_TITLE.GENERIC_NOTE_ADD,
          message: err.data,
        });
      } else if (res) {
        notify.success({
          title: SUCCESS_TITLE.GENERIC_NOTE_ADD,
          message: `Note added successfully to patient ${selectedPatient?.fullName}`,
        });
        if (refreshNotes) {
          refreshNotes(); // Call refreshNotes if it's defined
        }
      }
    }
    setTitle('');
    setDescription('');
    setLoading(false);
    onClose();
  };

  const onTitleChange = (event: any) => {
    const { value } = event.target;
    setTitle(value);
  };

  const onDescriptionChange = (event: any) => {
    const { value } = event.target;
    setDescription(value);
  };

  const onSearch = async (search: string): Promise<PatientLite[]> => {
    try {
      const res = await searchPatient(search);
      const data: PatientLite[] = res.data.map((row: PatientLite) => ({
        ...row,
        fullName: getFullName(row),
        phoneNo: `${row.phoneNo ? row.phoneNo : ''}`,
      }));
      return data;
    } catch (err) {
      notify.error({
        title: ERROR_TITLE.GENERIC_PATIENT_SEARCH,
        message: ERROR_MESSAGE.GENERIC_TRY_AGAIN,
      });
      return []; // Return empty array or handle error appropriately
    }
  };

  return (
    <div>
      <Dialog open={open} onOpenChange={onClose}>
        <DialogContent className="p-0 w-[370px] max-h-full flex flex-col gap-0">
          <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
            <h1 className="text-[14px] text-neutral-900 font-medium">
              Add Note
            </h1>
            <Button variant={'link'} size={'xmall'} onClick={onClose}>
              <CrossIcon />
            </Button>
          </DialogHeader>
          <div className="min-h-[550px]">
            <div className="p-4 space-y-3">
              {searchForPatient && (
                <div className="space-y-1">
                  <DebouncedSearchInput
                    label={'Patient'}
                    onSearch={onSearch}
                    onSelect={option =>
                      setSelectedPatient(option as PatientLite)
                    }
                  />
                </div>
              )}
              <div className="space-y-1">
                <Label>Title</Label>
                <Input
                  placeholder="Enter Title"
                  value={title}
                  onChange={onTitleChange}
                />
              </div>
              <div className="space-y-1">
                <Label>Description</Label>
                <TextEditor
                  enableImageUpload={false}
                  value={description}
                  onChange={date =>
                    onDescriptionChange({ target: { value: date } })
                  }
                  height={searchForPatient ? 230 : 300}
                  placeholder="Enter your text here..."
                />
              </div>
            </div>
          </div>

          <DialogFooter className="flex justify-end gap-3 w-full ">
            <Button
              variant={'link'}
              size={'small'}
              className="px-[20px]"
              onClick={onClose}
            >
              Close
            </Button>
            <Button
              variant={'primary'}
              size={'small'}
              disabled={
                !title ||
                !description ||
                title.trim() === '' ||
                description.trim() === '' ||
                (!id && !selectedPatient) ||
                loading
              }
              onClick={addNoteToPatient}
              isLoading={loading}
              trailingIcon={<OutlinedAddIcon />}
              className="px-[20px]"
            >
              Add Note
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddNoteModal;
