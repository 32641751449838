import React from 'react';
import { THEME } from '../../constants/ColorConstants';

interface EditIconProps {
  width?: number;
  height?: number;
  stroke?: string;
}

const Edit2Icon: React.FC<EditIconProps> = ({
  width = 16,
  height = 16,
  stroke = THEME.NEUTRAL_900,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clipPath="url(#clip0_3026_40415)">
      <path
        d="M4 1.33398V10.6673C4 11.0209 4.14048 11.3601 4.39052 11.6101C4.64057 11.8602 4.97971 12.0007 5.33333 12.0007H14.6667"
        stroke={stroke}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9997 14.6667V5.33333C11.9997 4.97971 11.8592 4.64057 11.6092 4.39052C11.3591 4.14048 11.02 4 10.6663 4H1.33301"
        stroke={THEME.SHADE_100}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3026_40415">
        <rect width="16" height="16" fill={THEME.SHADE_0} />
      </clipPath>
    </defs>
  </svg>
);

export default Edit2Icon;
