import React, { useEffect, useState } from 'react';
import { getAllPathways, getAllTeams } from '../services/api';
import {
  Input,
  Select,
  SelectItem,
  SelectValue,
  SelectContent,
  SelectTrigger,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Label,
  Checkbox,
} from '../components/UI';
import { SearchIcon, FilterIcon } from '../assets/icons';
import { notify } from '../components/common';

import PathStages from '../components/sections/Patient/PatientJourney/PathStages';
import { RootState } from '@/redux/store/store';
import { useSelector } from 'react-redux';
import { getFullName } from '../utils/Helper';
import BoardLoader from '../components/loaders/BoardLoader';

const ClinicBoard = () => {
  const [pathways, setPathways] = useState<Record<string, PathwayLite>>();
  const [assignees, setAssignees] =
    useState<Record<string, StaffInformation>>();
  const [assigneeTeams, setAssigneeTeams] =
    useState<Record<string, TeamLite>>();
  const [consultants, setConsultants] =
    useState<Record<string, StaffInformation>>();
  const [selectedPathway, setSelectedPathway] = useState<PathwayLite>();
  const [selectedAssignee, setSelectedAssignee] = useState<StaffInformation>();
  const [selectedConsultant, setSelectedConsultant] =
    useState<StaffInformation>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedAssigneeTeam, setSelectedAssigneeTeam] = useState<TeamLite>();
  const [isAssigneeEnable, setIsAssigneeEnable] = useState<boolean>(false);
  const [archived, setArchived] = useState<boolean>(false);
  const [isAssigneeTeamEnable, setIsAssigneeTeamEnable] =
    useState<boolean>(false);
  const [isConsultantEnable, setIsConsultantEnable] = useState<boolean>(false);
  const staffs = useSelector<RootState, StaffInformation[]>(
    state => state.staffs.staffs
  );
  const [searchQuery, setSearchQuery] = useState<string>();
  const getPathways = async () => {
    setIsLoading(true);
    const [response, error] = await getAllPathways();
    if (response) {
      const allPathwaysOptions: Record<string, PathwayLite> = {};
      response.map(
        (res: PathwayLite) => (allPathwaysOptions[res.publicId as string] = res)
      );
      setPathways(allPathwaysOptions);
      setSelectedPathway(response[0]);
    } else {
      notify.error({
        title: 'Failed to fetch the pathways',
        message: error.data,
      });
    }
    setIsLoading(false);
  };
  const createTeamOptions = async () => {
    const [res] = await getAllTeams();
    if (res) {
      const allTeamOptions: Record<string, TeamLite> = {};
      res.map(
        (team: TeamLite) => (allTeamOptions[team.publicId as string] = team)
      );
      setAssigneeTeams(allTeamOptions);
    }
  };
  const createAssigneeOptions = () => {
    const allAssigneeOptions: Record<string, StaffInformation> = {};
    staffs.map(
      (staff: StaffInformation) =>
        (allAssigneeOptions[staff.publicId as string] = staff)
    );
    setAssignees(allAssigneeOptions);
  };
  const createConsultantOptions = () => {
    const allConsultantOptions: Record<string, StaffInformation> = {};
    staffs.map(
      (staff: StaffInformation) =>
        (allConsultantOptions[staff.publicId as string] = staff)
    );
    setConsultants(allConsultantOptions);
  };
  useEffect(() => {
    getPathways();
    createConsultantOptions();
    createTeamOptions();
    createAssigneeOptions();
  }, []);
  const handleEnableConsultant = (isChecked: boolean) => {
    setSelectedConsultant(undefined);
    setIsConsultantEnable(isChecked);
  };
  const handleEnableAssignee = (isChecked: boolean) => {
    setSelectedAssignee(undefined);
    setIsAssigneeEnable(isChecked);
  };
  const handleEnableAssigneeTeam = (isChecked: boolean) => {
    setSelectedAssigneeTeam(undefined);
    setIsAssigneeTeamEnable(isChecked);
  };

  return (
    <div className="custom-height flex flex-col">
      <div className="flex justify-between ml-3">
        <Input
          leadingIcon={<SearchIcon />}
          placeholder="Search for patient, consultant, assignee, team"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSearchQuery(e.target.value)
          }
          className="w-[400px]"
        />
        <div className="flex gap-2">
          {pathways && (
            <Select
              value={selectedPathway?.publicId}
              onValueChange={value => setSelectedPathway(pathways[value])}
            >
              <SelectTrigger className="w-fit">
                <SelectValue placeholder="Pathway" />
              </SelectTrigger>
              <SelectContent className="bg-shades-0">
                {Object.keys(pathways).map(publicId => (
                  <SelectItem
                    value={pathways[publicId].publicId}
                    key={pathways[publicId].publicId}
                  >
                    {pathways[publicId].name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
          {isConsultantEnable && consultants && (
            <Select
              value={selectedConsultant?.publicId}
              onValueChange={value => setSelectedConsultant(consultants[value])}
              disabled={!selectedPathway}
            >
              <SelectTrigger className="w-fit">
                <SelectValue placeholder="Consultant" />
              </SelectTrigger>
              <SelectContent className="bg-shades-0">
                {Object.keys(consultants).map(publicId => (
                  <SelectItem
                    value={consultants[publicId].publicId}
                    key={consultants[publicId].publicId}
                  >
                    {getFullName(consultants[publicId])}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
          {isAssigneeEnable && assignees && (
            <Select
              value={selectedAssignee?.publicId}
              onValueChange={value => setSelectedAssignee(assignees[value])}
              disabled={!selectedPathway}
            >
              <SelectTrigger className="w-fit">
                <SelectValue placeholder="Assignee" />
              </SelectTrigger>
              <SelectContent className="bg-shades-0">
                {Object.keys(assignees).map(publicId => (
                  <SelectItem
                    value={assignees[publicId].publicId}
                    key={assignees[publicId].publicId}
                  >
                    {getFullName(assignees[publicId])}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
          {isAssigneeTeamEnable && assigneeTeams && (
            <Select
              value={selectedAssigneeTeam?.publicId}
              onValueChange={value =>
                setSelectedAssigneeTeam(assigneeTeams[value])
              }
              disabled={!selectedPathway}
            >
              <SelectTrigger className="w-fit">
                <SelectValue placeholder="Assignee Team" />
              </SelectTrigger>
              <SelectContent className="bg-shades-0">
                {Object.keys(assigneeTeams).map(publicId => (
                  <SelectItem
                    value={assigneeTeams[publicId].publicId}
                    key={assigneeTeams[publicId].publicId}
                  >
                    {assigneeTeams[publicId].name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
          <Popover>
            <PopoverTrigger>
              <div>
                <Button
                  variant={'link'}
                  leadingIcon={<FilterIcon />}
                  size={'small'}
                >
                  Filter
                </Button>
              </div>
            </PopoverTrigger>
            <PopoverContent className="py-1 bg-shades-0">
              <div>
                <div className="flex justify-between py-2 items-center">
                  <Label>Consultants</Label>
                  <Checkbox
                    checked={isConsultantEnable}
                    onCheckedChange={check => handleEnableConsultant(!!check)}
                  />
                </div>
                <div className="flex justify-between py-2 items-center">
                  <Label>Assignee</Label>
                  <Checkbox
                    checked={isAssigneeEnable}
                    onCheckedChange={check => handleEnableAssignee(!!check)}
                  />
                </div>
                <div className="flex justify-between py-2 items-center">
                  <Label>Assignee Team</Label>
                  <Checkbox
                    checked={isAssigneeTeamEnable}
                    onCheckedChange={check => handleEnableAssigneeTeam(!!check)}
                  />
                </div>
                <div className="h-[1px] bg-neutral-100 w-full mb-[5px] mt-[5px]" />
                <div className="flex justify-between py-2 items-center">
                  <Label>Archived</Label>
                  <Checkbox
                    checked={archived}
                    onCheckedChange={check => setArchived(!!check)}
                  />
                </div>
              </div>
            </PopoverContent>
          </Popover>
        </div>
      </div>
      {isLoading ? (
        <div className="flex-1 overflow-x-hidden">
          <BoardLoader showTopPathwayLoader={false} />
        </div>
      ) : !pathways ? (
        <div className="h-full w-full flex items-center justify-center">
          <p className="text-[14px] font-semibold text-neutral-100">
            No pathways found. Please add pathways.
          </p>
        </div>
      ) : (
        selectedPathway && (
          <div className="flex-1 overflow-x-hidden">
            <PathStages
              patientPathway={selectedPathway}
              consultant={selectedConsultant}
              assignee={selectedAssignee}
              assigneeTeam={selectedAssigneeTeam}
              archived={archived}
              searchQuery={searchQuery}
              showNoOfEvents
            />
          </div>
        )
      )}
    </div>
  );
};

export default ClinicBoard;
