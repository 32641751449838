/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { InputVariant } from '../../../UI';

const SectionForm: React.FC<SectionFormProps> = ({ fields, onInput, name, data, validationErrors }) => {
  return (
    <div className="flex flex-col gap-6 items-center">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 w-full">
        {fields.map(field => (
          <div key={field.publicId} className="col-span-1 md:col-span-1 ">
            <InputVariant
              field={field}
              data={data}
              onInput={onInput}
              validationErrors={validationErrors}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionForm;
