import React, { useEffect, useState } from 'react';
import { Button, Slider } from '../../../../UI';
import {
  DownloadIcon,
  LeftArrowIcon,
  PrinterIcon,
} from '../../../../../assets/icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LoaderIcon from '../../../../../assets/icons/Loader.svg';
import { getFillFormPDF } from '../../../../../services/api';
import PdfViewer from '../../../../common/PdfViewer';
import { usePrintFile } from '../../../../../hooks/usePrintFile';
import { useDownloadFile } from '../../../../../hooks/useDownloadFile';
import { ERROR_MESSAGE } from '../../../../../constants/NotificationConstants';
import { notify } from '../../../../../components/common/Alert';

const MedicalReportView = () => {
  const navigate = useNavigate();
  const { id, publicId } = useParams();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [objectURL, setObjectURL] = useState<string>('');
  const printFile = usePrintFile();
  const { downloadFileByObjUrl } = useDownloadFile();

  const backHandler = () => {
    navigate(location.state.from, {
      state: {
        formFormatPublicId: location.state.selectedFormFormatPublicId,
        showingAll: location.state.showingAll,
      },
    });
  };

  const downloadToDevice = () => {
    if (objectURL) {
      downloadFileByObjUrl(
        objectURL,
        `${id}-${location.state.formFormatName}.pdf`
      );
    }
  };

  useEffect(() => {
    const getFilledFormPDFData = async () => {
      if (!publicId) return;
      setIsLoading(true);
      const [res, error] = await getFillFormPDF(publicId);
      if (error) {
        notify.error({
          message: ERROR_MESSAGE.GENERIC_TRY_AGAIN,
          title: 'Failed to fetch PDF',
        });
        // navigate(-1); TODO: going back twice - needs fix
      } else if (res) {
        const url = window.URL.createObjectURL(res);
        setObjectURL(url);
      }
      setIsLoading(false);
    };
    getFilledFormPDFData();
  }, []);

  return (
    <>
      <div className="border-b border-neutral-100 p-3 flex justify-between items-center">
        <Button
          variant={'outlined'}
          size={'small'}
          leadingIcon={<LeftArrowIcon />}
          onClick={backHandler}
        >
          Back
        </Button>
        {location.state.formFormatName && (
          <h1 className="text-neutral-900 font-medium whitespace-nowrap">
            {location.state.formFormatName}
          </h1>
        )}
        <div className="flex gap-3">
          <Button
            variant="outlined"
            size={'small'}
            leadingIcon={<DownloadIcon />}
            disabled={!objectURL || isLoading}
            onClick={downloadToDevice}
          >
            Download
          </Button>
          <Button
            variant="outlined"
            size={'small'}
            leadingIcon={<PrinterIcon />}
            disabled={!objectURL || isLoading}
            onClick={() => printFile(objectURL)}
          >
            Print
          </Button>
        </div>
      </div>
      <Slider variant={'vertical'} className="h-full">
        {isLoading || !objectURL ? (
          <div className="flex flex-col justify-center items-center h-[500px]">
            <img src={LoaderIcon} className="w-[40px] h-[40px] animate-spin" />
          </div>
        ) : (
          <PdfViewer pdfUrl={objectURL} />
        )}
      </Slider>
    </>
  );
};

export default MedicalReportView;
