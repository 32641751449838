/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Slider } from '../../../UI';
import React, { useState } from 'react';
import StageCard from './StageCard';
import { Plus } from 'lucide-react';
import { AlertDialog, notify } from '../../../common';
import { useParams } from 'react-router-dom';
import {
  ERROR_MESSAGE,
  ERROR_TITLE,
  SUCCESS_TITLE,
} from '../../../../constants/NotificationConstants';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { createEvent } from '../../../../services/api/endPoints/events';
const StageColum: React.FC<StageColumProps> = ({
  events,
  stage,
  setEventToUpdateId,
  removeArchivedEvent,
  handleAddUnarchiveEvent,
  showPatient,
  showNoOfEvents,
  enableAddToPathway,
  pathway,
  addToPathway,
}) => {
  const [openAddToPathwayModal, setOpenAddToPathwayModal] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleDragStart = (
    e: React.DragEvent<HTMLDivElement>,
    event: BoardEvent
  ) => {
    setEventToUpdateId(event.eventPublicId);
    e.dataTransfer.setData('text/plain', event.eventPublicId);
  };
  const user = useSelector<RootState, UserEntities>(state => state.user.user);
  const currentStageEvents = events.filter(
    event => event.currentStage.publicId === stage.publicId
  );
  const { id } = useParams();
  const addHandler = async () => {
    if (!enableAddToPathway || !pathway) return;
    if (!id) {
      notify.error({
        title: 'Patient ID not found',
        message: ERROR_MESSAGE.GENERIC_TRY_AGAIN,
      });
      return;
    }
    setIsLoading(true);
    const data: CreateEvent = {
      staffPublicId: user.staff.publicId,
      patientPublicId: id,
      pathwayPublicId: pathway.publicId,
    };
    const [response, error] = await createEvent(data);
    if (response) {
      notify.success({
        title: SUCCESS_TITLE.GENERIC_PATIENT_ADD_TO_PATHWAY,
        message: `Patient added to ${pathway.name}`,
      });
      addToPathway!(response.data);
    } else {
      notify.error({
        title: ERROR_TITLE.GENERIC_PATIENT_ADD_TO_PATHWAY,
        message:
          !error || !error.data ? ERROR_MESSAGE.GENERIC_TRY_AGAIN : error.data,
      });
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    setOpenAddToPathwayModal(false);
  };

  return (
    <>
      {enableAddToPathway && openAddToPathwayModal && pathway && (
        <AlertDialog
          type="default"
          actionButtonText="Add"
          alertTitle={'Add to pathway'}
          onClose={() => setOpenAddToPathwayModal(false)}
          open={openAddToPathwayModal}
          onAction={addHandler}
          isActionStarted={isLoading}
        >
          Are you sure you want to add this patient to {pathway.name}?
        </AlertDialog>
      )}
      <div className="h-full min-h-[48px] w-[300px] p-3 rounded-lg border border-neutral-100 bg-neutral-50 space-y-2 flex flex-col">
        <div className="flex item-center gap-2">
          <p className="font-medium  text-[14px]">{stage.name}</p>
          {showNoOfEvents && (
            <div className="border border-neutral-100 rounded-full h-[24px] w-[24px] flex items-center justify-center p-2">
              <p className="text-[14px] font-semibold leading-[0px]">
                {currentStageEvents.length}
              </p>
            </div>
          )}
        </div>
        <Slider variant={'vertical'} className="flex-1 gap-2">
          {currentStageEvents.map(event => (
            <StageCard
              key={event.eventPublicId}
              task={event}
              onDragStart={handleDragStart}
              removeArchivedEvent={removeArchivedEvent}
              showPatient={showPatient}
              handleAddUnarchiveEvent={handleAddUnarchiveEvent}
            />
          ))}
          {enableAddToPathway && (
            <Button
              variant={'link'}
              className="w-full justify-start bg-neutral-100/40 rounded text-neutral-600"
              leadingIcon={<Plus size={17}/>}
              onClick={() => setOpenAddToPathwayModal(true)}
            >
              Add to pathway
            </Button>
          )}
        </Slider>
      </div>
    </>
  );
};

export default StageColum;
