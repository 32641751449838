import React, { FC } from 'react';
import { THEME } from '../../constants/ColorConstants';

interface SuccessStepIconProps {
  width?: number;
  height?: number;
  stroke?: string;
}

const SuccessStepIcon: FC<SuccessStepIconProps> = ({
  width = 36,
  height = 37,
  stroke = THEME.PRIMARY_500,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 36 37"
      fill="none"
    >
      <rect y="0.5" width="36" height="36" rx="18" fill={stroke} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.5485 12.8515C26.0171 13.3201 26.0171 14.0799 25.5485 14.5485L15.9485 24.1485C15.4799 24.6172 14.7201 24.6172 14.2514 24.1485L9.45145 19.3485C8.98282 18.8799 8.98282 18.1201 9.45145 17.6515C9.92008 17.1828 10.6799 17.1828 11.1485 17.6515L15.1 21.6029L23.8514 12.8515C24.3201 12.3828 25.0799 12.3828 25.5485 12.8515Z"
        fill={THEME.SHADE_0}
      />
    </svg>
  );
};

export default SuccessStepIcon;
