import { Button, Input, Label } from '../../../../components/UI';
import LeftIcon from '../../../../assets/icons/LeftIcon';
import React, { useState } from 'react';
import { PenIcon, SearchIcon } from '../../../../assets/icons';
import { TextEditor } from '../../../../components/common';

const EmailManagerPage = () => {
  const [value, setValue] = useState('');

  const handleChange = (newValue: string) => {
    setValue(newValue);
  };
  const searchData = [
    {
      name: 'Patient Details',
      data: [
        {
          name: 'patient.name',
          description: 'Provides the name of the patient',
        },
        {
          name: 'patient.dob',
          description: 'Provides the dob of the patient',
        },
        {
          name: 'patient.gender',
          description: 'Provides the gender of the patient',
        },
        {
          name: 'patient.name',
          description: 'Provides the name of the patient',
        },
      ],
    },
    {
      name: 'Appointment Details',
      data: [
        {
          name: 'appointment.name',
          description: 'Provides the name of the appointment',
        },
        {
          name: 'appointment.start',
          description: 'Provides the start of the appointment',
        },
        {
          name: 'appointment.end',
          description: 'Provides the end of the appointment',
        },
        {
          name: 'appointment.date',
          description: 'Provides the date of the appointment',
        },
      ],
    },
  ];

  return (
    <div className="full-height">
      <div className="flex flex-row items-start justify-between p-2">
        <div className="flex flex-row items-start gap-3">
          <div
            className="border border-neutral-900 p-1 rounded"
            style={{ display: 'inline-flex' }}
          >
            <LeftIcon />
          </div>
          <div className="w-[25rem]">
            <h1 className="text text-neutral-900 font-semibold text-[14px]">
              Appointment confirmation email
            </h1>
            <p className="text text-neutral-300 text-[14px]">
              The confirmation email is sent when patient books an appointment
            </p>
          </div>
        </div>
        <div>
          <Button trailingIcon={<PenIcon />}>Edit Email</Button>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 pt-4">
        <div className="border border-neutral-100 rounded-lg p-3">
          <div>
            <Label>Title</Label>
            <Input placeholder="Title" inputSize="medium" />
          </div>
          <div className="pt-2">
            <Label>Subject</Label>
            <Input placeholder="Subject" inputSize="medium" />
          </div>
          <div className="pt-2">
            <Label>Content</Label>
            <TextEditor
              value={value}
              onChange={handleChange}
              height={400}
              placeholder="Enter your text here..."
            />
          </div>
        </div>
        <div className="border border-neutral-100 rounded-lg">
          <div className="p-3">
            <div>
              <h1 className="text text-neutral-800 font-semibold text-[14px]">
                The data points
              </h1>
              <p className="text text-neutral-300 text-[14px]">
                Provides the name of the patient
              </p>
            </div>
            <div className="pt-2">
              <Input
                leadingIcon={<SearchIcon />}
                placeholder="Search for data points"
                inputSize="small"
              />
            </div>
          </div>
          <hr />
          <div className="p-3">
            {searchData.map(data => (
              <div key={data.name}>
                <h1 className="text text-neutral-900 text-[14px] font-semibold">
                  {data.name}
                </h1>
                {data.data.map(innerData => (
                  <div
                    key={innerData.name}
                    className="mt-2 mb-2 px-2 py-1 border border-neutral-200 rounded-lg"
                  >
                    <h1 className="text text-neutral-900 text-[14px] font-semibold">
                      {innerData.name}
                    </h1>
                    <p className="text text-neutral-300 text-[14px]">
                      {innerData.description}
                    </p>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailManagerPage;
