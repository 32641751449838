import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: StaffState = { staffs: [] };

export const staffsSlice = createSlice({
  name: 'staffs',
  initialState,
  reducers: {
    addStaffs: (state, action: PayloadAction<StaffInformation[]>) => {
      state.staffs = action.payload;
    },
    deleteStaff: (state, action: PayloadAction<{ publicId: string }>) => {
      state.staffs = state.staffs.filter(
        staff => staff.publicId !== action.payload.publicId
      );
    },
    addStaff: (state, action: PayloadAction<StaffInformation>) => {
      state.staffs.push(action.payload);
    },
    updateStaff: (state, action: PayloadAction<StaffInformation>) => {
      const staffIndex = state.staffs.findIndex(
        staff => staff.publicId === action.payload.publicId
      );
      state.staffs[staffIndex] = action.payload;
    },
    storeProfilePictureBase64DataUrl: (state,action: PayloadAction<{publicId:string,profilePictureBase64DataUrl:string}>) => {
      const staffIndex = state.staffs.findIndex(
        staff => staff.publicId === action.payload.publicId
      );
      if (staffIndex === -1) return;
      if (action.payload.profilePictureBase64DataUrl)
        state.staffs[staffIndex].profilePictureBase64DataUrl = action.payload.profilePictureBase64DataUrl;
    }
  },
});

export default staffsSlice.reducer;
export const { addStaffs, deleteStaff, addStaff, updateStaff, storeProfilePictureBase64DataUrl } =
  staffsSlice.actions;
