import React from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../UI';

const ActionDropdown: React.FC<DropdownType> = ({
  drownDownItems,
  multipleDropDownSections,
  className,
  side,
  align,
  alignOffset,
  sideOffset,
  children,
}) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>
      <DropdownMenuContent
        side={side}
        align={align}
        alignOffset={alignOffset}
        sideOffset={sideOffset}
        className={className}
      >
        {!multipleDropDownSections &&
          drownDownItems &&
          drownDownItems.length > 0 &&
          drownDownItems.map((item, index) => (
            <DropdownMenuItem
              disabled={item.disabled}
              key={`${index}-${item.label}`}
              onClick={() => item.action()}
            >
              {item.Icon}
              {item.label}
            </DropdownMenuItem>
          ))}
        {multipleDropDownSections &&
          multipleDropDownSections.map(
            (dropdownItems: dropDownMenuItem[], idx) => (
              <>
                {dropdownItems.map((item, index) => (
                  <DropdownMenuItem
                    key={`${index}-${item.label}`}
                    onClick={() => item.action()}
                    disabled={item.disabled}
                  >
                    {item.Icon}
                    {item.label}
                  </DropdownMenuItem>
                ))}
                {idx !== multipleDropDownSections.length - 1 && (
                  <div className="h-[1px] w-full bg-neutral-100 my-2" />
                )}
              </>
            )
          )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default ActionDropdown;
