/* eslint-disable indent */
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../UI';
import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
  flexRender,
  SortingState,
  getSortedRowModel,
} from '@tanstack/react-table';
import Skeleton from 'react-loading-skeleton';

interface TableOptions {
  isSortable?: boolean;
  sortOrder?: 'asc' | 'desc';
  sortOnKey?: string;
}

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  tableClass?: string;
  tableHeadClass?: string;
  tableCellClass?: string;
  tableBodyRowClass?: string;
  tableHeadRowClass?: string;
  isLoading: boolean;
  loadingRecordCount?: number;
  tableOptions?: TableOptions;
}

const DataTable = <TData, TValue>({
  columns,
  data,
  tableClass = '',
  tableHeadClass = '',
  tableCellClass = '',
  tableBodyRowClass = '',
  tableHeadRowClass = '',
  isLoading = true,
  loadingRecordCount = 10,
  tableOptions = {},
}: DataTableProps<TData, TValue>) => {
  const { isSortable = true, sortOrder = 'asc', sortOnKey = '' } = tableOptions;
  const [sorting, setSorting] = React.useState<SortingState>([
    { id: sortOnKey, desc: sortOrder === 'desc' },
  ]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: isSortable ? setSorting : undefined,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  return !isLoading ? (
    <Table className={tableClass}>
      <TableHeader>
        {table.getHeaderGroups().map(headerGroup => (
          <TableRow key={headerGroup.id} className={tableHeadRowClass}>
            {headerGroup.headers.map(header => {
              return (
                <TableHead
                  key={header.id}
                  className={tableHeadClass}
                  onClick={() => {
                    if (isSortable && header.column.getCanSort()) {
                      header.column.toggleSorting();
                    }
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </TableHead>
              );
            })}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {table.getRowModel().rows?.length ? (
          table.getRowModel().rows.map(row => (
            <TableRow
              key={row.id}
              data-state={row.getIsSelected() && 'selected'}
              className={tableBodyRowClass}
            >
              {row.getVisibleCells().map(cell => (
                <TableCell key={cell.id} className={tableCellClass}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell
              colSpan={columns.length}
              className="h-24 text-center text-[14px]"
            >
              No results.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  ) : (
    <Table className={tableClass}>
      <TableHeader>
        {table.getHeaderGroups().map(headerGroup => (
          <TableRow key={headerGroup.id} className={tableHeadRowClass}>
            {headerGroup.headers.map(header => {
              return (
                <TableHead key={header.id} className={tableHeadClass}>
                  <Skeleton width={150} height={25} />
                </TableHead>
              );
            })}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {Array.from({ length: loadingRecordCount }, (_, index) => {
          return (
            <TableRow key={index + 'skeleton'} className={tableBodyRowClass}>
              {table.getHeaderGroups()[0].headers.map(() => (
                <TableCell key={index} className={tableCellClass}>
                  <Skeleton width={150} height={25} />
                </TableCell>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export { DataTable };
