/* eslint-disable @typescript-eslint/no-explicit-any */

import { useDispatch } from 'react-redux';
import { activateSnackbar } from '../redux/slice/snackbarSlice';
import { getContentType } from '../utils/files.utl';
import { downloadFileByUrl } from '../services/api/endPoints/files.api';
import { notify } from '../components/common/Alert';
import { ERROR_MESSAGE, ERROR_TITLE } from '../constants/NotificationConstants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DownloadFileResponse = [string | null, any | null];

type UseDownloadFile = {
  getFileSrc: (url: string) => Promise<DownloadFileResponse>;
  downloadFile: (url: string, fileName?: string) => Promise<void>;
  getSVGFile: (url: string) => Promise<DownloadFileResponse>;
  downloadFileByObjUrl: (blobUrl: string, fileName: string) => void;
};

export const useDownloadFile = (): UseDownloadFile => {
  const dispatch = useDispatch();

  const getFileSrc = async (url: string): Promise<DownloadFileResponse> => {
    try {
      const fileName = url.split('/').pop() || 'file';
      const [response, error] = await downloadFileByUrl({ url });
      if (response) {
        const blob = new Blob([response], { type: getContentType(fileName) });
        const blobUrl = URL.createObjectURL(blob);
        return [blobUrl, null];
      } else {
        return [null, error];
      }
    } catch (error: any) {
      return [null, error.response.data];
    }
  };

  const getSVGFile = async (url: string): Promise<DownloadFileResponse> => {
    try {
      const [response, error] = await downloadFileByUrl({ url });
      if (response) {
        const blobUrl = URL.createObjectURL(response);
        const svgResponse = await fetch(blobUrl);
        const svgContent = await svgResponse.text();
        return [svgContent, null];
      } else {
        return [null, error];
      }
    } catch (error: any) {
      return [null, error.response.data];
    }
  };

  const downloadFileByObjUrl = (blobUrl: string, fileName: string): void => {
    // Create a link element
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = fileName; // Set the downloaded file name
    // Programmatically trigger the download
    link.click();
    // Clean up the blob URL
    URL.revokeObjectURL(blobUrl);
  };

  const downloadFile = async (
    url: string,
    fileName?: string
  ): Promise<void> => {
    if (url) {
      let resolvedFileName = fileName;
      if (!resolvedFileName) {
        resolvedFileName = url.split('/').pop() || 'file';
      }
      const data: { url: string } = {
        url: url,
      };
      try {
        const [response, error] = await downloadFileByUrl(data);
        if (response) {
          // Set the appropriate Content-Type based on the file extension
          const blob = new Blob([response], {
            type: getContentType(resolvedFileName),
          });
          const blobUrl = URL.createObjectURL(blob);

          // Create a link element
          const link = document.createElement('a');
          link.href = blobUrl;
          link.download = resolvedFileName; // Set the downloaded file name
          // Programmatically trigger the download
          link.click();

          // Clean up the blob URL
          URL.revokeObjectURL(blobUrl);
          dispatch(
            activateSnackbar({
              active: true,
              message: 'FILE_DOWNLOADED_SUCCESSFULLY',
              variant: 'SNACKBAR_INFO',
            })
          );
        } else if (error) {
          notify.error({
            title: `Failed to download file '${resolvedFileName}'`,
            message: error.data,
          });
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        notify.error({
          title: `Failed to download file '${resolvedFileName}'`,
          message: error.response.data,
        });
      }
    } else {
      notify.error({
        title: ERROR_TITLE.GENERIC_DOWNLOAD_FILE,
        message: ERROR_MESSAGE.GENERIC_TRY_AGAIN,
      });
    }
  };

  return {
    getFileSrc,
    downloadFile,
    getSVGFile,
    downloadFileByObjUrl,
  };
};
