import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Slider } from '../UI';
import { GentlemanIcon, TeamIcon, OutlinedUserIcon } from '../../assets/icons';

const BoardLoader = ({
  showTopPathwayLoader = true,
}: {
  showTopPathwayLoader?: boolean;
}) => {
  return (
    <>
      {showTopPathwayLoader && (
        <div className="flex gap-3 p-3">
          <div
            className={
              ' py-1 px-1.5 rounded-[112px] flex items-center gap-2 cursor-pointer '
            }
          >
            <span className={'font-medium text-[14px] flex gap-2'}>
              <Skeleton width={170} height={30} />{' '}
              <Skeleton width={170} height={30} />
              <Skeleton width={170} height={30} />
            </span>
          </div>
        </div>
      )}
      <Slider variant={'horizontal'} className="p-3 gap-4 h-full">
        {Array.from({ length: 4 }, (_, index) => (
          <div
            key={index}
            className="h-full min-h-[48px] w-[300px] p-3 rounded-lg border border-neutral-100 bg-neutral-50 space-y-2 flex flex-col"
          >
            <div className="flex gap-2 items-center">
              <p className="font-medium text-[14px]">
                <Skeleton width={170} height={25} />
              </p>
            </div>
            <Slider variant={'vertical'} className="flex-grow gap-2">
              {Array.from({ length: 4 }, (_, index) => (
                <div
                  key={index}
                  className="cursor-grab p-4 rounded border border-neutral-100 shadow-[0px_2px_4px_0px_rgba(0,0,0,0.08)] bg-shades-0 space-y-2"
                >
                  <Skeleton width={170} height={25} />

                  <p className="font-medium text text-sm text-neutral-600 flex gap-2 items-center">
                    <GentlemanIcon stroke="currentColor" />
                    <span className="ml-2">
                      <Skeleton width={170} height={25} />
                    </span>
                  </p>
                  <div className="grid grid-cols-2">
                    <p className="flex items-center font-medium text text-sm text-neutral-600">
                      <OutlinedUserIcon stroke="currentColor" />
                      <span className="ml-2">
                        <Skeleton width={80} height={25} />
                      </span>
                    </p>
                    <p className="flex items-center font-medium text text-sm text-neutral-600">
                      <TeamIcon stroke="currentColor" />{' '}
                      <span className="ml-2">
                        <Skeleton width={80} height={25} />
                      </span>
                    </p>
                  </div>
                  <hr />
                  <div className="grid grid-cols-2">
                    <Skeleton width={80} height={25} />

                    <Skeleton width={80} height={25} />
                  </div>
                </div>
              ))}{' '}
            </Slider>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default BoardLoader;
