import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  Button,
  DialogFooter,
  Slider,
  Label,
  Input,
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
  Checkbox,
} from '../../../../UI';
import {
  CrossIcon,
  DownArrowIcon,
  OutlinedAddIcon,
} from '../../../../../assets/icons';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import {
  createPathway,
  getAllTeams,
  getPathwayById,
  updatePathway,
} from '../../../../../services/api';
import { getFullName } from '../../../../../utils/Helper';
import { useDispatch } from 'react-redux';
import {
  addPathway,
  updatePathway as updatePathwayRedux,
} from '../../../../../redux/slice';
import { notify } from '../../../../../components/common';
import {
  ERROR_TITLE,
  SUCCESS_TITLE,
} from '../../../../../constants/NotificationConstants';

const AddAndEditPathwayModal = ({
  onClose,
  open,
  ...props
}: AddAndEditPathwayModalProps) => {
  const [data, setData] = useState<PathwayFormInput>({
    name: '',
    pathwayStages: [
      {
        name: '',
        defaultAssigneePublicId: '',
        stageIndex: 0,
        defaultAssigneeTeamPublicId: '',
      },
    ],
  });
  const [formErrors, setFormErrors] = useState<{
    name: string | null;
    pathwayStageMinError: string | null;
    pathwayStages: {
      name: string | null;
      defaultAssigneePublicId: string | null;
      defaultAssigneeTeamPublicId: string | null;
    }[];
  }>({
    name: null,
    pathwayStageMinError: null,
    pathwayStages: [
      {
        name: null,
        defaultAssigneePublicId: null,
        defaultAssigneeTeamPublicId: null,
      },
    ],
  });
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const [lastStageIndex, setLastStageIndex] = useState<number>(0);
  const { staffs } = useSelector<RootState, StaffState>(state => state.staffs);
  const [teams, setTeams] = useState<Team[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [teamsError, setTeamsError] = useState<string | null>(null);
  const dispatch = useDispatch();
  useEffect(() => {
    const loadEditData = async () => {
      if (props.type !== 'Edit') return;
      setIsFetching(true);
      const [res, error] = await getPathwayById(props.selectedPathwayId);
      if (res) {
        const data: PathwayFormInput = {
          name: res.name,
          pathwayStages: res.pathwayStages.map(
            (pathwayStage: PathwayStages) => ({
              publicId: pathwayStage.publicId,
              name: pathwayStage.name,
              stageIndex: pathwayStage.stageIndex,
              defaultAssigneePublicId: pathwayStage.defaultAssignee
                ? pathwayStage.defaultAssignee.publicId
                : '',
              defaultAssigneeTeamPublicId: pathwayStage.defaultAssigneeTeam
                ? pathwayStage.defaultAssigneeTeam.publicId
                : '',
            })
          ),
        };
        setData(data);
        setLastStageIndex(res.pathwayStages.length - 1);
        setIsFetching(false);
      } else if (error) {
        notify.error({
          title: ERROR_TITLE.GENERIC_PATHWAY_FETCH,
          message: error.data,
        });
        setError(true);
      }
    };
    loadEditData();
    const getTeams = async () => {
      const [res, error] = await getAllTeams();
      if (res) {
        setTeams(res);
      } else {
        setTeamsError(error);
      }
    };
    getTeams();
  }, []);
  const addStageHandler = () => {
    setData(prev => ({
      ...prev,
      pathwayStages: [
        ...prev.pathwayStages,
        {
          name: '',
          defaultAssigneePublicId: '',
          stageIndex: lastStageIndex + 1,
          defaultAssigneeTeamPublicId: '',
        },
      ],
    }));
    setFormErrors(prev => ({
      ...prev,
      pathwayStageMinError: null,
      pathwayStages: [
        ...prev.pathwayStages,
        {
          name: null,
          defaultAssigneePublicId: null,
          defaultAssigneeTeamPublicId: null,
        },
      ],
    }));
    setLastStageIndex(prev => prev + 1);
  };

  const removeStageHandler = (indexToBeRemoved: number) => {
    const copiedData = { ...data };
    const errors = { ...formErrors };
    const newPathwayStagesErrorsTracker = [];
    const newPathwayStage: StageInput[] = [];
    for (let index = 0; index < copiedData.pathwayStages.length; index++) {
      const copiedStageData = { ...copiedData.pathwayStages[index] };
      index !== indexToBeRemoved &&
        newPathwayStage.push({
          ...copiedStageData,
          stageIndex:
            index > indexToBeRemoved
              ? copiedStageData.stageIndex - 1
              : copiedStageData.stageIndex,
        });
      index !== indexToBeRemoved &&
        newPathwayStagesErrorsTracker.push({
          ...errors.pathwayStages[index],
        });
    }
    copiedData.pathwayStages = newPathwayStage;
    errors.pathwayStages = newPathwayStagesErrorsTracker;
    if (newPathwayStage.length === 0) {
      errors.pathwayStageMinError = 'At least one pathway stage is required';
    }
    setData(copiedData);
    setFormErrors(errors);
    setLastStageIndex(prev => prev - 1);
  };
  const stageInputHandler = (
    index: number,
    name: keyof StageInput,
    value: any
  ) => {
    if (name === 'stageIndex') return;
    const copiedData = { ...data };
    const errors = { ...formErrors };
    if (name === 'finalStage') {
      copiedData.pathwayStages[index][name] = value as boolean;
    } else {
      copiedData.pathwayStages[index][name] = value;
      if (name !== 'publicId') errors.pathwayStages[index][name] = null;
    }
    setFormErrors(errors);
    setData(copiedData);
  };
  const inputHandler = (name: keyof PathwayFormInput, value: string) => {
    if (name === 'pathwayStages') return;
    setData(prev => ({
      ...prev,
      [name]: value,
    }));
    setFormErrors(prev => ({
      ...prev,
      name: null,
    }));
  };
  const isValidInputs = () => {
    const error = { ...formErrors };
    let isValid = true;
    if (!data.name || data.name === '') {
      error.name = 'Pathway name is required';
      isValid = false;
    }
    if (data.pathwayStages.length === 0) {
      error.pathwayStageMinError = 'At least one pathway stage is required';
      isValid = false;
    } else {
      for (const pathwayStage of data.pathwayStages) {
        if (!pathwayStage.name || pathwayStage.name === '') {
          error.pathwayStages[pathwayStage.stageIndex].name =
            'Stage name is required';
          isValid = false;
        }
        if (
          !pathwayStage.defaultAssigneePublicId ||
          pathwayStage.defaultAssigneePublicId === ''
        ) {
          error.pathwayStages[pathwayStage.stageIndex].defaultAssigneePublicId =
            'Assignee is required';
          isValid = false;
        }
        if (
          !pathwayStage.defaultAssigneeTeamPublicId ||
          pathwayStage.defaultAssigneeTeamPublicId === ''
        ) {
          error.pathwayStages[
            pathwayStage.stageIndex
          ].defaultAssigneeTeamPublicId = 'Assignee team is required';
          isValid = false;
        }
      }
    }
    setFormErrors(error);
    return isValid;
  };
  const submitHandler = async () => {
    if (!isValidInputs()) return;
    setIsLoading(true);
    if (props.type === 'Create') {
      const [res, error] = await createPathway(data);
      if (res) {
        dispatch(addPathway(getStructuredData(res)));
        notify.success({
          title: SUCCESS_TITLE.GENERIC_PATHWAY_ADD,
          message: `'${res.name}' added successfully`,
        });
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_PATHWAY_ADD,
          message: error.data,
        });
        setIsLoading(false);
        return;
      }
    } else if (props.type === 'Edit') {
      const updateData = { ...data, publicId: props.selectedPathwayId };
      const [res, error] = await updatePathway(updateData);
      if (res) {
        dispatch(updatePathwayRedux(getStructuredData(res)));
        notify.success({
          title: SUCCESS_TITLE.GENERIC_PATHWAY_UPDATE,
          message: `'${props.pathwayName}' updated successfully`,
        });
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_PATHWAY_UPDATE,
          message: error.data,
        });
        setIsLoading(false);
        return;
      }
    }
    setIsLoading(false);
    onClose();
  };
  const getStructuredData = (res: Pathway) => {
    const reduxData: PathwayLite = {
      name: res.name,
      publicId: res.publicId,
      pathwayStages: res.pathwayStages
        ? res.pathwayStages.map(pathwayStage => ({
          publicId: pathwayStage.publicId!,
          name: pathwayStage.name,
          stageIndex: pathwayStage.stageIndex,
          defaultAssigneePublicId: pathwayStage.defaultAssignee
            ? pathwayStage.defaultAssignee.publicId
            : '',
          defaultAssigneeTeamPublicId: pathwayStage.defaultAssigneeTeam
            ? pathwayStage.defaultAssigneeTeam.publicId
            : '',
          finalStage: pathwayStage.finalStage,
          maxWaitTimeDays: pathwayStage.maxWaitTimeDays,
        }))
        : [],
    };
    return reduxData;
  };
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="p-0 w-[65vw] md:w-[533px] flex flex-col gap-0 border border-neutral-100 max-h-[70%]">
        <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
          <h1 className="text-[14px] text-neutral-900 font-semibold">
            {props.type === 'Create' ? 'Add pathway' : 'Edit pathway'}
          </h1>
          <Button variant={'link'} size={'xmall'} onClick={onClose}>
            <CrossIcon />
          </Button>
        </DialogHeader>
        <Slider className="flex-1" variant={'vertical'}>
          <div className="p-4 space-y-3">
            <div className="space-y-1">
              <Label htmlFor="name">Pathway name</Label>
              <Input
                placeholder="Enter pathway name"
                name="name"
                disabled={props.type === 'Edit' && isFetching}
                value={data.name}
                hasError={!!formErrors.name}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  inputHandler('name', e.target.value);
                }}
              />
              {formErrors.name && (
                <small className="text-destructive-500 mt-2">
                  {formErrors.name}
                </small>
              )}
            </div>
            <hr />
            <Label className="block" htmlFor="name">
              Stages
            </Label>
            {formErrors.pathwayStageMinError && (
              <small className="text-destructive-500 mt-2">
                {formErrors.pathwayStageMinError}
              </small>
            )}
            {data.pathwayStages.map((pathStage, index) => (
              <>
                <div key={pathStage.stageIndex} className="space-y-3">
                  <div className="space-y-1">
                    <div className="flex w-full justify-between items-center">
                      <Label htmlFor="name">Stage name</Label>
                      <Button
                        variant={'link'}
                        size={'xmall'}
                        className="p-0 h-[0px]"
                        onClick={() => removeStageHandler(index)}
                      >
                        <CrossIcon />
                      </Button>
                    </div>
                    <Input
                      placeholder="Enter stage name"
                      name="name"
                      hasError={!!formErrors.pathwayStages[index]?.name}
                      value={pathStage.name}
                      disabled={props.type === 'Edit' && isFetching}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        stageInputHandler(index, 'name', e.target.value)
                      }
                    />
                    {formErrors.pathwayStages[index]?.name && (
                      <small className="text-destructive-500">
                        {formErrors.pathwayStages[index]?.name}
                      </small>
                    )}
                  </div>
                  <div className="flex gap-3 w-full">
                    <div className="space-y-1 w-full">
                      <Label htmlFor="defaultAssigneePublicId">
                        Select assignee
                      </Label>
                      <Select
                        required
                        name="defaultAssigneePublicId"
                        onValueChange={value =>
                          stageInputHandler(
                            index,
                            'defaultAssigneePublicId',
                            value
                          )
                        }
                        disabled={props.type === 'Edit' && isFetching}
                        value={pathStage.defaultAssigneePublicId}
                      >
                        <SelectTrigger
                          className="w-full"
                          hasError={!!formErrors.pathwayStages[index]?.defaultAssigneePublicId}
                          selectIcon={<DownArrowIcon />}
                        >
                          <SelectValue placeholder="Select" />
                        </SelectTrigger>
                        <SelectContent className="bg-shades-0 z-[999999]">
                          {staffs.map(staff => (
                            <SelectItem
                              key={staff.publicId}
                              value={staff.publicId}
                            >
                              {getFullName(staff)}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      {formErrors.pathwayStages[index]?.defaultAssigneePublicId && (
                        <small className="text-destructive-500">
                          {
                            formErrors.pathwayStages[index]
                              .defaultAssigneePublicId
                          }
                        </small>
                      )}
                    </div>
                    <div className="space-y-1 w-full">
                      <Label htmlFor="defaultAssigneeTeamPublicId">
                        Select team
                      </Label>
                      <Select
                        required
                        name="defaultAssigneeTeamPublicId"
                        onValueChange={value =>
                          stageInputHandler(
                            index,
                            'defaultAssigneeTeamPublicId',
                            value
                          )
                        }
                        disabled={props.type === 'Edit' && isFetching}
                        value={pathStage.defaultAssigneeTeamPublicId}
                      >
                        <SelectTrigger
                          className="w-full"
                          hasError={!!formErrors.pathwayStages[index]?.defaultAssigneePublicId}
                          selectIcon={<DownArrowIcon />}
                        >
                          <SelectValue placeholder="Select" />
                        </SelectTrigger>
                        <SelectContent className="bg-shades-0 z-[999999]">
                          {!teamsError ? (
                            teams?.map(team => (
                              <SelectItem
                                key={team.publicId}
                                value={team.publicId}
                              >
                                {team.name}
                              </SelectItem>
                            ))
                          ) : (
                            <p className="text-[14px] font-semibold">
                              {teamsError}
                            </p>
                          )}
                        </SelectContent>
                      </Select>
                      {formErrors.pathwayStages[index]?.defaultAssigneeTeamPublicId && (
                        <small className="text-destructive-500">
                          {
                            formErrors.pathwayStages[index]
                              .defaultAssigneeTeamPublicId
                          }
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-between items-center">
                    <div className="flex items-center gap-1 text-neutral-600">
                      <Checkbox
                        checked={pathStage.finalStage}
                        onCheckedChange={check =>
                          stageInputHandler(index, 'finalStage', !!check)
                        }
                      />{' '}
                      Mark stage as final
                    </div>
                  </div>
                </div>
                {index !== data.pathwayStages.length - 1 && <hr />}
              </>
            ))}

            <div className="w-full flex justify-end">
              <Button
                trailingIcon={<OutlinedAddIcon stroke="currentColor" />}
                variant={'outlined'}
                size={'xmall'}
                onClick={addStageHandler}
                disabled={props.type === 'Edit' && isFetching}
              >
                Add stage
              </Button>
            </div>
          </div>
        </Slider>
        <DialogFooter>
          <Button
            variant={'link'}
            size={'small'}
            className="w-full"
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            size={'small'}
            onClick={submitHandler}
            className="w-full"
            variant={'primary'}
            isLoading={isLoading}
            disabled={
              isLoading || error || (props.type === 'Edit' && isFetching)
            }
          >
            {props.type === 'Create' ? 'Add pathway' : 'Save pathway'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AddAndEditPathwayModal;
