import { sortTreatmentCycles } from '../../utils/Helper';
import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';

const initialState: TreatmentCycleState = { treatmentCycles: [] };

export const treatmentCyclesSlice = createSlice({
  name: 'treatmentCycles',
  initialState,
  reducers: {
    addTreatmentCycles: (state, action: PayloadAction<TreatmentCycle[]>) => {
      state.treatmentCycles = sortTreatmentCycles(action.payload);
    },
    deleteTreatmentCycle: (
      state,
      action: PayloadAction<{ publicId: string }>
    ) => {
      state.treatmentCycles = state.treatmentCycles.filter(
        treatmentCycle => treatmentCycle.publicId !== action.payload.publicId
      );
    },
    addTreatmentCycle: (state, action: PayloadAction<TreatmentCycle>) => {
      state.treatmentCycles.push(action.payload);
      state.treatmentCycles = sortTreatmentCycles(current(state.treatmentCycles) as TreatmentCycle[]);
    },
    updateTreatmentCycle: (state, action: PayloadAction<TreatmentCycle>) => {
      const siteIndex = state.treatmentCycles.findIndex(
        treatmentCycle => treatmentCycle.publicId === action.payload.publicId
      );
      state.treatmentCycles[siteIndex] = action.payload;
      state.treatmentCycles = sortTreatmentCycles(current(state.treatmentCycles) as TreatmentCycle[]);
    },
    toggleCancelRestartTreatmentCycle: (
      state,
      action: PayloadAction<{ publicId: string }>
    ) => {
      const siteIndex = state.treatmentCycles.findIndex(
        treatmentCycle => treatmentCycle.publicId === action.payload.publicId
      );
      if (state.treatmentCycles[siteIndex].cancelledDate) {
        delete state.treatmentCycles[siteIndex].cancelledDate;
      } else {
        state.treatmentCycles[siteIndex].cancelledDate = new Date().toString();
      }
      state.treatmentCycles = sortTreatmentCycles(current(state.treatmentCycles) as TreatmentCycle[]);
    },
  },
});

export default treatmentCyclesSlice.reducer;
export const {
  addTreatmentCycles,
  deleteTreatmentCycle,
  addTreatmentCycle,
  updateTreatmentCycle,
  toggleCancelRestartTreatmentCycle
} = treatmentCyclesSlice.actions;
