import { THEME } from '../../constants/ColorConstants';
import React, { ReactNode } from 'react';

const BookingCalendarSidebarIcon = ({ isActive }: { isActive: Boolean }) => {
  const strokeColor = isActive
    ? THEME.SHADE_200
    : THEME.SHADE_0;
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9394 3.24121H3.24243C2.55625 3.24121 2 3.79746 2 4.48364V13.1806C2 13.8668 2.55625 14.423 3.24243 14.423H11.9394C12.6256 14.423 13.1818 13.8668 13.1818 13.1806V4.48364C13.1818 3.79746 12.6256 3.24121 11.9394 3.24121Z"
        stroke={strokeColor}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.0752 2V4.48485"
        stroke={strokeColor}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.10547 2V4.48485"
        stroke={strokeColor}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 6.96875H13.1818"
        stroke={strokeColor}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default BookingCalendarSidebarIcon;
