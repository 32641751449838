import React from 'react';

interface CustomIconProps {
  stroke?: string;
}

const FirstAidBoxIcon: React.FC<CustomIconProps> = ({ stroke = 'currentColor' }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3684 5.52941H2.63158C2.46407 5.52941 2.30343 5.60378 2.18499 5.73616C2.06654 5.86854 2 6.04808 2 6.23529V13.2941C2 13.4813 2.06654 13.6609 2.18499 13.7933C2.30343 13.9256 2.46407 14 2.63158 14H13.3684C13.5359 14 13.6966 13.9256 13.815 13.7933C13.9335 13.6609 14 13.4813 14 13.2941V6.23529C14 6.04808 13.9335 5.86854 13.815 5.73616C13.6966 5.60378 13.5359 5.52941 13.3684 5.52941ZM11.1579 2H4.84211V5.52941H11.1579V2Z"
      stroke={stroke}
      strokeWidth="1.3"
      strokeLinejoin="round"
    />
    <path
      d="M6.33301 9.66667H9.66634M7.99967 8V11.3333"
      stroke={stroke}
      strokeWidth="1.3"
      strokeLinecap="round"
    />
  </svg>
);

export default FirstAidBoxIcon;
