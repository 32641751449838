import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: SwivoClient = {
  client: {
    clientId: null,
    name: null,
    url: null,
    publicId: null,
    smallLogoUrl: null,
    pdfHeaderFormatUrl: null,
    pdfFooterFormatUrl: null,
    prescriptionPdfFormatUrl: null,
    recommendServiceEmailNotificationFormat: {
      subject: null,
      htmlBodyFormatUrl: null,
      from: null,
      replyTo: null,
      html: false,
    },
    prescriptionEmailNotificationFormat: {
      subject: null,
      htmlBodyFormatUrl: null,
      from: null,
      replyTo: null,
      html: false,
    },
    patientDetailsFormFormat: {
      publicId: '',
      name: '',
      visible: '',
      type: undefined,
    },
  },
};

export const clientSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clientDetails: (
      state: SwivoClient,
      action: PayloadAction<ClientEntities>
    ) => {
      state.client = action.payload;
    },
    removeClientDetails: () => initialState,
  },
});

export default clientSlice.reducer;
export const { clientDetails, removeClientDetails } = clientSlice.actions;