import { THEME } from '../../constants/ColorConstants';
import React, { ReactNode } from 'react';

const DashboardSidebarIcon = ({ isActive }: { isActive: Boolean }) => {
  const strokeColor = isActive
    ? THEME.SHADE_200
    : THEME.SHADE_0;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M6 2H2.66667C2.29848 2 2 2.29848 2 2.66667V7.33333C2 7.70152 2.29848 8 2.66667 8H6C6.36819 8 6.66667 7.70152 6.66667 7.33333V2.66667C6.66667 2.29848 6.36819 2 6 2Z"
        stroke={strokeColor}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3335 2H10.0002C9.63197 2 9.3335 2.29848 9.3335 2.66667V4.66667C9.3335 5.03486 9.63197 5.33333 10.0002 5.33333H13.3335C13.7017 5.33333 14.0002 5.03486 14.0002 4.66667V2.66667C14.0002 2.29848 13.7017 2 13.3335 2Z"
        stroke={strokeColor}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3335 8H10.0002C9.63197 8 9.3335 8.29848 9.3335 8.66667V13.3333C9.3335 13.7015 9.63197 14 10.0002 14H13.3335C13.7017 14 14.0002 13.7015 14.0002 13.3333V8.66667C14.0002 8.29848 13.7017 8 13.3335 8Z"
        stroke={strokeColor}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 10.6666H2.66667C2.29848 10.6666 2 10.9651 2 11.3333V13.3333C2 13.7015 2.29848 14 2.66667 14H6C6.36819 14 6.66667 13.7015 6.66667 13.3333V11.3333C6.66667 10.9651 6.36819 10.6666 6 10.6666Z"
        stroke={strokeColor}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DashboardSidebarIcon;
