/* eslint-disable @typescript-eslint/no-explicit-any */
import { URL } from '../requestConstructor';

export const triggerWixFetchCall = async (startDate: Date, toDate: Date) => {
  try {
    const response = await URL.post('/booking/loadWixBookings', {
      startDate: startDate,
      toDate: toDate,
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
};

export const getAppointmentsWithDate = async (formData: {
  fromDate: string;
  toDate: string;
}) => {
  try {
    const response = await URL.get('/booking/get', { params: formData });
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const getAppointmentByPatientId = async (id: string) => {
  try {
    const response = await URL.get(`/booking/get?patientPublicId=${id}`);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const getNextBookingByPatientId = async (id: string) => {
  try {
    const response = await URL.get(
      `/booking/get?patientPublicId=${id}&nextBooking=true`
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const createNewBooking = async (data: CreateBookingRequest) => {
  try {
    const response = await URL.post('/booking/create', data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response ? error.response.data : error.message];
  }
};

export const getResourceBookings = async (formData: {
  resourcePublicId?: string;
  fromDate?: Date | '';
  toDate?: Date | '';
  resourceOnly?: boolean;
  blocked?: boolean;
}) => {
  try {
    const response = await URL.get('/booking/get', { params: formData });
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const updateBooking = async (event: CreateBookingRequest) => {
  try {
    const response = await URL.put('booking/update', event);
    return [response?.data, null];
  } catch (error: any) {
    return [null, error.response ? error.response.data : error.message];
  }
};
