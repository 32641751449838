import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { uploadFile } from '../../../../../services/api';
import { notify, UploadProfilePictureCamera } from '../../../../common';
import '../../../Scanner/constants/cropper.css';
import { v4 as uuidv4 } from 'uuid';
const StaffProfilePictureCamera = () => {
  const navigate = useNavigate();
  const [loading, isLoading] = useState<boolean>(false);
  const location = useLocation();

  const onUpload = async (file: File) => {
    const profilePictureFileData = new FormData();
    profilePictureFileData.append('containerName', 'patient_profile_pictures');
    const fileName = location.state.fileName
      ? location.state.fileName
      : uuidv4();
    profilePictureFileData.append(
      'fileName',
      `${fileName}.${file.name.split('.').pop()}`
    );
    profilePictureFileData.append('file', file);
    isLoading(true);
    const [res, error] = await uploadFile(profilePictureFileData);
    if (error) {
      notify.error({
        title: 'Unable to upload the image',
        message: error.data,
      });
      isLoading(false);
      return;
    }
    isLoading(false);
    navigate(location.state.from, {
      state: { profilePictureUrl: res },
    });
  };

  const backHandler = () => {
    navigate(location.state.from, {
      state: { profilePictureUrl: '' },
    });
  };

  return (
    <UploadProfilePictureCamera
      onUpload={onUpload}
      loading={loading}
      backHandler={backHandler}
      heading="Capture staff profile picture"
    />
  );
};

export default StaffProfilePictureCamera;
