/* eslint-disable @typescript-eslint/no-explicit-any */
import { URL } from '../../requestConstructor';
import { FormatType } from '../../../../types/form/type';

export const getFormFormatByType = async (type: FormatType) => {
  try {
    const response = await URL.get(`/form/format/get?type=${type}`);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const getFormFormatById = async (publicId: string) => {
  try {
    const response = await URL.get(`/form/format/get?publicId=${publicId}`);
    return [response.data, null];
  } catch (error: any) {
    return [null, error?.response];
  }
};

export const getHandWrittenFormFormat = async (publicId: string) => {
  try {
    const res = await URL.get(
      `/form/format/handwritten/get?publicId=${publicId}`
    );
    return [res.data, null];
  } catch (error) {
    return [null, error];
  }
};
