import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  Button,
  DialogFooter,
  Label,
  Input,
  Slider,
} from '../../../../UI';
import { CrossIcon } from '../../../../../assets/icons';
import AutoComplete from '../../../../../components/UI/AutoComplete';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import {
  createNewSite,
  getSite,
  updateSite,
} from '../../../../../services/api';
import { notify } from '../../../../../components/common';
import {
  addSite,
  updateSite as updateSiteReducer,
} from '../../../../../redux/slice';
import { useDispatch } from 'react-redux';
import {
  ERROR_TITLE,
  SUCCESS_TITLE,
} from '../../../../../constants/NotificationConstants';
type AddAndEditModalProps = {
  open: boolean;
  onClose: () => void;
} & (
  | {
      type: 'Create';
    }
  | {
      type: 'Edit';
      selectedSiteId: string | null;
    }
);
const AddAndEditSiteModal = ({
  open,
  onClose,
  ...props
}: AddAndEditModalProps) => {
  const dispatch = useDispatch();

  const [siteOldName, setSiteOldName] = useState<string>('');
  const user = useSelector<RootState, UserEntities>(state => state.user.user);
  const [data, setData] = useState<SiteFormInput>({
    name: '',
    staff: user.staff,
  });
  const [formErrors, setFormErrors] = useState<
    Record<keyof SiteFormInput, string | null>
  >({
    name: null,
    staff: null,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const staffs = useSelector<RootState, StaffInformation[]>(
    state => state.staffs.staffs
  );

  useEffect(() => {
    const setSiteDetails = async () => {
      if (props.type !== 'Edit') return;
      if (!props.selectedSiteId) {
        notify.error({
          title: ERROR_TITLE.GENERIC_SITE_FETCH,
          message: 'Site Id not found. Please try again',
        });
        return;
      }
      const [res, error] = await getSite(props.selectedSiteId);
      if (res) {
        const site: Site = res;
        setData({
          name: site.name,
          staff: site.ownerStaff,
        });
        setSiteOldName(site.name);
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_SITE_FETCH,
          message: error.data,
        });
      }
    };
    setSiteDetails();
  }, []);
  const isValidInputs = () => {
    const error = { ...formErrors };
    let isValid = true;
    if (!data.name || data.name == '') {
      error['name'] = 'Site name is required';
      isValid = false;
    }
    if (!data.staff || Object.keys(data.staff).length === 0) {
      error['staff'] = 'Head staff is required';
      isValid = false;
    }
    setFormErrors(error);
    return isValid;
  };
  const onSubmit = async () => {
    if (!isValidInputs()) return;
    setIsLoading(true);
    if (props.type === 'Create') {
      const obj = {
        name: data.name,
        ownerStaffPublicId: data.staff.publicId,
      };
      const [response, error] = await createNewSite(obj);
      onClose();
      if (response) {
        notify.success({
          title: SUCCESS_TITLE.GENERIC_SITE_ADD,
          message: `'${siteOldName}' created successfully`,
        });
        dispatch(addSite(response));
        onClose();
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_SITE_ADD,
          message: error.data,
        });
      }
    } else if (props.type == 'Edit') {
      const obj = {
        publicId: props.selectedSiteId,
        name: data.name,
        ownerStaffPublicId: data.staff.publicId,
      };
      const [response, error] = await updateSite(obj);
      onClose();
      if (response) {
        notify.success({
          title: SUCCESS_TITLE.GENERIC_SITE_UPDATE,
          message: `'${siteOldName}' updated successfully`,
        });
        dispatch(updateSiteReducer(response));
        onClose();
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_SITE_UPDATE,
          message: error.data,
        });
      }
    }
  };
  const inputHandler = (name: keyof SiteFormInput, value: any) => {
    const error = { ...formErrors };
    error[name] = null;
    setData(prev => ({
      ...prev,
      [name]: value,
    }));
    setFormErrors(error);
  };
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="p-0 w-[65vw] md:w-[533px] flex flex-col gap-0 border border-neutral-100 max-h-[80%]">
        <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
          <h1 className="text-[14px] text-neutral-900 font-semibold">
            {props.type === 'Edit' ? 'Edit Site' : 'Add Site'}
          </h1>
          <Button variant={'link'} size={'xmall'} onClick={onClose}>
            <CrossIcon />
          </Button>
        </DialogHeader>
        <Slider className="flex-1" variant={'vertical'}>
          <div className="p-4 space-y-4">
            <div className="space-y-1">
              <Label htmlFor="siteName">Site name</Label>
              <Input
                placeholder="Enter site name"
                name="siteName"
                value={data.name}
                onChange={e => inputHandler('name', e.target.value)}
                hasError={!!formErrors.name}
              />
              {formErrors.name && (
                <small className="text-destructive-500">
                  {formErrors.name}
                </small>
              )}
            </div>
            <div className="space-y-1">
              <Label htmlFor="headStaff">Head staff</Label>
              <AutoComplete
                setSelectedValue={values => inputHandler('staff', values[0])}
                selectedValue={[data.staff]}
                options={staffs}
                labelField={'fullName'}
                valueField={'publicId'}
                placeholder={'Select Staff'}
                hasError={!!formErrors.staff}
              />
              {formErrors.staff && (
                <small className="text-destructive-500">
                  {formErrors.staff}
                </small>
              )}
            </div>
          </div>
        </Slider>
        <DialogFooter>
          <Button
            variant={'link'}
            size={'small'}
            className="w-full"
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            size={'small'}
            variant={'primary'}
            onClick={onSubmit}
            className="w-full"
            isLoading={isLoading}
            disabled={isLoading}
          >
            {props.type === 'Edit' ? 'Save site' : 'Add site'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AddAndEditSiteModal;
