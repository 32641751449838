import React from 'react';
import { THEME } from '../../constants/ColorConstants';

interface RedoIconProps {
  width?: number;
  height?: number;
  color?: string;
}

const RedoIcon: React.FC<RedoIconProps> = ({
  width = 16,
  height = 16,
  color = THEME.NEUTRAL_900,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M11.333 1.33398L13.9997 4.00065L11.333 6.66732"
      stroke={color}
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 7.33333V6.66667C2 5.95942 2.28095 5.28115 2.78105 4.78105C3.28115 4.28095 3.95942 4 4.66667 4H14"
      stroke={THEME.SHADE_100}
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.66667 14.6673L2 12.0007L4.66667 9.33398"
      stroke={THEME.SHADE_100}
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 8.66602V9.33268C14 10.0399 13.719 10.7182 13.219 11.2183C12.7189 11.7184 12.0406 11.9993 11.3333 11.9993H2"
      stroke={THEME.SHADE_100}
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RedoIcon;
