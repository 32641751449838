/* eslint-disable quotes */
import { ColumnDef } from '@tanstack/react-table';
import {
  DeleteIcon,
  DirectedArrowIcon,
  DotMenu,
  DownloadIcon,
  // FilterIcon,
  OpenEyeIcon2,
  OutlinedAddIcon,
  SendIcon,
} from '../../../../../assets/icons';
import { Button, Slider, buttonVariants } from '../../../../UI';
import React, { ReactNode, useEffect, useState } from 'react';
import { ActionDropdown, DataTable, AlertDialog } from '../../../../common';
import { Link, useParams } from 'react-router-dom';
import {
  deletePrescription,
  downloadPrescription,
  getPrescriptions,
  sendPrescription,
} from '../../../../../services/api';
import { getSimpleDate } from '../../../../../utils/date.utl';
import { notify } from '../../../../common/Alert';
import {
  ERROR_MESSAGE,
  ERROR_TITLE,
} from '../../../../../constants/NotificationConstants';
import { THEME } from '../../../../../constants/ColorConstants';

const Prescriptions = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [prescriptions, setPrescriptions] = useState<PrescriptionUIType[]>([]);
  const [buttonLoading, setButtonLoading] = useState<Map<string, boolean>>(
    new Map<string, boolean>()
  );
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [selectedDeletePrescription, setSelectedDeletePrescription] =
    useState<PrescriptionUIType | null>(null);
  const [isDeleteModalOpen, setIsDeleModalOpen] = useState<boolean>(false);
  const toggleButtonLoading = (btnRowId: string, state: boolean) => {
    const newBtnLoading = new Map<string, boolean>(buttonLoading);
    newBtnLoading.set(btnRowId, state);
    setButtonLoading(newBtnLoading);
  };

  const removePrescription = (idToRemove: string) => {
    const updatedPrescriptions = prescriptions.filter(
      prescription => prescription.id !== idToRemove
    );
    setPrescriptions(updatedPrescriptions);
  };

  const prescriptionColumnDef: ColumnDef<PrescriptionUIType>[] = [
    {
      accessorKey: 'id',
      header: 'Id',
    },
    {
      accessorKey: 'dateCreated',
      header: ({ column }) => {
        return (
          <button
            className="flex gap-1 items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Date Created
            {column.getIsSorted() === 'asc' ? (
              <div className="rotate-180">
                <DirectedArrowIcon fill={THEME.NEUTRAL_500} />{' '}
              </div>
            ) : (
              <DirectedArrowIcon fill={THEME.NEUTRAL_500} />
            )}
          </button>
        );
      },
      cell: ({ row }) => {
        return <span>{getSimpleDate(row.original.dateCreated)}</span>;
      },
    },
    {
      accessorKey: 'createdBy',
      header: 'Created By',
    },
    {
      id: 'actions',
      cell: ({ row }) => {
        const downloadSavePrescription = async () => {
          const btnLoadingKey = `Download_${row.original.id}`;
          toggleButtonLoading(btnLoadingKey, true);
          const [res, err] = await downloadPrescription(row.original.id);
          if (err) {
            notify.error({
              message: ERROR_MESSAGE.GENERIC_TRY_AGAIN,
              title: 'Failed to download prescription',
            });
            toggleButtonLoading(row.original.id, false);
            return;
          }

          const url = window.URL.createObjectURL(res);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${row.original.id}.pdf`);
          document.body.appendChild(link);
          link.click();
          link.parentNode?.removeChild(link);
          notify.success({
            title: 'Prescription downloaded successfully',
          });
          toggleButtonLoading(row.original.id, false);
        };

        const sendPrescriptionToPatient = async () => {
          const btnLoadingKey = `Send_${row.original.id}`;
          toggleButtonLoading(btnLoadingKey, true);
          const [res, err] = await sendPrescription(row.original.id);
          if (err) {
            notify.error({
              title: 'Failed to send prescription',
              message: err.data,
            });
          } else if (res) {
            notify.success({
              title: 'Prescription sent successfully',
              message: 'Patient will receive the prescription in an email',
            });
          }
          toggleButtonLoading(row.original.id, false);
        };

        const dropDownMenu: {
          label: string;
          Icon: ReactNode;
          action: () => void;
        }[] = [
          {
            label: 'Delete',
            Icon: <DeleteIcon stroke={THEME.DESTRUCTIVE_500} />,
            action: () => {
              setSelectedDeletePrescription(row.original);
              setIsDeleModalOpen(true);
            },
          },
        ];
        return (
          <div className="flex justify-end">
            <div className="flex gap-8">
              <div className="flex gap-2">
                <Link
                  className={buttonVariants({
                    variant: 'outlined',
                    size: 'xmall',
                  })}
                  to={'view/' + row.original.id}
                >
                  View <OpenEyeIcon2 />
                </Link>
                <Button
                  variant={'outlined'}
                  className="h-[30px]"
                  size={'xmall'}
                  trailingIcon={<DownloadIcon />}
                  isLoading={buttonLoading.get(`Download_${row.original.id}`)}
                  onClick={downloadSavePrescription}
                >
                  Download
                </Button>
                <Button
                  variant={'outlined'}
                  className="h-[30px]"
                  size={'xmall'}
                  trailingIcon={<SendIcon />}
                  isLoading={buttonLoading.get(`Send_${row.original.id}`)}
                  onClick={sendPrescriptionToPatient}
                >
                  Send
                </Button>
              </div>
              <ActionDropdown
                drownDownItems={dropDownMenu}
                side="bottom"
                align="end"
                sideOffset={3}
                alignOffset={18}
              >
                <button className="w-8 h-8 p-0 outline-none border-none">
                  <span className="sr-only">Open menu</span>
                  <DotMenu />
                </button>
              </ActionDropdown>
            </div>
          </div>
        );
      },
    },
  ];

  const deletePrescriptionForPatient = async () => {
    if (!selectedDeletePrescription) return;
    setIsDeleting(true);
    const [res, err] = await deletePrescription(selectedDeletePrescription.id);
    if (err) {
      notify.error({
        message: err.data,
        title: 'Failed to delete prescription',
      });
      setIsDeleting(false);
      return;
    } else if (res) {
      removePrescription(selectedDeletePrescription.id);
      notify.success({
        title: 'Prescription deleted successfully',
      });
      closeDeleteModalHandler();
    }
  };
  const closeDeleteModalHandler = () => {
    setSelectedDeletePrescription(null);
    setIsDeleting(false);
    setIsDeleModalOpen(false);
  };

  useEffect(() => {
    const fetchPrescriptions = async () => {
      try {
        if (id) {
          setIsLoading(true);
          const [res, err] = await getPrescriptions('', id, '');
          if (err) {
            notify.error({
              message: err.data,
              title: ERROR_TITLE.GENERIC_PRESC,
            });
            return;
          }

          const convPrescriptions: PrescriptionUIType[] = res.map(
            (prescription: Prescription) => ({
              id: prescription.publicId,
              dateCreated:
                prescription.whenCreated && new Date(prescription.whenCreated),
              createdBy: prescription.staff.fullName,
            })
          );
          //sort by date created
          convPrescriptions.sort(
            (a, b) =>
              (b.dateCreated && b.dateCreated.getTime()) -
              (a.dateCreated && a.dateCreated.getTime())
          );
          setPrescriptions(convPrescriptions);

          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        notify.error({
          message: 'Please try again later.',
          title: 'Failed to fetch prescriptions',
        });
      }
    };
    fetchPrescriptions();
  }, []);

  return (
    <>
      {isDeleteModalOpen && selectedDeletePrescription && (
        <AlertDialog
          type="delete"
          open={isDeleteModalOpen}
          onClose={closeDeleteModalHandler}
          onAction={deletePrescriptionForPatient}
          alertTitle={`Deleting prescription ID - ${selectedDeletePrescription.id}`}
          isActionStarted={isDeleting}
          actionButtonText={isDeleting ? 'Deleting' : 'Delete'}
        >
          Are you sure you want to delete prescription with id -{' '}
          {selectedDeletePrescription.id} ?
        </AlertDialog>
      )}
      <div>
        <div className="p-3 flex justify-between items-center">
          <h1 className="text-neutral-900 font-medium whitespace-nowrap">
            Prescriptions
          </h1>
          <div className="flex gap-3">
            {/* <Button
            className="inline-flex"
            variant={'link'}
            size={'xmall'}
            trailingIcon={<FilterIcon />}
          >
            Filter by
          </Button> */}
            <Link
              className={buttonVariants({ variant: 'primary', size: 'xmall' })}
              to="create"
            >
              <OutlinedAddIcon /> Add Prescription
            </Link>
          </div>
        </div>
        <Slider variant={'vertical'} className="h-[500px]">
          <DataTable
            columns={prescriptionColumnDef}
            data={prescriptions}
            isLoading={isLoading}
          />
        </Slider>
      </div>
    </>
  );
};

export default Prescriptions;
