import { THEME } from '../../constants/ColorConstants';
import React, { FC } from 'react';

interface CrossStepIconProps {
  width?: number;
  height?: number;
  stroke?: string;
}

const CrossStepIcon: FC<CrossStepIconProps> = ({
  width = 36,
  height = 37,
  stroke = THEME.DESTRUCTIVE_700,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <rect width="36" height="36" rx="18" fill={stroke} />
      <path
        fill="none"
        stroke={THEME.SHADE_0}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M11 11l14 14m0-14L11 25"
      />
    </svg>
  );
};

export default CrossStepIcon;
