import React, { useState, useMemo, useEffect } from 'react';
import { AlertDialog, DataTable, notify } from '../../../../common';
import { Button, Slider } from '../../../../UI';
import {
  AddIcon,
  DeleteIcon,
  DirectedArrowIcon,
} from '../../../../../assets/icons';
import { ColumnDef } from '@tanstack/react-table';
import AddAndEditSiteModal from './AddAndEditSiteModal';
import { deleteSite, getAllSites } from '../../../../../services/api';
import { addSites } from '../../../../../redux/slice/sitesSlice';
import { deleteSite as deleteSiteReducer } from '../../../../../redux/slice/sitesSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { ERROR_TITLE, SUCCESS_TITLE } from '../../../../../constants/NotificationConstants';
import { Edit2 } from 'lucide-react';
import { THEME } from '../../../../../constants/ColorConstants';

const Sites = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAddSiteModalOpen, setIsAddSiteModalOpen] = useState<boolean>(false);
  const [isEditSiteModalOpen, setIsEditSiteModalOpen] =
    useState<boolean>(false);
  const [selectedSiteId, setSelectedSiteId] = useState<string | null>(null);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [selectedDeleteSite, setSelectedDeleteSite] = useState<Site | null>(
    null
  );
  const sites = useSelector<RootState, Site[]>(state => state.sites.sites);
  const getSites = async () => {
    const [sites, error] = await getAllSites();
    if (sites) {
      dispatch(addSites(sites));
    } else {
      notify.error({
        title: ERROR_TITLE.GENERIC_ALL_SITE_FETCH,
        message: error.data,
      });
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getSites();
  }, []);
  const deleteSites = async () => {
    if (!selectedDeleteSite) return;
    setIsDeleting(true);
    const [, err] = await deleteSite(selectedDeleteSite.publicId);
    if (!err) {
      dispatch(deleteSiteReducer({ publicId: selectedDeleteSite.publicId }));
      notify.error({
        title: SUCCESS_TITLE.GENERIC_SITE_DELETE,
        message: `'${selectedDeleteSite.name}' deleted successfully`,
      });
    } else {
      notify.error({
        title: ERROR_TITLE.GENERIC_SITE_DELETE,
        message: err.data,
      });
    }
    closeDeleteModalHandler();
  };
  const closeDeleteModalHandler = () => {
    setIsDeleting(false);
    setSelectedDeleteSite(null);
    setIsDeleteModalOpen(false);
  };
  const siteColumn: ColumnDef<Site>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: ({ column }) => {
          return (
            <button
              className="flex gap-1 items-center"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
            >
              Name
              {column.getIsSorted() === 'asc' ? (
                <div className="rotate-180">
                  <DirectedArrowIcon fill={THEME.NEUTRAL_500} />{' '}
                </div>
              ) : (
                <DirectedArrowIcon fill={THEME.NEUTRAL_500} />
              )}
            </button>
          );
        },
      },
      {
        accessorKey: 'ownerStaff.fullName',
        header: ({ column }) => {
          return (
            <button
              className="flex gap-1 items-center"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
            >
              Head Staff
              {column.getIsSorted() === 'asc' ? (
                <div className="rotate-180">
                  <DirectedArrowIcon fill={THEME.NEUTRAL_500} />{' '}
                </div>
              ) : (
                <DirectedArrowIcon fill={THEME.NEUTRAL_500} />
              )}
            </button>
          );
        },
      },
      {
        accessorKey: 'ownerStaff.email',
        header: ({ column }) => {
          return (
            <button
              className="flex gap-1 items-center"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
            >
              Staff Email
              {column.getIsSorted() === 'asc' ? (
                <div className="rotate-180">
                  <DirectedArrowIcon fill={THEME.NEUTRAL_500} />{' '}
                </div>
              ) : (
                <DirectedArrowIcon fill={THEME.NEUTRAL_500} />
              )}
            </button>
          );
        },
      },
      {
        id: 'actions',
        cell: ({ row }) => {
          return (
            <div className="flex justify-end">
              <div className="flex gap-2">
                <Button
                  variant={'outlined'}
                  size={'xmall'}
                  className="md:px-2 font-semibold text-[14px] gap-1"
                  trailingIcon={<Edit2 size={15} />}
                  onClick={() => {
                    setIsEditSiteModalOpen(true);
                    setSelectedSiteId(row.original.publicId);
                  }}
                >
                  Edit
                </Button>
                <Button
                  variant={'outlined'}
                  className="md:px-2 font-semibold text-[14px] gap-1"
                  size={'xmall'}
                  trailingIcon={<DeleteIcon />}
                  onClick={() => {
                    setSelectedDeleteSite(row.original);
                    setIsDeleteModalOpen(true);
                  }}
                >
                  Delete
                </Button>
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div>
      {isAddSiteModalOpen && (
        <AddAndEditSiteModal
          open={isAddSiteModalOpen}
          onClose={() => setIsAddSiteModalOpen(false)}
          type="Create"
        />
      )}
      {isEditSiteModalOpen && (
        <AddAndEditSiteModal
          open={isEditSiteModalOpen}
          onClose={() => {
            setIsEditSiteModalOpen(false);
            setSelectedSiteId(null);
          }}
          type="Edit"
          selectedSiteId={selectedSiteId}
        />
      )}
      {isDeleteModalOpen && selectedDeleteSite && (
        <AlertDialog
          type="delete"
          actionButtonText={!isDeleting ? 'Delete' : 'Deleting'}
          alertTitle={`Deleting ${selectedDeleteSite.name}`}
          onClose={closeDeleteModalHandler}
          open={isDeleteModalOpen}
          onAction={deleteSites}
          isActionStarted={isDeleting}
        >
          Are you sure you want to delete {selectedDeleteSite.name} ?
        </AlertDialog>
      )}
      <div className="px-[12px]  py-[16px] w-full flex justify-between items-center ">
        <h2 className="font-semibold text-[16px] text-neutral-900">Site</h2>
        <div className="flex gap-3 items-center justify-center">
          {/* <Button variant={'link'} leadingIcon={<FilterIcon />} size={'xmall'}>
            Filter
          </Button> */}
          <Button
            variant={'primary'}
            size={'small'}
            onClick={() => setIsAddSiteModalOpen(true)}
            trailingIcon={<AddIcon stroke="currentColor" />}
          >
            Add site
          </Button>
        </div>
      </div>
      <Slider variant={'vertical'} className="max-h-[520px]">
        {' '}
        <DataTable columns={siteColumn} data={sites} isLoading={isLoading} />
      </Slider>
    </div>
  );
};

export default Sites;
