import { URL } from '../requestConstructor';

export const getAllEggCollections = async (treatmentCyclePublicId: string) => {
  try {
    const response = await URL.get(
      `/eggCollection/get?treatmentCyclePublicId=${treatmentCyclePublicId}`
    );

    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const createEggCollection = async (data: CreateBookingRequest) => {
  try {
    const response = await URL.post('/eggCollection/create', data);

    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const updateEggCollection = async (data: UpdateEggCollectionRequest) => {
  try {
    const response = await URL.put('/eggCollection/update', data);

    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const deleteEggCollection = async (publicId: string) => {
  try {
    const response = await URL.delete(
      `/eggCollection/delete?publicId=${publicId}`
    );

    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};
