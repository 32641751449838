import { URL } from '../requestConstructor';

export const getAllTeams = async () => {
  try {
    const response = await URL.get('/staff/team/get/all');
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const deleteTeam = async (id: string) => {
  try {
    const response = await URL.delete(`/staff/team/delete?publicId=${id}`);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const createNewTeam = async (data: CreateTeamRequest) => {
  try {
    const response = await URL.post('/staff/team/create', data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const updateTeam = async (data: UpdateTeamRequest) => {
  try {
    const response = await URL.put('/staff/team/update', data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const getTeam = async (id: string) => {
  try {
    const response = await URL.get(`/staff/team/get?publicId=${id}`);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};
