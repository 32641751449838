import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialNotificationState: NotificationState = {
  totalUnread: 0,
  hasNew: false,
  notifications: {},
};

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState: initialNotificationState,
  reducers: {
    setInitialNotifications: (
      state: NotificationState,
      action: PayloadAction<Notification[]>
    ) => {
      const notifications: Record<string, Notification[]> = {};
      let totalUnread = 0;
      action.payload.forEach(notification => {
        //create a key for each notification based on the date, but only take the date part of the whenCreated
        const key = new Date(notification.whenCreated).toDateString();
        totalUnread += notification.hasRead ? 0 : 1;

        //if the key already exists, push the notification to the array, else create a new array
        if (notifications[key]) {
          notifications[key].push(notification);
        } else {
          notifications[key] = [notification];
        }
      });

      //sort notifications by date
      Object.keys(notifications).forEach(key => {
        notifications[key].sort(
          (a, b) => new Date(b.whenCreated).getTime() - new Date(a.whenCreated).getTime()
        );
      });

      state.notifications = notifications;
      state.hasNew = totalUnread > 0;
      state.totalUnread = totalUnread;
    },

    addNotification: (
      state: NotificationState,
      action: PayloadAction<Notification>
    ) => {
      if (!action.payload) {
        return;
      }

      const key = new Date(action.payload.whenCreated).toDateString();

      //if the key already exists, push the notification to the array, else create a new array
      if (state.notifications[key]) {
        state.notifications[key].push(action.payload);
      } else {
        state.notifications[key] = [action.payload];
      }

      state.hasNew = true;
      state.totalUnread += 1;
    },

    markAllNotificationsAsRead: (state: NotificationState) => {
      state.hasNew = false;
      state.totalUnread = 0;

      //update all notifications to hasRead = true
      state.notifications = Object.keys(state.notifications).reduce(
        (acc: Record<string, Notification[]>, key) => {
          acc[key] = state.notifications[key].map(notification => {
            return { ...notification, hasRead: true };
          });
          return acc;
        },
        {}
      );
    },

    markNotificationAsRead: (state: NotificationState, action: PayloadAction<string>) => {
      if (state.totalUnread > 0) {
        state.totalUnread -= 1;
      }

      //update the notification to hasRead = true
      state.notifications = Object.keys(state.notifications).reduce(
        (acc: Record<string, Notification[]>, key) => {
          acc[key] = state.notifications[key].map(notification => {
            if (notification.publicId === action.payload) {
              return { ...notification, hasRead: true };
            }
            return notification;
          });
          return acc;
        },
        {}
      );
    },

    markAllNotificationsAsOpened: (state: NotificationState) => {
      //update all notifications to hasOpened = true
      state.notifications = Object.keys(state.notifications).reduce(
        (acc: Record<string, Notification[]>, key) => {
          acc[key] = state.notifications[key].map(notification => {
            return { ...notification, hasOpened: true };
          });
          return acc;
        },
        {}
      );
    },

    markNotificationAsOpened: (state: NotificationState, action: PayloadAction<string>) => {
      //update the notification to hasOpened = true
      state.notifications = Object.keys(state.notifications).reduce(
        (acc: Record<string, Notification[]>, key) => {
          acc[key] = state.notifications[key].map(notification => {
            if (notification.publicId === action.payload) {
              return { ...notification, hasOpened: true };
            }
            return notification;
          });
          return acc;
        },
        {}
      );
    },
  },
});

export default notificationSlice.reducer;
export const {
  setInitialNotifications,
  addNotification,
  markAllNotificationsAsRead,
  markNotificationAsRead,
  markAllNotificationsAsOpened,
  markNotificationAsOpened,
} = notificationSlice.actions;
