//Reducer actions
export const REDUCER_ACTIONS = {
  RESET_STORE: 'RESET_STORE',
};

// Async Collection actions
export const FETCH = 'FETCH';
export const FETCH_FAILED = 'FETCH_FAILED';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const RESET = 'RESET';
export const UPDATE_COLLECTION = 'UPDATE_COLLECTION';
export const REMOVE_MEMBER_IN_COLLECTION = 'REMOVE_MEMBER_IN_COLLECTION';

export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_PAGE = 'SET_PAGE';
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE';

export const MEMBER_FETCH_SUCCESS = 'MEMBER_FETCH_SUCCESS';
export const UPDATE_MEMBER = 'UPDATE_MEMBER';

// Async form actions
export const FORM_UPDATE_FIELDS = 'FORM_UPDATE_FIELDS';
export const FORM_IN_PROGRESS = 'FORM_IN_PROGRESS';
export const FORM_FAILED = 'FORM_FAILED';
export const FORM_SUCCESS = 'FORM_SUCCESS';
export const FORM_RESET = 'FORM_RESET';
export const FORM_UPDATE_ERRORS = 'FORM_UPDATE_ERRORS';
export const FORM_CLEAR_ERRORS = 'FORM_CLEAR_ERRORS';
export const FORM_CLEAR_FIELDS = 'FORM_CLEAR_FIELDS';

// Approvals
export const APPROVALS = 'APPROVALS';
export const FETCH_APPROVALS = 'FETCH_APPROVALS';
export const PATCH_APPROVALS = 'PATCH_APPROVALS';
