import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ResourceBookingState {
  booking: ResourceBooking[];
  filteredBookings: ResourceBooking[];
  filters: {
    selectedLocations: string[];
    selectedResources: string[];
    selectedConsultants: string[];
    selectedServices: string[];
  };
}

const initialState: ResourceBookingState = {
  booking: [],
  filteredBookings: [],
  filters: {
    selectedLocations: [],
    selectedResources: [],
    selectedConsultants: [],
    selectedServices: [],
  },
};

export const resourceBookingSlice = createSlice({
  name: 'resourceBooking',
  initialState,
  reducers: {
    addResourceBookings: (state, action: PayloadAction<ResourceBooking[]>) => {
      state.booking = action.payload;

      // Check if filters are already applied
      if (
        state.filters.selectedLocations.length > 0 ||
        state.filters.selectedResources.length > 0 ||
        state.filters.selectedConsultants.length > 0 ||
        state.filters.selectedServices.length > 0
      ) {
        // Filter the new bookings based on current filters
        const filteredBookings = action.payload.filter(booking => {
          return (
            (state.filters.selectedLocations.length === 0 ||
              (booking.location &&
                state.filters.selectedLocations.includes(
                  booking.location.publicId
                ))) &&
            (state.filters.selectedResources.length === 0 ||
              (booking.resource &&
                state.filters.selectedResources.includes(
                  booking.resource.publicId
                ))) &&
            (state.filters.selectedConsultants.length === 0 ||
              (booking.staff &&
                state.filters.selectedConsultants.includes(
                  booking.staff.publicId
                ))) &&
            (state.filters.selectedServices.length === 0 ||
              (booking.service &&
                state.filters.selectedServices.includes(
                  booking.service.publicId
                )))
          );
        });

        // Update filteredBookings with the filtered result
        state.filteredBookings = filteredBookings;
      } else {
        // No filters applied, set filteredBookings to all bookings
        state.filteredBookings = action.payload;
      }
    },
    filterResourceBookings: (
      state,
      action: PayloadAction<{
        selectedLocations: string[];
        selectedResources: string[];
        selectedConsultants: string[];
        selectedServices: string[];
      }>
    ) => {
      const {
        selectedLocations,
        selectedResources,
        selectedConsultants,
        selectedServices,
      } = action.payload;

      // Update filters
      state.filters.selectedLocations = selectedLocations;
      state.filters.selectedResources = selectedResources;
      state.filters.selectedConsultants = selectedConsultants;
      state.filters.selectedServices = selectedServices;

      // Filter the booking based on new filters
      const filteredBookings = state.booking.filter(booking => {
        return (
          (selectedLocations.length === 0 ||
            (booking.location &&
              selectedLocations.includes(booking.location.publicId))) &&
          (selectedResources.length === 0 ||
            (booking.resource &&
              selectedResources.includes(booking.resource.publicId))) &&
          (selectedConsultants.length === 0 ||
            (booking.staff &&
              selectedConsultants.includes(booking.staff.publicId))) &&
          (selectedServices.length === 0 ||
            (booking.service &&
              selectedServices.includes(booking.service.publicId)))
        );
      });

      // Update filteredBookings with the filtered result
      state.filteredBookings = filteredBookings;
    },
    deleteResourceBooking: (
      state,
      action: PayloadAction<{ publicId: string }>
    ) => {
      state.filteredBookings = state.filteredBookings.filter(
        booking => booking.publicId !== action.payload.publicId
      );
    },
    addResourceBooking: (state, action: PayloadAction<ResourceBooking>) => {
      // Check if filters are already applied
      if (
        state.filters.selectedLocations.length > 0 ||
        state.filters.selectedResources.length > 0 ||
        state.filters.selectedConsultants.length > 0 ||
        state.filters.selectedServices.length > 0
      ) {
        // Filter the new booking based on current filters
        const newBooking = action.payload;
        const isFiltered =
          (state.filters.selectedLocations.length === 0 ||
            (newBooking.location &&
              state.filters.selectedLocations.includes(
                newBooking.location.publicId
              ))) &&
          (state.filters.selectedResources.length === 0 ||
            (newBooking.resource &&
              state.filters.selectedResources.includes(
                newBooking.resource.publicId
              ))) &&
          (state.filters.selectedConsultants.length === 0 ||
            (newBooking.staff &&
              state.filters.selectedConsultants.includes(
                newBooking.staff.publicId
              ))) &&
          (state.filters.selectedServices.length === 0 ||
            (newBooking.service &&
              state.filters.selectedServices.includes(
                newBooking.service.publicId
              )));

        if (isFiltered) {
          // Add the new booking to filteredBookings
          state.filteredBookings.push(newBooking);
        }
      } else {
        // No filters applied, add the new booking directly
        state.filteredBookings.push(action.payload);
      }
    },
    updateResourceBooking: (state, action: PayloadAction<ResourceBooking>) => {
      const index = state.filteredBookings.findIndex(
        booking => booking.publicId === action.payload.publicId
      );
      if (index !== -1) {
        state.filteredBookings[index] = action.payload;
      }
    },
  },
});

export default resourceBookingSlice.reducer;
export const {
  addResourceBookings,
  deleteResourceBooking,
  filterResourceBookings,
  addResourceBooking,
  updateResourceBooking,
} = resourceBookingSlice.actions;
