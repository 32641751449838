export const CONSTANT = {
  FEMALE: 'Female',
  BLOCKED: 'Blocked',
  EMAIL_REGEX: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
};

export const RESULT_STATUS = {
  PENDING: 'Pending',
  RESULT_AVAILABLE: 'Results Available',
};

export const PATHWAY_DEADLINE_STATUS = {
  DEADLINE_CROSSED: 'Deadline crossed',
};

export const ALERT = {
  SUCCESS: {},
  FAILURE: {},
};

export const PAYMENT_STATUS = {
  PAID: 'Paid',
  NOTPAID: 'notPaid',
};

export const dayNames = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];

export const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

export const weekDays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const HEIGHT_UNITS = {
  CM: 'CM',
  INCH: 'INCH',
};

export const WEIGHT_UNITS = {
  KG: 'KG',
  LBS: 'LBS',
};
