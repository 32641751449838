/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ERROR_TO_DO } from '../../../types';
import { URL } from '../requestConstructor';

export const getPrescriptions = async (
  publicId: string,
  patientPublicId: string,
  staffPublicId: string
) => {
  try {
    const response = await URL.get(
      `/prescription/get?publicId=${publicId}&patientPublicId=${patientPublicId}&staffPublicId=${staffPublicId}`
    );
    return [response.data, null];
  } catch (error: ERROR_TO_DO) {
    return [null, error.response];
  }
};

export const downloadPrescription = async (publicId: string) => {
  try {
    const response = await URL.get(`/prescription/pdf?publicId=${publicId}`, {
      responseType: 'blob',
    });
    return [response.data, null];
  } catch (error: ERROR_TO_DO) {
    return [null, error.response];
  }
};

export const sendPrescription = async (publicId: string) => {
  try {
    const response = await URL.post(`/prescription/send?publicId=${publicId}`);
    return [response, null];
  } catch (error: ERROR_TO_DO) {
    return [null, error.response];
  }
};

export const deletePrescription = async (publicId: string) => {
  try {
    const response = await URL.delete(
      `/prescription/delete?publicId=${publicId}`
    );
    return [response, null];
  } catch (error: ERROR_TO_DO) {
    return [null, error.response];
  }
};

export const createPrescription = async (prescription: any) => {
  try {
    const response = await URL.post('/prescription/create', prescription);
    return [response.data, null];
  } catch (error: ERROR_TO_DO) {
    if (error.response.status === 400) {
      return [null, error.response.data];
    } else {
      return [null, error];
    }
  }
};
