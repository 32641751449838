import { ArrowUpIcon, DownArrowIcon } from '../../../../assets/icons';
import { Button } from '../../../UI';
import React, { useState } from 'react';
import Pathway from './Pathway';
// Need to move proper directory
export interface StageProps {
  id: number;
  title: string;
  consultantName: string;
  location: string;
  startTime: string;
  endTime: string;
}
export interface StageType {
  stageId: number;
  stageTitle: string;
  stages: StageProps[];
}

const PatientJourney = () => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  return (
    <div className="border border-neutral-100 rounded-lg overflow-hidden">
      <div
        className={
          'p-2  flex items-center justify-between ' +
          (isOpen && 'border-b border-neutral-100')
        }
      >
        <h1 className="font-semibold text-[16px] text-neutral-900">
          Patient Journey
        </h1>
        <Button
          onClick={() => setIsOpen(prev => !prev)}
          trailingIcon={isOpen ? <ArrowUpIcon /> : <DownArrowIcon />}
          variant={'link'}
          size={'small'}
        >
          {isOpen ? 'Collapse' : 'Expand'}
        </Button>
      </div>
      {isOpen && <Pathway />}
    </div>
  );
};

export default PatientJourney;
