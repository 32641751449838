/* eslint-disable indent */
import RightArrow from '../../../assets/icons/RightArrow';
import React, { useState } from 'react';
import { Button, Input, Label } from '../../UI';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  firstPasswordReset,
  getUserDetails,
  loginUser,
} from '../../../services/api';
import { useDispatch } from 'react-redux';
import { addUserCredentials } from '../../../redux/slice';
import { ROUTES } from '../../../constants/NavigationConstants';
import { notify } from '../../../components/common';
import {
  ERROR_MESSAGE,
  SUCCESS_MESSAGE,
} from '../../../constants/NotificationConstants';

const UpdatePasswordForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const username = location.state && location.state.username;
  const password = location.state && location.state.password;
  const clientId = location.state && location.state.clientId;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isValid, setIsValid] = useState<boolean>(true);

  const [passwords, setPassword] = useState<{
    newPassword: string;
    currentPassword: string;
    confirmNewPassword: string;
  }>({
    newPassword: '',
    currentPassword: password,
    confirmNewPassword: '',
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const obj: FirstPasswordReset = {
      clientId: clientId,
      username: username,
      oldPassword: passwords.currentPassword,
      newPassword: passwords.newPassword,
      twoFactorReset: true,
    };
    setIsLoading(true);
    const [response, error] = await firstPasswordReset(obj);

    setIsLoading(false);
    if (response) {
      notify.success({
        title: 'Password update',
        message: SUCCESS_MESSAGE.PASSWORD_UPDATE_SUCCEEDED,
      });
      setIsLoading(false);
      if (response) {
        if (response.data) {
          navigate(ROUTES.ROUTE_TO_SET_2FA, {
            state: {
              username,
              password: obj.newPassword,
              clientId,
              qrCode: response.data,
            },
            replace: true,
          });
        } else {
          getToken(username, obj.newPassword);
        }
      } else {
        setPasswordError(error);
        navigate(ROUTES.ROUTE_TO_SIGN_IN, {
          replace: true,
        });
      }
    } else {
      if (error.status === 401) {
        setPasswordError(error.data);
      } else {
        setError(error.data);
      }
      notify.error({
        title: 'Password update',
        message: ERROR_MESSAGE.PASSWORD_UPDATE_FAILED,
      });
    }
  };

  const getToken = async (username: string, password: string) => {
    const [res, error] = await loginUser({
      clientId: clientId,
      username: username,
      password: password,
    });
    if (res) {
      const { data } = await getUserDetails();

      const userRoles: string[] = [];
      for (const role of data.roles) {
        userRoles.push(role.name);
      }
      data.roles = userRoles;
      dispatch(addUserCredentials(data));
      navigate(ROUTES.ROUTE_TO_OVERVIEW, { replace: true });
    } else {
      setPasswordError(error);
      notify.error({
        title: 'Login',
        message: ERROR_MESSAGE.LOGIN_FAILED,
      });
    }
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.id === 'newPassword') {
      const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
      setIsValid(regex.test(e.target.value));
    }

    setPassword({
      ...passwords,
      [e.target.id]: e.target.value,
    });
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col  gap-3">
        <div className="w-full flex flex-col gap-1">
          <Label htmlFor="currentPassword">Old password</Label>
          <Input
            id="currentPassword"
            type="password"
            placeholder="Enter Old Password"
            required={true}
            onChange={onChangeInput}
            value={passwords.currentPassword}
          />{' '}
          {passwordError && (
            <p className="text-sm text-destructive-500">{passwordError}</p>
          )}
        </div>
        <div className="w-full flex flex-col gap-1">
          <Label htmlFor="newPassword">New password</Label>
          <Input
            id="newPassword"
            type="password"
            placeholder="Enter new password"
            required={true}
            onChange={onChangeInput}
            value={passwords.newPassword}
          />

          {!isValid && (
            <p className="text-sm text-destructive-500">
              Password must contain at least 8 characters, including uppercase
              and lowercase letters, and at least one digit.
            </p>
          )}
        </div>
        <div className="w-full flex flex-col gap-1">
          <Label htmlFor="confirmNewPassword">Confirm password</Label>
          <Input
            id="confirmNewPassword"
            type="password"
            placeholder="Enter new password"
            required={true}
            onChange={onChangeInput}
            value={passwords.confirmNewPassword}
          />

          {passwords.newPassword !== passwords.confirmNewPassword &&
            passwords.confirmNewPassword.length > 0 && (
              <p className="text-sm text-destructive-500">
                Password does not match!
              </p>
            )}
        </div>
      </div>

      <p className="text-sm text-neutral-500 my-7">
        By proceeding, I acknowledge that I have read the{' '}
        <span className="underline text-neutral-600">Privacy Policy </span> and
        agree to Swivo’s{' '}
        <span className=" text-neutral-600">Terms of Use </span>.
      </p>
      {error && <p className="text-sm text-destructive-500">{error}</p>}
      <Button
        style={{ width: '100%' }}
        type="submit"
        variant={'primary'}
        size="medium"
        disabled={
          passwords.newPassword !== passwords.confirmNewPassword ||
          passwords.newPassword.length === 0
        }
        isLoading={isLoading}
        trailingIcon={<RightArrow />}
      >
        Continue
      </Button>
    </form>
  );
};

export default UpdatePasswordForm;
