// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ERROR_TO_DO } from '../../../types';
import { URL } from '../requestConstructor';

export const getAllPatients = async () => {
  try {
    const response = await URL.get('/patient/get/all');
    return [response, null];
  } catch (error: ERROR_TO_DO) {
    return [null, error.response.data];
  }
};

export const getPatients = async () => {
  try {
    const response = await URL.get('/patient/get');
    return [response, null];
  } catch (error: ERROR_TO_DO) {
    return [null, error.response.data];
  }
};

export const getPatientByPublicId = async (id: string) => {
  try {
    const response = await URL.get(`/patient/get?publicId=${id}`);
    return response;
  } catch (error: ERROR_TO_DO) {
    return error.response;
  }
};

export const createPatient = async (userData: CreatePatientInterface) => {
  try {
    const response = await URL.post('/patient/create', userData);
    return [response.data, null];
  } catch (error: ERROR_TO_DO) {
    return [null, error?.response?.data];
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updatePatient = async (patientData: any) => {
  try {
    const response = await URL.put('/patient/update', patientData);
    return [response.data, null];
  } catch (error: ERROR_TO_DO) {
    return [null, error?.response?.data];
  }
};

export const searchPatient = async (namePart: string) => {
  try {
    const response = await URL.get(`/patient/search?searchTerm=${namePart}`);
    return response;
  } catch (error: ERROR_TO_DO) {
    return error.response;
  }
};

export const getAllAllergies = async () => {
  try {
    const response = await URL.get('/patient/allergies/get/all');
    return [response.data, null];
  } catch (error: ERROR_TO_DO) {
    return [null, error.response];
  }
};

export const removePartner = async (data: RemovePartner) => {
  try {
    const response = await URL.put('/patient/update', data);
    return [response.data, null];
  } catch (error: ERROR_TO_DO) {
    return error.response;
  }
};
