import React from 'react';
import { THEME } from '../../constants/ColorConstants';

interface MedicineIconProps {
  stroke?: string;
}

const MedicineIcon: React.FC<MedicineIconProps> = ({
  stroke = 'currentColor',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clip-path="url(#clip0_2559_28399)">
      <path
        d="M6.99992 13.6663L13.6666 6.99968C13.9781 6.69439 14.226 6.33038 14.396 5.92869C14.5659 5.527 14.6546 5.0956 14.6568 4.65944C14.659 4.22328 14.5747 3.79101 14.4088 3.38762C14.2429 2.98424 13.9987 2.61774 13.6903 2.30932C13.3819 2.0009 13.0154 1.75669 12.612 1.59079C12.2086 1.4249 11.7763 1.34061 11.3402 1.34282C10.904 1.34502 10.4726 1.43366 10.0709 1.60363C9.66922 1.77359 9.30521 2.02149 8.99992 2.33301L2.33325 8.99968C2.02174 9.30496 1.77383 9.66897 1.60387 10.0707C1.43391 10.4724 1.34526 10.9038 1.34306 11.3399C1.34086 11.7761 1.42514 12.2083 1.59104 12.6117C1.75693 13.0151 2.00115 13.3816 2.30957 13.69C2.61798 13.9985 2.98448 14.2427 3.38787 14.4086C3.79125 14.5745 4.22352 14.6587 4.65968 14.6565C5.09585 14.6543 5.52724 14.5657 5.92893 14.3957C6.33062 14.2258 6.69464 13.9779 6.99992 13.6663Z"
        stroke={stroke}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.66675 5.66699L10.3334 10.3337"
        stroke={stroke}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2559_28399">
        <rect width="16" height="16" fill={THEME.SHADE_0} />
      </clipPath>
    </defs>
  </svg>
);

export default MedicineIcon;
