import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../../../constants/NavigationConstants';
import { uploadFile } from '../../../../services/api';
import {
  notify,
  UploadProfilePictureModal,
} from '../../../../components/common';
import { updatePatient } from '../../../../services/api/endPoints/patients';

const UploadProfilePicModal = ({
  open,
  onClose,
  publicId,
  onChangeProfilePicture,
}: {
  open: boolean;
  onClose: () => void;
  onChangeProfilePicture: (profilePictureUrl: string) => void;
  publicId: string;
}) => {
  const [loading, isLoading] = useState<boolean>(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const uploadFileHandler = async (file: File) => {
    isLoading(true);
    const profilePictureFileData = new FormData();
    profilePictureFileData.append('containerName', 'patient_profile_pictures');
    profilePictureFileData.append(
      'fileName',
      `${publicId}.${file.name.split('.').pop()}`
    );
    profilePictureFileData.append('file', file);
    const [res, error] = await uploadFile(profilePictureFileData);
    if (error) {
      notify.error({
        title: 'Unable to upload the image',
        message: error.data,
      });
      isLoading(false);
      return;
    }
    const profileImageUploadData = {
      publicId: publicId,
      profilePictureUrl: res,
    };
    const [imageChangeRes, errorImageChange] = await updatePatient(
      profileImageUploadData
    );
    if (errorImageChange) {
      notify.error({
        title: 'Unable to upload the image',
        message: errorImageChange.data,
      });
      isLoading(false);
      return;
    }
    notify.success({
      title: 'Successfully updated patient profile picture',
    });
    onChangeProfilePicture(imageChangeRes.profilePictureUrl);
    isLoading(false);
    onClose();
  };
  return (
    <UploadProfilePictureModal
      open={open}
      onUpload={uploadFileHandler}
      onClose={onClose}
      loading={loading}
      navigateToTakePictureHandler={() =>
        navigate(
          `${ROUTES.ROUTE_TO_PATIENT_PROFILE}${id}${ROUTES.ROUTE_TO_PATIENT_PROFILE_PICTURE_CAMERA}`
        )
      }
    />
  );
};

export default UploadProfilePicModal;
