import { THEME } from '../../constants/ColorConstants';
import {
  ALIGN_CENTER,
  ALIGN_LEFT,
  ALIGN_RIGHT,
  BLACK_COLOR,
  BLUE_COLOR,
  ELECTRONICALLY_APPROVED,
} from '../../constants/jspdf.constants';

// import fertilityLogo from './logo.png';
// import gynaeLogo from '../assets/logo/400PngdpiLogo.png';

export const pageHeight = 270;
export const pageWidth = 165;
export const marginLeft = 15; //
export const spaceBetween = 5;
export const textFontPoint = 10;
export const subTitleFontPoint = 12;
export const reportTitlePoint = 14; //
export const commonSpaceBetween = 5; //
export const drNamePosition = 20; // globalPosition + drNamePosition
export const centerPosition = 105;
const urlText = 'www.p4fertility.co.uk';
const clinicNames = 'Birmingham  •  Leicester  •  London  •  Watford';
export const addHeader = (
  doc: any,
  clinicAddress: string,
  email: string,
  phone: string
) => {
  doc.addImage('fertilityLogo', 'PNG', 5, 0, 70, 35);
  doc.setFontSize(12).text('P4 Fertility', 195, 15, null, null, ALIGN_RIGHT);
  doc.setFontSize(8).text(clinicAddress, 195, 19, null, null, ALIGN_RIGHT);
  doc
    .setTextColor(BLUE_COLOR)
    .setFontSize(8)
    .textWithLink('E: ' + email, 172, 26, ALIGN_RIGHT, {
      url: `mailto:${email}`,
    });
  doc.setTextColor(BLACK_COLOR);
  doc.textWithLink('P: ' + phone, 195, 26, ALIGN_RIGHT);

  generateHr(doc, 28);
  return doc;
};

export const addFooter = (doc: any) => {
  generateHr(doc, 282);
  doc.setFillColor(0, 46, 110);
  doc.rect(0, 280, 210, 30, 'F');
  doc
    .setFontSize(11)
    .setTextColor(THEME.SHADE_0)
    .textWithLink(urlText, centerPosition, 286, ALIGN_CENTER, { urlText });
  doc
    .setTextColor(THEME.SHADE_0)
    .text(clinicNames, centerPosition, 293, ALIGN_CENTER)
    .setTextColor(BLACK_COLOR);
  return doc;
};

export const addGynaeFooter = (doc: any) => {
  generateHr(doc, 282);
  doc.setFillColor(148, 33, 97);
  doc.rect(0, 280, 210, 30, 'F');

  return doc;
};
// function to generate a line
export const generateHr = (doc: any, y: number) => {
  doc.line(marginLeft, y, 195, y, 'S');
};

export const getValueOrDefault = (value: string | number | Date) =>
  value ? value : '';

export const getTextHeight = (
  doc: any,
  position: number,
  text: string,
  clinicAddress: string,
  globalPosition: number,
  email: string,
  phone: string
) => {
  const arr = text.split(' ');
  if ((commonSpaceBetween * arr.length) / 15 + position > 250) {
    doc.addPage('a4', '1');
    addHeader(doc, clinicAddress, email, phone);
    addFooter(doc);

    return 25 + 5;
  } else {
    return globalPosition + 5 + (commonSpaceBetween * arr.length) / 15;
  }
};

export const addTitle = (doc: any, title: string, gp: number) => {
  doc
    .setFontSize(reportTitlePoint)
    .text(title, centerPosition, gp + 5, null, null, ALIGN_CENTER);

  return gp + 5;
};

export const addCreatedDate = (doc: any, createdDate: string, gp: number) => {
  doc
    .setFontSize(textFontPoint)
    .text('Date: ' + createdDate, 195, gp, null, null, ALIGN_RIGHT);
  return gp + commonSpaceBetween;
};

export const addSignature = (
  doc: any,
  signature: string,
  globalPosition: number
) => {
  doc
    .text(signature, marginLeft, globalPosition + drNamePosition)
    .text(
      ELECTRONICALLY_APPROVED,
      marginLeft,
      globalPosition + drNamePosition + commonSpaceBetween
    );
};

export const addProtocolInfo = (
  doc: any,
  clinicAddress: string,
  email: string,
  phone: string
) => {
  let scopeHeight = 40;

  doc = addOnePage(doc, clinicAddress, email, phone);
  doc
    .setFont('normal', 'bold')
    .setFontSize(textFontPoint)
    .text('The risks of IVF', marginLeft, 40)
    .setFont('normal', 'normal')
    .setFontSize(textFontPoint)
    .text(
      'There are several risks associated with IVF/ICSI treatment that you should be aware of. These include the possibility of cycle cancellation due to poor ovarian response, changes in treatment protocol which may result in increased medication costs, ovarian hyperstimulation syndrome (OHSS) occurring in 3% to 8% of cycles, multiple pregnancies occurring in 10% of cycles, infection, bleeding, pain, mood changes, risk of ectopic pregnancy occurring in 1.5% of cycles, fertilization failure occurring in 1% of cycles, and the potential for birth defects with ICSI. For additional information, we recommend visiting our website.',
      marginLeft + 10,
      (scopeHeight += spaceBetween),
      { maxWidth: 170, align: 'justify' },
      null
    )
    .setTextColor(BLUE_COLOR)
    .textWithLink(
      'https://www.nhs.uk/conditions/ivf/risks/',
      marginLeft + 10,
      (scopeHeight += spaceBetween * 5),
      ALIGN_LEFT,
      'https://www.nhs.uk/conditions/ivf/risks/'
    );
  doc
    .setTextColor(THEME.SHADE_100)
    .setFont('normal', 'bold')
    .setFontSize(textFontPoint)
    .text(
      'BMI (Body Mass Index): ',
      marginLeft,
      (scopeHeight += spaceBetween * 2)
    )
    .setFont('normal', 'normal')
    .setFontSize(textFontPoint)
    .text(
      //"Your BMI needs to be less than 35 before starting IVF/ICSI treatment",
      'It is important that your Body Mass Index (BMI) is less than 35 before beginning IVF/ICSI treatment. If your BMI exceeds 35, we recommend working with your healthcare provider to develop a plan to achieve a healthier BMI before proceeding with treatment. This is because a higher BMI has been associated with a lower success rate in IVF/ICSI treatment and can increase the risk of complications during pregnancy.',
      marginLeft + 10,
      (scopeHeight += spaceBetween),
      { maxWidth: 170, align: 'justify' }
    )
    .setFont('normal', 'bold')
    .text('Supplements: ', marginLeft, (scopeHeight += spaceBetween * 4))
    .setFont('normal', 'normal')
    .text(
      //"Folic acid and vitamin D are recommended; any additional supplements are optional.",
      'We strongly recommend taking folic acid and vitamin D supplements during your treatment. While any additional supplements are optional, we recommend consulting with your healthcare provider to determine whether any additional supplements may be beneficial for your particular situation.',
      marginLeft + 10,
      (scopeHeight += spaceBetween),
      { maxWidth: 170, align: 'justify' }
    )
    .setFont('normal', 'bold')
    .text('Trigger injection: ', marginLeft, (scopeHeight += spaceBetween * 3))
    .setFont('normal', 'normal')
    .text(
      //"It is important to keep the exact timing of your trigger injection. Trigger injection is advised 36 hours before egg collection. Please contact fertility team if you have any questions.",
      'Maintaining precise timing of your trigger injection is critical. It is recommended that you administer the trigger injection precisely 36 hours prior to your scheduled egg collection. If you have any concerns or questions, please do not hesitate to contact our fertility team.',
      marginLeft + 10,
      (scopeHeight += spaceBetween),
      { maxWidth: 170, align: 'justify' }
    )
    .setFont('normal', 'bold')
    .text('Counselling: ', marginLeft, (scopeHeight += spaceBetween * 3))
    .setFont('normal', 'normal')
    .text(
      //"Professional counselling is available as a part of your treatment cycle. Please contact the team on 0121 446 1501 to book counselling. Counselling can be booked before, during and after your treatment.",
      'As part of your treatment cycle, we offer professional counselling services that you may find beneficial. To schedule an appointment, please contact our team at 0121 446 1501. Counselling sessions can be arranged before, during, or after your treatment as needed.',
      marginLeft + 10,
      (scopeHeight += spaceBetween),
      { maxWidth: 170, align: 'justify' }
    )
    .setFont('normal', 'bold')
    .text(
      'In treatment support: ',
      marginLeft,
      (scopeHeight += spaceBetween * 3)
    )
    .setFont('normal', 'normal')
    .text(
      //"In treatment support is offered by our nursing team at The Priory Hospital. Please contact them on 0121 446 1501 for any in treatment queries. If you require urgent care or suffer with complications, please report to your nearest A&E. When it is safe and appropriate to do so please notify us.",
      'Throughout your treatment, our nursing team at The Priory Hospital is available to provide you with any necessary support. Please do not hesitate to contact them at 0121 446 1501 with any in-treatment queries. However, if you experience urgent medical concerns or complications, please visit your nearest A&E immediately. Once it is safe and feasible, please inform us of any emergencies that may have arisen.',
      marginLeft + 10,
      (scopeHeight += spaceBetween),
      { maxWidth: 170, align: 'justify' }
    )
    .setFont('normal', 'bold')
    .text('Cost of treatment: ', marginLeft, (scopeHeight += spaceBetween * 4))
    .setFont('normal', 'normal')
    .text(
      //"You will receive a costed treatment plan with consent paperwork once you call in with Day 1 of your menstrual cycle.",
      'Upon calling in with Day 1 of your menstrual cycle, you will be provided with a comprehensive treatment plan including cost breakdowns and necessary consent forms.',
      marginLeft + 10,
      (scopeHeight += spaceBetween),
      { maxWidth: 170, align: 'justify' }
    )
    .setFont('normal', 'bold')
    .text('Freeze all: ', marginLeft, (scopeHeight += spaceBetween * 2))
    .setFont('normal', 'normal')
    .text(
      //"To reduce the risk of ovarian hyperstimulation stimulation syndrome (OHSS), patients with high number of eggs are advised to freeze all embryos and not to transfer any during their treatment cycle. A freeze all cycle could be recommended if a polyp or an abnormality in lining is seen.",
      'Patients who produce a high number of eggs during fertility treatment are advised to minimize the risk of developing Ovarian Hyperstimulation Syndrome (OHSS) by freezing all embryos instead of transferring them during their treatment cycle. Furthermore, a freeze-all cycle may be suggested in the event that a polyp or lining abnormality is detected.',
      marginLeft + 10,
      (scopeHeight += spaceBetween),
      { maxWidth: 170, align: 'justify' }
    )
    .setFont('normal', 'bold')
    .text(
      'Ovarian Hyper stimulation Syndrome (OHSS): ',
      marginLeft,
      (scopeHeight += spaceBetween * 4)
    )
    .setFont('normal', 'normal')
    .text(
      //"In a minority of women undergoing treatment, the ovarian response exceeds what we aim for and results in a clinical condition called OHSS. Mild OHSS has been estimated to affect around one-third of cycles, while moderate or severe OHSS risk is from 3.1% to 8% which may need hospital treatment. If this does occur, your consultant will have to make a decision of whether or not to continue with your treatment cycle.",
      'In a small percentage of women undergoing fertility treatment, the ovarian response may exceed the desired outcome, leading to a clinical condition known as Ovarian Hyperstimulation Syndrome (OHSS). Mild OHSS affects roughly one-third of cycles, while moderate or severe OHSS occurs in 3.1% to 8% of cycles, and may require hospitalization. Should OHSS arise, your consultant will evaluate whether or not to proceed with your treatment cycle.',
      marginLeft + 10,
      (scopeHeight += spaceBetween),
      { maxWidth: 170, align: 'justify' }
    );
  doc = addOnePage(doc, clinicAddress, email, phone);
  doc
    .setFontSize(textFontPoint)
    .setFont('normal', 'bold')
    .text('Multiple Pregnancy:', marginLeft, (scopeHeight = 40))
    .setFont('normal', 'normal')
    .text(
      // "If more than 1 embryo is replaced, there's an increased chance of producing twins or triplets. The risks include miscarriage, preterm birth, pregnancy-related high blood pressure and pre- eclampsia, gestational diabetes, anaemia, heavy bleeding and needing a caesarean section.",
      'When multiple embryos are implanted, the likelihood of giving birth to twins or triplets is elevated. However, this comes with an increased risk of complications such as miscarriage, preterm birth, pregnancy-related hypertension and pre-eclampsia, gestational diabetes, anemia, excessive bleeding, and the need for a caesarean section.',
      marginLeft + 10,
      (scopeHeight += spaceBetween),
      { maxWidth: 170, align: 'justify' }
    )
    .setFont('normal', 'bold')
    .text('Add-Ons:', marginLeft, (scopeHeight += spaceBetween * 3))
    .setFont('normal', 'normal')
    .text(
      // "Optional add-ons often claim to be effective at improving the chances of having a baby but the evidence to support this for most fertility patients, is usually missing or not very reliable. It is likely to involve an additional cost on top of the cost of a routine cycle of proven fertility treatment.",
      'Although optional add-ons may promise to increase the chances of conceiving a child, there is often a lack of credible evidence to support their efficacy for most fertility patients. Additionally, these add-ons often require an extra cost on top of the routine expenses associated with proven fertility treatments.',
      marginLeft + 10,
      (scopeHeight += spaceBetween),
      { maxWidth: 170, align: 'justify' }
    );

  doc
    .setFont('normal', 'bold')
    .text(
      'Further information:',
      marginLeft,
      (scopeHeight += spaceBetween * 2 + 3)
    )
    .setFont('normal', 'normal')
    .text('The HFEA website', marginLeft + 10, (scopeHeight += spaceBetween))
    .setTextColor(BLUE_COLOR)
    .textWithLink(
      'www.hfea.gov.uk',
      marginLeft + 40,
      scopeHeight,
      ALIGN_LEFT,
      'www.hfea.gov.uk'
    );
  doc.setTextColor(BLACK_COLOR).text(' and ', marginLeft + 67, scopeHeight);
  doc
    .setTextColor(BLUE_COLOR)
    .textWithLink(
      ' www.p4fertility.co.uk',
      marginLeft + 75,
      scopeHeight,
      ALIGN_LEFT,
      ' www.p4fertility.co.uk'
    );
};

export const addOnePage = (
  doc: any,
  clinicAddress: string,
  email: string,
  phone: string
) => {
  doc.addPage('a4', '1').setProperties({ title: 'P4 Fertility' });
  addHeader(doc, clinicAddress, email, phone);
  addFooter(doc);
  return doc;
};
