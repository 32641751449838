import React, { memo, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { CalendarIcon, ClockIcon, UpDownArrowIcon } from '../../assets/icons';

import { Button, buttonVariants, Label } from '../UI';
import { Calendar } from '../UI';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../UI';
import { cn } from '../../utils';
import { DatePickerProps } from '../../types/uiComponents/type';

const DatePicker: React.FC<DatePickerProps> = memo(
  ({
    handleDateChange,
    variant = 'date',
    buttonClass = '',
    showOutsideDays = true,
    initialValue,
    disableAfterDate,
    hasError,
    disabled = false,
  }) => {
    const [date, setDate] = useState<Date | undefined>(new Date());
    const [hour, setHour] = useState<string>('00');
    const [min, setMin] = useState<string>('00');
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
      if (!initialValue) return;

      let dateValue;

      if (typeof initialValue === 'string') {
        // Check if the input string is a Unix timestamp
        const unixTimestamp = Number(initialValue);
        if (!isNaN(unixTimestamp)) {
          dateValue = new Date(unixTimestamp);
        } else {
          // Assume it's an ISO string
          dateValue = new Date(initialValue);
        }
      } else if (initialValue instanceof Date) {
        dateValue = initialValue;
      }

      if (dateValue) {
        if (variant === 'date' || variant === 'fullDate') {
          setDate(dateValue);
        } else if (variant === 'dateTime') {
          setDate(dateValue);
          const hours = dateValue.getHours().toString().padStart(2, '0');
          const minutes = dateValue.getMinutes().toString().padStart(2, '0');
          setHour(hours);
          setMin(minutes);
        } else if (variant === 'time') {
          const hours = dateValue.getHours().toString().padStart(2, '0');
          const minutes = dateValue.getMinutes().toString().padStart(2, '0');
          setHour(hours);
          setMin(minutes);
        }
      }
    }, [initialValue, variant]);

    const dateHandler = (e?: Date) => {
      if (e) {
        setDate(e);
        handleDateChange(e);
        if (variant === 'date' || variant === 'fullDate') {
          setIsOpen(false);
        }
      }
    };
    const applyDate = () => {
      if (variant === 'dateTime' || variant === 'time') {
        if (date !== undefined) {
          const dateObject = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            Number(hour),
            Number(min)
          );

          handleDateChange(dateObject);
          setIsOpen(false);
        }
      }
    };

    const cancelHandler = () => {
      setIsOpen(false);
    };

    return (
      <Popover open={isOpen} onOpenChange={setIsOpen}>
        <PopoverTrigger asChild className="w-full" disabled={disabled}>
          <div className="cursor-pointer">
            {variant === 'date' && (
              <div
                className={cn(
                  'px-[6px] py-[8px]',
                  buttonVariants({ variant: 'outlined', size: 'medium' }),
                  hasError && 'border-destructive-300',
                  buttonClass
                )}
              >
                {date ? (
                  date.toDateString() === new Date().toDateString() ? (
                    <span>Today</span>
                  ) : (
                    <span>{format(date, 'PP')}</span>
                  )
                ) : (
                  <span>Pick a date</span>
                )}
                <CalendarIcon />
              </div>
            )}
            {variant === 'dateTime' && (
              <div
                className={cn(
                  'px-[6px] py-[8px]',
                  buttonVariants({ variant: 'outlined', size: 'medium' }),
                  hasError && 'border-destructive-300',
                  'border-neutral-100 w-full justify-between'
                )}
              >
                {date ? (
                  <span>
                    {format(date, 'PP') +
                      ' at ' +
                      (hour && min ? `${hour}:${min}` : '00:00')}
                  </span>
                ) : (
                  <span className="text-neutral-400">dd/mm/yyyy at time</span>
                )}
                <CalendarIcon />
              </div>
            )}
            {variant === 'fullDate' && (
              <div
                className={cn(
                  'px-[6px] py-[8px]',
                  buttonVariants({ variant: 'outlined', size: 'medium' }),
                  hasError && 'border-destructive-300',
                  'border-neutral-100 w-full justify-between'
                )}
              >
                {date ? (
                  <span>{format(date, 'PP')}</span>
                ) : (
                  <span className="text-neutral-400">dd/mm/yyyy</span>
                )}
                <CalendarIcon />
              </div>
            )}
            {variant === 'time' && (
              <div
                className={cn(
                  'px-[6px] py-[8px] w-full  justify-between',
                  buttonVariants({ variant: 'outlined', size: 'medium' }),
                  'border-neutral-100 w-full  justify-between',
                  hasError && 'border-destructive-300'
                )}
              >
                {hour && min ? <span>{`${hour}:${min}`}</span> : 'Pick a time'}
                <ClockIcon />
              </div>
            )}
          </div>
        </PopoverTrigger>
        <PopoverContent
          className="w-auto p-3 z-[999999] bg-shades-0"
          style={{
            zIndex: 999999,
            maxHeight: '80vh',
            overflowY: 'auto',
          }}
        >
          {(variant === 'date' ||
            variant === 'dateTime' ||
            variant === 'fullDate') && (
            <Calendar
              mode="single"
              selected={date}
              defaultMonth={date}
              onSelect={e => dateHandler(e)}
              showOutsideDays={showOutsideDays}
              initialFocus
              className="p-0 z-[0]"
              disableAfterDate={disableAfterDate}
            />
          )}
          {(variant === 'dateTime' || variant === 'time') && (
            <>
              <div className="border-t border-neutral-100 mt-3">
                <TimePicker
                  min={min}
                  setMin={setMin}
                  hour={hour}
                  setHour={setHour}
                />
              </div>
              <div className="border-t border-neutral-100 flex gap-3 pt-3 mt-3">
                <Button
                  variant={'link'}
                  size={'large'}
                  className="w-full"
                  onClick={cancelHandler}
                >
                  Close
                </Button>
                <Button
                  variant={'primary'}
                  size={'large'}
                  className="w-full"
                  onClick={applyDate}
                >
                  Apply
                </Button>
              </div>
            </>
          )}
        </PopoverContent>
      </Popover>
    );
  }
);

const TimePicker = ({
  hour,
  setHour,
  min,
  setMin,
}: {
  hour: string;
  setHour: (val: string) => void;
  min: string;
  setMin: (val: string) => void;
}) => {
  const hours: string[] = React.useMemo(() => {
    const hoursArray: string[] = [];
    for (let i = 0; i < 24; i++) {
      const formattedHour = i.toString().padStart(2, '0');
      hoursArray.push(formattedHour);
    }
    return hoursArray;
  }, []);
  const mins = React.useMemo(() => {
    const minsArray: string[] = [];
    for (let i = 0; i < 60; i++) {
      const formattedHour = i.toString().padStart(2, '0');
      minsArray.push(formattedHour);
    }
    return minsArray;
  }, []);
  return (
    <div className="flex justify-between gap-3">
      <div className="space-y2 w-full">
        <Label>Hour</Label>
        <Select onValueChange={value => setHour(value)}>
          <SelectTrigger className="w-full" selectIcon={<UpDownArrowIcon />}>
            <SelectValue placeholder={hour} />
          </SelectTrigger>
          <SelectContent className="bg-shades-0 z-[999999]">
            {hours.map(option => (
              <SelectItem key={option} value={option} className="w-[200px]">
                {option}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <div className="space-y2 w-full">
        <Label>Minutes</Label>
        <Select onValueChange={value => setMin(value)}>
          <SelectTrigger className="w-full" selectIcon={<UpDownArrowIcon />}>
            <SelectValue placeholder={min} />
          </SelectTrigger>
          <SelectContent className="bg-shades-0 z-[999999]">
            {mins.map(option => (
              <SelectItem key={option} value={option} className="w-[200px]">
                {option}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  );
};

export { DatePicker };
