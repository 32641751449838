import React, { useState, useMemo, useEffect } from 'react';
import { AlertDialog, DataTable, notify } from '../../../../common';
import { Button, Slider } from '../../../../UI';
import {
  AddIcon,
  DeleteIcon,
  // FilterIcon,
  OpenEyeIcon2,
  DirectedArrowIcon,
} from '../../../../../assets/icons';
import { ColumnDef } from '@tanstack/react-table';
import { Edit2 } from 'lucide-react';
import AddAndEditTeamModal from './AddAndEditTeamModal';
import ViewTeamModal from './ViewTeamModal';
import { deleteTeam, getAllTeams } from '../../../../../services/api';
import { useDispatch } from 'react-redux';
import {
  addTeams,
  deleteTeam as deleteTeamRedux,
} from '../../../../../redux/slice';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import {
  ERROR_TITLE,
  SUCCESS_TITLE,
} from '../../../../../constants/NotificationConstants';
const Teams = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAddTeamModalOpen, setIsAddTeamModalOpen] = useState<boolean>(false);
  const [isEditTeamModalOpen, setIsEditTeamModalOpen] =
    useState<boolean>(false);
  const [selectedTeamId, setSelectedTeamId] = useState<string | null>(null);
  const [isViewTeamModalOpen, setIsViewTeamModalOpen] =
    useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [selectedDeleteTeam, setSelectedDeleteTeam] = useState<TeamLite | null>(
    null
  );
  const dispatch = useDispatch();

  const { teams } = useSelector<RootState, TeamState>(state => state.teams);
  useEffect(() => {
    const getTeams = async () => {
      setIsLoading(true);
      const [res] = await getAllTeams();
      if (res) {
        dispatch(addTeams(res));
      }
      setIsLoading(false);
    };
    getTeams();
  }, []);

  const teamsColumn: ColumnDef<TeamLite>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: ({ column }) => {
          return (
            <button
              className="flex gap-1 items-center"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
            >
              Team name
              {column.getIsSorted() === 'asc' ? (
                <div className="rotate-180">
                  <DirectedArrowIcon fill="currentColor" />{' '}
                </div>
              ) : (
                <DirectedArrowIcon fill="currentColor" />
              )}
            </button>
          );
        },
      },
      {
        accessorKey: 'location.name',
        header: 'Location',
      },
      {
        accessorKey: 'teamLeader.fullName',
        header: 'Team leader',
      },
      {
        id: 'actions',
        cell: ({ row }) => {
          return (
            <div className="flex justify-end">
              <div className="flex gap-2">
                <Button
                  variant={'outlined'}
                  className="md:px-2 font-semibold text-[14px] gap-1"
                  size={'xmall'}
                  trailingIcon={<OpenEyeIcon2 />}
                  onClick={() => {
                    setIsViewTeamModalOpen(true);
                    setSelectedTeamId(row.original.publicId);
                  }}
                >
                  View
                </Button>
                <Button
                  variant={'outlined'}
                  className="md:px-2 font-semibold text-[14px] gap-2"
                  trailingIcon={<Edit2 size={15} />}
                  size={'xmall'}
                  onClick={() => {
                    setSelectedTeamId(row.original.publicId);
                    setIsEditTeamModalOpen(true);
                  }}
                >
                  Edit
                </Button>
                <Button
                  variant={'outlined'}
                  className="md:px-2 font-semibold text-[14px] gap-1"
                  trailingIcon={<DeleteIcon />}
                  size={'xmall'}
                  onClick={() => {
                    setSelectedDeleteTeam(row.original);
                    setIsDeleteModalOpen(true);
                  }}
                >
                  Delete
                </Button>
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  const deleteHandler = async () => {
    if (!selectedDeleteTeam) return;
    setIsDeleting(true);
    const [, err] = await deleteTeam(selectedDeleteTeam.publicId);
    if (!err) {
      dispatch(deleteTeamRedux({ publicId: selectedDeleteTeam.publicId }));
      notify.success({
        title: SUCCESS_TITLE.GENERIC_TEAM_DELETE,
        message: `'${selectedDeleteTeam.name}' deleted successfully`,
      });
    } else {
      notify.error({
        title: ERROR_TITLE.GENERIC_TEAM_DELETE,
        message: err.data,
      });
    }
    closeDeleteModalHandler();
  };
  const closeDeleteModalHandler = () => {
    setIsDeleting(false);
    setSelectedDeleteTeam(null);
    setIsDeleteModalOpen(false);
  };
  return (
    <div>
      {isAddTeamModalOpen && (
        <AddAndEditTeamModal
          open={isAddTeamModalOpen}
          type="Create"
          onClose={() => setIsAddTeamModalOpen(false)}
        />
      )}
      {isEditTeamModalOpen && selectedTeamId && (
        <AddAndEditTeamModal
          open={isEditTeamModalOpen}
          type="Edit"
          selectedTeamId={selectedTeamId}
          onClose={() => {
            setIsEditTeamModalOpen(false);
            setSelectedTeamId(null);
          }}
        />
      )}
      {isViewTeamModalOpen && selectedTeamId && (
        <ViewTeamModal
          open={isViewTeamModalOpen}
          onClose={() => {
            setIsViewTeamModalOpen(false);
            setSelectedTeamId(null);
          }}
          selectedTeamId={selectedTeamId}
        />
      )}
      {isDeleteModalOpen && selectedDeleteTeam && (
        <AlertDialog
          type="delete"
          actionButtonText={!isDeleting ? 'Delete' : 'Deleting'}
          alertTitle={`Deleting ${selectedDeleteTeam.name}`}
          onClose={closeDeleteModalHandler}
          open={isDeleteModalOpen}
          onAction={deleteHandler}
          isActionStarted={isDeleting}
        >
          Are you sure you want to delete {selectedDeleteTeam.name} ?
        </AlertDialog>
      )}
      <div className="px-[12px]  py-[16px] w-full flex justify-between items-center ">
        <h2 className="font-semibold text-[16px] text-neutral-900">Team</h2>
        <div className="flex gap-3 items-center justify-center">
          {/* <Button variant={'link'} leadingIcon={<FilterIcon />} size={'xmall'}>
            Filter
          </Button> */}
          <Button
            variant={'primary'}
            size={'small'}
            onClick={() => setIsAddTeamModalOpen(true)}
            trailingIcon={<AddIcon stroke="currentColor" />}
          >
            Add team
          </Button>
        </div>
      </div>
      <Slider variant={'vertical'} className="max-h-[520px]">
        <DataTable columns={teamsColumn} data={teams} isLoading={isLoading} />
      </Slider>
    </div>
  );
};

export default Teams;
