/* eslint-disable indent */
import { ColumnDef } from '@tanstack/react-table';
import {
  DeleteIcon,
  DirectedArrowIcon,
  DotMenu,
  DownArrowIcon,
  NotePenIcon,
  OpenEyeIcon2,
  OutlinedAddIcon,
  RightArrow,
  SendIcon,
} from '../../../../../assets/icons';
import { Button, Slider, buttonVariants } from '../../../../UI';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { ActionDropdown, DataTable, AlertDialog } from '../../../../common';
import { Link, useLocation, useParams } from 'react-router-dom';
import {
  FilledForm,
  FormFormat,
  Format,
  FormatGroup,
  FormatType,
} from '../../../../../types/form/type.d';
import {
  getFormFormatByType,
  getFilledForms,
  sendFormFilled,
  deleteFormFilled,
} from '../../../../../services/api';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuGroup,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuSubContent,
} from '../../../../UI';
import { getAppointmentReadableDateTime } from '../../../../../utils/date.utl';
import { notify } from '../../../../common/Alert';
import { ERROR_MESSAGE } from '../../../../../constants/NotificationConstants';
import { THEME } from '../../../../../constants/ColorConstants';

const ALL_FORMATS_OPT = {
  publicId: 'all',
  name: 'All',
};

const MedicalNotes = ({
  type = FormatType.MEDICAL_NOTE,
}: {
  type?: FormatType;
}) => {
  const [allFormFormats, setAllFormFormat] = useState<FormFormat[]>([]);
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(false);
  const [allFilledForms, setAllFilledForms] = useState<FilledForm[]>([]);
  const [formFormatsLoading, setFormFormatsLoading] = useState<boolean>(false);
  const [selectedFormFormat, setSelectedFormFormat] = useState<{
    name: string;
    publicId: string;
  } | null>(null);
  const { id } = useParams<{ id: string }>() as {
    id: string;
  };
  const [isSendingPublicId, setIsSendingPublicId] = useState<string | null>(
    null
  );
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [selectedDeleteFilledForm, setSelectedDeleteFilledForm] =
    useState<FilledForm | null>(null);
  const [isDeleteModalOpen, setIsDeleModalOpen] = useState<boolean>(false);

  const location = useLocation();

  useEffect(() => {
    const getAllFormFormatData = async () => {
      setFormFormatsLoading(true);
      const [response, error] = await getFormFormatByType(type);
      if (response) {
        // currently this logic will work only for 1 layer data
        const formatData: FormFormat[] = [];

        const selectedFormFormatData: {
          name: string;
          publicId: string;
        } =
          location.state &&
          !location.state.showingAll &&
          location.state.formFormatPublicId &&
          location.state.formFormatPublicId != ALL_FORMATS_OPT.publicId
            ? { publicId: location.state?.formFormatPublicId, name: '' }
            : ALL_FORMATS_OPT;

        //define a map of groups (string) to Formats Format[]
        const formatsByGroupPublicId = new Map<string, Format[]>();
        const groupByPublicId = new Map<string, FormatGroup>();

        for (const formFormatsData of response) {
          // if we are coming back from edit/create then need to set the selected form prevously
          if (
            selectedFormFormatData.publicId &&
            selectedFormFormatData.publicId === formFormatsData.publicId
          ) {
            selectedFormFormatData.name = formFormatsData.name;
          }

          if (!groupByPublicId.has(formFormatsData.group.publicId)) {
            groupByPublicId.set(formFormatsData.group.publicId, {
              publicId: formFormatsData.group.publicId,
              name: formFormatsData.group.name,
              isDefault: formFormatsData.group.isDefault,
            });
          }

          if (!formatsByGroupPublicId.has(formFormatsData.group.publicId)) {
            formatsByGroupPublicId.set(formFormatsData.group.publicId, []);
          }

          formatsByGroupPublicId.get(formFormatsData.group.publicId)?.push({
            publicId: formFormatsData.publicId,
            name: formFormatsData.name,
            handwrittenFormatUrl: formFormatsData.handwrittenFormatUrl,
            printFormatUrl: formFormatsData.printFormatUrl,
          });
        }

        //add groups & formats to formatData
        for (const [groupPublicId, formats] of formatsByGroupPublicId) {
          formatData.push({
            group: groupByPublicId.get(groupPublicId) as FormatGroup,
            formats: formats,
          });
        }

        //add all option with default group
        formatData.push({
          group: {
            publicId: ALL_FORMATS_OPT.publicId,
            name: ALL_FORMATS_OPT.name,
            isDefault: true,
          },
          formats: [ALL_FORMATS_OPT],
        });
        setAllFormFormat(formatData);
        selectFormFormat(selectedFormFormatData);
      } else {
        notify.error({
          title: `Unable to get ${
            type === FormatType.MEDICAL_NOTE
              ? 'medical report'
              : type === FormatType.HANDWRITTEN_NOTE
                ? 'handwritten note'
                : 'administrative form'
          } formats`,
          message: error?.data || ERROR_MESSAGE.GENERIC_TRY_AGAIN,
        });
      }
      setFormFormatsLoading(false);
    };
    getAllFormFormatData();
  }, []);

  const medicalNoteColumnDefn: ColumnDef<FilledForm>[] = useMemo(
    () => [
      {
        accessorKey: 'formFormat.name',
        id: 'name',
        header: ({ column }) => {
          return (
            <button
              className="flex gap-1 items-center"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
            >
              Name
              {column.getIsSorted() === 'asc' ? (
                <div className="rotate-180">
                  <DirectedArrowIcon fill={THEME.NEUTRAL_500} />{' '}
                </div>
              ) : (
                <DirectedArrowIcon fill={THEME.NEUTRAL_500} />
              )}
            </button>
          );
        },

        cell: ({ row }) => {
          return (
            <p className="space-y-5">
              {row.getValue('name')}{' '}
              {row.original.draft && (
                <span className="rounded-xl border border-neutral-100 px-1.5">
                  Draft
                </span>
              )}
            </p>
          );
        },
      },
      {
        accessorKey: 'whenCreated',
        header: ({ column }) => {
          return (
            <button
              className="flex gap-1 items-center"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
            >
              Date Created
              {column.getIsSorted() === 'asc' ? (
                <div className="rotate-180">
                  <DirectedArrowIcon fill={THEME.NEUTRAL_500} />{' '}
                </div>
              ) : (
                <DirectedArrowIcon fill={THEME.NEUTRAL_500} />
              )}
            </button>
          );
        },
        cell: ({ row }) => {
          return (
            <>
              {getAppointmentReadableDateTime(
                new Date(row.original.whenCreated)
              )}
            </>
          );
        },
      },
      {
        accessorKey: 'whenLastUpdated',
        header: ({ column }) => {
          return (
            <button
              className="flex gap-1 items-center"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
            >
              Date Updated
              {column.getIsSorted() === 'asc' ? (
                <div className="rotate-180">
                  <DirectedArrowIcon fill={THEME.NEUTRAL_500} />{' '}
                </div>
              ) : (
                <DirectedArrowIcon fill={THEME.NEUTRAL_500} />
              )}
            </button>
          );
        },
        cell: ({ row }) => {
          return (
            <>
              {row.original.whenLastUpdated
                ? getAppointmentReadableDateTime(
                    new Date(row.original.whenLastUpdated)
                  )
                : ''}
            </>
          );
        },
      },
      {
        accessorKey: 'staff.fullName',
        header: 'Created By',
      },
      {
        id: 'actions',
        cell: ({ row }) => {
          const dropDownMenu: {
            label: string;
            Icon: ReactNode;
            action: () => void;
          }[] = [
            {
              label: 'Delete',
              Icon: <DeleteIcon stroke={THEME.DESTRUCTIVE_500} />,
              action: () => {
                setSelectedDeleteFilledForm(row.original);
                setIsDeleModalOpen(true);
              },
            },
          ];
          return (
            <div className="flex justify-end">
              <div className="flex gap-8">
                <div className="flex gap-2">
                  {type == FormatType.HANDWRITTEN_NOTE ? (
                    <Link
                      className={buttonVariants({
                        variant: 'outlined',
                        size: 'xmall',
                      })}
                      to={
                        row.original.formFormat
                          ? 'create/' + row.original.formFormat.publicId
                          : ''
                      }
                      state={{
                        from: location.pathname,
                        editMode: true,
                        filledFormPublicId: row.original.publicId,
                        formFormatName: row.original.formFormat.name,
                        locationPublicId: row.original.location?.publicId,
                        showingAll:
                          selectedFormFormat &&
                          selectedFormFormat.name === ALL_FORMATS_OPT.name,
                      }}
                    >
                      View <OpenEyeIcon2 />
                    </Link>
                  ) : (
                    <Link
                      className={buttonVariants({
                        variant: 'outlined',
                        size: 'xmall',
                      })}
                      to={'view/' + row.original.publicId}
                      state={{
                        from: location.pathname,
                        filledFormPublicId: row.original.publicId,
                        formFormatName: row.original.formFormat.name,
                        selectedFormFormatPublicId:
                          selectedFormFormat?.publicId,
                        showingAll:
                          selectedFormFormat &&
                          selectedFormFormat.name === ALL_FORMATS_OPT.name,
                      }}
                    >
                      View <OpenEyeIcon2 />
                    </Link>
                  )}
                  <Link
                    className={buttonVariants({
                      variant: 'outlined',
                      size: 'xmall',
                    })}
                    to={
                      row.original.formFormat
                        ? 'create/' + row.original.formFormat.publicId
                        : ''
                    }
                    state={{
                      from: location.pathname,
                      editMode: true,
                      filledFormPublicId: row.original.publicId,
                      formFormatName: row.original.formFormat.name,
                      locationPublicId: row.original.location?.publicId,
                      selectedFormFormatPublicId: selectedFormFormat?.publicId,
                      showingAll:
                        selectedFormFormat &&
                        selectedFormFormat.name === ALL_FORMATS_OPT.name,
                    }}
                  >
                    Edit <NotePenIcon />
                  </Link>
                  {type != FormatType.HANDWRITTEN_NOTE && (
                    <Button
                      variant={'outlined'}
                      size={'xmall'}
                      className="w-[90px]"
                      disabled={
                        isSendingPublicId
                          ? isSendingPublicId === row.original.publicId &&
                            row.original.draft
                          : row.original.draft
                      }
                      trailingIcon={<SendIcon />}
                      isLoading={isSendingPublicId === row.original.publicId}
                      onClick={() => sendHandler(row.original.publicId)}
                    >
                      Send
                    </Button>
                  )}
                </div>
                <ActionDropdown
                  side="bottom"
                  align="end"
                  sideOffset={3}
                  alignOffset={18}
                  drownDownItems={dropDownMenu}
                >
                  <button className="w-8 h-8 p-0 outline-none border-none">
                    <span className="sr-only">Open menu</span>
                    <DotMenu />
                  </button>
                </ActionDropdown>
              </div>
            </div>
          );
        },
      },
    ],
    [isSendingPublicId, selectedFormFormat, allFilledForms]
  );

  const deleteHandler = async () => {
    if (!selectedDeleteFilledForm) return;
    setIsDeleting(true);
    const [response, error] = await deleteFormFilled(
      selectedDeleteFilledForm.publicId
    );
    if (response) {
      const copiedData: FilledForm[] = [...allFilledForms];
      const updatedData = copiedData.filter(
        filledForm => filledForm.publicId !== selectedDeleteFilledForm.publicId
      );
      setAllFilledForms(updatedData);
      notify.success({
        title: 'Successfully deleted',
      });
    } else {
      notify.error({
        title: 'Unable to delete. Please try again later',
        message: error.data,
      });
    }
    closeDeleteModalHandler();
  };
  const closeDeleteModalHandler = () => {
    setSelectedDeleteFilledForm(null);
    setIsDeleting(false);
    setIsDeleModalOpen(false);
  };

  const sendHandler = async (publicId: string) => {
    setIsSendingPublicId(publicId);
    const [response, error] = await sendFormFilled(publicId);
    if (response && !error) {
      notify.success({
        title: 'Successfully sent to patient',
        message: 'Patient will receive it in an email.',
      });
    } else {
      notify.error({
        message: error.data,
        title: 'Failed to send',
      });
    }
    setIsSendingPublicId(null);
  };

  const selectFormFormat = async (data: { name: string; publicId: string }) => {
    setDataIsLoading(true);
    setSelectedFormFormat(data);
    let [response, error] = [null, null];

    if (data && data.name === ALL_FORMATS_OPT.name) {
      [response, error] = await getFilledForms(id, undefined, undefined, type);
    } else if (data && data.publicId) {
      [response, error] = await getFilledForms(
        id,
        data.publicId,
        undefined,
        type
      );
    }

    if (response) {
      setAllFilledForms(response);
    } else if (error) {
      notify.error({
        title: `Unable to get ${data.name} ${
          type === FormatType.MEDICAL_NOTE
            ? 'reports'
            : type === FormatType.HANDWRITTEN_NOTE
              ? 'notes'
              : 'forms'
        }`,
        message: ERROR_MESSAGE.GENERIC_TRY_AGAIN,
      });
    }
    setDataIsLoading(false);
  };

  return (
    <>
      {isDeleteModalOpen && selectedDeleteFilledForm && (
        <AlertDialog
          type="delete"
          open={isDeleteModalOpen}
          onClose={closeDeleteModalHandler}
          onAction={deleteHandler}
          alertTitle={`Deleting ${selectedDeleteFilledForm.formFormat.name}`}
          isActionStarted={isDeleting}
          actionButtonText={isDeleting ? 'Deleting' : 'Delete'}
        >
          Are you sure you want to delete{' '}
          {selectedDeleteFilledForm.formFormat.name} ?
        </AlertDialog>
      )}
      <div className="flex flex-col h-[520px]">
        <div className="p-3 flex justify-between items-center">
          <FromFormatDropDown
            formFormatInfo={allFormFormats}
            selectedFormFormatName={selectedFormFormat?.name}
            selectFormFormat={selectFormFormat}
          />
          <h1 className="text-neutral-900 font-medium whitespace-nowrap truncate">
            {selectedFormFormat ? selectedFormFormat.name : ''}
          </h1>
          {selectedFormFormat &&
          selectedFormFormat.name !== ALL_FORMATS_OPT.name ? (
            <Link
              className={buttonVariants({ variant: 'primary', size: 'small' })}
              to={
                selectedFormFormat
                  ? 'create/' + selectedFormFormat.publicId
                  : ''
              }
              state={{
                from: location.pathname,
                formFormatName: selectedFormFormat?.name,
              }}
            >
              <OutlinedAddIcon /> Create
            </Link>
          ) : (
            <Button
              className={buttonVariants({ variant: 'primary', size: 'small' })}
              disabled={true}
            >
              <OutlinedAddIcon /> Create
            </Button>
          )}
        </div>
        <Slider variant={'vertical'} className="flex-1">
          <DataTable
            columns={medicalNoteColumnDefn}
            data={allFilledForms}
            isLoading={dataIsLoading || formFormatsLoading}
          />
        </Slider>
      </div>
    </>
  );
};

const FromFormatDropDown = ({
  formFormatInfo,
  selectedFormFormatName,
  selectFormFormat,
}: {
  formFormatInfo: FormFormat[];
  selectedFormFormatName: string | undefined;
  selectFormFormat: (data: { name: string; publicId: string }) => void;
}) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div>
          <Button
            variant="outlined"
            size={'small'}
            trailingIcon={<DownArrowIcon stroke={THEME.SHADE_100} />}
            className="max-w-[200px]"
          >
            <span className="truncate">{selectedFormFormatName}</span>
          </Button>
        </div>
      </DropdownMenuTrigger>
      {formFormatInfo && formFormatInfo.length > 0 && (
        <DropdownMenuContent
          className="min-w-[200px]"
          side={'bottom'}
          align={'start'}
          // alignOffset={40}
        >
          <DropdownMenuGroup>
            {formFormatInfo.map(format =>
              format.group.isDefault ? (
                <DropdownMenuItem
                  key={format.formats[0].publicId}
                  onClick={() =>
                    selectFormFormat({
                      publicId: format.formats[0].publicId,
                      name: format.formats[0].name,
                    })
                  }
                >
                  {format.formats[0].name}
                </DropdownMenuItem>
              ) : (
                <DropdownMenuSub key={format.group.publicId}>
                  <DropdownMenuSubTrigger>
                    {format.group.name}
                    <RightArrow />
                  </DropdownMenuSubTrigger>
                  <DropdownMenuPortal>
                    <DropdownMenuSubContent>
                      {format.formats.map(format => (
                        <DropdownMenuItem
                          key={format.publicId}
                          onClick={() =>
                            selectFormFormat({
                              publicId: format.publicId,
                              name: format.name,
                            })
                          }
                        >
                          {format.name}
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenuSubContent>
                  </DropdownMenuPortal>
                </DropdownMenuSub>
              )
            )}
          </DropdownMenuGroup>
        </DropdownMenuContent>
      )}
    </DropdownMenu>
  );
};

export default MedicalNotes;
