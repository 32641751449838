/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactNode, useState } from 'react';
import {
  Dot,
  OutlinedUserIcon,
  TeamIcon,
  GentlemanIcon,
  UnarchiveIcon,
} from '../../../../assets/icons';
import { useNavigate } from 'react-router-dom';
import { addDaysToDateTime } from '../../../../utils/date.utl';
import { PATHWAY_DEADLINE_STATUS } from '../../../../constants/constants';
import ArchiveIcon from '../../../../assets/icons/ArchiveIcon';
import {
  Button,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../../../../components/UI';
import { archiveEvent } from '../../../../services/api/endPoints/events';
import { notify } from '../../../../components/common';
import { ROUTES } from '../../../../constants/NavigationConstants';
import { THEME } from '../../../../constants/ColorConstants';

const StageCard: React.FC<TaskCardProps> = ({
  task,
  onDragStart,
  removeArchivedEvent,
  handleAddUnarchiveEvent,
  showPatient,
}) => {
  const navigate = useNavigate();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const deadline = addDaysToDateTime(
    task.whenLastStageUpdated,
    task.currentStage.maxWaitTimeDays || 15
  );

  const handleArchiveEvent = async () => {
    setIsUpdating(true);
    const [response, error] = await archiveEvent({
      publicId: task.eventPublicId,
      archived: true,
    });
    setIsUpdating(false);
    if (response) {
      removeArchivedEvent(task);
      notify.success({
        title: 'Archived successfully.',
      });
    } else {
      notify.error({
        title: 'Failed to archive.',
      });
    }
  };
  const handleUnarchiveEvent = async () => {
    setIsUpdating(true);
    const [response, error] = await archiveEvent({
      publicId: task.eventPublicId,
      archived: false,
    });
    setIsUpdating(false);
    if (response) {
      handleAddUnarchiveEvent(task);
      notify.success({
        title: 'Unarchived successfully.',
      });
    } else {
      notify.error({
        title: 'Failed to unarchive.',
        message: error.data,
      });
    }
  };
  const displayData = (
    icon: ReactNode,
    value: string,
    tooltipValue: string
  ) => {
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <p className="flex font-medium text text-sm text-neutral-600 space-x-2">
              <div className="mt-[1px]">{icon}</div>
              <span className="text-left leading-tight">{value}</span>
            </p>
          </TooltipTrigger>
          <TooltipContent>{tooltipValue}</TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  };
  const handlePatientClick = () => {
    sessionStorage.setItem('patientProfilePreviousPage', ROUTES.CLINIC_BOARD);
    navigate(`${ROUTES.ROUTE_TO_PATIENT_PROFILE}${task.patient.publicId}`);
  };
  return (
    <div
      className="cursor-grab p-4 rounded border space-y-2 border-neutral-100 shadow-[0px_2px_4px_0px_rgba(0,0,0,0.08)] bg-shades-0"
      draggable
      onDragStart={e => onDragStart(e, task)}
    >
      <div className="flex justify-between relative">
        {showPatient ? (
          <p
            className="text-primary-700 font-medium text text-sm cursor-pointer"
            onClick={handlePatientClick}
          >
            {task.patient?.fullName}
          </p>
        ) : (
          <p className="font-medium text text-sm text-neutral-900">
            {task.pathwayName}
          </p>
        )}
        {task.archived ? (
          <Button
            className="absolute right-0 border-none pr-0 !important"
            leadingIcon={<UnarchiveIcon stroke={THEME.NEUTRAL_500} />}
            variant={'outlined'}
            size={'xmall'}
            isLoading={isUpdating}
            onClick={handleUnarchiveEvent}
          ></Button>
        ) : (
          task.currentStage.finalStage && (
            <Button
              className="absolute right-0 border-none pr-0 !important"
              leadingIcon={<ArchiveIcon stroke={THEME.PRIMARY_500} />}
              variant={'outlined'}
              size={'xmall'}
              isLoading={isUpdating}
              onClick={handleArchiveEvent}
            ></Button>
          )
        )}
      </div>
      <div>
        {showPatient && (
          <p className="font-medium text text-sm text-neutral-900">
            {task.pathwayName}
          </p>
        )}
      </div>
      <div>
        {task.staff &&
          displayData(
            <GentlemanIcon stroke="currentColor" />,
            task.staff.fullName ?? '',
            `Consultant : ${task.staff.fullName}`
          )}
      </div>
      <div className="grid grid-cols-2 gap-2 items-start">
        {task.assignee &&
          displayData(
            <OutlinedUserIcon stroke="currentColor" />,
            task.assignee.fullName ?? '',
            `Assignee : ${task.assignee.fullName}`
          )}
        {task.assigneeTeam &&
          displayData(
            <TeamIcon stroke="currentColor" />,
            task.assigneeTeam.name,
            `Assignee team : ${task.assigneeTeam.name}`
          )}
      </div>
      <hr />
      <div className="flex justify-between">
        <p className="items-center font-medium text text-sm text-neutral-600">
          <span className="ml-2">Deadline</span>
        </p>
        <p className="items-center font-medium text text-sm text-neutral-600">
          <span className="ml-2"> {deadline.newDate}</span>
        </p>
      </div>
      <div className="flex gap-2">
        {task.archived && (
          <p
            className={
              'py-1 px-2 flex items-center gap-1 rounded border border-neutral-400 text text-sm w-[fit-content] bg-neutral-50'
            }
          >
            <Dot fill={THEME.NEUTRAL_500} />
            <span>Archived</span>
          </p>
        )}
        {deadline.isPast && (
          <p
            className={
              'py-1 px-2 flex items-center gap-1 rounded border border-rose-400 text text-sm w-[fit-content] bg-warning-50'
            }
          >
            <Dot fill={THEME.DESTRUCTIVE_500} />
            <span>{PATHWAY_DEADLINE_STATUS.DEADLINE_CROSSED}</span>
          </p>
        )}
      </div>
    </div>
  );
};

export default StageCard;
