import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: TreatmentsState = { treatments: [] };

export const treatmentsSlice = createSlice({
  name: 'treatments',
  initialState,
  reducers: {
    addTreatments: (state, action: PayloadAction<TreatmentLite[]>) => {
      state.treatments = action.payload;
    },
    deleteTreatment: (state, action: PayloadAction<{ publicId: string }>) => {
      state.treatments = state.treatments.filter(
        treatment => treatment.publicId !== action.payload.publicId
      );
    },
    addTreatment: (state, action: PayloadAction<TreatmentLite>) => {
      state.treatments.push(action.payload);
    },
    updateTreatment: (state, action: PayloadAction<TreatmentLite>) => {
      const siteIndex = state.treatments.findIndex(
        treatment => treatment.publicId === action.payload.publicId
      );
      state.treatments[siteIndex] = action.payload;
    },
  },
});

export default treatmentsSlice.reducer;
export const { addTreatments, deleteTreatment, addTreatment, updateTreatment } =
  treatmentsSlice.actions;
