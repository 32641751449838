import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogFooter } from '../../../UI';
import { Button } from '../../../UI';
import { CrossIcon, Edit2Icon } from '../../../../assets/icons';
import defaultImage from '../../../../assets/images/360_F_118019822_6CKXP6rXmVhDOzbXZlLqEM2ya4HhYzSV.jpg';
const ViewProfilePicModal = ({
  open,
  onClose,
  imageUrl,
  onEdit,
}: {
  open: boolean;
  onClose: () => void;
  onEdit: () => void;
  imageUrl: string | null;
}) => {
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="p-0 w-[60vh] md:w-[440px] min-h-[400px] max-h-[70vh] flex flex-col gap-0">
        <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
          <h1 className="text-[14px] text-neutral-900 font-medium">
            Patient profile picture
          </h1>
          <Button variant={'link'} size={'xmall'} onClick={onClose}>
            <CrossIcon />
          </Button>
        </DialogHeader>
        <div className="p-2 flex items-center justify-center">
          <img
            src={imageUrl ? imageUrl : defaultImage}
            alt="patient-profile"
            className="rounded-full h-[400px] w-[400px] object-fit"
          />
        </div>
        <DialogFooter className="flex gap-3 w-full ">
          <Button
            variant={'link'}
            size={'medium'}
            className="w-full"
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            size={'medium'}
            onClick={onEdit}
            className="w-full"
            leadingIcon={<Edit2Icon />}
          >
            Edit
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ViewProfilePicModal;
