import { FC } from 'react';
import { THEME } from '../../constants/ColorConstants';

const LinkIcon: FC<{
  stroke?: string;
}> = ({ stroke = THEME.NEUTRAL_900 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M9.01579 6.98421C8.14796 6.11638 6.74093 6.11638 5.8731 6.98421L3.65087 9.20643C2.78304 10.0743 2.78304 11.4813 3.65087 12.3491C4.51871 13.217 5.92574 13.217 6.79357 12.3491L7.40555 11.7371M6.98421 9.01579C7.85204 9.88362 9.25907 9.88363 10.1269 9.01579L12.3491 6.79357C13.217 5.92574 13.217 4.51871 12.3491 3.65087C11.4813 2.78304 10.0743 2.78304 9.20643 3.65087L8.59554 4.26177"
        stroke={stroke}
        strokeWidth="1.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LinkIcon;
