import React, { FC } from 'react';
import { THEME } from '../../constants/ColorConstants';

const DownArrowIcon: FC<{
  stroke?: string;
}> = ({ stroke = THEME.PRIMARY_600 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M4 6L8 10L12 6"
        stroke={stroke}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DownArrowIcon;
