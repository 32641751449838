/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store/store';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  ActionDropdown,
  AlertDialog,
  DataTable,
  notify,
} from '../../../../../components/common';
import { Slider, Button } from '../../../../../components/UI';
import {
  DeleteIcon,
  DirectedArrowIcon,
  DotMenu,
  LeftArrowIcon,
  NotePenIcon,
  OpenEyeIcon2,
  OutlinedAddIcon,
  EmbryoLogyIcon,
} from '../../../../../assets/icons';
import { ColumnDef } from '@tanstack/react-table';
import { THEME } from '../../../../../constants/ColorConstants';
import { getReadableDate } from '../../../../../utils/date.utl';
import {
  getAllEggCollections,
  deleteEggCollection,
} from '../../../../../services/api/endPoints/eggCollection';
import { useDispatch } from 'react-redux';
import {
  addEggCollections,
  deleteEggCollection as deleteEggCollectionRedux,
} from '../../../../../redux/slice';
import {
  ERROR_MESSAGE,
  ERROR_TITLE,
  SUCCESS_TITLE,
} from '../../../../../constants/NotificationConstants';
import EggCollectionModal from './EggCollectionModal';
import EggCollectionViewModal from './EggCollectionViewModal';
const EggCollections = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { eggCollections } = useSelector<RootState, EggCollectionState>(
    state => state.eggCollection
  );
  const [selectedEggCollection, setSelectedEggCollection] =
    useState<EggCollectionType | null>(null);
  const navigate = useNavigate();
  const { treatmentCycleId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const [addEggCollectionModalOpen, setAddEggCollectionModalOpen] =
    useState<boolean>(false);
  const [updateEggCollectionModalOpen, setUpdateEggCollectionModalOpen] =
    useState<boolean>(false);
  const [deleteEggCollectionModalOpen, setDeleteEggCollectionModalOpen] =
    useState<boolean>(false);
  const [viewEggCollectionModalOpen, setViewEggCollectionModalOpen] =
    useState<boolean>(true);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  useEffect(() => {
    const getEggCollections = async () => {
      if (!treatmentCycleId) {
        notify.error({
          title: ERROR_TITLE.GENERIC_ALL_EGG_COLLECTION_FETCH,
          message: 'Treatment cycle not found',
        });
        return;
      }
      setIsLoading(true);
      const [res, error] = await getAllEggCollections(treatmentCycleId);
      if (res) {
        dispatch(addEggCollections(res));
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_ALL_EGG_COLLECTION_FETCH,
          message: error?.data || ERROR_MESSAGE.GENERIC_TRY_AGAIN,
        });
      }
      setIsLoading(false);
    };
    getEggCollections();
  }, []);
  const eggCollectionColumn: ColumnDef<EggCollectionType>[] = [
    {
      accessorKey: 'publicId',
      header: ({ column }) => {
        return (
          <button
            className="flex gap-1 items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Public ID
            {column.getIsSorted() === 'asc' ? (
              <div className="rotate-180">
                <DirectedArrowIcon fill={THEME.NEUTRAL_500} />{' '}
              </div>
            ) : (
              <DirectedArrowIcon fill={THEME.NEUTRAL_500} />
            )}
          </button>
        );
      },
    },
    {
      accessorKey: 'collectionDate',

      header: ({ column }) => {
        return (
          <button
            className="flex gap-1 items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Start date
            {column.getIsSorted() === 'asc' ? (
              <div className="rotate-180">
                <DirectedArrowIcon fill={THEME.NEUTRAL_500} />{' '}
              </div>
            ) : (
              <DirectedArrowIcon fill={THEME.NEUTRAL_500} />
            )}
          </button>
        );
      },
      cell: ({ row }) => {
        return <>{getReadableDate(row.original.collectionDate)}</>;
      },
    },

    {
      accessorKey: 'performedBy.fullName',

      header: 'Performed by',
    },

    {
      accessorKey: 'follicles',

      header: ({ column }) => {
        return (
          <button
            className="flex gap-1 items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Follicles
            {column.getIsSorted() === 'asc' ? (
              <div className="rotate-180">
                <DirectedArrowIcon fill={THEME.NEUTRAL_500} />{' '}
              </div>
            ) : (
              <DirectedArrowIcon fill={THEME.NEUTRAL_500} />
            )}
          </button>
        );
      },
      cell: ({ row }) => {
        return <>{row.original.follicles}</>;
      },
    },

    {
      accessorKey: 'eggs',

      header: ({ column }) => {
        return (
          <button
            className="flex gap-1 items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Eggs
            {column.getIsSorted() === 'asc' ? (
              <div className="rotate-180">
                <DirectedArrowIcon fill={THEME.NEUTRAL_500} />{' '}
              </div>
            ) : (
              <DirectedArrowIcon fill={THEME.NEUTRAL_500} />
            )}
          </button>
        );
      },
      cell: ({ row }) => {
        return <>{row.original.eggs}</>;
      },
    },
    {
      id: 'action',
      cell: ({ row }) => {
        const dropDownMenu: dropDownMenuItem[] = [
          {
            label: 'Delete',
            Icon: <DeleteIcon stroke={THEME.DESTRUCTIVE_500} />,
            disabled: !!location?.state?.isReadOnly,
            action: () => {
              setSelectedEggCollection(row.original);
              setDeleteEggCollectionModalOpen(true);
            },
          },
        ];
        return (
          <div className="flex justify-end gap-8">
            <div className="flex gap-2">
              <Button
                variant={'outlined'}
                className="h-[30px]"
                size={'xmall'}
                trailingIcon={<OpenEyeIcon2 />}
                isLoading={false}
                onClick={() => {
                  setSelectedEggCollection(row.original);
                  setViewEggCollectionModalOpen(true);
                }}
              >
                View
              </Button>
              <Button
                variant={'outlined'}
                className="h-[30px]"
                size={'xmall'}
                trailingIcon={<NotePenIcon stroke="currentColor" />}
                isLoading={false}
                onClick={() => {
                  setSelectedEggCollection(row.original);
                  setUpdateEggCollectionModalOpen(true);
                }}
                disabled={!!location?.state?.isReadOnly}
              >
                Edit
              </Button>
              <Button
                variant={'outlined'}
                className="h-[30px]"
                size={'xmall'}
                isLoading={false}
                onClick={() => {}}
                trailingIcon={<EmbryoLogyIcon />}
              >
                Embryos
              </Button>
              <ActionDropdown
                drownDownItems={dropDownMenu}
                side="bottom"
                align="end"
                sideOffset={3}
                alignOffset={18}
              >
                <button className="w-8 h-8 p-0 outline-none border-none">
                  <span className="sr-only">Open menu</span>
                  <DotMenu />
                </button>
              </ActionDropdown>
            </div>
          </div>
        );
      },
    },
  ];
  const closeDeleteModalHandler = () => {
    setIsDeleting(false);
    setSelectedEggCollection(null);
    setDeleteEggCollectionModalOpen(false);
  };
  const deleteEggCollectionHandler = async () => {
    if (!selectedEggCollection) return;
    setIsDeleting(true);
    const [, error] = await deleteEggCollection(selectedEggCollection.publicId);
    if (!error) {
      notify.success({
        title: SUCCESS_TITLE.GENERIC_EGG_COLLECTION_DELETE,
        message: `Egg collection '${selectedEggCollection.publicId}' has been deleted`,
      });
      dispatch(
        deleteEggCollectionRedux({ publicId: selectedEggCollection.publicId })
      );
      closeDeleteModalHandler();
    } else {
      notify.error({
        title: ERROR_TITLE.GENERIC_EGG_COLLECTION_DELETE,
        message: error?.data || ERROR_MESSAGE.GENERIC_TRY_AGAIN,
      });
      setIsDeleting(false);
    }
  };
  return (
    <>
      {addEggCollectionModalOpen && (
        <EggCollectionModal
          type="Create"
          open={addEggCollectionModalOpen}
          onClose={() => setAddEggCollectionModalOpen(false)}
        />
      )}
      {updateEggCollectionModalOpen && selectedEggCollection && (
        <EggCollectionModal
          type="Edit"
          eggCollection={selectedEggCollection}
          open={updateEggCollectionModalOpen}
          onClose={() => {
            setSelectedEggCollection(null);
            setUpdateEggCollectionModalOpen(false);
          }}
        />
      )}
      {viewEggCollectionModalOpen && selectedEggCollection && (
        <EggCollectionViewModal
          open={viewEggCollectionModalOpen}
          eggCollection={selectedEggCollection}
          onClose={() => {
            setViewEggCollectionModalOpen(false);
            setSelectedEggCollection(null);
          }}
        />
      )}
      {deleteEggCollectionModalOpen && selectedEggCollection && (
        <AlertDialog
          type="delete"
          open={deleteEggCollectionModalOpen}
          onClose={closeDeleteModalHandler}
          onAction={deleteEggCollectionHandler}
          alertTitle={`Deleting egg collection '${selectedEggCollection.publicId}'`}
          isActionStarted={isDeleting}
          actionButtonText={isDeleting ? 'Deleting' : 'Delete'}
        >
          Are you sure you want to delete egg collection '{selectedEggCollection.publicId}' ?
        </AlertDialog>
      )}
      <div>
        <div className="p-3 flex justify-between items-center">
          <Button
            variant={'outlined'}
            size={'xmall'}
            leadingIcon={<LeftArrowIcon />}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          <h1 className="text-neutral-900 font-medium whitespace-nowrap">
            {location?.state?.treatmentCycleName} - Egg collection
          </h1>
          <div className="flex gap-3">
            <Button
              variant={'primary'}
              leadingIcon={<OutlinedAddIcon />}
              size={'xmall'}
              onClick={() => setAddEggCollectionModalOpen(true)}
              disabled={!!location?.state?.isReadOnly}
            >
              Add egg collection
            </Button>
          </div>
        </div>
        <Slider variant={'vertical'} className="h-[500px]">
          <DataTable
            columns={eggCollectionColumn}
            data={eggCollections}
            isLoading={isLoading}
          />
        </Slider>
      </div>
    </>
  );
};

export default EggCollections;
