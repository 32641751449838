// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ERROR_TO_DO } from '@/types';
import { URL } from '../requestConstructor';

export const createEvent = async (event: CreateEvent) => {
  try {
    const response = await URL.post('/event/create', event);

    return [response, null];
  } catch (error: ERROR_TO_DO) {
    return [null, error?.response];
  }
};

export const getEventByPatientID = async (patientId: string) => {
  try {
    const response = await URL.get(`/event/get?patientPublicId=${patientId}`);
    if (response.status === 200) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: ERROR_TO_DO) {
    return [null, error?.response?.data];
  }
};

export const updateEventStage = async (event: UpdateEventRequest) => {
  try {
    const response = await URL.put('event/update', event);
    return [response?.data, null];
  } catch (error: ERROR_TO_DO) {
    return [null, error.response];
  }
};

export const archiveEvent = async (event: ArchiveEventRequest) => {
  try {
    const response = await URL.put('event/update', event);
    if (response?.status >= 200 && response.status < 300) {
      return [response.data, null];
    } else if (response?.status >= 400 && response.status < 600) {
      return [null, response.data];
    } else {
      return [null, response];
    }
  } catch (error: ERROR_TO_DO) {
    return [null, error.response.data];
  }
};

export const getEventsByPathwayId = async (id: string, archived?: boolean) => {
  let queryString = `pathwayPublicId=${id}`;
  if (archived) {
    queryString = `pathwayPublicId=${id}&archived=true`;
  }

  try {
    const response = await URL.get(`/event/get?${queryString}`);
    if (response.status === 200) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: ERROR_TO_DO) {
    return [null, error?.response];
  }
};

export const getAllEvents = async () => {
  try {
    const response = await URL.get('/event/get/all');
    if (response.status === 200) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: ERROR_TO_DO) {
    return [null, error.response.data];
  }
};
export const getAllEventByIds = async (
  patientId: string,
  pathwayId: string
) => {
  try {
    const response = await URL.get(
      `/event/get?patientPublicId=${patientId}&pathwayPublicId=${pathwayId}`
    );
    if (response.status === 200) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: ERROR_TO_DO) {
    return [null, error.response.data];
  }
};

export const getArchivedEventsByPathwayId = async (pathwayId: string) => {
  try {
    const response = await URL.get(
      `/event/get?pathwayPublicId=${pathwayId}&archived=true`
    );
    if (response.status === 200) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: ERROR_TO_DO) {
    return [null, error.response.data];
  }
};

export const getArchivedEventsByPatientId = async (patientId: string) => {
  try {
    const response = await URL.get(
      `/event/get?patientPublicId=${patientId}&archived=true`
    );
    if (response.status === 200) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: ERROR_TO_DO) {
    return [null, error.response.data];
  }
};
