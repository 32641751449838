import { URL } from '../requestConstructor';

export const getSchedule = async (
  publicId: string,
  resourcePublicId: string,
  staffPublicId: string
) => {
  try {
    const response = await URL.get(
      `/booking/schedule/get?publicId=${publicId}&resourcePublicId=${resourcePublicId}&staffPublicId=${staffPublicId}`
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, 'Server Error: ' + error.response.data];
  }
};

export const createNewSchedule = async (data: CreateScheduleRequest) => {
  try {
    const response = await URL.post('/booking/schedule/create', data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response ? error.response.data : error.message];
  }
};

export const updateSchedule = async (data: CreateScheduleRequest) => {
  try {
    const response = await URL.put('/booking/schedule/update', data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response ? error.response.data : error.message];
  }
};
export const deleteSchedule = async (publicId: string) => {
  try {
    const response = await URL.delete(
      `/booking/schedule/delete?publicId=${publicId}`
    );
    return [response, null];
  } catch (error: any) {
    return [null, error.response ? error.response.data : error.message];
  }
};
