import React, { useEffect, useState } from 'react';
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoperClose,
  Checkbox,
} from '../../UI';
import { ListFilter } from 'lucide-react';
import { DownArrowIcon } from '../../../assets/icons';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../redux/store/store';
import { getAllResources } from '../../../services/api/endPoints/resources';
import { filterResourceBookings } from '../../../redux/slice/resourceBookingSlice';
import { notify } from '../../../components/common';

const ResourceBookingFilter = React.memo(() => {
  const dispatch = useDispatch();
  const applyFilters = () => {
    dispatch(
      filterResourceBookings({
        selectedLocations,
        selectedResources,
        selectedConsultants,
        selectedServices,
      })
    );
  };

  const [locationDropdown, setLocationDropdown] = useState<boolean>(false);
  const [resourceDropdown, setResourceDropdown] = useState<boolean>(false);
  const [consultantDropdown, setConsultantDropdown] = useState<boolean>(false);
  const [serviceDropdown, setServiceDropdown] = useState<boolean>(false);

  const locations = useSelector<RootState, Location[]>(
    state => state.locations.locations
  );
  const staffs = useSelector<RootState, StaffInformation[]>(
    state => state.staffs.staffs
  );
  const services = useSelector<RootState, Service[]>(
    state => state.services.services
  );
  const [resources, setResources] = useState<Resource[]>([]);

  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [selectedResources, setSelectedResources] = useState<string[]>([]);
  const [selectedConsultants, setSelectedConsultants] = useState<string[]>([]);
  const [selectedServices, setSelectedServices] = useState<string[]>([]);

  const getResources = async () => {
    const [response, error] = await getAllResources();
    if (response) {
      setResources(response);
    } else {
      notify.error({ title: 'Error Fetching Resources', message: error });
    }
  };

  useEffect(() => {
    getResources();
  }, []);

  const handleSelectAll = (
    setSelected: React.Dispatch<React.SetStateAction<string[]>>,
    selected: string[],
    items: { publicId: string }[]
  ) => {
    if (selected.length === items.length) {
      setSelected([]);
    } else {
      setSelected(items.map(item => item.publicId));
    }
  };

  const handleCheckboxChange = (
    setSelected: React.Dispatch<React.SetStateAction<string[]>>,
    selected: string[],
    publicId: string
  ) => {
    if (selected.includes(publicId)) {
      setSelected(selected.filter(id => id !== publicId));
    } else {
      setSelected([...selected, publicId]);
    }
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <div>
          <Button variant={'link'} size={'xmall'} leadingIcon={<ListFilter />}>
            <span className="sr-only">Open menu</span>
            Filter
          </Button>
        </div>
      </PopoverTrigger>
      <PopoverContent
        align="center"
        className="min-w-[310px] bg-shades-0 space-y-3"
      >
        <div>
          {/* Location Filter */}
          <div className="flex flex-row items-center justify-between px-5 py-3 border-b border-neutral-100">
            <h1 className="text-sm not-italic font-medium leading-[120%]">
              Location
            </h1>
            <div
              className="cursor-pointer"
              onClick={() => setLocationDropdown(!locationDropdown)}
            >
              {locationDropdown ? (
                <div className="transform rotate-180">
                  <DownArrowIcon />
                </div>
              ) : (
                <div>
                  <DownArrowIcon />
                </div>
              )}
            </div>
          </div>
          {locationDropdown && (
            <div className="px-5 py-3 border-b border-neutral-100 flex flex-col gap-2 max-h-[200px] overflow-y-auto">
              <div className="flex flex-row items-center justify-between">
                <h1 className="text-sm not-italic font-medium leading-[120%] text-primary-600">
                  Select all Location
                </h1>
                <div>
                  <Checkbox
                    onCheckedChange={() =>
                      handleSelectAll(
                        setSelectedLocations,
                        selectedLocations,
                        locations
                      )
                    }
                    checked={selectedLocations.length === locations.length}
                  />
                </div>
              </div>
              {locations.map(location => (
                <div
                  key={location.publicId}
                  className="flex flex-row items-center justify-between"
                >
                  <h1 className="text-sm not-italic font-medium leading-[120%]">
                    {location.name}
                  </h1>
                  <div>
                    <Checkbox
                      onCheckedChange={() =>
                        handleCheckboxChange(
                          setSelectedLocations,
                          selectedLocations,
                          location.publicId
                        )
                      }
                      checked={selectedLocations.includes(location.publicId)}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* Resource Filter */}
          <div className="flex flex-row items-center justify-between px-5 py-3 border-b border-neutral-100">
            <h1 className="text-sm not-italic font-medium leading-[120%]">
              Resource
            </h1>
            <div
              className="cursor-pointer"
              onClick={() => setResourceDropdown(!resourceDropdown)}
            >
              {resourceDropdown ? (
                <div className="transform rotate-180">
                  <DownArrowIcon />
                </div>
              ) : (
                <div>
                  <DownArrowIcon />
                </div>
              )}
            </div>
          </div>
          {resourceDropdown && (
            <div className="px-5 py-3 border-b border-neutral-100 flex flex-col gap-2 max-h-[200px] overflow-y-auto">
              <div className="flex flex-row items-center justify-between">
                <h1 className="text-sm not-italic font-medium leading-[120%] text-primary-600">
                  Select all Resources
                </h1>
                <div>
                  <Checkbox
                    onCheckedChange={() =>
                      handleSelectAll(
                        setSelectedResources,
                        selectedResources,
                        resources
                      )
                    }
                    checked={selectedResources.length === resources.length}
                  />
                </div>
              </div>
              {resources.map(resource => (
                <div
                  key={resource.publicId}
                  className="flex flex-row items-center justify-between"
                >
                  <h1 className="text-sm not-italic font-medium leading-[120%]">
                    {resource.name}
                  </h1>
                  <div>
                    <Checkbox
                      onCheckedChange={() =>
                        handleCheckboxChange(
                          setSelectedResources,
                          selectedResources,
                          resource.publicId
                        )
                      }
                      checked={selectedResources.includes(resource.publicId)}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* Consultant Filter */}
          <div className="flex flex-row items-center justify-between px-5 py-3 border-b border-neutral-100">
            <h1 className="text-sm not-italic font-medium leading-[120%]">
              Consultant
            </h1>
            <div
              className="cursor-pointer"
              onClick={() => setConsultantDropdown(!consultantDropdown)}
            >
              {consultantDropdown ? (
                <div className="transform rotate-180">
                  <DownArrowIcon />
                </div>
              ) : (
                <div>
                  <DownArrowIcon />
                </div>
              )}
            </div>
          </div>
          {consultantDropdown && (
            <div className="px-5 py-3 border-b border-neutral-100 flex flex-col gap-2 max-h-[200px] overflow-y-auto">
              <div className="flex flex-row items-center justify-between">
                <h1 className="text-sm not-italic font-medium leading-[120%] text-primary-600">
                  Select all Consultant
                </h1>
                <div>
                  <Checkbox
                    onCheckedChange={() =>
                      handleSelectAll(
                        setSelectedConsultants,
                        selectedConsultants,
                        staffs
                      )
                    }
                    checked={selectedConsultants.length === staffs.length}
                  />
                </div>
              </div>
              {staffs.map(staff => (
                <div
                  key={staff.publicId}
                  className="flex flex-row items-center justify-between"
                >
                  <h1 className="text-sm not-italic font-medium leading-[120%]">
                    {staff.fullName}
                  </h1>
                  <div>
                    <Checkbox
                      onCheckedChange={() =>
                        handleCheckboxChange(
                          setSelectedConsultants,
                          selectedConsultants,
                          staff.publicId
                        )
                      }
                      checked={selectedConsultants.includes(staff.publicId)}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* Service Filter */}
          <div className="flex flex-row items-center justify-between px-5 py-3 border-b border-neutral-100">
            <h1 className="text-sm not-italic font-medium leading-[120%]">
              Service
            </h1>
            <div
              className="cursor-pointer"
              onClick={() => setServiceDropdown(!serviceDropdown)}
            >
              {serviceDropdown ? (
                <div className="transform rotate-180">
                  <DownArrowIcon />
                </div>
              ) : (
                <div>
                  <DownArrowIcon />
                </div>
              )}
            </div>
          </div>
          {serviceDropdown && (
            <div className="px-5 py-3 border-b border-neutral-100 flex flex-col gap-2 max-h-[200px] overflow-y-auto">
              <div className="flex flex-row items-center justify-between">
                <h1 className="text-sm not-italic font-medium leading-[120%] text-primary-600">
                  Select all Services
                </h1>
                <div>
                  <Checkbox
                    onCheckedChange={() =>
                      handleSelectAll(
                        setSelectedServices,
                        selectedServices,
                        services
                      )
                    }
                    checked={selectedServices.length === services.length}
                  />
                </div>
              </div>
              {services.map(service => (
                <div
                  key={service.publicId}
                  className="flex flex-row items-center justify-between"
                >
                  <h1 className="text-sm not-italic font-medium leading-[120%]">
                    {service.name}
                  </h1>
                  <div>
                    <Checkbox
                      onCheckedChange={() =>
                        handleCheckboxChange(
                          setSelectedServices,
                          selectedServices,
                          service.publicId
                        )
                      }
                      checked={selectedServices.includes(service.publicId)}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="flex gap-[8rem] w-full">
          <PopoperClose className="w-full">
            <Button variant={'link'} size={'large'} className={'h-[30px] '}>
              Close
            </Button>
          </PopoperClose>
          <PopoperClose className="w-full">
            <Button
              variant={'primary'}
              size={'large'}
              className={'h-[30px] '}
              onClick={applyFilters}
            >
              Apply
            </Button>
          </PopoperClose>
        </div>
      </PopoverContent>
    </Popover>
  );
});

export default ResourceBookingFilter;
