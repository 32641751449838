import React from 'react';
import { Button, Slider } from '../../UI';
import { DirectedArrowIcon } from '../../../assets/icons';
import {
  getReadableDateString,
  getReadableTime,
} from '../../../utils/date.utl';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
// import { ActivityFeedType } from '../../../types/enums';
import { THEME } from '../../../constants/ColorConstants';
import { useNavigate } from 'react-router-dom';
import { markNotificationAsOpened } from '../../../services/api/endPoints/notifications';
import { markNotificationAsOpened as markNotificationAsOpenedRedux } from '../../../redux/slice';

const ActivityFeed = () => {
  // const [activityType, setActivityType] = useState<
  //   ActivityFeedType.DAILY | ActivityFeedType.PRIORITY
  // >(ActivityFeedType.DAILY);

  const { notifications } = useSelector<RootState, NotificationState>(
    state => state.notifications
  );

  return (
    <div className="h-full rounded-md border border-neutral-100 flex flex-col">
      <div className="flex justify-between items-center flex-none">
        <h2 className="px-[12px] py-[14px] font-semibold text-[16px] text-neutral-900">
          Activity Feed
        </h2>
        {/* <div className="flex  pr-2">
          <Button
            variant="link"
            className={
              'px-[16px] border rounded-l-md font-medium ' +
              (activityType === ActivityFeedType.DAILY
                ? 'border-neutral-900 text-neutral-900'
                : 'border-neutral-500 border-r-0 text-neutral-500')
            }
            size={'xmall'}
            onClick={() => setActivityType(ActivityFeedType.DAILY)}
          >
            Daily
          </Button>
          <Button
            variant="link"
            className={
              'px-[16px] border-t border-b border-r rounded-r-md font-medium ' +
              (activityType === ActivityFeedType.PRIORITY
                ? 'border-neutral-900 text-neutral-900 border-l'
                : 'border-neutral-500 text-neutral-500')
            }
            size={'xmall'}
            onClick={() => setActivityType(ActivityFeedType.PRIORITY)}
          >
            Priority
          </Button>
        </div> */}
      </div>
      <Slider variant="vertical" className="h-full flex-auto">
        {Object.keys(notifications).length > 0 ? (
          <ul>
            {Array.from(Object.keys(notifications))
              .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
              .map(activityFeedTime => (
                <>
                  <li
                    key={activityFeedTime}
                    className="bg-neutral-50 p-[12px] font-semibold text-neutral-900 border-b border-t border-neutral-100 sticky top-0 z-[9999]"
                  >
                    {getReadableDateString(new Date(activityFeedTime))}
                  </li>
                  {/* Each group will have multiple list items  */}

                  {notifications[activityFeedTime]
                    ?.map(feedData => (
                      <FeedItem
                        key={feedData.publicId}
                        id={feedData.publicId}
                        title={feedData.title}
                        message={feedData.message}
                        date={feedData.whenCreated}
                        actionName={feedData.actionName}
                        action={feedData.action}
                        hasOpened={feedData.hasOpened}
                        hasRead={feedData.hasRead}
                      />
                    ))}
                </>
              ))}
          </ul>
        ) : (
          <p className="flex justify-center items-center h-full border-t">
            No notifications
          </p>
        )}
      </Slider>
    </div>
  );
};

const FeedItem: React.FC<{
  id: string;
  title: string;
  message?: string;
  actionName?: string;
  action?: string;
  hasOpened?: boolean;
  hasRead?: boolean;
  date: Date;
}> = ({ id, title, message, actionName, action, hasOpened, hasRead, date }) => {
  const navigate = useNavigate();

  const onActionClick = () => {
    if (action) {
      navigate(action);
      if (!hasOpened) {
        markNotificationAsOpenedRedux(id);
        markNotificationAsOpened(id);
      }
    }
  };

  return (
    <li
      key={id}
      className={
        'px-[12px] py-[12px] border-b border-neutral-100 text-[14px] transition-all delay-150' +
        (hasOpened
          ? ' hover:bg-primary-300 '
          : hasRead
            ? ' bg-primary-50 hover:bg-primary-300  '
            : ' bg-primary-100 border-neutral-200 hover:bg-primary-300 ')
      }
    >
      <div className="w-full flex justify-between items-start">
        <div className="flex justify-start">
          <p className="font-semibold text-neutral-900">{title}</p>
        </div>
        <div className="flex justify-end">
          <p className="text-neutral-600">{getReadableTime(new Date(date))}</p>
        </div>
      </div>
      <div className="w-full flex justify-between items-start">
        <p className="text-neutral-600 max-w-[85%]">{message}</p>
        {actionName && action && (
          <div className="flex justify-end">
            <Button
              variant={'link'}
              size={'xmall'}
              trailingIcon={
                <span className="rotate-90">
                  <DirectedArrowIcon fill={THEME.PRIMARY_500} />
                </span>
              }
              onClick={onActionClick}
              className="text-primary-500 w-[78px] p-0 justify-start"
            >
              {actionName}
            </Button>
          </div>
        )}
      </div>
    </li>
  );
};

export default ActivityFeed;
