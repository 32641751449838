import React from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { Lock } from 'lucide-react';
import { ROUTES } from '../constants/NavigationConstants';
import { Button } from '../components/UI';

const Unauthorized: React.FC = () => {
  const authCookie = Cookies.get('authToken');
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const handleOverview = () => {
    navigate(ROUTES.ROUTE_TO_OVERVIEW);
  };

  const handleSignIn = () => {
    navigate(ROUTES.ROUTE_TO_SIGN_IN);
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <Lock size={64} className="text-primary-500" />
      <h1 className="text-2xl font-bold text-destructive-600 mt-4">
        Access Denied
      </h1>
      <p className="text-gray-600 mt-2 text-center max-w-xs">
        {authCookie
          ? 'You do not have permission to view this page.'
          : 'Please sign in to view the resource'}
      </p>
      <div className="mt-6 flex space-x-4">
        {authCookie ? (
          <>
            <Button onClick={handleBack} variant={'secondary'}>
              Back
            </Button>
            <Button onClick={handleOverview} variant={'primary'}>
              Overview
            </Button>
          </>
        ) : (
          <Button onClick={handleSignIn} variant={'primary'}>
            Sign In
          </Button>
        )}
      </div>
    </div>
  );
};

export default Unauthorized;
