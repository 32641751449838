import React from 'react';

type UnarchiveIconProps = {
  stroke?: string;
};

const UnarchiveIcon: React.FC<UnarchiveIconProps> = ({
  stroke = 'currentColor',
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0002 2H2.00016C1.63197 2 1.3335 2.29848 1.3335 2.66667V4.66667C1.3335 5.03486 1.63197 5.33333 2.00016 5.33333H14.0002C14.3684 5.33333 14.6668 5.03486 14.6668 4.66667V2.66667C14.6668 2.29848 14.3684 2 14.0002 2Z"
      stroke={stroke}
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.6665 5.33325V12.6666C2.6665 13.0202 2.80698 13.3593 3.05703 13.6094C3.30708 13.8594 3.64622 13.9999 3.99984 13.9999H11.9998C12.3535 13.9999 12.6926 13.8594 12.9426 13.6094C13.1927 13.3593 13.3332 13.0202 13.3332 12.6666V5.33325"
      stroke={stroke}
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 10L8 8L6 10"
      stroke={stroke}
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default UnarchiveIcon;
