import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  Button,
  Slider,
} from '../../../../UI';
import { CrossIcon } from '../../../../../assets/icons';
import { getTeam } from '../../../../../services/api';
import { notify } from '../../../../../components/common';
import { ERROR_TITLE } from '../../../../../constants/NotificationConstants';
import Skeleton from 'react-loading-skeleton';
import { getFullName } from '../../../../../utils/Helper';
const ViewTeamModal = ({
  open,
  onClose,
  selectedTeamId,
}: ViewTeamModalProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [team, setTeam] = useState<Team | null>(null);
  useEffect(() => {
    const getTeamDetails = async () => {
      setIsLoading(true);
      const [res, error] = await getTeam(selectedTeamId);
      if (res) {
        setTeam(res);
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_TEAM_FETCH,
          message: error.data,
        });
      }
      setIsLoading(false);
    };
    getTeamDetails();
  }, []);
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="p-0  w-[65vw] md:w-[533px] flex flex-col gap-0 border border-neutral-100 max-h-[70%]">
        <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
          <h1 className="text-[14px] text-neutral-900 font-semibold">
            Team details
          </h1>
          <Button variant={'link'} size={'xmall'} onClick={onClose}>
            <CrossIcon />
          </Button>
        </DialogHeader>
        <Slider className="flex-1" variant={'vertical'}>
          {isLoading ? (
            <div className="grid grid-cols-2 p-4  gap-2 ">
              {Array.from({ length: 7 }, (_, index) => {
                return (
                  <>
                    <div key={index} className="cols-span-1 flex items-center">
                      <Skeleton width={150} height={20} />
                    </div>
                    <div className="cols-span-1 flex items-center">
                      <Skeleton width={150} height={20} />
                    </div>
                  </>
                );
              })}
            </div>
          ) : team ? (
            <div className="p-4 space-y-4">
              <div className="grid grid-cols-2">
                <p className="text-[14px] font-semibold text-neutral-500">
                  Team
                </p>
                <p className="text-[14px] font-semibold text-neutral-900">
                  {team.name}
                </p>
              </div>
              <div className="grid grid-cols-2">
                <p className="text-[14px] font-semibold text-neutral-500">
                  Location
                </p>
                <p className="text-[14px] font-semibold text-neutral-900">
                  {team.location.name}
                </p>
              </div>
              <div className="grid grid-cols-2">
                <p className="text-[14px] font-semibold text-neutral-500">
                  Team leader
                </p>
                <p className="text-[14px] font-semibold text-neutral-900">
                  {getFullName(team.teamLeader)}
                </p>
              </div>
              <div className="grid grid-cols-2">
                <p className="text-[14px] font-semibold text-neutral-500">
                  Team members
                </p>
                <div>
                  {team.teamMembers?.map((member, idx) => (
                    <p
                      className="text-[14px] font-semibold text-neutral-900"
                      key={idx}
                    >
                      {getFullName(member)}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div className="h-[400px] flex items-center justify-center">
              <p className="text-[14px] text-neutral-900 font-semibold">
                No information found
              </p>
            </div>
          )}
        </Slider>
      </DialogContent>
    </Dialog>
  );
};

export default ViewTeamModal;
