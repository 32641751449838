/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ERROR_TO_DO } from '../../../types';
import { URL } from '../requestConstructor';

export const getNotes = async (publicId: string, patientPublicId: string) => {
  try {
    const response = await URL.get(
      `/note/get?publicId=${publicId}&patientPublicId=${patientPublicId}`
    );
    return [response.data, null];
  } catch (error: ERROR_TO_DO) {
    return [null, error.response];
  }
};

export const deleteNote = async (publicId: string) => {
  try {
    const response = await URL.delete(`/note/delete?publicId=${publicId}`);
    return [response, null];
  } catch (error: ERROR_TO_DO) {
    return [null, error.response];
  }
};

export const createNote = async (note: NoteCreateRequest) => {
  try {
    const response = await URL.post('/note/create', note);
    return [response.data, null];
  } catch (error: ERROR_TO_DO) {
    return [null, error.response];
  }
};
