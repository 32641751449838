import { FC } from 'react';
import { THEME } from '../../constants/ColorConstants';

const LocationIcon: FC<{
  stroke?: string;
}> = ({ stroke = THEME.NEUTRAL_500 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M13.3327 7.16732C13.3327 11.1673 7.99935 15.1673 7.99935 15.1673C7.99935 15.1673 2.66602 11.1673 2.66602 7.16732C2.66602 5.75283 3.22792 4.39628 4.22811 3.39608C5.22831 2.39589 6.58486 1.83398 7.99935 1.83398C9.41384 1.83398 10.7704 2.39589 11.7706 3.39608C12.7708 4.39628 13.3327 5.75283 13.3327 7.16732Z"
        stroke={stroke}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 9.16602C9.10457 9.16602 10 8.27059 10 7.16602C10 6.06145 9.10457 5.16602 8 5.16602C6.89543 5.16602 6 6.06145 6 7.16602C6 8.27059 6.89543 9.16602 8 9.16602Z"
        stroke={stroke}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LocationIcon;
