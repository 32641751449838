import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { Button, DialogFooter } from '../UI';
import {
  CameraIcon,
  Edit2Icon,
  LeftArrowIcon,
  RedoIcon,
  RightArrow,
} from '../../assets/icons';
import Camera from '../sections/Scanner/Camera';
import { base64ToFile } from '../../utils/files.utl';
import Cropper, { ReactCropperElement } from 'react-cropper';
import '../sections/Scanner/constants/cropper.css';
const UploadProfilePictureCamera = ({
  onUpload,
  loading,
  backHandler,
  heading,
}: {
  onUpload: (file: File) => void;
  loading?: boolean;
  backHandler: () => void;
  heading: string;
}) => {
  const webcamRef = useRef<Webcam>(null);
  const cropperRef = useRef<ReactCropperElement>(null);
  const [originalImage, setOriginalImage] = useState<string>();
  const [image, setImage] = useState<string>();
  const onCapture = () => {
    const imageSrc = webcamRef.current?.getScreenshot() || '';
    setOriginalImage(imageSrc);
  };

  const onCropHandler = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      setImage(cropper.getCroppedCanvas().toDataURL());
    }
  };
  const uploadHandler = () => {
    if (!image) return;
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      const file = base64ToFile(
        cropper.getCroppedCanvas().toDataURL(),
        'webcam-image.jpeg'
      );
      onUpload(file);
    }
  };
  return (
    <>
      <div className="p-3">
        <div className="custom-height flex flex-col h-full border rounded border-neutral-100">
          <div className="flex-none relative flex justify-center items-center p-3 border-b border-b-neutral-100">
            <h1 className="text-[14px] text-neutral-900 font-medium">
              {heading}
            </h1>
            <Button
              className="absolute left-3 top-2"
              leadingIcon={<LeftArrowIcon />}
              variant={'outlined'}
              size={'xmall'}
              onClick={backHandler}
            >
              Back
            </Button>
          </div>
          <div className="flex-auto overflow-y-auto flex flex-1 flex-col items-center justify-center gap-2">
            {!originalImage ? (
              <Camera ref={webcamRef} />
            ) : (
              <div className="h-[580px] w-[580px] flex items-center justify-center">
                {' '}
                {!image ? (
                  <Cropper
                    src={originalImage}
                    initialAspectRatio={1 / 1}
                    guides={false}
                    ref={cropperRef}
                    dragMode={'none'}
                    zoomable={false}
                    height={400}
                  />
                ) : (
                  <img src={image} className="h-[580px] w-[580px] object-fit" />
                )}
              </div>
            )}
          </div>
          <DialogFooter>
            {!originalImage ? (
              <div className="flex items-center justify-center w-full">
                <Button
                  variant={'outlined'}
                  className="rounded-full w-[64px] h-[64px] p-0"
                  onClick={onCapture}
                >
                  <CameraIcon />
                </Button>
              </div>
            ) : (
              <div className="flex justify-between p-3 w-full">
                <div className="flex gap-3">
                  <Button
                    variant={'outlined'}
                    size={'small'}
                    onClick={() => {
                      setOriginalImage(undefined);
                      setImage(undefined);
                    }}
                    disabled={loading}
                    leadingIcon={<RedoIcon />}
                  >
                    Retake
                  </Button>
                  {image && (
                    <Button
                      variant={'outlined'}
                      size={'small'}
                      onClick={() => setImage(undefined)}
                      disabled={loading}
                      leadingIcon={<Edit2Icon />}
                    >
                      Edit
                    </Button>
                  )}
                </div>
                {image ? (
                  <Button
                    size={'small'}
                    onClick={uploadHandler}
                    trailingIcon={<RightArrow />}
                    isLoading={loading}
                    disabled={loading}
                  >
                    Upload
                  </Button>
                ) : (
                  <Button
                    size={'small'}
                    onClick={onCropHandler}
                    trailingIcon={<RightArrow />}
                  >
                    Continue
                  </Button>
                )}
              </div>
            )}
          </DialogFooter>
        </div>
      </div>
    </>
  );
};

export default UploadProfilePictureCamera;
