import React, { useEffect } from 'react';
import ProjectRoutes from './routes';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { useDispatch } from 'react-redux';
import { io } from 'socket.io-client';
import {
  BROWSER_NOTIFICATION_PERMISSION,
  SOCKET_EVENTS,
} from './constants/WebNotifications';
import Cookies from 'js-cookie';
import { setInitialNotifications } from './redux/slice';
import { getLastWeekNotifications } from './services/api/endPoints/notifications';
import { ERROR_MESSAGE } from './constants/NotificationConstants';
import { notify } from './components/common';

const App = () => {
  const authCookie = Cookies.get('authToken');
  const user = useSelector<RootState, UserEntities>(state => state.user.user);
  const dispatch = useDispatch();

  useEffect(() => {
    let socket: any;

    const fetchLastWeekNotifications = async () => {
      const [data, error] = await getLastWeekNotifications();
      if (error) {
        notify.error({
          title: 'Failed to fetch notifications',
          message: error ? error.data : ERROR_MESSAGE.GENERIC_TRY_AGAIN,
        });
      } else {
        dispatch(setInitialNotifications(data));
      }
    };

    if (!!authCookie && user) {
      //load notifications
      fetchLastWeekNotifications();

      if (!process.env.REACT_APP_NOTIFICATION_SOCKET_URL) return;

      socket = io(process.env.REACT_APP_NOTIFICATION_SOCKET_URL, {
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
      });
      socket.io.on('reconnect', () => {
        socket?.emit(SOCKET_EVENTS.NEW_CONNECTION, user.publicId);
      });

      socket?.emit(SOCKET_EVENTS.NEW_CONNECTION, user.publicId);

      socket?.on(SOCKET_EVENTS.NEW_NOTIFICATION, async (data: any) => {
        const notification = JSON.parse(data);

        //Reload Notifications
        fetchLastWeekNotifications();

        //Browser Notification
        const permission = await Notification.requestPermission();
        if (permission === BROWSER_NOTIFICATION_PERMISSION) {
          new Notification(notification.data.title, {
            body: notification.data.message,
          });
        }
      });
    }
    return () => {
      if (socket) socket?.close();
    };
  }, [authCookie]);

  return (
    <div className="App">
      <ProjectRoutes />
    </div>
  );
};

export default App;
