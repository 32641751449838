import React from 'react';

interface EmbryoLogyTransferIconProps {
  stroke?: string;
}

const EmbryoLogyTransferIcon: React.FC<EmbryoLogyTransferIconProps> = ({ stroke = 'currentColor' }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z"
      stroke={stroke}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.35 10.7C6.5433 10.7 6.7 10.5433 6.7 10.35C6.7 10.1567 6.5433 10 6.35 10C6.1567 10 6 10.1567 6 10.35C6 10.5433 6.1567 10.7 6.35 10.7Z"
      fill="black"
      stroke={stroke}
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.75 13.5C7.16421 13.5 7.5 13.1642 7.5 12.75C7.5 12.3358 7.16421 12 6.75 12C6.33579 12 6 12.3358 6 12.75C6 13.1642 6.33579 13.5 6.75 13.5Z"
      fill="black"
      stroke={stroke}
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.35 12.2C4.5433 12.2 4.7 12.0433 4.7 11.85C4.7 11.6567 4.5433 11.5 4.35 11.5C4.1567 11.5 4 11.6567 4 11.85C4 12.0433 4.1567 12.2 4.35 12.2Z"
      fill="black"
      stroke={stroke}
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 8.33325V6.99992C2 6.29267 2.28095 5.6144 2.78105 5.1143C3.28115 4.6142 3.95942 4.33325 4.66667 4.33325H12.6667"
      stroke={stroke}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.3335 7.66667L12.6668 4.33333L9.3335 1"
      stroke={stroke}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EmbryoLogyTransferIcon;
