import React, { ReactNode } from 'react';
import { THEME } from '../../constants/ColorConstants';

const ScheduleIcon = ({ isActive }: { isActive: Boolean }) => {
  const strokeColor = isActive
    ? THEME.SHADE_200
    : THEME.NEUTRAL_50;
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_273_622)">
        <path
          d="M8.00065 14.6673C11.6825 14.6673 14.6673 11.6825 14.6673 8.00065C14.6673 4.31875 11.6825 1.33398 8.00065 1.33398C4.31875 1.33398 1.33398 4.31875 1.33398 8.00065C1.33398 11.6825 4.31875 14.6673 8.00065 14.6673Z"
          stroke={strokeColor}
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8 4V8L10.6667 9.33333"
          stroke={strokeColor}
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_273_622">
          <rect width="16" height="16" fill={THEME.SHADE_0} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ScheduleIcon;
