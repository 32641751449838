import React, { useState, useMemo, useEffect } from 'react';
import { AlertDialog, DataTable, notify } from '../../../../common';
import { Button, Slider } from '../../../../UI';
// import defaultImage from '../../../../../assets/images/360_F_118019822_6CKXP6rXmVhDOzbXZlLqEM2ya4HhYzSV.jpg';
import {
  AddIcon,
  DeleteIcon,
  DirectedArrowIcon,
  // FilterIcon,
  OpenEyeIcon2,
} from '../../../../../assets/icons';
import { ColumnDef } from '@tanstack/react-table';
import { Edit2, KeyRound } from 'lucide-react';
import AddAndEditStaffModal from './AddAndEditStaffModal';
import ResetPasswordModal from './ResetPasswordModal';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import ViewStaff from './ViewStaff';
import { useDispatch } from 'react-redux';
import { deleteStaff as deleteStaffRedux } from '../../../../../redux/slice';
import { deleteStaff } from '../../../../../services/api';
import {
  ERROR_TITLE,
  SUCCESS_TITLE,
} from '../../../../../constants/NotificationConstants';
import { getFullName } from '../../../../../utils/Helper';
import ProfileImage from '../../../../common/ProfilePicture';
// import { downloadFileByUrl } from '../../../../../services/api/endPoints/files.api';
// import Skeleton from 'react-loading-skeleton';
const Staffs = () => {
  const [isAddStaffModalOpen, setIsAddStaffModalOpen] =
    useState<boolean>(false);
  const [selectedUsername, setSelectedUsername] = useState<string | null>(null);
  const [isResetPasswordModalOpen, setIsResetPasswordModal] =
    useState<boolean>(false);
  const [isViewStaffModalOpen, setIsViewStaffModalOpen] =
    useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [selectedDeleteStaff, setSelectedDeleteStaff] =
    useState<StaffInformation | null>(null);
  const staffs = useSelector<RootState, StaffInformation[]>(
    state => state.staffs.staffs
  );
  const user = useSelector<RootState, UserEntities>(state => state.user.user);
  const [isEditStaffModalOpen, setIsEditStaffModalOpen] =
    useState<boolean>(false);
  const [selectedStaffId, setSelectedStaffId] = useState<string | null>(null);
  const dispatch = useDispatch();
  useEffect(() => {
    const currentModalState = sessionStorage.getItem('currentModalState');
    if (currentModalState) {
      const data = JSON.parse(currentModalState);
      if (data.isEditModal) {
        setIsEditStaffModalOpen(true);
        setSelectedStaffId(data.selectedStaffId);
      } else {
        setIsAddStaffModalOpen(true);
      }
    }
  }, []);
  const staffsColumn: ColumnDef<StaffInformation>[] = useMemo(
    () => [
      {
        accessorKey: 'fullName',
        header: ({ column }) => {
          return (
            <button
              className="flex gap-1 items-center"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
            >
              Name
              {column.getIsSorted() === 'asc' ? (
                <div className="rotate-180">
                  <DirectedArrowIcon fill="currentColor" />{' '}
                </div>
              ) : (
                <DirectedArrowIcon fill="currentColor" />
              )}
            </button>
          );
        },
        cell: ({ row }) => {
          return (
            <div className="flex items-center gap-1">
              <ProfileImage
                user={row.original}
                height={'32px'}
                width={'32px'}
              />
              <p className="text-neutral-900 font-semibold text-[14px] text-nowrap add-ellipsis">
                {row.getValue('fullName')}
              </p>
            </div>
          );
        },
      },
      {
        accessorKey: 'username',
        header: 'Username',
      },
      {
        accessorKey: 'email',
        header: 'Staff email',
      },
      {
        accessorKey: 'phoneNo',
        header: 'Contact',
      },
      {
        accessorKey: 'type',
        header: 'Type',
      },
      {
        id: 'actions',
        cell: ({ row }) => {
          return (
            <div className="flex justify-end">
              <div className="flex gap-2">
                <Button
                  variant={'outlined'}
                  size={'xmall'}
                  className="md:px-2 font-semibold text-[14px] gap-1"
                  trailingIcon={<OpenEyeIcon2 />}
                  onClick={() => {
                    setSelectedStaffId(row.original.publicId);
                    setIsViewStaffModalOpen(true);
                  }}
                >
                  View
                </Button>
                <Button
                  variant={'outlined'}
                  size={'xmall'}
                  className="md:px-2 font-semibold text-[14px] gap-2"
                  trailingIcon={<Edit2 size={15} />}
                  onClick={() => {
                    setIsEditStaffModalOpen(true);
                    setSelectedStaffId(row.original.publicId);
                  }}
                >
                  Edit
                </Button>
                <Button
                  variant={'outlined'}
                  size={'xmall'}
                  className="md:px-2 font-semibold text-[14px] gap-1"
                  trailingIcon={<DeleteIcon stroke="currentColor" />}
                  onClick={() => {
                    setSelectedDeleteStaff(row.original);
                    setIsDeleteModalOpen(true);
                  }}
                  disabled={user.staff.publicId === row.original.publicId}
                >
                  Delete
                </Button>
                <Button
                  variant={'outlined'}
                  size={'xmall'}
                  onClick={() => {
                    setSelectedUsername(row.original.username);
                    setIsResetPasswordModal(true);
                  }}
                  trailingIcon={<KeyRound size={18} />}
                  disabled={user.staff.publicId === row.original.publicId}
                />
              </div>
            </div>
          );
        },
      },
    ],
    []
  );
  const deleteHandler = async () => {
    if (!selectedDeleteStaff) return;
    setIsDeleting(true);
    const [, err] = await deleteStaff(selectedDeleteStaff.publicId);
    if (!err) {
      dispatch(deleteStaffRedux({ publicId: selectedDeleteStaff.publicId }));
      notify.success({
        title: SUCCESS_TITLE.GENERIC_STAFF_DELETE,
        message: `'${selectedDeleteStaff.fullName}' deleted successfully`,
      });
    } else {
      notify.error({
        title: ERROR_TITLE.GENERIC_STAFF_DELETE,
        message: err.data,
      });
    }
    closeDeleteModalHandler();
  };
  const closeDeleteModalHandler = () => {
    setIsDeleting(false);
    setSelectedDeleteStaff(null);
    setIsDeleteModalOpen(false);
  };
  return (
    <>
      {isAddStaffModalOpen && (
        <AddAndEditStaffModal
          open={isAddStaffModalOpen}
          onClose={() => setIsAddStaffModalOpen(false)}
          type="Create"
        />
      )}
      {isEditStaffModalOpen && (
        <AddAndEditStaffModal
          open={isEditStaffModalOpen}
          onClose={() => {
            setIsEditStaffModalOpen(false);
            setSelectedStaffId(null);
          }}
          type="Edit"
          selectedStaffId={selectedStaffId}
        />
      )}
      {isResetPasswordModalOpen && (
        <ResetPasswordModal
          open={isResetPasswordModalOpen}
          onClose={() => setIsResetPasswordModal(false)}
          staffUsername={selectedUsername}
        />
      )}
      {isViewStaffModalOpen && (
        <ViewStaff
          open={isViewStaffModalOpen}
          selectedStaffId={selectedStaffId}
          onClose={() => {
            setSelectedStaffId(null);
            setIsViewStaffModalOpen(false);
          }}
        />
      )}
      {isDeleteModalOpen && selectedDeleteStaff && (
        <AlertDialog
          type="delete"
          actionButtonText={!isDeleting ? 'Delete' : 'Deleting'}
          alertTitle={`Deleting ${getFullName(selectedDeleteStaff)}`}
          onClose={closeDeleteModalHandler}
          open={isDeleteModalOpen}
          onAction={deleteHandler}
          isActionStarted={isDeleting}
        >
          Are you sure you want to delete {getFullName(selectedDeleteStaff)} ?
        </AlertDialog>
      )}
      <div className="px-[12px]  py-[16px] w-full flex justify-between items-center">
        <h2 className="font-semibold text-[16px] text-neutral-900">Staff</h2>
        <div className="flex gap-3 items-center justify-center">
          {/* <Button variant={'link'} leadingIcon={<FilterIcon />} size={'small'}>
            Filter
          </Button> */}
          <Button
            variant={'primary'}
            size={'small'}
            onClick={() => setIsAddStaffModalOpen(true)}
            trailingIcon={<AddIcon stroke="currentColor" />}
          >
            Add staff
          </Button>
        </div>
      </div>
      <Slider variant={'vertical'} className="flex-1">
        <DataTable columns={staffsColumn} data={staffs} isLoading={false} />
      </Slider>
    </>
  );
};

export default Staffs;
