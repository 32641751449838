import React, { useState } from 'react';
import { Button, Input, Label } from '../../../../UI';
import {
  DeleteIcon,
  LeftArrowIcon,
  RightArrow,
} from '../../../../../assets/icons';
import OulinedAddIcon from '../../../../../assets/icons/OulinedAddIcon';
import { useNavigate, useParams } from 'react-router-dom';
import { createPrescription } from '../../../../../services/api';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { notify } from '../../../../common/Alert';
import { THEME } from '../../../../../constants/ColorConstants';

const PrescriptionForm = () => {
  const { id } = useParams();
  const user = useSelector<RootState, UserEntities>(state => state.user.user);
  const [loading, isLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  interface MedicineType {
    medicineName: string;
    direction: string;
    duration: string;
  }
  const [medicines, setMedicines] = React.useState<MedicineType[]>([
    {
      medicineName: '',
      direction: '',
      duration: '',
    },
  ]);
  const onInput = (e: React.ChangeEvent<HTMLInputElement>, pos: number) => {
    if (pos >= medicines.length) return;
    const newMedicines: MedicineType[] = [...medicines];
    medicines[pos][e.target.name as keyof MedicineType] = e.target.value;
    setMedicines(newMedicines);
  };
  const addMedicine = () => {
    if (medicines.length < 8) {
      const newMedicines: MedicineType[] = [...medicines];
      newMedicines.push({
        medicineName: '',
        direction: '',
        duration: '',
      });
      setMedicines(newMedicines);
    }
  };

  const removeLastMedicine = () => {
    const newMedicines: MedicineType[] = [...medicines];
    newMedicines.pop();
    setMedicines(newMedicines);
  };

  const resetMedicine = () => {
    setMedicines([]);
  };

  const savePrescrition = async () => {
    isLoading(true);
    const prescriptionCreateRequest = {
      patientPublicId: id,
      staffPublicId: user.staff.publicId,
      prescriptionRows: medicines
        .filter(medicine => medicine.medicineName)
        .map((medicine: MedicineType) => ({
          medicine: medicine.medicineName,
          duration: medicine.duration,
          directions: medicine.direction,
        })),
    };

    const [res, error] = await createPrescription(prescriptionCreateRequest);

    if (res) {
      notify.success({
        title: 'Prescription saved successfully',
        message: `New prescription ${res.publicId} has been created`,
      });
    } else if (error) {
      notify.error({
        title: 'Failed to save prescription',
        message: error.data,
      });
    }
    isLoading(false);
    navigate(-1);
  };

  return (
    <>
      <div className="border-b border-neutral-100 p-3 relative">
        <h1 className="text-neutral-900 font-medium whitespace-nowrap text-center">
          Add prescrition
        </h1>
        <Button
          variant={'outlined'}
          size={'xmall'}
          leadingIcon={<LeftArrowIcon />}
          className="absolute left-3 top-[12%]"
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </div>
      <div className="p-6 space-y-5">
        {medicines.map((medicine, idx) => (
          <div className="space-y-5" key={idx}>
            <div className="flex justify-between">
              <div className="space-y-1">
                <Label>Medicine</Label>
                <Input
                  placeholder="Medicine"
                  value={medicine.medicineName}
                  onChange={e => onInput(e, idx)}
                  name="medicineName"
                />
              </div>
              <div className="space-y-1">
                <Label>Direction</Label>
                <Input
                  placeholder="Direction"
                  value={medicine.direction}
                  onChange={e => onInput(e, idx)}
                  name="direction"
                />
              </div>
              <div className="space-y-1">
                <Label>Duration</Label>
                <Input
                  value={medicine.duration}
                  name="duration"
                  onChange={e => onInput(e, idx)}
                  placeholder="Duration"
                />
              </div>
            </div>
            {idx != medicines.length - 1 && (
              <div className="height-[1px] bg-neutral-100" />
            )}
          </div>
        ))}
        <div className="flex justify-end">
          <Button
            onClick={addMedicine}
            variant={'outlined'}
            size={'xmall'}
            trailingIcon={<OulinedAddIcon stroke={THEME.SHADE_100} />}
            disabled={medicines.length >= 8}
          >
            Add Medicine
          </Button>
        </div>
      </div>

      <div className="flex justify-between items-center border-t border-neutral-100 py-4 px-3">
        <div className="flex gap-4">
          <Button variant={'outlined'} size={'small'} onClick={resetMedicine}>
            Reset
          </Button>
          <Button
            onClick={removeLastMedicine}
            variant={'outlined'}
            size={'small'}
            trailingIcon={<DeleteIcon stroke={THEME.SHADE_100} />}
            disabled={medicines.length <= 1}
          >
            Remove Medicine
          </Button>
        </div>
        <Button
          variant={'primary'}
          size={'small'}
          trailingIcon={<RightArrow stroke={THEME.SHADE_0} />}
          onClick={savePrescrition}
          isLoading={loading}
        >
          Save prescription
        </Button>
      </div>
    </>
  );
};

export default PrescriptionForm;
