import { THEME } from '../../constants/ColorConstants';
import React, { FC } from 'react';

interface DeleteIconProps {
  stroke?: string;
}

const DeleteIcon: FC<DeleteIconProps> = ({ stroke = THEME.NEUTRAL_900 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M2 4.5H14"
        stroke={stroke}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6673 4.5V13.8333C12.6673 14.5 12.0007 15.1667 11.334 15.1667H4.66732C4.00065 15.1667 3.33398 14.5 3.33398 13.8333V4.5"
        stroke={stroke}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33398 4.50065V3.16732C5.33398 2.50065 6.00065 1.83398 6.66732 1.83398H9.33398C10.0007 1.83398 10.6673 2.50065 10.6673 3.16732V4.50065"
        stroke={stroke}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DeleteIcon;
