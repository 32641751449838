import React, { FC } from 'react';
import { THEME } from '../../constants/ColorConstants';

interface DisableStepProps {
  width?: number;
  height?: number;
}

const DisableStep: FC<DisableStepProps> = ({ width = 36, height = 37 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 36 37"
      fill={THEME.SHADE_0}
    >
      <rect
        x="1"
        y="1.5"
        width="34"
        height="34"
        rx="17"
        stroke={THEME.NEUTRAL_100}
        strokeWidth="2"
      />
    </svg>
  );
};

export default DisableStep;
