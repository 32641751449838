import React, { useState } from 'react';
import ActionDropdown from '../../../components/common/ActionDropdown';
import { Button } from '../../../components/UI';
import { DownArrow } from '../../../assets/icons';
import ResourceBookingFilter from './ResourceBookingFilter';
import BookResourceModal from './BookResourceModal';
import BlockTimeModal from './BlockTimeModal';

const RightTopNav = () => {
  // const location = useLocation();
  const [openAddPatientModal, setOpenPatientModal] = useState(false);
  const [openAddBlockTimeModal, setOpenAddBlockTimeModal] = useState(false);

  const addDropdownItems = [
    {
      label: 'Block Time',
      Icon: null,
      action: () => setOpenAddBlockTimeModal(true),
    },
    {
      label: 'Resource Booking',
      Icon: null,
      action: () => setOpenPatientModal(true),
    },
  ];


  return (
    <div>
      <div className="flex flex-row items-center justify-between px-5 py-4 border-b border-neutral-100">
        <div className="flex flex-row gap-8">
          <h1 className="text-large not-italic font-medium">Calendar</h1>
        </div>
        <div className="flex ">
          <ResourceBookingFilter />
          <ActionDropdown
            side="bottom"
            align="start"
            sideOffset={2}
            drownDownItems={addDropdownItems}
          >
            <div>
              <Button trailingIcon={<DownArrow />} variant={'primary'} className="w-[75px] h-[34px] gap-1">
                Add
              </Button>
            </div>
          </ActionDropdown>
        </div>
      </div>
      <BookResourceModal
        open={openAddPatientModal}
        onClose={() => setOpenPatientModal(false)}
      />
      <BlockTimeModal
        open={openAddBlockTimeModal}
        onClose={() => setOpenAddBlockTimeModal(false)}
      />
    </div>
  );
};

export default RightTopNav;
