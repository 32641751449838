import { createSlice } from '@reduxjs/toolkit';
import { clearCookies } from '../../utils/Helper';

const initialState: AuthState = {
  auth: {
    token: null,
    expiresIn: 0,
    tokenType: '',
    refreshToken: '',
    refreshTokenExpiresIn: 0,
    whenExpires: null,
    whenRefreshTokenExpires: null,
  },
  isTokenExpiredModalOpen: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => clearCookies(),
    openTokenExpiredModal: state => {
      state.isTokenExpiredModalOpen = true;
    },
    closeTokenExpiredModal: state => {
      state.isTokenExpiredModalOpen = false;
    },
  },
});

export default authSlice.reducer;
export const { logout, openTokenExpiredModal, closeTokenExpiredModal } =
  authSlice.actions;
