/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Dialog,
  DialogContent,
  DialogHeader,
  Button,
  Slider,
  Label,
} from '../../../../components/UI';
import { CrossIcon } from '../../../../assets/icons';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { getFormFormatById } from '../../../..//services/api';
import { notify } from '../../../../components/common';
import { ERROR_MESSAGE } from '../../../../constants/NotificationConstants';
import Skeleton from 'react-loading-skeleton';

const PatientDetailsModal = ({
  open,
  onClose,
  patient,
}: {
  open: boolean;
  onClose: () => void;
  patient: PatientInformation;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [patientFormFormat, setPatientFormFormat] = useState<any>();
  const [patientDetailsValuesMap, setPatientDetailsValuesMap] = useState<
    Record<string, any>
  >({});
  const { patientDetailsFormFormat } = useSelector<RootState, ClientEntities>(
    state => state.client.client
  );

  useEffect(() => {
    const fetchAllFormFormats = async () => {
      setIsLoading(true);
      const [response, error] = await getFormFormatById(
        patientDetailsFormFormat?.publicId
      );
      if (response) {
        setPatientFormFormat(response);
        setPatientDetailsValuesMap(
          patient.patientDetailsForm?.formFieldValuesMap
        );
      } else {
        notify.error({
          title: 'Failed to fetch patient details',
          message:
            !error || !error.data
              ? ERROR_MESSAGE.GENERIC_TRY_AGAIN
              : error.data,
        });
      }
      setIsLoading(false);
    };
    fetchAllFormFormats();
  }, []);

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="p-0 tablet:w-[800px] md:w-[887px] flex flex-col gap-0 border border-neutral-100 max-h-[70%] min-h-[40%]">
        <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
          <h1 className="text-[14px] text-neutral-900 font-semibold">
            Patient details
          </h1>
          <Button variant={'link'} size={'xmall'} onClick={onClose}>
            <CrossIcon />
          </Button>
        </DialogHeader>
        <Slider className="flex-1 " variant={'vertical'}>
          {isLoading ? (
            <div className="flex flex-col gap-3 p-3 ">
              {Array.from({ length: 8 }, (_, index) => (
                <div className="w-full flex gap-1" key={index}>
                  <Skeleton height={30} containerClassName="flex-1" />
                  <Skeleton height={30} containerClassName="flex-1" />
                </div>
              ))}{' '}
            </div>
          ) : !patientDetailsFormFormat || !patientFormFormat || !patientDetailsValuesMap || patientDetailsValuesMap.length === 0 ? (
            <div className="flex w-full justify-center items-center mt-10">
              <p className="text-neutral-600">No patient details found</p>
            </div>
          ) : (
            <div className="space-y-3 p-3">
              {patientFormFormat?.sections?.map(
                (section: any, index: number) => (
                  <>
                    <div className="space-y-2" key={section.publicId}>
                      <Label>{section.label}</Label>
                      {section.fields.map((field: any) => (
                        <div className="flex gap-1" key={field.publicId}>
                          <p className="text-neutral-500 font-medium text-[14px] w-full">
                            {field.label}
                          </p>
                          <p className="text-neutral-900 font-medium text-[14px] w-full">
                            {patientDetailsValuesMap[field.publicId]
                              ? patientDetailsValuesMap[field.publicId]
                              : '-'}
                          </p>
                        </div>
                      ))}
                    </div>
                    {index !== patientFormFormat?.sections?.length - 1 && (
                      <hr />
                    )}
                  </>
                )
              )}
            </div>
          )}
        </Slider>
      </DialogContent>
    </Dialog>
  );
};

export default PatientDetailsModal;
