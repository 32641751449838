/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  Label,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  Slider,
} from '../../../../UI';
import {
  CrossIcon,
  DeleteIcon,
  LeftArrowIcon,
  RightArrow,
} from '../../../../../assets/icons';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getAllTreatments,
  getAllServices,
  createTreatmentCycle,
  updateTreatmentCycle,
  getAllDonors,
} from '../../../../../services/api';
import {
  addTreatmentCycle,
  updateTreatmentCycle as updateTreatmentCycleRedux,
} from '../../../../../redux/slice';
import { notify } from '../../../../common/Alert';
import { DatePicker, SelectWithInputAndAddOption } from '../../../../common';
import MultipleSelect from '../../../../common/MultipleSelet';
import {
  ERROR_MESSAGE,
  ERROR_TITLE,
  SUCCESS_TITLE,
} from '../../../../../constants/NotificationConstants';
import { useDispatch } from 'react-redux';

const TreatmentCycleModal = ({
  open,
  onClose,
  ...props
}: AddAndEditTreatmentCycleModalProps) => {
  const { id } = useParams();
  const [formData, setFormData] = useState<TreatmentCycleForm>({
    treatmentPublicId: '',
    startDate: new Date(),
    donor: '',
    lastMenstrualPeriod: new Date(),
    servicePublicIds: [],
  });
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [treatments, setTreatments] = useState<Record<string, string>>({});
  const [services, setServices] = useState<Record<string, string>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [donors, setDonors] = useState([]);
  const [isGettingTreatmentData, setIsGettingTreatmentData] =
    useState<boolean>(false);
  const [isGettingServicesData, setIsGettingServicesData] =
    useState<boolean>(false);
  const [isGettingDonorsData, setIsGettingDonorsData] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const getTreatments = async () => {
    setIsGettingTreatmentData(true);
    const [treatments] = await getAllTreatments();
    if (treatments) {
      let treatmentOptions: Record<string, string> = {};
      treatments.map((treatment: any) => {
        treatmentOptions[treatment.publicId as string] = treatment.name;
      });
      setTreatments(treatmentOptions);
    }
    setIsGettingTreatmentData(false);
  };
  const getServices = async () => {
    setIsGettingServicesData(true);
    const [services] = await getAllServices();
    if (services) {
      let serviceOptions: Record<string, string> = {};
      services.map((service: any) => {
        serviceOptions[service.publicId as string] = service.name;
      });
      setServices(serviceOptions);
    }
    setIsGettingServicesData(false);
  };
  const getDonors = async () => {
    setIsGettingDonorsData(true);
    const [donors] = await getAllDonors();
    if (donors) {
      setDonors(donors);
    }
    setIsGettingDonorsData(false);
  };

  useEffect(() => {
    getTreatments();
    getServices();
    getDonors();
    if (props.type === 'Edit') {
      const form: TreatmentCycleForm = {
        treatmentPublicId: props.treatmentCycle.treatment.publicId,
        startDate: new Date(props.treatmentCycle.startDate),
        donor: props.treatmentCycle.donor ?? '',
        lastMenstrualPeriod: props.treatmentCycle.lastMenstrualPeriod
          ? new Date(props.treatmentCycle.lastMenstrualPeriod)
          : new Date(),
        servicePublicIds: props.treatmentCycle.services
          ? props.treatmentCycle.services.map(service => service.publicId)
          : [],
      };
      setFormData(form);
    }
  }, []);

  const inputHandler = (name: keyof TreatmentCycleForm, value: any) => {
    const errors = { ...formErrors };
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
    if (errors[name]) {
      delete errors[name];
    }
    setFormErrors(errors);
  };

  const isValidInput = () => {
    const errors = { ...formErrors };
    if (!formData.treatmentPublicId || formData.treatmentPublicId === '') {
      errors.treatmentPublicId = 'Treatment is required';
    }
    if (!formData.startDate) {
      errors.startDate = 'Start date is required';
    }
    if (!formData.donor || formData.donor === '') {
      errors.donor = 'Donor is required';
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const handleSubmit = async () => {
    if (!isValidInput()) return;
    if (props.type === 'Create') {
      const data = {
        patientPublicId: id,
        ...formData,
      };
      setIsLoading(true);
      const [res, error] = await createTreatmentCycle(data);
      if (res) {
        notify.success({
          title: SUCCESS_TITLE.GENERIC_TREATMENT_CYCLE_START,
          message: `New treatment cycle '${res.treatment.name}' has been started`,
        });
        dispatch(addTreatmentCycle(res));
        onClose();
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_TREATMENT_CYCLE_START,
          message: error?.data || ERROR_MESSAGE.GENERIC_TRY_AGAIN,
        });
      }
      setIsLoading(false);
    } else if (props.type === 'Edit') {
      const data = {
        publicId: props.treatmentCycle.publicId,
        patientPublicId: id,
        ...formData,
      };
      setIsLoading(true);
      const [res, error] = await updateTreatmentCycle(data);
      if (res) {
        notify.success({
          title: SUCCESS_TITLE.GENERIC_TREATMENT_CYCLE_UPDATE,
          message: `New treatment cycle '${res.treatment.name}' has been updated`,
        });
        dispatch(updateTreatmentCycleRedux(res));
        onClose();
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_TREATMENT_CYCLE_UPDATE,
          message: error?.data || ERROR_MESSAGE.GENERIC_TRY_AGAIN,
        });
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onClose}>
        <DialogContent className="p-0 w-[65vw] md:w-[533px] flex flex-col gap-0 border border-neutral-100 max-h-[80%]">
          <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
            <h1 className="text-[14px] text-neutral-900 font-semibold">
              {props.type === 'Edit'
                ? 'Edit treatment cycle'
                : 'Start new cycle'}
            </h1>
            <Button variant={'link'} size={'xmall'} onClick={onClose}>
              <CrossIcon />
            </Button>
          </DialogHeader>
          <Slider className="flex-1" variant={'vertical'}>
            <div className="p-4 space-y-4">
              <div className="w-full space-y-1">
                <Label className="after:content-['*'] after:text-destructive-500">
                  Treatment
                </Label>
                <Select
                  name="treatmentPublicId"
                  value={formData.treatmentPublicId}
                  onValueChange={value =>
                    inputHandler('treatmentPublicId', value)
                  }
                >
                  <SelectTrigger
                    disabled={props.type === 'Edit' || isGettingTreatmentData}
                  >
                    <SelectValue placeholder="Select" />
                  </SelectTrigger>
                  <SelectContent className="bg-shades-0 z-[9999999]">
                    {Object.keys(treatments).length > 0 ? (
                      Object.keys(treatments).map(publicId => (
                        <SelectItem key={publicId} value={publicId}>
                          {treatments[publicId]}
                        </SelectItem>
                      ))
                    ) : (
                      <p>No treatments found</p>
                    )}
                  </SelectContent>
                </Select>
                {formErrors.treatmentPublicId && (
                  <small className="text-destructive-500">
                    {formErrors.treatmentPublicId}
                  </small>
                )}
              </div>
              <div className="w-full space-y-1">
                <Label className="after:content-['*'] after:text-destructive-500">
                  Start date
                </Label>
                <DatePicker
                  variant="fullDate"
                  handleDateChange={value => inputHandler('startDate', value)}
                  initialValue={formData.startDate}
                  hasError={!!formErrors.startDate}
                />
                {formErrors.startDate && (
                  <small className="text-destructive-500">
                    {formErrors.startDate}
                  </small>
                )}
              </div>
              <div className="w-full space-y-1">
                <Label className="after:content-['*'] after:text-destructive-500">
                  Donor
                </Label>
                <SelectWithInputAndAddOption
                  name="donor"
                  type="single"
                  inputPlaceholder="Enter donor"
                  value={formData.donor}
                  options={donors}
                  setValue={value => inputHandler('donor', value)}
                  setOptions={setDonors}
                  hasError={!!formErrors.donor}
                  isLoading={isGettingDonorsData}
                />
                {formErrors.donor && (
                  <small className="text-destructive-500">
                    {formErrors.donor}
                  </small>
                )}
              </div>
              <div className="w-full space-y-1">
                <Label>Last menstrual period (LMP) </Label>
                <DatePicker
                  variant="fullDate"
                  handleDateChange={value =>
                    inputHandler('lastMenstrualPeriod', value)
                  }
                  initialValue={formData.lastMenstrualPeriod}
                />
              </div>
              <div className="w-full space-y-1">
                <Label>Treatment services </Label>
                <MultipleSelect
                  options={services}
                  selectedValues={formData.servicePublicIds}
                  setSelectedValues={(value:any) =>
                    inputHandler('servicePublicIds', value)
                  }
                  disabled={isGettingServicesData}
                />
              </div>
            </div>
          </Slider>
          <DialogFooter>
            <Button
              variant={'link'}
              size={'small'}
              className="w-full"
              onClick={onClose}
            >
              Close
            </Button>
            <Button
              size={'small'}
              variant={'primary'}
              onClick={handleSubmit}
              className="w-full"
              isLoading={isLoading}
              disabled={isLoading}
            >
              {props.type === 'Edit'
                ? 'Save treatment cycle'
                : 'Start new cycle'}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TreatmentCycleModal;
