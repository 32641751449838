import React, { useEffect, useState } from 'react';
import { CrossIcon, DownArrowIcon } from '../../../assets/icons';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  Button,
  DialogFooter,
  Label,
  Slider,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  Input,
} from '../../UI';
import { DatePicker, notify } from '../../../components/common';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { getLocationResources } from '../../../services/api/endPoints/resources';
import { createNewBooking } from '../../../services/api/endPoints/bookings';
import { useDispatch } from 'react-redux';
import { addResourceBooking } from '../../../redux/slice/resourceBookingSlice';

const BlockTimeModal: React.FC<Modal> = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [locationErrorMessage, setLocationErrorMessage] = useState<string>('');

  const [selectedLocation, setSelectedLocation] = useState<string>('');
  const locations = useSelector<RootState, Location[]>(
    state => state.locations.locations
  );
  const [resources, setResources] = useState<Resource[]>([]);
  const [selectedResource, setSelectedResource] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const handleDateChange = (newDate: Date | null, type: 'start' | 'end') => {
    if (newDate) {
      if (type === 'start') {
        setStartDate(newDate);
      } else {
        setEndDate(newDate);
      }
    }
  };

  const fetchResources = async (locationId: string) => {
    setIsLoading(true);
    const [data, error] = await getLocationResources('', locationId);
    setIsLoading(false);
    if (data) {
      setLocationErrorMessage('');
      const resourceInfo: Resource[] = data.map((resource: any) => ({
        publicId: resource.publicId,
        name: resource.name,
      }));
      setResources(resourceInfo);
      if (data.length === 0) {
        setLocationErrorMessage('No resources found for the selected location');
      }
    } else {
      notify.error({ title: 'Error Fetching Resource', message: error });
    }
  };

  useEffect(() => {
    if (selectedLocation) {
      fetchResources(selectedLocation);
    }
  }, [selectedLocation]);

  const handleBlockTime = async () => {
    setIsLoading(true);
    const blockTimeData = {
      resourcePublicId: selectedResource,
      locationPublicId: selectedLocation,
      appointmentTime: startDate,
      appointmentEndTime: endDate,
      title: title,
      description: description,
      blocked: true,
    };

    const [response, error] = await createNewBooking(blockTimeData);
    setIsLoading(false);

    if (response) {
      dispatch(addResourceBooking(response));
      notify.success({ title: 'Block Time Added Successfully!' });
      onClose();
    } else {
      notify.error({ title: 'Failed to add block time', message: error });
    }
  };

  return (
    <div>
      <Dialog open={open} onOpenChange={onClose}>
        <DialogContent className="p-0 tablet:w-[40rem] md:w-[40rem] flex flex-col gap-0 border border-neutral-100 max-h-[80%]">
          <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
            <h1 className="text-[14px] text-neutral-900 font-semibold">
              Block off time
            </h1>
            <Button variant={'link'} size={'xmall'} onClick={onClose}>
              <CrossIcon />
            </Button>
          </DialogHeader>
          <Slider className="flex-1" variant={'vertical'}>
            <div className="p-[1rem]">
              <div>
                <Label className="pb-1">Location</Label>
                <Select onValueChange={value => setSelectedLocation(value)}>
                  <SelectTrigger
                    className="w-full"
                    selectIcon={<DownArrowIcon />}
                  >
                    <SelectValue placeholder="Select Location" />
                  </SelectTrigger>
                  <SelectContent className="bg-shades-0 z-[999999]">
                    {locations.map(location => (
                      <SelectItem
                        key={location.publicId}
                        value={location.publicId}
                      >
                        {location.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {locationErrorMessage && (
                  <div className="mt-2 text-red-500 text-sm text-left">
                    {locationErrorMessage}
                  </div>
                )}
              </div>
              {resources.length > 0 && (
                <div className="mt-2">
                  <Label>Resource</Label>
                  <Select
                    onValueChange={value => {
                      setSelectedResource(value);
                    }}
                  >
                    <SelectTrigger
                      className="w-full"
                      selectIcon={<DownArrowIcon />}
                    >
                      <SelectValue placeholder="Select Resource" />
                    </SelectTrigger>
                    <SelectContent className="bg-shades-0 z-[999999]">
                      {resources.map(resource => (
                        <SelectItem
                          key={resource.publicId}
                          value={resource.publicId}
                        >
                          {resource.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              )}
              <div className="pt-2">
                <Label>Title</Label>
                <Input
                  id="title"
                  type="text"
                  name="Title"
                  placeholder="Title"
                  required={true}
                  onChange={e => {
                    setTitle(e.target.value);
                  }}
                />
              </div>
              <div className="pt-2">
                <Label>Description</Label>
                <Input
                  id="description"
                  type="text"
                  name="Description"
                  placeholder="Description"
                  required={true}
                  onChange={e => {
                    setDescription(e.target.value);
                  }}
                />
              </div>
              <div className="pt-2 pb-2 flex flex-col gap-2">
                <div>
                  <Label>Start Date & Time</Label>
                  <DatePicker
                    variant="dateTime"
                    handleDateChange={date => handleDateChange(date, 'start')}
                  />
                </div>
                <div>
                  <Label>End Date & Time</Label>
                  <DatePicker
                    variant="dateTime"
                    handleDateChange={date => handleDateChange(date, 'end')}
                  />
                </div>
              </div>
            </div>
          </Slider>
          <DialogFooter>
            <Button
              variant={'link'}
              size={'small'}
              className="w-full"
              onClick={onClose}
            >
              Close
            </Button>
            <Button
              size={'small'}
              onClick={handleBlockTime}
              className="w-full"
              variant={'primary'}
              isLoading={isLoading}
              disabled={isLoading}
            >
              Block Time
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default BlockTimeModal;
