import React, { FC } from 'react';
import { THEME } from '../../constants/ColorConstants';

interface SendIconProps {
  stroke?: string;
}

const SendIcon: FC<SendIconProps> = ({ stroke = THEME.SHADE_100 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M10.5 11.3332L13.8333 7.99984L10.5 4.6665"
        stroke={stroke}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.16663 12V10.6667C3.16663 9.95942 3.44758 9.28115 3.94767 8.78105C4.44777 8.28095 5.12605 8 5.83329 8H13.8333"
        stroke={stroke}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SendIcon;
