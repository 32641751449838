import React, { ReactNode } from 'react';
import {
  LocationIcon,
  PersonIcon,
  TargetIcon,
  // ProgressIcon,
  ResourceIcon,
  FirstAidBoxIcon,
} from '../../../../assets/icons';
import { NavLink, Outlet } from 'react-router-dom';
import { Activity, Route } from 'lucide-react';
import { ROUTES } from '../../../../constants/NavigationConstants';

const ClinicManagerTabs = () => {
  return (
    <div className="border border-neutral-100 rounded-lg border-collapse custom-height flex flex-col">
      <div className="w-full">
        <div className="h-10 flex justify-start gap-2 overflow-x-auto border-b border-neutral-100 bg-neutral-50 rounded-t-lg">
          <NavItem to={ROUTES.ADMIN.STAFFS}>
            <PersonIcon stroke="currentColor" /> Staff
          </NavItem>
          <NavItem to={ROUTES.ADMIN.SITES}>
            <TargetIcon /> Sites
          </NavItem>
          <NavItem to={ROUTES.ADMIN.LOCATIONS}>
            <LocationIcon stroke="currentColor" /> Locations
          </NavItem>
          <NavItem to={ROUTES.ADMIN.RESOURCES}>
            <ResourceIcon stroke="currentColor" /> Resource
          </NavItem>
          <NavItem to={ROUTES.ADMIN.TEAMS}>
            <PersonIcon stroke="currentColor" /> Teams
          </NavItem>
          <NavItem to={ROUTES.ADMIN.PATHWAYS}>
            <Route size={15} /> Pathways
          </NavItem>
          <NavItem to={ROUTES.ADMIN.SERVICES}>
            <FirstAidBoxIcon /> Services
          </NavItem>
          <NavItem to={ROUTES.ADMIN.TREATMENT}>
            <Activity size={15} /> Treatments
          </NavItem>
        </div>
      </div>
      <div className="overflow-x-auto flex flex-col flex-1">
        <Outlet />
      </div>
    </div>
  );
};

const NavItem = ({ to, children }: { to: string; children: ReactNode }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        [
          'h-full inline-flex items-center justify-center gap-2 whitespace-nowrap px-3 py-2 text-sm font-medium transition-all',
          isActive ? 'border-b border-primary-500 text-primary-500 ' : '',
        ].join(' ')
      }
    >
      {children}
    </NavLink>
  );
};

export default ClinicManagerTabs;
