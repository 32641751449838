import { URL } from '../requestConstructor';

export const getLocationResources = async (
  publicId: string,
  locationPublicId: string
) => {
  try {
    const response = await URL.get(
      `/location/resource/get?publicId=${publicId}&locationPublicId=${locationPublicId}`
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, 'Server Error: ' + error.response.data];
  }
};

export const getAllResources = async () => {
  try {
    const response = await URL.get('/location/resource/get/all');
    return [response.data, null];
  } catch (error: any) {
    return [null, 'Server Error: ' + error.response.data];
  }
};
