import React, { useState } from 'react';
import LabTestForm from '../components/sections/Patient/PatientInfoTabs/Labs/LabTestForm';

const CreateTest = () => {
  const [key, setKey] = useState<number>(0);

  const resetForm = () => {
    setKey(prevKey => prevKey + 1);
  };
  return (
    <div className="w-full rounded-md border border-neutral-100">
      <LabTestForm key={key} onReset={resetForm} />
    </div>
  );
};

export default CreateTest;
