/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { notify } from '../../../../common';
import {
  ERROR_MESSAGE,
  ERROR_TITLE,
} from '../../../../../constants/NotificationConstants';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../../../UI';
import {
  EndIcon,
  LeftArrowIcon,
  NotePenIcon,
  EggCollectionIcon,
  EmbryoLogyIcon,
  EmbryologyTransferIcon,
} from '../../../../..//assets/icons';
import LoaderIcon from '../../../../../assets/icons/Loader.svg';
import { getReadableDate } from '../../../../../utils/date.utl';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import TreatmentCycleModal from './TreatmentCycleModal';
import EndTreatmentCycleModal from './EndTreatmentCycleModal';
import { PATIENT_ROUTES } from '../../../../../constants/NavigationConstants';

const TreatmentCycleView = () => {
  const { treatmentCycleId } = useParams();
  const [treatmentCycle, setTreatmentCycle] = useState<TreatmentCycle>();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { treatmentCycles } = useSelector<RootState, TreatmentCycleState>(
    state => state.treatmentCycles
  );
  const [isUpdateTreatmentCycleModalOpen, setIsUpdateTreatmentCycleModalOpen] =
    useState<boolean>(false);
  const [isEndTreatmentCycleModalOpen, setIsEndTreatmentCycleModalOpen] =
    useState<boolean>(false);
  useEffect(() => {
    const getTreatmentCycleDetails = () => {
      if (!treatmentCycleId) {
        notify.error({
          title: ERROR_TITLE.GENERIC_FETCH_TREATMENT_CYCLE,
          message: ERROR_MESSAGE.GENERIC_TRY_AGAIN,
        });
        return;
      }
      setIsLoading(true);
      const treatmentCycle = treatmentCycles.find(
        treatmentCycle => treatmentCycle.publicId === treatmentCycleId
      );
      if (treatmentCycle) {
        setTreatmentCycle(treatmentCycle);
      } else {
        notify.error({
          title: 'Failed to fetch treatment',
          message: ERROR_MESSAGE.GENERIC_TRY_AGAIN,
        });
      }
      setIsLoading(false);
    };
    getTreatmentCycleDetails();
  }, [treatmentCycles]);

  return (
    <>
      {isUpdateTreatmentCycleModalOpen && treatmentCycle && (
        <TreatmentCycleModal
          type="Edit"
          open={isUpdateTreatmentCycleModalOpen}
          onClose={() => {
            setIsUpdateTreatmentCycleModalOpen(false);
          }}
          treatmentCycle={treatmentCycle}
        />
      )}
      {isEndTreatmentCycleModalOpen && treatmentCycle && (
        <EndTreatmentCycleModal
          open={isEndTreatmentCycleModalOpen}
          onClose={() => {
            setIsEndTreatmentCycleModalOpen(false);
          }}
          treatmentCycle={treatmentCycle}
        />
      )}
      <div className="border-b border-neutral-100 p-3 flex justify-between items-center">
        <Button
          variant={'outlined'}
          size={'xmall'}
          leadingIcon={<LeftArrowIcon />}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
        {treatmentCycle && (
          <h1 className="whitespace-nowrap text-center space-x-2">
            <span className="text-neutral-900 font-medium ">
              {treatmentCycle.treatment.name}
            </span>
            {treatmentCycle.endDate ? (
              <span className="rounded-xl border border-neutral-100 px-1.5">
                Completed
              </span>
            ) : treatmentCycle.cancelledDate ? (
              <span className="rounded-xl border border-destructive-500 bg-destructive-100 px-1.5 text-destructive-500">
                Cancelled
              </span>
            ) : (
              <span className="rounded-xl border border-success-500 bg-success-100 px-1.5 text-success-500">
                Active
              </span>
            )}
          </h1>
        )}
        <div className="flex gap-2">
          <Button
            variant={'outlined'}
            size={'xmall'}
            trailingIcon={<NotePenIcon stroke="currentColor" />}
            disabled={!treatmentCycle || isLoading || !!treatmentCycle.endDate}
            onClick={() => setIsUpdateTreatmentCycleModalOpen(true)}
          >
            Edit
          </Button>
          <Button
            variant={'outlined'}
            size={'xmall'}
            onClick={() => setIsEndTreatmentCycleModalOpen(true)}
            disabled={!!treatmentCycle?.endDate || !treatmentCycle || isLoading}
            trailingIcon={<EndIcon />}
          >
            End
          </Button>
        </div>
      </div>
      {isLoading ? (
        <div className="flex flex-col justify-center items-center h-[500px]">
          <img src={LoaderIcon} className="w-[40px] h-[40px] animate-spin" />
        </div>
      ) : treatmentCycle ? (
        <div className="h-[500px] p-3 space-y-2">
          <div className="border rounded w-full grid grid-cols-2">
            <div className="border-r p-3 flex flex-col gap-2">
              <div className="grid grid-cols-2">
                <p className="text-[14px] font-semibold text-neutral-500">
                  Treatment
                </p>
                <p className="text-[14px] font-semibold text-neutral-900">
                  {treatmentCycle.treatment.name}
                </p>
              </div>
              <div className="grid grid-cols-2">
                <p className="text-[14px] font-semibold text-neutral-500">
                  Start date
                </p>
                <p className="text-[14px] font-semibold text-neutral-900">
                  {getReadableDate(treatmentCycle.startDate)}
                </p>
              </div>
              <div className="grid grid-cols-2">
                <p className="text-[14px] font-semibold text-neutral-500">
                  Last menstruation period (LMP)
                </p>
                <p className="text-[14px] font-semibold text-neutral-900">
                  {treatmentCycle.lastMenstrualPeriod
                    ? getReadableDate(treatmentCycle.lastMenstrualPeriod)
                    : '-'}
                </p>
              </div>
              <div className="grid grid-cols-2">
                <p className="text-[14px] font-semibold text-neutral-500">
                  Services
                </p>
                <div className="space-y-1">
                  {treatmentCycle.services ? (
                    treatmentCycle.services.map(service => (
                      <p className="text-[14px] font-semibold text-neutral-900">
                        {service.name}
                      </p>
                    ))
                  ) : (
                    <p className="text-[14px] font-semibold text-neutral-900">
                      -
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className=" p-3 flex flex-col gap-2">
              <div className="grid grid-cols-2">
                <p className="text-[14px] font-semibold text-neutral-500">
                  End date
                </p>
                <p className="text-[14px] font-semibold text-neutral-900">
                  {treatmentCycle.endDate
                    ? getReadableDate(treatmentCycle.endDate)
                    : '-'}
                </p>
              </div>
              <div className="grid grid-cols-2">
                <p className="text-[14px] font-semibold text-neutral-500">
                  Reason for ending
                </p>
                <p className="text-[14px] font-semibold text-neutral-900">
                  {treatmentCycle.reasonForEnding
                    ? treatmentCycle.reasonForEnding
                    : '-'}
                </p>
              </div>
              <div className="grid grid-cols-2">
                <p className="text-[14px] font-semibold text-neutral-500">
                  Cancel date
                </p>
                <p className="text-[14px] font-semibold text-neutral-900">
                  {treatmentCycle.cancelledDate
                    ? getReadableDate(treatmentCycle.cancelledDate)
                    : '-'}
                </p>
              </div>
            </div>
          </div>
          <div className="border rounded w-full grid grid-cols-2">
            <div className="border-r border-neutral-100 flex justify-between p-3 items-center">
              <p className="text-[14px] font-semibold text-neutral-500">
                Procedures
              </p>
              <div className="flex gap-2">
                <Button
                  variant={'outlined'}
                  size={'xmall'}
                  trailingIcon={<EggCollectionIcon />}
                  disabled={!treatmentCycle || isLoading}
                  onClick={() =>
                    navigate(`${PATIENT_ROUTES.EGG_COLLECTION.HOME}`, {
                      state: {
                        isReadOnly:
                          !!treatmentCycle.endDate ||
                          !!treatmentCycle.cancelledDate,
                        treatmentCycleName: treatmentCycle.treatment.name,
                      },
                    })
                  }
                >
                  Egg collection
                </Button>
                <Button
                  variant={'outlined'}
                  size={'xmall'}
                  trailingIcon={<EmbryoLogyIcon />}
                  disabled={!treatmentCycle || isLoading}
                >
                  Embryology
                </Button>
                <Button
                  variant={'outlined'}
                  size={'xmall'}
                  trailingIcon={<EmbryologyTransferIcon />}
                  disabled={!treatmentCycle || isLoading}
                >
                  Embryo transfer
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center h-[500px]">
          <p className="text-[14px] text-neutral-500">
            No treatment cycle details found. Please try again later
          </p>
        </div>
      )}
    </>
  );
};

export default TreatmentCycleView;
