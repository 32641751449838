import { FC } from 'react';
import { THEME } from '../../constants/ColorConstants';

const OutlinedUserIcon: FC<{
  stroke?: string;
}> = ({ stroke = THEME.NEUTRAL_500 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M12.6667 2.5H3.33333C2.59695 2.5 2 3.09695 2 3.83333V13.1667C2 13.903 2.59695 14.5 3.33333 14.5H12.6667C13.403 14.5 14 13.903 14 13.1667V3.83333C14 3.09695 13.403 2.5 12.6667 2.5Z"
        stroke={stroke}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 9.16602C9.10457 9.16602 10 8.27059 10 7.16602C10 6.06145 9.10457 5.16602 8 5.16602C6.89543 5.16602 6 6.06145 6 7.16602C6 8.27059 6.89543 9.16602 8 9.16602Z"
        stroke={stroke}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66797 14.5007V13.1673C4.66797 12.8137 4.80844 12.4746 5.05849 12.2245C5.30854 11.9745 5.64768 11.834 6.0013 11.834H10.0013C10.3549 11.834 10.6941 11.9745 10.9441 12.2245C11.1942 12.4746 11.3346 12.8137 11.3346 13.1673V14.5007"
        stroke={stroke}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default OutlinedUserIcon;
