import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  Button,
  DialogFooter,
  Label,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Slider,
  Checkbox,
} from '../../../../UI';
import { CrossIcon, DownArrowIcon } from '../../../../../assets/icons';
import {
  getAllSites,
  getStaff,
  updateStaff,
} from '../../../../../services/api';
import { Upload } from 'lucide-react';
import { createStaff } from '../../../../../services/api';
import { notify } from '../../../../../components/common';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { getFullName } from '../../../../../utils/Helper';
import {
  addStaff,
  updateStaff as updateStaffRedux,
} from '../../../../../redux/slice';
import { useDispatch } from 'react-redux';
import {
  ERROR_TITLE,
  SUCCESS_TITLE,
} from '../../../../../constants/NotificationConstants';
import UploadProfilePictureStaffModal from './UploadProfilePictureStaffModal';
import Skeleton from 'react-loading-skeleton';
import { downloadFileByUrl } from '../../../../../services/api/endPoints/files.api';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../../constants/NavigationConstants';
import { updateUserStaffDetails } from '../../../../../redux/slice';
const AddAndEditStaffModal = ({
  open,
  onClose,
  ...props
}: AddAndEditModalProps) => {
  const [step, setStep] = useState<number>(1);
  const staffs = useSelector<RootState, StaffInformation[]>(
    state => state.staffs.staffs
  );
  const user = useSelector<RootState, UserEntities>(state => state.user.user);
  const [consultantMangerOptions, setConsultantMangerOptions] = useState<
    Record<string, string>
  >({});
  const titleOptions: { label: string; value: string }[] = [
    { value: 'Mr.', label: 'Mr' },
    { value: 'Ms.', label: 'Ms' },
    { value: 'Mrs.', label: 'Mrs' },
    { value: 'Sir', label: 'Sir' },
    { value: 'Mx', label: 'Mx' },
    { value: 'Madam', label: 'Madam' },
    { value: 'Dr.', label: 'Dr' },
  ];
  const [siteOptions, setSiteOptions] = useState<Record<string, string>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [data, setData] = useState<StaffFormInputType>({
    title: '',
    firstName: '',
    email: '',
    phoneNo: '',
    type: 'Superadmin',
    siteExternalId: '',
    lastName: '',
    username: '',
    password: '',
    secretaryPublicIds: [],
    twoFactorEnabled: false,
    profilePictureUrl: '',
    designation: '',
  });
  const [formErrors, setFormErrors] = useState<
    Record<keyof StaffFormInputType, string | null>
  >({
    title: null,
    firstName: null,
    email: null,
    phoneNo: null,
    type: null,
    siteExternalId: null,
    lastName: null,
    username: null,
    password: null,
    secretaryPublicIds: null,
    twoFactorEnabled: null,
    profilePictureUrl: null,
    designation: null,
  });
  const [oldData, setOldData] = useState<StaffInformation | null>(null);
  const [addProfilePictureModal, setAddProfilePictureModal] =
    useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [isDownloading, setIsDownLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const getProfileImage = async (profilePictureUrl: string) => {
    if (!profilePictureUrl || profilePictureUrl === '') return;
    setIsDownLoading(true);
    const [res] = await downloadFileByUrl({ url: profilePictureUrl });
    if (res) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result as string);
      };
      reader.readAsDataURL(res);
    }
    setIsDownLoading(false);
  };
  const [isGettingStaffData, setIsGettingStaff] = useState<boolean>(false);
  const restoreModalState = (storedData: any) => {
    if (
      location.state &&
      'profilePictureUrl' in location.state &&
      location.state.profilePictureUrl !== ''
    ) {
      getProfileImage(location.state.profilePictureUrl);
    } else {
      getProfileImage(storedData.data.profilePictureUrl);
    }

    setData({
      ...storedData.data,
      profilePictureUrl:
        location.state &&
        'profilePictureUrl' in location.state &&
        location.state.profilePictureUrl !== ''
          ? location.state.profilePictureUrl
          : storedData.data.profilePictureUrl,
    });
    setOldData(storedData.oldData);
  };
  useEffect(() => {
    const setStaffDetails = async () => {
      if (props.type !== 'Edit') return;
      if (!props.selectedStaffId) {
        notify.error({
          title: 'Unable to load staff information',
          message: 'Username not available. Please reload the page',
        });
        return;
      }
      setIsGettingStaff(true);
      const [res, error] = await getStaff(props.selectedStaffId);
      if (res) {
        const staff: StaffInformation = res;
        const data: StaffFormInputType = {
          title: staff.title,
          firstName: staff.firstName,
          lastName: staff.lastName,
          email: staff.email,
          phoneNo: staff.phoneNo,
          type: staff.type,
          siteExternalId: staff.siteExternalId,
          username: staff.username,
          password: '',
          secretaryPublicIds: staff.secretaries
            ? staff.secretaries.map(value => value.publicId)
            : [],
          profilePictureUrl: staff.profilePictureUrl
            ? staff.profilePictureUrl
            : '',
          designation: staff.designation ? staff.designation : '',
        };
        getProfileImage(data.profilePictureUrl);
        setData(data);
        setOldData(staff);
        setIsGettingStaff(false);
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_STAFF_FETCH,
          message: error.data,
        });
      }
    };
    const currentModalState = sessionStorage.getItem('currentModalState');
    if (currentModalState) {
      const currentData = JSON.parse(currentModalState);
      restoreModalState(currentData);
    } else {
      setStaffDetails();
    }
    const staffOptions: Record<string, string> = {};
    staffs.map(staff => {
      staffOptions[staff.publicId] = getFullName(staff);
    });
    setConsultantMangerOptions(staffOptions);
    // Once the site tab code is implemented we can replace the code -getSites() with redux site state
    const getSites = async () => {
      const [response,] = await getAllSites();
      if (response) {
        const results: Record<string, string> = {};
        response.map((element: Site) => {
          results[element.externalId] = element.name;
        });
        setSiteOptions(results);
      }
    };
    getSites();
  }, []);

  const handleInput = (name: keyof StaffFormInputType, value: any) => {
    const error = { ...formErrors };
    error[name as keyof StaffFormInputType] = null;
    setData(prev => ({
      ...prev,
      [name]: value,
    }));
    setFormErrors(error);
  };
  const isValidInput = () => {
    let isValid = true;
    const error = { ...formErrors };
    if (step === 1) {
      if (!data.firstName || data.firstName === '') {
        error.firstName = 'First name is required';
        isValid = false;
      }
      if (!data.lastName || data.lastName === '') {
        error.lastName = 'Last name is required';
        isValid = false;
      }
      if (!data.email || data.email === '') {
        error.email = 'Email is required';
        isValid = false;
      } else if (
        !data.email.match(
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        )
      ) {
        error.email = 'Please provide a valid email';
        isValid = false;
      }
      if (!data.type || data.type === '') {
        error.type = 'Staff Type is required';
        isValid = false;
      }
      if (!data.siteExternalId || data.siteExternalId === '') {
        error.siteExternalId = 'Site is required';
        isValid = false;
      }
      if (!data.phoneNo || data.phoneNo === '') {
        error.phoneNo = 'Phone number is required';
        isValid = false;
      }
    } else if (step === 2) {
      if (!data.username || data.username === '') {
        error.username = 'Username is required';
        isValid = false;
      }
      if (!data.password || data.password === '') {
        error.password = 'Password is require';
        isValid = false;
      }
    }
    setFormErrors(error);
    return isValid;
  };
  const stepActionHandler = async () => {
    if (!isValidInput()) return;
    if (step == 2 || props.type === 'Edit') {
      await handleSubmit();
      return;
    }
    setStep(2);
  };
  const handleSubmit = async () => {
    setIsLoading(true);
    const getRoles = (type: string) => {
      switch (type) {
        case 'Admin':
          return ['ROLE_ADMIN'];
        case 'Superadmin':
          return ['ROLE_SUPERADMIN'];
        case 'Consultant':
          return ['ROLE_CONSULTANT'];
        case 'Secretary':
          return ['ROLE_SECRETARY'];
      }
    };
    const requestData = {
      ...data,
      roles: getRoles(data.type),
    };
    if (props.type === 'Create') {
      const [response, error] = await createStaff(requestData);
      if (response) {
        dispatch(
          addStaff({ ...response, profilePictureBase64DataUrl: imageUrl })
        );
        notify.success({
          title: SUCCESS_TITLE.GENERIC_STAFF_ADD,
          message: `'${getFullName(response)}' added successfully`,
        });
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_STAFF_ADD,
          message: error.data,
        });
        setIsLoading(false);
        return;
      }
    } else if (props.type == 'Edit') {
      const [response, error] = await updateStaff({
        publicId: props.selectedStaffId,
        ...requestData,
      });
      if (response) {
        dispatch(
          updateStaffRedux({
            ...response,
            profilePictureBase64DataUrl: imageUrl,
          })
        );

        if (props.selectedStaffId === user.staff.publicId) {
          dispatch(updateUserStaffDetails(response));
        }
        notify.success({
          title: SUCCESS_TITLE.GENERIC_STAFF_UPDATE,
          message: `'${oldData ? getFullName(oldData) : data.username}' updated successfully`,
        });
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_STAFF_UPDATE,
          message: error.data,
        });
        setIsLoading(false);
        return;
      }
    }
    setIsLoading(false);
    closeHandler();
  };
  const closeHandler = () => {
    sessionStorage.removeItem('currentModalState');
    onClose();
  };
  const consultManagerSelect = (selectValues: string[]) => {
    setData(prev => ({
      ...prev,
      secretaryPublicIds: [...selectValues],
    }));
  };
  const onChangeProfilePicture = (profilePictureUrl: string) => {
    setData(prev => ({
      ...prev,
      profilePictureUrl: profilePictureUrl,
    }));
    getProfileImage(profilePictureUrl);
  };
  const navigateToTakePictureHandler = () => {
    const currentModalState = {
      data: { ...data },
      isEditModal: props.type === 'Edit',
      oldData: { ...oldData },
      selectedStaffId:
        props.type === 'Edit' ? props.selectedStaffId : undefined,
    };

    sessionStorage.setItem(
      'currentModalState',
      JSON.stringify(currentModalState)
    );
    navigate(ROUTES.ADMIN.STAFF_TAKE_PICTURE, {
      state: {
        from: location.pathname,
        fileName:
          data.profilePictureUrl != ''
            ? data.profilePictureUrl.split('/').pop()?.split('.')[0]
            : undefined,
      },
    });
  };
  return (
    <>
      {addProfilePictureModal && (
        <UploadProfilePictureStaffModal
          open={addProfilePictureModal}
          onClose={() => setAddProfilePictureModal(false)}
          onChangeProfilePicture={onChangeProfilePicture}
          navigateToTakePictureHandler={navigateToTakePictureHandler}
          profilePictureFileName={
            data.profilePictureUrl != ''
              ? data.profilePictureUrl.split('/').pop()?.split('.')[0]
              : undefined
          }
        />
      )}
      <Dialog open={open} onOpenChange={closeHandler}>
        <DialogContent className="p-0 w-[70vw] md:w-[544px] flex flex-col gap-0 border border-neutral-100 max-h-[70%]">
          <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
            <h1 className="text-[14px] text-neutral-900 font-semibold">
              {props.type === 'Create' ? 'Add staff' : 'Edit staff'}
            </h1>
            <Button variant={'link'} size={'xmall'} onClick={closeHandler}>
              <CrossIcon />
            </Button>
          </DialogHeader>
          <Slider className="flex-1" variant={'vertical'}>
            <div className="p-4 space-y-4">
              {step === 1 ? (
                <>
                  {' '}
                  <div className="flex gap-4">
                    {isDownloading ? (
                      <Skeleton height={80} width={80} circle />
                    ) : imageUrl ? (
                      <img
                        src={imageUrl}
                        className="h-[80px] w-[80px] object-fit rounded-full"
                        alt="staff-profile-picture"
                      />
                    ) : (
                      <div className="h-[80px] w-[80px] rounded-full bg-shades-100/[0.2]" />
                    )}

                    <div className="space-y-2 flex flex-col justify-center">
                      <Button
                        variant={'outlined'}
                        size={'xmall'}
                        trailingIcon={<Upload size={15} />}
                        onClick={() => setAddProfilePictureModal(true)}
                        disabled={isGettingStaffData}
                      >
                        Upload image
                      </Button>
                      <p className="text-[14px] text-neutral-500">
                        400 X 400 px jpg or png format
                      </p>
                    </div>
                  </div>
                  <div className="grid grid-cols-7 gap-3">
                    <div className="space-y-1">
                      <Label htmlFor="title">Title</Label>
                      <Select
                        required
                        name="title"
                        onValueChange={value => handleInput('title', value)}
                        disabled={isGettingStaffData}
                        value={data.title}
                      >
                        <SelectTrigger
                          className="w-full"
                          selectIcon={<DownArrowIcon />}
                        >
                          <SelectValue placeholder="Title" />
                        </SelectTrigger>
                        <SelectContent className="bg-shades-0 z-[999999]">
                          {titleOptions.map(title => (
                            <SelectItem value={title.value} key={title.value}>
                              {title.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                    <div className="col-span-3 space-y-1">
                      <Label htmlFor="firstName">First name</Label>
                      <Input
                        placeholder="Enter first name"
                        id="firstName"
                        name="firstName"
                        onChange={e => handleInput('firstName', e.target.value)}
                        value={data.firstName}
                        disabled={isGettingStaffData}
                        hasError={!!formErrors.firstName}
                      />
                      {formErrors.firstName && (
                        <small className="text-destructive-500">
                          {formErrors.firstName}
                        </small>
                      )}
                    </div>
                    <div className="col-span-3 space-y-1">
                      <Label htmlFor="lastName">Last name</Label>
                      <Input
                        placeholder="Enter last name"
                        id="lastName"
                        name="lastName"
                        onChange={e => handleInput('lastName', e.target.value)}
                        value={data.lastName}
                        disabled={isGettingStaffData}
                        hasError={!!formErrors.lastName}
                      />
                      {formErrors.lastName && (
                        <small className="text-destructive-500">
                          {formErrors.lastName}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="space-y-1">
                    <Label htmlFor="email">Email</Label>
                    <Input
                      placeholder="Enter email"
                      name="email"
                      onChange={e => handleInput('email', e.target.value)}
                      type="email"
                      disabled={props.type === 'Edit'}
                      value={data.email}
                      hasError={!!formErrors.email}
                    />
                    {formErrors.email && (
                      <small className="text-destructive-500">
                        {formErrors.email}
                      </small>
                    )}
                  </div>
                  <div className="space-y-1">
                    <Label htmlFor="phoneNo">Phone number</Label>
                    <Input
                      placeholder="Enter phone number"
                      id="phoneNo"
                      name="phoneNo"
                      type="tel"
                      onChange={e => handleInput('phoneNo', e.target.value)}
                      value={data.phoneNo}
                      disabled={isGettingStaffData}
                      hasError={!!formErrors.phoneNo}
                    />
                    {formErrors.phoneNo && (
                      <small className="text-destructive-500">
                        {formErrors.phoneNo}
                      </small>
                    )}
                  </div>
                  <div className="space-y-1">
                    <Label htmlFor="type">Staff type</Label>
                    <Select
                      required
                      name="type"
                      onValueChange={value => handleInput('type', value)}
                      value={data.type}
                      disabled={isGettingStaffData}
                    >
                      <SelectTrigger
                        className="w-full"
                        selectIcon={<DownArrowIcon />}
                        hasError={!!formErrors.type}
                      >
                        <SelectValue placeholder="Select" />
                      </SelectTrigger>
                      <SelectContent className="bg-shades-0 z-[999999]">
                        <SelectItem value="Admin">Admin</SelectItem>
                        <SelectItem value="Superadmin">Superadmin</SelectItem>
                        <SelectItem value="Consultant">Consultant</SelectItem>
                        <SelectItem value="Secretary">Secretary</SelectItem>
                      </SelectContent>
                    </Select>
                    {formErrors.type && (
                      <small className="text-destructive-500">
                        {formErrors.type}
                      </small>
                    )}
                  </div>
                  <div className="space-y-1">
                    <Label htmlFor="designation">Designation</Label>
                    <Input
                      placeholder="Enter designation"
                      id="designation"
                      name="designation"
                      type="text"
                      onChange={e => handleInput('designation', e.target.value)}
                      value={data.designation}
                      disabled={isGettingStaffData}
                    />
                  </div>
                  <div className="space-y-1">
                    {siteOptions && (
                      <>
                        <Label htmlFor="site">Staff site</Label>
                        <Select
                          required
                          name="siteExternalId"
                          value={data.siteExternalId}
                          onValueChange={value =>
                            handleInput('siteExternalId', value)
                          }
                          disabled={isGettingStaffData}
                        >
                          <SelectTrigger
                            className="w-full"
                            selectIcon={<DownArrowIcon />}
                            hasError={!!formErrors.siteExternalId}
                          >
                            <SelectValue placeholder="Select" />
                          </SelectTrigger>
                          <SelectContent className="bg-shades-0 z-[999999]">
                            {Object.keys(siteOptions).map(key => (
                              <SelectItem value={key} key={key}>
                                {siteOptions[key]}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>{' '}
                        {formErrors.siteExternalId && (
                          <small className="text-destructive-500">
                            {formErrors.siteExternalId}
                          </small>
                        )}
                      </>
                    )}
                  </div>
                  <div className="space-y-1">
                    <Label htmlFor="consultantManager">
                      Consultant manager
                    </Label>
                    <MultipleSelect
                      options={consultantMangerOptions}
                      selectedValues={data.secretaryPublicIds}
                      setSelectedValues={consultManagerSelect}
                      disabled={isGettingStaffData}
                    />
                  </div>{' '}
                </>
              ) : (
                props.type === 'Create' && (
                  <>
                    <div className="space-y-1">
                      <Label htmlFor="username">Username</Label>
                      <Input
                        placeholder="Enter username"
                        id="username"
                        name="username"
                        onChange={e => handleInput('username', e.target.value)}
                        value={data.username}
                        disabled={isGettingStaffData}
                        hasError={!!formErrors.username}
                      />
                      {formErrors.username && (
                        <small className="text-destructive-500">
                          {formErrors.username}
                        </small>
                      )}
                    </div>
                    <div className="space-y-1">
                      <Label htmlFor="password">Password</Label>
                      <Input
                        placeholder="Enter password"
                        id="password"
                        name="password"
                        type="password"
                        onChange={e => handleInput('password', e.target.value)}
                        value={data.password}
                        disabled={isGettingStaffData}
                        hasError={!!formErrors.password}
                      />
                      {formErrors.password && (
                        <small className="text-destructive-500">
                          {formErrors.password}
                        </small>
                      )}
                    </div>
                    <div>
                      <Checkbox
                        disabled={isGettingStaffData}
                        id="isTwoFactorEnable"
                        onCheckedChange={checked =>
                          setData(prev => ({
                            ...prev,
                            twoFactorEnabled: !!checked,
                          }))
                        }
                      />{' '}
                      Enable two Factor Authentication
                    </div>
                  </>
                )
              )}
            </div>
          </Slider>
          <DialogFooter>
            <Button
              variant={'link'}
              size={'small'}
              className="w-full"
              onClick={closeHandler}
            >
              Close
            </Button>
            <Button
              size={'small'}
              onClick={stepActionHandler}
              className="w-full"
              isLoading={isLoading}
              disabled={isLoading || isGettingStaffData}
              variant={'primary'}
            >
              {props.type === 'Create' && step === 1
                ? 'Continue'
                : props.type === 'Edit'
                  ? 'Save staff'
                  : 'Add staff'}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

const MultipleSelect = ({
  options,
  selectedValues,
  setSelectedValues,
  disabled,
}: {
  options: Record<string, string>;
  selectedValues: string[];
  setSelectedValues: (value: string[]) => void;
  disabled: boolean;
}) => {
  const onOptionSelect = (value: string) => {
    if (value === 'Select') return;
    const newSelectedValues = [...selectedValues];
    const selectIndex = newSelectedValues.findIndex(
      selectValue => selectValue === value
    );
    if (selectIndex >= 0) {
      newSelectedValues.splice(selectIndex, 1);
      setSelectedValues(newSelectedValues);
    } else {
      newSelectedValues.push(value);
      setSelectedValues(newSelectedValues);
    }
  };
  return (
    <>
      <Select
        required
        defaultValue="Select"
        name="multiselect"
        onValueChange={onOptionSelect}
        disabled={disabled}
      >
        <SelectTrigger className="w-full" selectIcon={<DownArrowIcon />}>
          <SelectValue placeholder="Select" />
        </SelectTrigger>
        <SelectContent className="bg-shades-0 z-[999999]">
          <SelectItem value={'Select'}>Select</SelectItem>
          {options &&
            Object.keys(options).map(key => (
              <SelectItem value={key} key={key}>
                {options[key]}
              </SelectItem>
            ))}
        </SelectContent>
      </Select>
      {selectedValues && selectedValues.length > 0 && (
        <div className="border border-neutral-100 rounded-lg border-collapse p-3 space-y-3">
          {selectedValues.map(value => (
            <div key={value} className="w-full flex justify-between ">
              <p className="text-[14px]">{options[value]}</p>
              <Button
                onClick={() => onOptionSelect(value)}
                variant={'link'}
                size={'xmall'}
              >
                <CrossIcon />
              </Button>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default AddAndEditStaffModal;
