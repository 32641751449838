import React from 'react';
import { THEME } from '../../constants/ColorConstants';

interface UploadIconProps {
  stroke?: string;
}

const UploadIcon: React.FC<UploadIconProps> = ({ stroke = THEME.NEUTRAL_900 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10"
      stroke={stroke}
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.3334 5.33333L8.00008 2L4.66675 5.33333"
      stroke={THEME.SHADE_100}
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 2V10"
      stroke={THEME.SHADE_100}
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default UploadIcon;
