import React, { useState, useEffect } from 'react';
import { downloadFileByUrl } from '../../services/api/endPoints/files.api';
import defaultImage from '../../assets/images/360_F_118019822_6CKXP6rXmVhDOzbXZlLqEM2ya4HhYzSV.jpg';
import Skeleton from 'react-loading-skeleton';
import { cn } from '../../utils';
import { useDispatch } from 'react-redux';
import { storeProfilePictureBase64DataUrl } from '../../redux/slice';
const ProfileImage = ({
  user,
  height,
  width,
}: {
  user: StaffInformation;
  height: string;
  width: string;
}) => {
  const [isDownloading, setIsDownLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const dispatch = useDispatch();
  const getProfileImage = async () => {
    if (!user.profilePictureUrl || user.profilePictureUrl === '') return;
    if (user.profilePictureBase64DataUrl) {
      setImageUrl(user.profilePictureBase64DataUrl);
      return;
    }
    setIsDownLoading(true);
    const [res] = await downloadFileByUrl({ url: user.profilePictureUrl });
    if (res) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result as string);
        dispatch(
          storeProfilePictureBase64DataUrl({
            publicId: user.publicId,
            profilePictureBase64DataUrl: reader.result as string,
          })
        );
      };
      reader.readAsDataURL(res);
    }
    setIsDownLoading(false);
  };
  useEffect(() => {
    getProfileImage();
  }, [user]);
  return (
    <>
      {isDownloading ? (
        <Skeleton height={height} width={width} circle />
      ) : (
        <img
          src={!imageUrl ? defaultImage : imageUrl}
          className={cn([
            'rounded-full inline mr-2',
            `w-[${width}]`,
            `h-[${height}]`,
          ])}
        />
      )}
    </>
  );
};

export default ProfileImage;
