import React, { useState, useMemo, useEffect } from 'react';
import { AlertDialog, DataTable, notify } from '../../../../common';
import { Button, Slider } from '../../../../UI';
import {
  AddIcon,
  DeleteIcon,
  DirectedArrowIcon,
} from '../../../../../assets/icons';
import { ColumnDef } from '@tanstack/react-table';
import AddAndEditTreatmentModal from './AddAndEditTreatmentModal';
import { deleteTreatment, getAllTreatments } from '../../../../../services/api';
import {
  addTreatments,
  deleteTreatment as deleteTreatmentReducer,
} from '../../../../../redux/slice/treatmentsSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import {
  ERROR_MESSAGE,
  ERROR_TITLE,
  SUCCESS_TITLE,
} from '../../../../../constants/NotificationConstants';
import { Edit2 } from 'lucide-react';
import { THEME } from '../../../../../constants/ColorConstants';

const Treatments = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAddTreatmentModalOpen, setIsAddTreatmentModalOpen] =
    useState<boolean>(false);
  const [isEditTreatmentModalOpen, setIsEditTreatmentModalOpen] =
    useState<boolean>(false);
  const [selectedTreatment, setSelectedTreatment] =
    useState<TreatmentLite | null>(null);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const treatments = useSelector<RootState, TreatmentLite[]>(
    state => state.treatments.treatments
  );
  const getTreatments = async () => {
    const [treatments, error] = await getAllTreatments();
    if (treatments) {
      dispatch(addTreatments(treatments));
    } else {
      notify.error({
        title: ERROR_TITLE.GENERIC_ALL_TREATMENT_FETCH,
        message: error?.data ? error.data : ERROR_MESSAGE.GENERIC_TRY_AGAIN,
      });
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getTreatments();
  }, []);
  const deleteTreatmentHandler = async () => {
    if (!selectedTreatment) return;
    setIsDeleting(true);
    const [, err] = await deleteTreatment(selectedTreatment.publicId);
    if (!err) {
      dispatch(
        deleteTreatmentReducer({ publicId: selectedTreatment.publicId })
      );
      notify.success({
        title: SUCCESS_TITLE.GENERIC_TREATMENT_DELETE,
        message: `'${selectedTreatment.name}' deleted successfully`,
      });
    } else {
      notify.error({
        title: ERROR_TITLE.GENERIC_TREATMENT_DELETE,
        message: err?.data ? err.data : ERROR_MESSAGE.GENERIC_TRY_AGAIN,
      });
    }
    closeDeleteModalHandler();
  };
  const closeDeleteModalHandler = () => {
    setIsDeleting(false);
    setSelectedTreatment(null);
    setIsDeleteModalOpen(false);
  };
  const treatmentColumn: ColumnDef<TreatmentLite>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: ({ column }) => {
          return (
            <button
              className="flex gap-1 items-center"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
            >
              Name
              {column.getIsSorted() === 'asc' ? (
                <div className="rotate-180">
                  <DirectedArrowIcon fill={THEME.NEUTRAL_500} />{' '}
                </div>
              ) : (
                <DirectedArrowIcon fill={THEME.NEUTRAL_500} />
              )}
            </button>
          );
        },
      },
      {
        accessorKey: 'description',
        header: 'Description',
      },
      {
        id: 'actions',
        cell: ({ row }) => {
          return (
            <div className="flex justify-end">
              <div className="flex gap-2">
                <Button
                  variant={'outlined'}
                  size={'xmall'}
                  className="md:px-2 font-semibold text-[14px] gap-1"
                  trailingIcon={<Edit2 size={15} />}
                  onClick={() => {
                    setIsEditTreatmentModalOpen(true);
                    setSelectedTreatment(row.original);
                  }}
                >
                  Edit
                </Button>
                <Button
                  variant={'outlined'}
                  className="md:px-2 font-semibold text-[14px] gap-1"
                  size={'xmall'}
                  trailingIcon={<DeleteIcon />}
                  onClick={() => {
                    setSelectedTreatment(row.original);
                    setIsDeleteModalOpen(true);
                  }}
                >
                  Delete
                </Button>
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div>
      {isAddTreatmentModalOpen && (
        <AddAndEditTreatmentModal
          open={isAddTreatmentModalOpen}
          onClose={() => setIsAddTreatmentModalOpen(false)}
          type="create"
        />
      )}
      {isEditTreatmentModalOpen && selectedTreatment && (
        <AddAndEditTreatmentModal
          open={isEditTreatmentModalOpen}
          onClose={() => {
            setIsEditTreatmentModalOpen(false);
            setSelectedTreatment(null);
          }}
          type="edit"
          treatment={selectedTreatment}
        />
      )}
      {isDeleteModalOpen && selectedTreatment && (
        <AlertDialog
          type="delete"
          actionButtonText={!isDeleting ? 'Delete' : 'Deleting'}
          alertTitle={`Deleting ${selectedTreatment.name}`}
          onClose={closeDeleteModalHandler}
          open={isDeleteModalOpen}
          onAction={deleteTreatmentHandler}
          isActionStarted={isDeleting}
        >
          Are you sure you want to delete {selectedTreatment.name} ?
        </AlertDialog>
      )}
      <div className="px-[12px]  py-[16px] w-full flex justify-between items-center ">
        <h2 className="font-semibold text-[16px] text-neutral-900">Treatment</h2>
        <div className="flex gap-3 items-center justify-center">
          {/* <Button variant={'link'} leadingIcon={<FilterIcon />} size={'xmall'}>
            Filter
          </Button> */}
          <Button
            variant={'primary'}
            size={'small'}
            onClick={() => setIsAddTreatmentModalOpen(true)}
            trailingIcon={<AddIcon stroke="currentColor" />}
          >
            Add treatment
          </Button>
        </div>
      </div>
      <Slider variant={'vertical'} className="max-h-[520px]">
        {' '}
        <DataTable
          columns={treatmentColumn}
          data={treatments}
          isLoading={isLoading}
        />
      </Slider>
    </div>
  );
};

export default Treatments;
