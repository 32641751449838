/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { useDebounce } from '../../hooks';
import { Input, Label } from '.';
import UserIcon from '../../assets/icons/PersonIcon';

interface DebouncedSearchInputProps {
  label: string;
  required?: boolean;
  onSearch: (searchTerm: string) => Promise<BaseEntityInformation[]>; //replace this any with an interface having fullName and publicId
  onSelect: (selectedOption: BaseEntityInformation) => void;
  hasError?: boolean;
  initialValue?: BaseEntityInformation;
}

const DebouncedSearchInput: React.FC<DebouncedSearchInputProps> = ({
  label,
  required = false,
  onSearch,
  onSelect,
  hasError,
  initialValue,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [options, setOptions] = useState<BaseEntityInformation[]>([]);
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState<BaseEntityInformation>();
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (initialValue) {
      setSearchTerm(initialValue.fullName || '');
      setSelectedOption(initialValue);
    }
  }, [initialValue]);
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const results = await onSearch(debouncedSearchTerm);
        setOptions(results);
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    if (debouncedSearchTerm.length > 0) {
      fetchOptions();
    } else {
      if (options.length === 0) {
        setShowOptions(false);
      } else {
        setShowOptions(true);
      }
    }
  }, [debouncedSearchTerm, onSearch]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setOptions([]);
    setSearchTerm(value);
    setShowOptions(true);
  };

  const handleOptionSelect = (selectedOption: BaseEntityInformation) => {
    setSearchTerm(selectedOption.fullName || '');
    onSelect(selectedOption);
    setSelectedOption(selectedOption);
    setShowOptions(false);
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setShowOptions(false);

        setSearchTerm(selectedOption?.fullName || '');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectedOption]);

  return (
    <div className="relative space-y-2">
      <Label
        className={`${required ? 'after:content-["*"] after:text-destructive-500' : ''}`}
      >
        {label}
      </Label>
      <div
        className="relative"
        id="debounce-search-container"
        ref={containerRef}
      >
        <Input
          required={required}
          placeholder={`Search ${label}`}
          value={searchTerm}
          onChange={handleInputChange}
          onFocus={() => setShowOptions(true)}
          hasError={hasError}
        />
        {showOptions && (
          <div className="absolute border-neutral-100 w-full max-h-[20vh] z-50 bg-white rounded-md shadow-lg overflow-y-auto ">
            {options.map(option => (
              <div
                key={option.publicId}
                className="flex items-center justify-between  w-full p-2 hover:bg-gray-100 cursor-pointer border-b"
              >
                <div
                  className="flex items-center"
                  onClick={() => handleOptionSelect(option)}
                >
                  <UserIcon />
                  <div className="ml-2">
                    <div className="text-md font-semibold">
                      {option.fullName}
                    </div>
                    <div className="text-[14px] text-neutral-500">
                      {option.publicId}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default DebouncedSearchInput;
