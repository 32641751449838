import { URL } from '../requestConstructor';

export const getUserDetails = async () => {
  try {
    const response = await URL.get('/user/get');
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return error;
  }
};
