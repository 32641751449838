import { RightArrow } from '../../../assets/icons';
import React, { useEffect, useState } from 'react';
import { Input, Label, Button } from '../../UI';
import {
  getUserDetails,
  loginUser,
  preLoginCheck,
} from '../../../services/api';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addUserCredentials } from '../../../redux/slice/userSlice';
import { clientDetails } from '../../../redux/slice/clientSlice';
import { ROUTES } from '../../../constants/NavigationConstants';
import {
  getClient,
  getClientIdByURL,
} from '../../../services/api/endPoints/client';
import { useDownloadFile } from '../../../hooks/useDownloadFile';

const SignInForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getFileSrc } = useDownloadFile();
  const [password, setPassword] = useState<string>('');
  const [userName, setUserName] = useState<string>();
  const [passwordError, setPasswordError] = useState<string | null>('');
  const [userNameError, setUserNameError] = useState<string | null>(null);
  const [clientId, setClientId] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isClientLoading, setIsClientLoading] = useState<boolean>(true);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (userName && password) {
      setIsLoading(true);
      const [response, error] = await preLoginCheck({
        username: userName,
        password,
        clientId,
      });
      if (response) {
        if (response.passwordReset) {
          navigate(ROUTES.ROUTE_TO_UPDATE_PASSWORD, {
            state: { username: userName, password, clientId },
            replace: true,
          });
        } else {
          if (response.twoFactorEnabled) {
            if (!response.passwordReset && response.twoFactorReset) {
              navigate(ROUTES.ROUTE_TO_SET_2FA, {
                state: { username: userName, password, clientId },
                replace: true,
              });
            } else {
              navigate(ROUTES.ROUTE_TO_CONFIRM_2FA, {
                state: { username: userName, password, clientId },
                replace: true,
              });
            }
          } else {
            await getToken(userName, password);
          }
        }
      } else {
        if (error.status === 404) {
          setUserNameError(error.data);
        } else if (error.status === 401) {
          setPasswordError(error.data);
        }
      }
      setIsLoading(false);
    }
  };

  const getToken = async (username: string, password: string) => {
    const [loginRes] = await loginUser({ username, password, clientId });
    if (loginRes) {
      const { data } = await getUserDetails();
      const [clientRes] = await getClient();
      const [logoRes] = await getFileSrc(clientRes.smallLogoUrl);
      clientRes.smallLogoUrlBlob = logoRes as unknown as Blob;
      dispatch(clientDetails(clientRes));

      const userRoles: string[] = [];
      for (const role of data.roles) {
        userRoles.push(role.name);
      }
      data.roles = userRoles;
      dispatch(addUserCredentials(data));
      navigate(ROUTES.ROUTE_TO_OVERVIEW, { replace: true });
    }
  };

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredValue = event.target.value;
    if (enteredValue.includes('@')) {
      setUserNameError('Username cannot be email address.');
    } else {
      setUserNameError(null);
    }
    setUserName(enteredValue);
  };

  const setPasswordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };
  useEffect(() => {
    let currentUrl = window.location.href.split('//')[1].split('/')[0];

    const getClientId = async () => {
      setIsClientLoading(true);
      if (currentUrl.includes('localhost') || currentUrl.includes('dev')) {
        currentUrl = 'dev.medinotes.swivo.io';
      }

      const [response, error] = await getClientIdByURL(currentUrl);
      if (response) {
        dispatch(clientDetails(response));
        setClientId(response.clientId);
      } else {
        window.alert(error.data);
      }
      setIsClientLoading(false);
    };
    getClientId();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col gap-3">
        <div className="w-full flex flex-col gap-1">
          <Label htmlFor="Username">Username</Label>
          <Input
            id="Username"
            type="text"
            name="userName"
            placeholder="e.g. johndoe"
            required={true}
            onChange={onChangeInput}
            inputSize="medium"
          />
        </div>
        {userNameError && (
          <p className="text-sm text-destructive-500">{userNameError}</p>
        )}
        <div className="w-full flex flex-col gap-1">
          <Label htmlFor="Password">Password</Label>
          <Input
            id="Password"
            type="password"
            placeholder="Enter your password"
            required={true}
            hasError={!!passwordError}
            onChange={setPasswordHandler}
            inputSize="medium"
          />

          <p className="text-sm text-destructive-500">{passwordError}</p>
        </div>
      </div>

      <p className="text-sm text-neutral-500 my-7">
        By proceeding, I acknowledge that I have read the{' '}
        <a
          href="https://www.swivo.io/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
          className="underline text-neutral-600"
        >
          Privacy Policy
        </a>{' '}
        and agree to Swivo’s{' '}
        <a
          href="https://www.swivo.io/terms-conditions"
          target="_blank"
          rel="noopener noreferrer"
          className="underline text-neutral-600"
        >
          Terms of Use
        </a>
        .
      </p>
      <Button
        style={{ width: '100%' }}
        variant="primary"
        type="submit"
        size="large"
        isLoading={isLoading}
        disabled={isLoading || isClientLoading}
        trailingIcon={<RightArrow />}
      >
        {isLoading ? 'Signing in' : 'Sign in'}
      </Button>
    </form>
  );
};

export default SignInForm;
