import React, { useState } from 'react';

import { uploadFile } from '../../../../../services/api';
import {
  notify,
  UploadProfilePictureModal,
} from '../../../../../components/common';
import { v4 as uuidv4 } from 'uuid';

const UploadProfilePictureStaffModal = ({
  open,
  onClose,
  profilePictureFileName,
  onChangeProfilePicture,
  navigateToTakePictureHandler,
}: {
  open: boolean;
  onClose: () => void;
  onChangeProfilePicture: (profilePictureUrl: string) => void;
  navigateToTakePictureHandler: () => void;
  profilePictureFileName?: string;
}) => {
  const [loading, isLoading] = useState<boolean>(false);
  const uploadFileHandler = async (file: File) => {
    isLoading(true);
    const profilePictureFileData = new FormData();
    profilePictureFileData.append('containerName', 'patient_profile_pictures');
    const fileName = profilePictureFileName ? profilePictureFileName : uuidv4();
    profilePictureFileData.append(
      'fileName',
      `${fileName}.${file.name.split('.').pop()}`
    );
    profilePictureFileData.append('file', file);
    const [res, error] = await uploadFile(profilePictureFileData);
    if (error) {
      notify.error({
        title: 'Unable to upload the image',
        message: error.data,
      });
      isLoading(false);
      return;
    }
    onChangeProfilePicture(res);
    isLoading(false);
    onClose();
  };

  return (
    <UploadProfilePictureModal
      open={open}
      onClose={onClose}
      onUpload={uploadFileHandler}
      navigateToTakePictureHandler={navigateToTakePictureHandler}
      loading={loading}
    />
  );
};

export default UploadProfilePictureStaffModal;
