/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ChangeEvent, useState } from 'react';
import { Button, Input, Label } from '../../../../UI';
import { LeftArrowIcon, RightArrow } from '../../../../../assets/icons';
import { DatePicker, notify } from '../../../../common';
import { useNavigate } from 'react-router-dom';
import TestChips from './TestChips';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import AutoComplete from '../../../../../components/UI/AutoComplete';
import LabRadioOptions from './LabRadioOptions';
import DebouncedSearchInput from '../../../../../components/UI/DebouncedSearchInput';
import { searchPatient } from '../../../../../services/api/endPoints/patients';
import { getFullName } from '../../../../../utils/Helper';
import { createTDLTest } from '../../../../../services/api/endPoints/labs';
import { THEME } from '../../../../../constants/ColorConstants';
import { ERROR_MESSAGE, ERROR_TITLE, SUCCESS_MESSAGE, SUCCESS_TITLE } from '../../../../../constants/NotificationConstants';

interface InputChangeEvent extends ChangeEvent<HTMLInputElement> {
  target: HTMLInputElement & {
    name: string;
    value: string;
  };
}

const LabTestForm: React.FC<{
  patient?: PatientInformation | PatientLite;
  onReset: () => void;
}> = ({ patient, onReset }) => {
  const [selectedPatient, setSelectedPatient] = useState<
    PatientInformation | PatientLite | null
  >(patient || null);
  const navigate = useNavigate();
  const staffs = useSelector<RootState, StaffInformation[]>(
    state => state.staffs.staffs
  );
  const user = useSelector<RootState, UserEntities>(state => state.user.user);
  const [selectedTests, setSelectedTests] = useState<TDLTest[]>([]);
  const [selectedStaff, setSelectedStaff] = useState<StaffInformation>(
    user.staff
  );
  const [selectedOptions, setSelectedOptions] = useState<{
    [key: string]: string | boolean | null;
  }>({});
  const [testInputs, setTestInputs] = useState<TestInputs>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleInputChange = (e: InputChangeEvent) => {
    const { value, name } = e.target;
    setTestInputs(prev => ({ ...prev, [name]: value }));
  };

  const handleBookTest = async () => {
    setIsLoading(true);
    const obj: TestBookingObj = {
      ...testInputs,
      accountTo: 'D',
      isFasting: !!selectedOptions.isFasting,
      isGrey: !!selectedOptions.isGrey,
      needsKit: !!selectedOptions.needsKit,
      needsVenous: !!selectedOptions.needsVenous,
      staffPublicId: selectedStaff.publicId,
      patientPublicId: selectedPatient?.publicId,
      gender: selectedPatient?.gender,
      tests: selectedTests.map(item => {
        delete item.label;
        return item;
      }),
    };

    const [response, error] = await createTDLTest(obj);
    setIsLoading(false);
    if (response) {
      notify.success({
        title: SUCCESS_TITLE.GENERIC_LAB_TEST_CREATE,
        message: `Lab test booked successfully for ${selectedPatient?.fullName}`,
      });
    } else {
      notify.error({
        title: ERROR_TITLE.GENERIC_LAB_TEST_CREATE,
        message: error?.data ? error.data : ERROR_MESSAGE.GENERIC_TRY_AGAIN
      });
    }
    setTimeout(() => {
      onReset();
    }, 1000);
  };

  const onSearch = async (search: string): Promise<PatientLite[]> => {
    try {
      const res = await searchPatient(search);
      const data: PatientLite[] = res.data.map((row: PatientLite) => ({
        ...row,
        fullName: getFullName(row),
        phoneNo: `${row.phoneNo ? row.phoneNo : ''}`,
      }));
      return data;
    } catch (err) {
      return [];
    }
  };

  return (
    <div className="custom-height flex flex-col">
      <div className="border-b border-neutral-100 p-3 relative flex-none">
        <h1 className="text-neutral-900 font-medium whitespace-nowrap text-center">
          Book a Test
        </h1>
        <Button
          variant={'outlined'}
          size={'xmall'}
          leadingIcon={<LeftArrowIcon />}
          className="absolute left-3 top-[12%]"
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </div>
      <div className="overflow-y-auto pt-8 flex-auto">
        {!patient && (
          <div className="flex  items-center pb-5 gap-6 flex-col row-span-10">
            <div className="w-[660px] space-y-6">
              <DebouncedSearchInput
                label={'Patient'}
                onSearch={onSearch}
                required={true}
                onSelect={option =>
                  setSelectedPatient(option as PatientInformation)
                }
              />
            </div>
          </div>
        )}
        <div className="flex flex-col justify-center items-center pb-10 gap-6">
          <div className="w-[660px] space-y-6">
            {/* Search Test Section*/}
            <div className="space-y-2">
              <Label>Add Tests</Label>
              <TestChips setSelectedTests={setSelectedTests} />
            </div>
            {/* Additional Details Sections */}
            <div className="space-y-4">
              <h3 className="text-neutral-900 font-medium whitespace-nowrap text-center text-[14px]">
                Additional Details
              </h3>
              {staffs && (
                <div className="space-y-2">
                  <Label>Attending consultant</Label>
                  <AutoComplete
                    setSelectedValue={values =>
                      setSelectedStaff(values[0] || {})
                    }
                    selectedValue={[selectedStaff]}
                    options={staffs}
                    labelField={'fullName'}
                    valueField={'publicId'}
                    placeholder={'Select Staff'}
                  />
                </div>
              )}
              <div className="space-y-2">
                <Label>Sample Date</Label>
                <DatePicker
                  variant={'dateTime'}
                  handleDateChange={function (date: Date | null): void {
                    console.log(date);
                  }}
                />
              </div>
              <div className="space-y-2">
                <Label>Clinical Details</Label>
                <Input
                  placeholder="Clinical details"
                  onChange={handleInputChange}
                  name="clinicalDetails"
                />
              </div>
              <div className="space-y-2">
                <Label>Insurance number</Label>
                <Input
                  placeholder="Insurance number"
                  onChange={handleInputChange}
                  name="insuranceNumber"
                />
              </div>
              <div className="space-y-2">
                <Label>Insurance Company</Label>
                <Input
                  placeholder="Insurance company"
                  onChange={handleInputChange}
                  name="insuranceCompany"
                />
              </div>
              <LabRadioOptions
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
              />
            </div>
          </div>
          <p className=" justify-start items-left">
            * Note - Any Kit Ordered will be sent directly to{' '}
            {selectedPatient ? getFullName(selectedPatient) : 'the patient.'}
          </p>
        </div>
      </div>
      <div className="flex justify-between items-center border-t border-neutral-100 py-4 px-3 row-span-1">
        <Button variant={'outlined'} size={'small'} onClick={onReset}>
          Reset
        </Button>
        <Button
          variant={'primary'}
          size={'small'}
          disabled={isLoading}
          onClick={handleBookTest}
          trailingIcon={<RightArrow stroke={THEME.SHADE_0} />}
        >
          Book Test
        </Button>
      </div>
    </div>
  );
};

export default LabTestForm;
