import React from 'react';

interface EggCollectionIconProps {
  stroke?: string;
}

const EggCollectionIcon: React.FC<EggCollectionIconProps> = ({ stroke = 'currentColor' }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 14C9.76142 14 12 11.3137 12 8C12 4.68629 9.76142 2 7 2C4.23858 2 2 4.68629 2 8C2 11.3137 4.23858 14 7 14Z"
      stroke={stroke}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 11.5C8.55228 11.5 9 10.9404 9 10.25C9 9.55964 8.55228 9 8 9C7.44772 9 7 9.55964 7 10.25C7 10.9404 7.44772 11.5 8 11.5Z"
      fill="black"
      stroke={stroke}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EggCollectionIcon;
