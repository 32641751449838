import React, { useEffect, useState } from 'react';
import { Button } from '../../../../UI';
import {
  DownloadIcon,
  LeftArrowIcon,
  PrinterIcon,
} from '../../../../../assets/icons';
// import reportImg from '../../../../../assets/images/ReportView.png';
import { useNavigate, useParams } from 'react-router-dom';
import { downloadPrescription } from '../../../../../services/api';
import LoaderIcon from '../../../../../assets/icons/Loader.svg';
import PdfViewer from '../../../../common/PdfViewer';
import { usePrintFile } from '../../../../../hooks/usePrintFile';
import { useDownloadFile } from '../../../../../hooks/useDownloadFile';
import { notify } from '../../../../common/Alert';
import {
  ERROR_MESSAGE,
  ERROR_TITLE,
} from '../../../../../constants/NotificationConstants';

const PrescriptionView = () => {
  const { prescriptionId } = useParams();
  const [loading, isLoading] = useState<boolean>(false);
  const [pdfUrl, setPdfUrl] = useState<string>();
  const printFile = usePrintFile();
  const { downloadFileByObjUrl } = useDownloadFile();
  const navigate = useNavigate();

  useEffect(() => {
    const downloadPrescriptionPdfBlob = async () => {
      try {
        if (!prescriptionId) return;
        isLoading(true);
        const [res, err] = await downloadPrescription(prescriptionId);
        if (err) {
          notify.error({
            message: ERROR_MESSAGE.GENERIC_TRY_AGAIN,
            title: ERROR_TITLE.GENERIC_PRESC,
          });
          // navigate(-1); TODO: going back twice - needs fix
        } else if (res) {
          const url = window.URL.createObjectURL(res);
          setPdfUrl(url);
        }
      } catch (error) {
        notify.error({
          message: ERROR_MESSAGE.GENERIC_TRY_AGAIN,
          title: ERROR_TITLE.GENERIC_PRESC,
        });
      }
      isLoading(false);
    };

    downloadPrescriptionPdfBlob();
    return () => {
      if (pdfUrl) window.URL.revokeObjectURL(pdfUrl);
    };
  }, []);

  const downloadPrescriptionToDevice = () => {
    if (pdfUrl) {
      downloadFileByObjUrl(pdfUrl, `${prescriptionId}.pdf`);
    }
  };

  return (
    <>
      <div className="border-b border-neutral-100 p-3 flex justify-between items-center">
        <Button
          variant={'outlined'}
          size={'small'}
          leadingIcon={<LeftArrowIcon />}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
        <h1 className="text-neutral-900 font-medium whitespace-nowrap">
          {prescriptionId}
        </h1>
        <div className="flex gap-3">
          <Button
            variant="outlined"
            size={'small'}
            leadingIcon={<DownloadIcon />}
            disabled={!pdfUrl || loading}
            onClick={downloadPrescriptionToDevice}
          >
            Download
          </Button>
          <Button
            variant="outlined"
            size={'small'}
            leadingIcon={<PrinterIcon />}
            disabled={!pdfUrl || loading}
            onClick={() => printFile(pdfUrl)}
          >
            Print
          </Button>
        </div>
      </div>
      <div className="flex item-center justify-center overflow-y-auto">
        {loading && (
          <div className="flex flex-col justify-center items-center h-[500px]">
            <img src={LoaderIcon} className="w-[40px] h-[40px] animate-spin" />
          </div>
        )}

        {!loading && pdfUrl && <PdfViewer pdfUrl={pdfUrl} />}
      </div>
    </>
  );
};

export default PrescriptionView;
