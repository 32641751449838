import React from 'react';
import { THEME } from '../../constants/ColorConstants';

interface ScanIconProps {
  stroke1?: string;
  stroke2?: string;
}

const ScanIcon: React.FC<ScanIconProps> = ({
  stroke1 = THEME.NEUTRAL_900,
  stroke2 = THEME.SHADE_100,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M2 4.66667V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2H4.66667"
      stroke={stroke1}
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.3333 2H12.6666C13.0202 2 13.3593 2.14048 13.6094 2.39052C13.8594 2.64057 13.9999 2.97971 13.9999 3.33333V4.66667"
      stroke={stroke2}
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.9999 11.334V12.6673C13.9999 13.0209 13.8594 13.3601 13.6094 13.6101C13.3593 13.8602 13.0202 14.0007 12.6666 14.0007H11.3333"
      stroke={stroke2}
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.66667 14.0007H3.33333C2.97971 14.0007 2.64057 13.8602 2.39052 13.6101C2.14048 13.3601 2 13.0209 2 12.6673V11.334"
      stroke={stroke2}
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default ScanIcon;
