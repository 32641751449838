export const Titles = [
  'Mr.',
  'Mrs.',
  'Miss',
  'Ms.',
  'Dr.',
  'Professor',
  'Mx.',
  'Ind.',
  'Misc.',
  'Ser.',
  'Pr.',
  'S.',
  'Th.',
  'Ze.',
];

export const StaffTypes = ['Superadmin', 'Admin', 'Secretary', 'Consultant'];
export const Genders = ['Male', 'Female', 'Other'];

export const NewPatientFormInputs = [
  {
    label: 'First Name',
    type: 'text',
    id: 'firstName',
    placeholder: 'Enter here',
    required: true,
  },
  {
    label: 'Last Name',
    type: 'text',
    id: 'lastName',
    placeholder: 'Enter here',
    required: true,
  },

  {
    label: 'Email',
    type: 'email',
    id: 'email',
    placeholder: 'Enter here',
    required: true,
  },
  {
    label: 'Phone Number',
    type: 'tel',
    id: 'phoneNo',
    placeholder: 'Enter here',
    required: false,
  },
  {
    label: '',
    type: 'date',
    id: 'dateOfBirth',
    placeholder: '',
    required: true,
  },
];

export const PatientEditFormInputs = [
  {
    label: 'First Name',
    type: 'text',
    id: 'firstName',
    placeholder: 'Enter here',
    required: true,
  },
  {
    label: 'Last Name',
    type: 'text',
    id: 'lastName',
    placeholder: 'Enter here',
    required: true,
  },
  {
    label: 'Email',
    type: 'email',
    id: 'email',
    placeholder: 'Enter here',
    required: true,
  },
  {
    label: 'Phone Number',
    type: 'tel',
    id: 'phoneNo',
    placeholder: 'Enter here',
    required: false,
  },
  {
    label: '',
    type: 'date',
    id: 'dateOfBirth',
    placeholder: '',
    required: false,
  },
];

export const AddressForm = [
  {
    label: 'Street1',
    type: 'text',
    id: 'street1',
    placeholder: 'Enter street1 address',
    required: true,
  },
  {
    label: 'Street2',
    type: 'text',
    id: 'street2',
    placeholder: 'Enter street2 address',
    required: false,
  },
  {
    label: 'City',
    type: 'text',
    id: 'city',
    placeholder: 'Enter city',
    required: true,
  },
  {
    label: 'State/County',
    type: 'text',
    id: 'state',
    placeholder: 'Enter state/county',
    required: false,
  },

  {
    label: 'Country',
    type: 'text',
    id: 'country',
    placeholder: 'Enter country',
    required: false,
  },
  {
    label: 'Postal Code',
    type: 'text',
    id: 'postalCode',
    placeholder: 'Enter postal/zip code',
    required: true,
  },
];

export const ReferralForm = [
  {
    label: 'Referral Source',
    type: 'text',
    id: 'referralSource',
    placeholder: 'Enter here',
    required: false,
    options: ['Self', 'GP', 'NHS Patient', 'Consultant', 'MUMS'],
  },
];

export const FundingSourceForm = [
  {
    label: 'Funding Source',
    type: 'text',
    id: 'fundingSource',
    placeholder: 'Enter here',
    required: false,
    options: ['Self', 'NHS', 'insurance'],
  },
];

export const NextOfKinForm = [
  {
    label: 'Name',
    type: 'text',
    id: 'name',
    placeholder: 'Enter here',
    required: false,
    size: 'small',
  },
  {
    label: 'Relation to Patient',
    type: 'text',
    id: 'relation',
    placeholder: 'Enter here',
    required: false,
    size: 'small',
  },

  {
    label: 'Contact',
    type: 'text',
    id: 'contact',
    placeholder: 'Enter here',
    required: false,
    size: 'small',
  },
];

export const GPDetailsForm = [
  {
    label: 'Name',
    type: 'text',
    id: 'name',
    placeholder: 'Enter here',
    required: true,
    size: 'small',
  },

  {
    label: 'Contact',
    type: 'text',
    id: 'contact',
    placeholder: 'Enter here',
    required: false,
    size: 'small',
  },
  {
    label: 'Street',
    type: 'text',
    id: 'address.street1',
    placeholder: 'Enter here',
    required: false,
    size: 'small',
  },
  {
    label: 'City',
    type: 'text',
    id: 'address.city',
    placeholder: 'Enter here',
    required: false,
    size: 'small',
  },
  {
    label: 'Postal Code',
    type: 'text',
    id: 'address.postalCode',
    placeholder: 'Enter here',
    required: false,
    size: 'small',
  },
];

export const HFEAForm = [
  {
    label: 'Forename',
    type: 'text',
    id: 'forename',
    placeholder: 'Enter here',
    required: false,
  },
  {
    label: 'Surname',
    type: 'text',
    id: 'surname',
    placeholder: 'Enter here',
    required: false,
  },
  {
    label: 'Birth Forename',
    type: 'text',
    id: 'birthForename',
    placeholder: 'Enter here',
    required: false,
  },
  {
    label: 'Birth Surname',
    type: 'text',
    id: 'birthSurname',
    placeholder: 'Enter here',
    required: false,
  },
  {
    label: '',
    type: 'date',
    id: 'dateOfBirth',
    placeholder: 'Enter here',
    required: false,
  },
  {
    label: 'Gender',
    type: 'text',
    id: 'gender',
    placeholder: 'Enter here',
    required: false,
  },
  {
    label: 'Is Patient disabled',
    type: 'text',
    id: 'isPatientDisabled',
    placeholder: 'Enter here',
    required: false,
  },
  {
    label: 'Ethnicity',
    type: 'text',
    id: 'ethnicity',
    placeholder: 'Enter here',
    required: false,
  },
  {
    label: 'Birth town/City',
    type: 'text',
    id: 'birthTownCity',
    placeholder: 'Enter here',
    required: false,
  },
  {
    label: 'Birth Country',
    type: 'text',
    id: 'birthCountry',
    placeholder: 'Enter here',
    required: false,
  },
  {
    label: 'ID - Passport/NHS Number/ID card',
    type: 'text',
    id: 'idPassportNHSNumberIDCard',
    placeholder: 'Enter here',
    required: false,
  },
  {
    label: 'Travelled from overseas for treatment',
    type: 'text',
    id: 'travelledFromOverseasForTreatment',
    placeholder: 'Enter here',
    required: false,
  },
  {
    label: 'Previous Pregnancies Natural/IVF including miscarriages', //same
    type: 'text',
    id: 'previousPregnanciesNaturalIVFIncludingMiscarriages',
    placeholder: 'Enter here',
    required: false,
  },
  {
    label: 'Length of Infertility - years', //same
    type: 'text',
    id: 'lengthOfInfertilityYears',
    placeholder: 'Enter here',
    required: false,
  },
  {
    label: 'Weight',
    type: 'text',
    id: 'weight',
    placeholder: 'Enter here',
    required: false,
  },
  {
    label: 'Height',
    type: 'text',
    id: 'height',
    placeholder: 'Enter here',
    required: false,
  },
];

export const HFEAFormPartner = [
  {
    label: 'Partner Forename',
    type: 'text',
    id: 'partnerForename',
    placeholder: 'Enter here',
    required: false,
  },
  {
    label: 'Partner Surname',
    type: 'text',
    id: 'partnerSurname',
    placeholder: 'Enter here',
    required: false,
  },
  {
    label: 'Partner Birth Forename',
    type: 'text',
    id: 'partnerBirthForename',
    placeholder: 'Enter here',
    required: false,
  },
  {
    label: 'Partner Birth Surname',
    type: 'text',
    id: 'partnerBirthSurname',
    placeholder: 'Enter here',
    required: false,
  },
  {
    label: '',
    type: 'date',
    id: 'partnerDateOfBirth',
    placeholder: 'Enter here',
    required: false,
  },
  {
    label: 'Partner Gender',
    type: 'text',
    id: 'partnerGender',
    placeholder: 'Enter here',
    required: false,
  },
  {
    label: 'Is Partner disabled',
    type: 'text',
    id: 'isPartnerDisabled',
    placeholder: 'Enter here',
    required: false,
  },
  {
    label: 'Partner Ethnicity',
    type: 'text',
    id: 'partnerEthnicity',
    placeholder: 'Enter here',
    required: false,
  },
  {
    label: 'Partner Birth town/City',
    type: 'text',
    id: 'partnerBirthTownCity',
    placeholder: 'Enter here',
    required: false,
  },
  {
    label: 'Partner Birth Country',
    type: 'text',
    id: 'partnerBirthCountry',
    placeholder: 'Enter here',
    required: false,
  },
  {
    label: 'Partner ID - Passport/NHS Number/ID card',
    type: 'text',
    id: 'partnerIdPassportNHSNumberIDCard',
    placeholder: 'Enter here',
    required: false,
  },
  {
    label: 'Partner Travelled from overseas for treatment',
    type: 'text',
    id: 'partnerTravelledFromOverseasForTreatment',
    placeholder: 'Enter here',
    required: false,
  },
  {
    label: 'Partner Previous Pregnancies Natural/IVF including miscarriages',
    type: 'text',
    id: 'partnerPreviousPregnanciesNaturalIVFIncludingMiscarriages',
    placeholder: 'Enter here',
    required: false,
  },
  {
    label: 'Partner Length of Infertility - years',
    type: 'text',
    id: 'partnerLengthOfInfertilityYears',
    placeholder: 'Enter here',
    required: false,
  },
  {
    label: 'Partner Weight',
    type: 'text',
    id: 'partnerWeight',
    placeholder: 'Enter here',
    required: false,
  },
  {
    label: 'Partner Height',
    type: 'text',
    id: 'partnerHeight',
    placeholder: 'Enter here',
    required: false,
  },
];

export const Address = [
  {
    label: 'Street Address',
    type: 'text',
    id: 'address.street1',
    placeholder: 'Enter here',
    required: false,
  },
  {
    label: 'City',
    type: 'text',
    id: 'address.city',
    placeholder: 'Enter here',
    required: false,
  },
  {
    label: 'State',
    type: 'text',
    id: 'address.state',
    placeholder: 'Enter here',
    required: false,
  },

  {
    label: 'Country',
    type: 'text',
    id: 'address.country',
    placeholder: 'Enter here',
    required: false,
  },
  {
    label: 'Postal Code',
    type: 'text',
    id: 'address.postalCode',
    placeholder: 'Enter here',
    required: false,
  },
];
