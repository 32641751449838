import React from 'react';
import {
  Button,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../UI';
import { CrossIcon, DownArrowIcon } from '../../assets/icons';

const MultipleSelect = ({
  options,
  selectedValues,
  setSelectedValues,
  disabled,
}: MultipleSelectPropsType) => {
  const onOptionSelect = (value: string) => {
    if (value === 'Select') return;
    const newSelectedValues = selectedValues.includes(value)
      ? selectedValues.filter(selectValue => selectValue !== value)
      : [...selectedValues, value];
    setSelectedValues(newSelectedValues);
  };

  return (
    <>
      <Select
        required
        defaultValue="Select"
        name="multiselect"
        onValueChange={onOptionSelect}
      >
        <SelectTrigger
          className="w-full"
          selectIcon={<DownArrowIcon />}
          disabled={disabled}
        >
          <SelectValue placeholder="Select" />
        </SelectTrigger>
        <SelectContent className="bg-shades-0 z-[999999]">
          <SelectItem value={'Select'}>Select</SelectItem>
          {Object.keys(options).map(key => (
            <SelectItem value={key} key={key}>
              {options[key]}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      {selectedValues.length > 0 && (
        <div className="border border-neutral-100 rounded-lg border-collapse p-2 space-y-2">
          {selectedValues.map(value => (
            <div key={value} className="w-full flex justify-between ">
              <p className="text-[14px]">{options[value]}</p>
              <Button
                onClick={() => onOptionSelect(value)}
                variant={'link'}
                size={'xmall'}
              >
                <CrossIcon />
              </Button>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default MultipleSelect;
