import React, { useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
// import { Checkbox } from '../../../../UI';
import {
  DeleteIcon,
  DirectedArrowIcon,
  DotMenu,
  FileIcon,
  FolderIcon,
} from '../../../../../assets/icons';
import ActionDropdown from '../../../../common/ActionDropdown';
import { format } from 'date-fns';
import SendDocumentModal from './SendDocumentModal';
import { DataTable, AlertDialog } from '../../../../common';
import { Slider } from '../../../../UI';
import { useNavigate } from 'react-router-dom';
import { THEME } from '../../../../../constants/ColorConstants';

const FilesTable = ({
  setActiveFolder,
  files,
  onDelete,
  loading,
}: {
  setActiveFolder: (activeFolder: DocumentUIType) => void;
  files: DocumentUIType[];
  onDelete: (file?: DocumentUIType) => Promise<void>;
  loading: boolean;
}) => {
  const [showSendDocumentModal, setShowDocumentModal] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [selectedDeleteFile, setSelectedDeleteFile] =
    useState<DocumentUIType | null>(null);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const fileColumnDef: ColumnDef<DocumentUIType>[] = [
    // {
    //   id: 'select',
    //   header: ({ table }) => (
    //     <Checkbox
    //       checked={
    //         table.getIsAllPageRowsSelected() ||
    //         (table.getIsSomePageRowsSelected() && 'indeterminate')
    //       }
    //       onCheckedChange={value => table.toggleAllPageRowsSelected(!!value)}
    //       aria-label="Select all"
    //     />
    //   ),
    //   cell: ({ row }) => (
    //     <Checkbox
    //       checked={row.getIsSelected()}
    //       onCheckedChange={value => row.toggleSelected(!!value)}
    //       aria-label="Select row"
    //     />
    //   ),
    //   enableSorting: false,
    //   enableHiding: false,
    // },

    {
      accessorKey: 'name',
      header: ({ column }) => {
        return (
          <button
            className="flex gap-1 items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Name
            {column.getIsSorted() === 'asc' ? (
              <div className="rotate-180">
                <DirectedArrowIcon fill={THEME.NEUTRAL_500}/>{' '}
              </div>
            ) : (
              <DirectedArrowIcon fill={THEME.NEUTRAL_500} />
            )}
          </button>
        );
      },
      size: 80,
      cell: ({ row }) => {
        return (
          <button
            className="flex gap-1 items-center"
            onClick={() => {
              if (row.original.type === 'Folder') setActiveFolder(row.original);
              else if (row.original.type === 'Document') {
                navigate(`./view/${row.original.id}`);
              }
            }}
          >
            {row.original.type === 'Document' ? <FileIcon /> : <FolderIcon />}{' '}
            {row.original.name}
          </button>
          // <div className="flex items-center gap-2">
          //   {row.original.type === 'Document' ? <FileIcon /> : <FolderIcon />}{' '}
          //   <span>{row.original.name}</span>
          // </div>
        );
      },
    },

    {
      accessorKey: 'createdAt',
      header: 'Created At',
      size: 30,
      cell: ({ row }) => {
        return (
          <span>
            {row.original.createdAt &&
              format(row.original.createdAt, 'dd MMM yyyy h:mm a').toString()}
          </span>
        );
      },
    },

    {
      accessorKey: 'modifiedAt',
      header: 'Modified At',
      size: 30,
      cell: ({ row }) => {
        return (
          <span>
            {row.original.modifiedAt &&
              format(row.original.modifiedAt, 'dd MMM yyyy h:mm a').toString()}
          </span>
        );
      },
    },

    {
      id: 'action',
      cell: ({ row }) => {
        const dropDownMenuSection2: dropDownMenuItem[] = [
          {
            label: <span className={`text-[${THEME.DESTRUCTIVE_500}]`}>Delete</span>,
            Icon: <DeleteIcon stroke={THEME.DESTRUCTIVE_500} />,
            action: () => {
              setSelectedDeleteFile(row.original);
              setIsDeleteModalOpen(true);
            },
          },
        ];
        return (
          <div className="flex justify-end">
            <ActionDropdown
              multipleDropDownSections={[dropDownMenuSection2]}
              side="bottom"
              align="end"
              sideOffset={3}
              alignOffset={18}
            >
              <button className="w-8 h-8 p-0 outline-none border-none">
                <span className="sr-only">Open menu</span>
                <DotMenu />
              </button>
            </ActionDropdown>
          </div>
        );
      },
    },
  ];
  const deleteHandler = async () => {
    if (!selectedDeleteFile) return;
    setIsDeleting(true);
    await onDelete(selectedDeleteFile);
    closeDeleteModalHandler();
  };
  const closeDeleteModalHandler = () => {
    setSelectedDeleteFile(null);
    setIsDeleting(false);
    setIsDeleteModalOpen(false);
  };
  return (
    <div>
      <SendDocumentModal
        open={showSendDocumentModal}
        onClose={() => setShowDocumentModal(false)}
      />
      {isDeleteModalOpen && selectedDeleteFile && (
        <AlertDialog
          type="delete"
          open={isDeleteModalOpen}
          onClose={closeDeleteModalHandler}
          onAction={deleteHandler}
          alertTitle={`Deleting ${selectedDeleteFile.name}`}
          isActionStarted={isDeleting}
          actionButtonText={isDeleting ? 'Deleting' : 'Delete'}
        >
          Are you sure you want to delete {selectedDeleteFile.name} ?
        </AlertDialog>
      )}
      <Slider className="h-[500px]" variant={'vertical'}>
        <DataTable
          tableHeadRowClass="[&>*:nth-child(3)]:text-right [&>*:nth-child(4)]:text-right"
          tableBodyRowClass="[&>*:nth-child(3)]:text-right [&>*:nth-child(4)]:text-right"
          data={files}
          columns={fileColumnDef}
          isLoading={loading}
        />
      </Slider>
    </div>
  );
};

export default FilesTable;
