import React, { useEffect, useRef, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Slider,
} from '../UI';
import { Button } from '../UI';
import { CrossIcon, Edit2Icon, RightArrow } from '../../assets/icons';
import Cropper, { ReactCropperElement } from 'react-cropper';
import '../sections/Scanner/constants/cropper.css';
import { base64ToFile } from '../../utils/files.utl';

const UploadProfilePicture = ({
  onUpload,
  open,
  onClose,
  loading,
  navigateToTakePictureHandler,
}: {
  onUpload: (file: File) => void;
  open: boolean;
  onClose: () => void;
  loading?: boolean;
  navigateToTakePictureHandler: () => void;
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [originalImageUrl, setOriginalImageUrl] = useState<string>();
  const [imageUrl, setImageUrl] = useState<string>();
  const cropperRef = useRef<ReactCropperElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [adjustedDimensions, setAdjustedDimensions] = useState({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    const setResizedImage = () => {
      if (!imageUrl) return;
      const adjustedDimensions = getAdjustedDimensions(
        imageDimensions.width,
        imageDimensions.height
      );
      setAdjustedDimensions({
        width: adjustedDimensions.width,
        height: adjustedDimensions.height,
      });
    };
    window.addEventListener('resize', setResizedImage);
    return () => {
      window.removeEventListener('resize', setResizedImage);
    };
  }, []);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.onload = () => {
          const adjustedDimensions = getAdjustedDimensions(
            img.width,
            img.height
          );
          setImageDimensions({ width: img.width, height: img.height });
          setAdjustedDimensions({
            width: adjustedDimensions.width,
            height: adjustedDimensions.height,
          });
          setOriginalImageUrl(reader.result as string);
        };
        img.src = reader.result as string;
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      fileInputRef.current.click();
    }
  };

  const onCropHandler = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      setImageUrl(cropper.getCroppedCanvas().toDataURL());
    }
  };
  const cropOrUploadHandler = () => {
    if (!imageUrl) {
      onCropHandler();
    } else {
      if (!imageUrl) return;
      const file = base64ToFile(imageUrl, 'webcam-image.jpeg');
      onUpload(file);
    }
  };

  const editOrCloseHandler = () => {
    if (imageUrl) {
      setImageUrl(undefined);
    } else {
      onClose();
    }
  };
  const getAdjustedDimensions = (imgW: number, imgH: number) => {
    const containerWidth = modalRef?.current?.clientWidth || 440;
    const containerHeight = modalRef.current?.clientHeight || 440;
    const adjustedWidth = imgW > containerWidth ? containerWidth : imgW;
    const adjustedHeight = imgH > containerHeight ? containerHeight - 57 : imgH;
    return { width: adjustedWidth, height: adjustedHeight };
  };
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="p-0 w-[60%] md:max-w-[50%] md:min-w-[440px] flex flex-col gap-0 z-[9999999] h-[60%]">
        <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
          <h1 className="text-[14px] text-neutral-900 font-medium">
            Upload picture
          </h1>
          <Button variant={'link'} size={'xmall'} onClick={onClose}>
            <CrossIcon />
          </Button>
        </DialogHeader>
        <Slider className="flex-1" variant={'vertical'}>
          <input
            type="file"
            hidden
            ref={fileInputRef}
            onChange={handleFileChange}
            accept="image/*"
          />
          <div className="w-full h-full flex flex-col" ref={modalRef}>
            {!originalImageUrl ? (
              <div className="flex w-full h-full justify-center items-center">
                <p className="text-[14px] text-neutral-500 font-medium">
                  <span
                    className="text-primary-500 cursor-pointer"
                    onClick={handleUploadClick}
                  >
                    Click here to upload an image{' '}
                  </span>{' '}
                  or{' '}
                  <span
                    onClick={navigateToTakePictureHandler}
                    className="inline text-primary-500 cursor-pointer"
                  >
                    click a picture
                  </span>
                </p>
              </div>
            ) : (
              <div className="flex w-full flex-1 justify-center items-center">
                {!imageUrl ? (
                  <div
                    style={{
                      width: `${adjustedDimensions.width}px`,
                      height: `${adjustedDimensions.height}px`,
                    }}
                  >
                    <Cropper
                      src={originalImageUrl}
                      initialAspectRatio={1 / 1}
                      guides={false}
                      ref={cropperRef}
                      dragMode={'none'}
                      zoomable={false}
                      style={{
                        width: `${adjustedDimensions.width}px`,
                        height: `${adjustedDimensions.height}px`,
                      }}
                      aspectRatio={1}
                      responsive={true}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      width: `${adjustedDimensions.width}px`,
                      height: `${adjustedDimensions.height}px`,
                    }}
                  >
                    <img
                      src={imageUrl}
                      className="object-contain"
                      alt="staff-profile-picture"
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </Slider>
        {originalImageUrl && (
          <DialogFooter className="flex gap-3 w-full ">
            <Button
              variant={'link'}
              size={'medium'}
              className="w-full"
              onClick={editOrCloseHandler}
              leadingIcon={imageUrl && <Edit2Icon />}
            >
              {!imageUrl ? 'Close' : 'Edit'}
            </Button>
            <Button
              size={'medium'}
              onClick={cropOrUploadHandler}
              className="w-full"
              variant={'primary'}
              isLoading={loading}
              disabled={loading}
              trailingIcon={<RightArrow />}
            >
              {!imageUrl ? 'Continue' : 'Upload'}
            </Button>
          </DialogFooter>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UploadProfilePicture;
