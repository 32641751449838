/* eslint-disable indent */
import jsPDF from 'jspdf';
import { addFooter, centerPosition } from './pdf.util';
import tdlLogo from '../../assets/logo/TDLEVP_Secondary_FulCol_RGB-svg.jpg';
import {
  ADDRESS,
  ALIGN_CENTER,
  ALIGN_RIGHT,
  ALLERGIES,
  BLACK_COLOR,
  DATE_OF_BIRTH,
  HIGH_REPORT_FLAG_COLOR,
  NAME,
  PATIENT_ID,
  REPORT,
} from '../../constants/jspdf.constants';
import { getAppointmentReadableDateTime, getReadableDate } from '../date.utl';

let globalPosition = 10;
const marginLeft = 15; //
const spaceBetween = 6;
const textFontPoint = 11;
const reportTitlePoint = 15; //
const commonSpaceBetween = 10;

export const createTestResult = (
  result: TDLRequestedTestResponse,
  client: ClientEntities
) => {
  let doc = new jsPDF({ compress: true }).setProperties({
    title: client.name || '',
  });
  doc = addHeader(doc, result.staff, client);
  doc = addFooter(doc);
  addPatientInfo(doc, result);
  addTitle(doc);
  addResult(doc, result.testResult.Tests, result.staff, client);
  addSignature(doc, result.staff, client);

  doc.save(`${result.publicId}.pdf`);
  globalPosition = 10;
};

const addHeader = (
  doc: any,
  staff: StaffInformation,
  client: ClientEntities
) => {
  doc.addImage(client.smallLogoUrlBlob, 'PNG', 5, 0, 70, 35);
  doc
    .setFontSize(10)
    .text(
      `${staff.title} ${staff.firstName} ${staff.lastName}`,
      195,
      14,
      null,
      null,
      ALIGN_RIGHT
    )
    .text(
      'Consultant (Fertility & Gynecology)',
      195,
      20,
      null,
      null,
      ALIGN_RIGHT
    )
    .text(
      'Priory Hospital, Birmingham, B5 7UG',
      195,
      26,
      null,
      null,
      ALIGN_RIGHT
    );
  generateHr(doc, 28);
  return doc;
};

const addPatientInfo = (doc: any, result: TDLRequestedTestResponse) => {
  getGlobalPosition(25);

  doc
    .setFontSize(textFontPoint)
    .text(PATIENT_ID + result.patient.publicId, marginLeft, globalPosition)
    .text('Reference Id: ' + result.publicId, marginLeft + 100, globalPosition)

    .text(
      `${NAME} ${result.patient.title ?? ''} ${
        result.patient.firstName ?? ''
      } ${result.patient.lastName ?? ''}`,
      marginLeft,
      getGlobalPosition(spaceBetween)
    )
    .text('Sex: ' + result.testResult.Sex, marginLeft + 100, globalPosition)

    .text(
      ADDRESS +
        `${result.patient.address?.street1}, ${result.patient.address.city}, ${result.patient.address.postalCode} `,
      marginLeft,
      getGlobalPosition(spaceBetween)
    )
    .text(
      DATE_OF_BIRTH + getReadableDate(result.patient.dateOfBirth),

      marginLeft + 100,
      globalPosition
    )

    .text(
      'Consultant Name: ' + (result.staff?.signature || ''),
      marginLeft,
      getGlobalPosition(spaceBetween)
    )
    .text(ALLERGIES, marginLeft + 100, globalPosition)

    .text(
      'Sample Date: ' +
        (result.testResult.SampleDate
          ? getAppointmentReadableDateTime(
              new Date(result.testResult.SampleDate)
            )
          : ''),
      marginLeft,
      getGlobalPosition(spaceBetween)
    )
    .text(
      'Report Date: ' +
        (result.testResult.ReportedDate
          ? getAppointmentReadableDateTime(
              new Date(result.testResult?.ReportedDate)
            )
          : ''),
      marginLeft + 100,
      globalPosition
    );

  generateHr(doc, getGlobalPosition(spaceBetween));
};

const addTitle = (doc: any) => {
  doc

    .setFontSize(reportTitlePoint)
    .text(
      REPORT,
      centerPosition,
      getGlobalPosition(10),
      null,
      null,
      ALIGN_CENTER
    );

  return getGlobalPosition(0);
};

const addResult = (
  doc: jsPDF,
  testResults: TestResult[],
  staff: StaffInformation,
  client: ClientEntities
) => {
  doc.setFontSize(textFontPoint);
  doc.text('Test Name', marginLeft + 5, getGlobalPosition(commonSpaceBetween));
  doc.text('Result', marginLeft + 60, globalPosition);
  doc.text('UOM', marginLeft + 125, globalPosition);
  doc.text('Range', marginLeft + 160, globalPosition);
  doc.text('Flag', marginLeft + 220, globalPosition);
  let count = 0;
  const maxPageHeight = 200;
  if (getGlobalPosition(0) + commonSpaceBetween > maxPageHeight) {
    doc.addPage();
    doc = addHeader(doc, staff, client);
    doc = addFooter(doc);
    globalPosition = 35;
    count = 1;
  }

  testResults.forEach(
    (testResult: { TestName: string; ResultLines: any[] }) => {
      let yOffset = getGlobalPosition(0);
      if (count === 0) {
        count++;
      }
      if (yOffset + commonSpaceBetween > maxPageHeight) {
        doc.addPage();
        doc = addHeader(doc, staff, client);
        doc = addFooter(doc);
        globalPosition = 25;
        count = 1;
        yOffset = getGlobalPosition(0);
      }
      doc.text(
        testResult.TestName,
        marginLeft,
        getGlobalPosition(commonSpaceBetween)
      );
      generateHr(doc, getGlobalPosition(1));
      testResult.ResultLines.forEach(
        (line: {
          TestCode: string;
          TestName: string | string[];
          Result: string | string[];
          UOM: string | string[];
          RangeText: string | string[];
          Flag: string | string[];
        }) => {
          const rowOffset = getGlobalPosition(5);
          if (line.Flag === 'High') {
            addFlagColor(doc, line, rowOffset);
          } else {
            doc.text(line.TestName, marginLeft + 5, rowOffset, {
              maxWidth: 53,
            });
            doc.text(line.Result, marginLeft + 60, rowOffset, { maxWidth: 63 });
            getGlobalPosition(
              getTextLength(line.Result.toString(), line.TestCode) + 0
            );
            doc.text(line.UOM, marginLeft + 125, rowOffset);
            doc.text(line.RangeText, marginLeft + 160, rowOffset);
            doc.text(line.Flag, marginLeft + 220, rowOffset);
          }
        }
      );
    }
  );
};

const addSignature = (
  doc: any,
  staff: StaffInformation,
  client: ClientEntities
) => {
  addPage(doc, staff, client);
  generateHr(doc, 270);
  doc.addImage(tdlLogo, 'PNG', 68, 271, 5, 5);
  doc.text('Above tests are carried out by ', marginLeft, 275);
  doc.text(
    ' The Doctors Laboratory and authorised by Clinical Pathology TDL.',
    73,
    275
  );
};

const addFlagColor = (doc: jsPDF, line: any, rowOffset: number) => {
  doc.setTextColor(HIGH_REPORT_FLAG_COLOR);
  doc.text(line.TestName, marginLeft + 5, rowOffset, { maxWidth: 53 });
  doc.text(line.Result, marginLeft + 60, rowOffset, { maxWidth: 63 });
  getGlobalPosition(getTextLength(line.Result.toString(), line.TestCode) + 0);
  doc.text(line.UOM, marginLeft + 125, rowOffset);
  doc.text(line.RangeText, marginLeft + 160, rowOffset);
  doc.text(line.Flag, marginLeft + 220, rowOffset);
  doc.setTextColor(BLACK_COLOR);
};
const addPage = (
  doc: jsPDF,
  staff: StaffInformation,
  client: ClientEntities
) => {
  if (getGlobalPosition(0) + commonSpaceBetween > 200) {
    doc.addPage();
    doc = addHeader(doc, staff, client);
    doc = addFooter(doc);
    globalPosition = 35;
  }
};
export const getTextLength = (text: string, testCode: string) => {
  let wordsPerLine;
  switch (testCode) {
    case 'QHCG':
      wordsPerLine = 3;
      break;
    case 'VIDC':
      wordsPerLine = 2;
      break;
    case 'HEPB':
      wordsPerLine = 2;
      break;
    default:
      wordsPerLine = 5;
      break;
  }
  if (text) {
    const arr = text.split(' ');

    const h = arr.length / wordsPerLine;
    return h * 3;
  }
  return 0;
};

const generateHr = (doc: any, y: number) => {
  doc.line(marginLeft, y, 195, y, 'S');
};

const getGlobalPosition = (y: number) => {
  return (globalPosition += y);
};
