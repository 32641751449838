import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  Button,
  DialogFooter,
  Label,
  Input,
  Slider,
  Textarea,
} from '../../../../UI';
import { CrossIcon } from '../../../../../assets/icons';
import { createTreatment, updateTreatment } from '../../../../../services/api';
import { useDispatch } from 'react-redux';
import {
  addTreatment,
  updateTreatment as updateTreatmentRedux,
} from '../../../../../redux/slice/treatmentsSlice';
import { notify } from '../../../../../components/common';
import {
  ERROR_MESSAGE,
  ERROR_TITLE,
  SUCCESS_TITLE,
} from '../../../../../constants/NotificationConstants';
const AddAndEditResourceModal = ({
  open,
  onClose,
  ...props
}: AddAndEditTreatmentModalProps) => {
  const [data, setData] = useState<TreatmentLiteFormInput>({
    name: '',
    description: '',
  });
  const [formErrors, setFormErrors] = useState<
    Record<keyof TreatmentLiteFormInput, string | null>
  >({
    name: null,
    description: null,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.type === 'edit') {
      const initialData: TreatmentLiteFormInput = {
        name: props.treatment.name,
        description: props.treatment.description
          ? props.treatment.description
          : '',
      };
      setData(initialData);
    }
  }, []);

  const isValidInputs = () => {
    const error = { ...formErrors };
    let isValid = true;
    if (!data.name || data.name == '') {
      error.name = 'Treatment name is required';
      isValid = false;
    }
    setFormErrors(error);
    return isValid;
  };

  const submitHandler = async () => {
    if (!isValidInputs()) return;
    setIsLoading(true);
    if (props.type === 'create') {
      const [res, error] = await createTreatment(data);
      if (res) {
        dispatch(addTreatment(res));
        notify.success({
          title: SUCCESS_TITLE.GENERIC_TREATMENT_ADD,
          message: `'${res.name}' added successfully`,
        });
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_TREATMENT_ADD,
          message: error?.data ? error.data : ERROR_MESSAGE.GENERIC_TRY_AGAIN,
        });
        setIsLoading(false);
        return;
      }
    } else if (props.type === 'edit') {
      const [res, error] = await updateTreatment({
        ...data,
        publicId: props.treatment.publicId,
      });
      if (res) {
        dispatch(updateTreatmentRedux(res));
        notify.success({
          title: SUCCESS_TITLE.GENERIC_TREATMENT_UPDATE,
          message: `'${props.treatment.name}' updated successfully`,
        });
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_TREATMENT_UPDATE,
          message: error?.data ? error.data : ERROR_MESSAGE.GENERIC_TRY_AGAIN,
        });
        setIsLoading(false);
        return;
      }
    }
    setIsLoading(false);
    onClose();
  };
  const inputHandler = (name: keyof TreatmentLiteFormInput, value: string) => {
    const error = { ...formErrors };
    error[name] = null;
    setData(prev => ({
      ...prev,
      [name]: value,
    }));
    setFormErrors(error);
  };
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="p-0  w-[65vw] md:w-[533px] flex flex-col gap-0 border border-neutral-100 max-h-[70%]">
        <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
          <h1 className="text-[14px] text-neutral-900 font-semibold">
            {props.type == 'create' ? 'Add treatment' : 'Edit treatment'}
          </h1>
          <Button variant={'link'} size={'xmall'} onClick={onClose}>
            <CrossIcon />
          </Button>
        </DialogHeader>
        <Slider className="flex-1" variant={'vertical'}>
          <div className="p-4 space-y-3">
            <div className="col-span-3 space-y-1">
              <Label htmlFor="name">Name</Label>
              <Input
                placeholder="Enter treatment name"
                id="name"
                name="name"
                value={data.name}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  inputHandler('name', e.target.value)
                }
                hasError={!!formErrors.name}
              />
              {formErrors.name && (
                <small className="text-destructive-500">
                  {formErrors.name}
                </small>
              )}
            </div>
            <div className="col-span-3 space-y-1">
              <Label htmlFor="description">Description</Label>
              <Textarea
                placeholder="Enter description"
                id="description"
                name="description"
                value={data.description}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                  inputHandler('description', e.target.value)
                }
              />
            </div>
          </div>
        </Slider>
        <DialogFooter>
          <Button
            variant={'link'}
            size={'small'}
            className="w-full"
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            variant={'primary'}
            size={'small'}
            onClick={submitHandler}
            className="w-full"
            disabled={isLoading}
            isLoading={isLoading}
          >
            {props.type == 'create' ? 'Add treatment' : 'Save treatment'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AddAndEditResourceModal;
