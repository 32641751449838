import React, { FC } from 'react';
import { THEME } from '../../constants/ColorConstants';

const PenIcon: FC<{
  stroke?: string;
}> = ({ stroke = THEME.NEUTRAL_900 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M11.334 1.99912C11.5038 1.79848 11.7138 1.63516 11.9507 1.51958C12.1875 1.40399 12.4461 1.33868 12.7097 1.3278C12.9734 1.31692 13.2365 1.36071 13.4822 1.45638C13.728 1.55205 13.9509 1.69751 14.1369 1.88347C14.3228 2.06943 14.4677 2.29183 14.5623 2.53648C14.657 2.78114 14.6992 3.04268 14.6864 3.30444C14.6736 3.5662 14.606 3.82245 14.4879 4.05683C14.3699 4.29122 14.2039 4.49861 14.0007 4.66579L5.00065 13.6658L1.33398 14.6658L2.33398 10.9991L11.334 1.99912Z"
        stroke={stroke}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PenIcon;
