/* eslint-disable @typescript-eslint/no-explicit-any */
import { URL } from '../requestConstructor';

export const getClientIdByURL = async (url: string) => {
  try {
    const response = await URL.get(`/client/get/public?url=${url}`);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const getClient = async () => {
  try {
    const response = await URL.get('/client/get');
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const updateClient = async (data: any) => {
  try {
    const response = await URL.put('/client/update', data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};
