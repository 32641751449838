import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: ScheduleState = { schedule: [] };

export const resourceScheduleSlice = createSlice({
  name: 'resourceSchedule',
  initialState,
  reducers: {
    addResourceSchedules: (state, action: PayloadAction<SingleSchedule[]>) => {
      state.schedule = action.payload;
    },
    deleteResourceSchedule: (
      state,
      action: PayloadAction<{ publicId: string }>
    ) => {
      state.schedule = state.schedule.filter(
        site => site.publicId !== action.payload.publicId
      );
    },
    addResourceSchedule: (state, action: PayloadAction<SingleSchedule>) => {
      state.schedule.push(action.payload);
    },
    updateResourceSchedule: (state, action: PayloadAction<SingleSchedule>) => {
      const siteIndex = state.schedule.findIndex(
        site => site.publicId === action.payload.publicId
      );
      state.schedule[siteIndex] = action.payload;
    },
  },
});

// Export reducer and actions
export default resourceScheduleSlice.reducer;
export const {
  addResourceSchedules,
  deleteResourceSchedule,
  addResourceSchedule,
  updateResourceSchedule,
} = resourceScheduleSlice.actions;
