import React from 'react';
import Cookies from 'js-cookie';
import { Layout } from '../../layout';
import { RootState } from '@/redux/store/store';
import { useSelector } from 'react-redux';
import { ROUTES } from '../../constants/NavigationConstants';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

interface RequireAuthProps {
  allowedRoles: string[];
}

const RequireAuth: React.FC<RequireAuthProps> = ({ allowedRoles }) => {
  const location = useLocation();
  const authCookie = Cookies.get('authToken');
  const user = useSelector<RootState, UserEntities>(state => state.user.user);
  const roles = user.roles;

  if (!authCookie) {
    return (
      <Navigate
        to={ROUTES.ROUTE_TO_SIGN_IN}
        state={{ from: location }}
        replace
      />
    );
  }

  const hasAllowedRoles = roles?.some(
    (role: string) => allowedRoles?.includes(role)
  );

  if (hasAllowedRoles) {
    return (
      <Layout>
        <Outlet />
      </Layout>
    );
  }

  return (
    <Navigate
      to={ROUTES.ROUTE_TO_UNAUTHORIZED}
      state={{ from: location }}
      replace
    />
  );
};

export default RequireAuth;
