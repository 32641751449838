import React from 'react';
import { DirectedArrowIcon } from '../../../../../assets/icons';
import { Table, TableHeader, TableHead, TableRow } from '../../../../UI';
import { THEME } from '../../../../../constants/ColorConstants';

const EmptyFolder = ({ onUpload }: { onUpload: (file: File) => void }) => {
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    onUpload(e.dataTransfer.files[0]);
  };
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    onUpload(e.dataTransfer.files[0]);
  };
  return (
    <>
      <Table>
        <TableHeader>
          <TableRow className="[&>*:nth-child(1)]:w-[50px] [&>*:nth-child(3)]:text-right">
            {['', 'Name', 'Modified At', ''].map((header, idx) => {
              return (
                <TableHead key={idx}>
                  {header === 'Name' ? (
                    <div className="flex items-center gap-1">
                      Name <DirectedArrowIcon fill={THEME.NEUTRAL_500} />
                    </div>
                  ) : (
                    header
                  )}
                </TableHead>
              );
            })}
          </TableRow>
        </TableHeader>
      </Table>
      <div
        className="flex flex-col flex-grow justify-center items-center"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <p className="text-[16px] font-medium  text-neutral-900">
          This folder is empty
        </p>
        <p className="text-[14px] text-neutral-500">
          Drag and drop files onto this window to upload
        </p>
      </div>
    </>
  );
};

export default EmptyFolder;
