/* eslint-disable @typescript-eslint/no-explicit-any */
import { URL } from '../requestConstructor';

export const getAllLocations = async () => {
  try {
    const response = await URL.get('/location/get/all');
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const createNewLocation = async (data: CreateLocationRequest) => {
  try {
    const response = await URL.post('/location/create', data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response.data];
  }
};

export const updateLocation = async (data: UpdateLocationRequest) => {
  try {
    const response = await URL.put('/location/update', data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const deleteLocation = async (id: string) => {
  try {
    const response = await URL.delete(`/location/delete?publicId=${id}`);
    return [response, null];
  } catch (error: any) {
    return [null, error.response];
  }
};
