/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ERROR_TO_DO } from '../../../types';
import { URL } from '../requestConstructor';

export const getChildDocuments = async (
  patientPublicId: string,
  parentPublicId: string
) => {
  try {
    const response = await URL.get(
      `/document/children/get?parentPublicId=${parentPublicId}&patientPublicId=${patientPublicId}`
    );
    return [response.data, null];
  } catch (error: ERROR_TO_DO) {
    return [null, error.response];
  }
};

export const getDocument = async (
  publicId: string,
  patientPublicId: string,
  type: string
) => {
  try {
    const response = await URL.get(
      `/document/get?publicId=${publicId}&patientPublicId=${patientPublicId}&type=${type}`
    );
    return [response.data, null];
  } catch (error: ERROR_TO_DO) {
    return [null, error.response];
  }
};

export const deleteDocument = async (publicId: string) => {
  try {
    const response = await URL.delete(`/document/delete?publicId=${publicId}`);
    return [response, null];
  } catch (error: ERROR_TO_DO) {
    return [null, error.response];
  }
};

export const createDocument = async (document: DocumentCreateRequest) => {
  try {
    const response = await URL.post('/document/create', document);
    return [response.data, null];
  } catch (error: ERROR_TO_DO) {
    if (error.response.status === 400) {
      return [null, error.response.data];
    } else {
      return [null, error];
    }
  }
};

export const updateDocument = async (document: DocumentUpdateRequest) => {
  try {
    const response = await URL.put('/document/update', document);
    return [response.data, null];
  } catch (error: ERROR_TO_DO) {
    if (error.response.status === 400) {
      return [null, error.response.data];
    } else {
      return [null, error];
    }
  }
};
