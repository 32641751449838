import React, { useEffect, useMemo, useState } from 'react';
import { Slider } from '../../../UI';
import StageColum from './StageColum';
import {
  getEventByPatientID,
  getEventsByPathwayId,
  updateEventStage,
} from '../../../../services/api/endPoints/events';
import { useParams } from 'react-router-dom';
import { notify } from '../../../../components/common';
import { getFullName } from '../../../../utils/Helper';
import BoardLoader from '../../../../components/loaders/BoardLoader';
import { ERROR_MESSAGE } from '../../../../constants/NotificationConstants';

const PathStages = ({
  patientPathway,
  consultant,
  assignee,
  assigneeTeam,
  archived,
  searchQuery,
  showNoOfEvents,
  enableAddToPathway,
}: PathStagesPropsType) => {
  const { id } = useParams();
  const [events, setEvents] = useState<BoardEvent[]>([]);
  const [eventToUpdateId, setEventToUpdateId] = useState<string>();
  const [showPatient, setShowPatient] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    const getEvents = async (pathway: PathwayLite) => {
      setIsLoading(true);
      let response;
      if (id) {
        [response] = await getEventByPatientID(id);
      } else {
        [response] = await getEventsByPathwayId(pathway.publicId);
        setShowPatient(true);
      }
      if (response) {
        const data: BoardEvent[] = [];

        response?.forEach((element: EventLite) => {
          data.push({
            pathwayName: patientPathway.name,
            patient: element.patient,
            staff: element.staff,
            currentStage: element.currentStage,
            eventPublicId: element.publicId,
            assignee: element.assignee,
            assigneeTeam: element.assigneeTeam,
            whenLastStageUpdated: element.whenLastStageUpdated,
            archived: element.archived,
          });
        });
        setEvents(data);
        setIsLoading(false);
      } else {
        // notify.error({
        //   title: 'Failed to fetch data',
        // });
      }
    };
    getEvents(patientPathway);
  }, [id, patientPathway]);
  useEffect(() => {
    const getArchivedEvents = async (pathway: PathwayLite) => {
      if (archived) {
        const [response, error] = await getEventsByPathwayId(
          pathway.publicId,
          true
        );
        if (response) {
          const data: BoardEvent[] = [];
          response?.forEach((element: EventLite) => {
            data.push({
              pathwayName: patientPathway.name,
              patient: element.patient,
              staff: element.staff,
              currentStage: element.currentStage,
              eventPublicId: element.publicId,
              assignee: element.assignee,
              assigneeTeam: element.assigneeTeam,
              whenLastStageUpdated: element.whenLastStageUpdated,
              archived: element.archived,
            });
          });
          setEvents((prevEvents: BoardEvent[]) => [...prevEvents, ...data]);
        } else {
          notify.error({
            title: 'Failed to fetch archived events',
            message: error?.data || ERROR_MESSAGE.GENERIC_TRY_AGAIN,
          });
        }
      } else {
        let newEvents = [...events];
        newEvents = newEvents.filter(event => event.archived === false);
        setEvents(newEvents);
      }
    };
    getArchivedEvents(patientPathway);
  }, [archived]);
  const addToPathway = (data: any) => {
    const event: BoardEvent = {
      pathwayName: patientPathway.name,
      patient: data.patient,
      staff: data.staff,
      currentStage: data.currentStage,
      eventPublicId: data.publicId,
      assignee: data.assignee,
      assigneeTeam: data.assigneeTeam,
      whenLastStageUpdated: data.whenLastStageUpdated,
      archived: data.archived,
    };
    setEvents(prev => ([
      ...prev,
      event,
    ]));
  };
  const filteredEvents: BoardEvent[] = useMemo(() => {
    // if (!assignee && !assigneeTeam) return [...events];
    let newEvents = consultant
      ? events.filter(event => event.staff.publicId === consultant.publicId)
      : [...events];
    newEvents = assignee
      ? newEvents.filter(event => event.assignee.publicId === assignee.publicId)
      : [...newEvents];
    newEvents = assigneeTeam
      ? newEvents.filter(
        event => event.assigneeTeam.publicId === assigneeTeam.publicId
      )
      : [...newEvents];
    newEvents =
      !searchQuery || searchQuery === ''
        ? [...newEvents]
        : newEvents.filter(
          event =>
            event.patient.fullName
              .toLowerCase()
              .includes(
                searchQuery.toLowerCase() ||
                event.patient.publicId
                  .toLowerCase()
                  .includes(searchQuery)
                  .toLowerCase()
              ) ||
            getFullName(event.assignee)
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            event.assigneeTeam.name
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            getFullName(event.staff)
              .toLowerCase()
              .includes(searchQuery.toLowerCase())
        );
    return newEvents;
  }, [consultant, assignee, assigneeTeam, events, searchQuery]);
  const setEventsHandler = (
    prevEvents: BoardEvent[],
    stage: StageLite
  ): BoardEvent[] => {
    return prevEvents?.map((event: BoardEvent) => {
      if (event.eventPublicId === eventToUpdateId) {
        return {
          ...event,
          currentStage: stage,
        };
      }
      return event;
    });
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = async (
    e: React.DragEvent<HTMLDivElement>,
    stage: StageLite
  ) => {
    e.preventDefault();
    if (eventToUpdateId) {
      const updateStageObj: UpdateEventRequest = {
        publicId: eventToUpdateId,
        currentStagePublicId: stage.publicId,
      };
      const prevEvents = [...events];
      const prevStage = { ...stage };
      setEvents((prevEvents: BoardEvent[]) =>
        setEventsHandler(prevEvents, stage)
      );
      const [response] = await updateEventStage(updateStageObj);
      if (response) {
        notify.success({
          title: `Moved to stage ${stage.name}`,
        });
      } else {
        setEvents(() => setEventsHandler(prevEvents, prevStage));
        notify.error({
          title: `Failed to move to stage ${stage.name}`,
        });
      }
    }
  };

  const removeArchivedEvent = (archivedEvent: BoardEvent) => {
    setEvents(prevEvents =>
      prevEvents?.filter(
        (event: BoardEvent) =>
          event.eventPublicId !== archivedEvent.eventPublicId
      )
    );
  };
  const handleAddUnarchiveEvent = (unarchiveEvent: BoardEvent) => {
    setEvents(prevEvents =>
      prevEvents.map(event =>
        event.eventPublicId === unarchiveEvent.eventPublicId
          ? { ...unarchiveEvent, archived: false }
          : { ...event }
      )
    );
  };
  return (
    <>
      {isLoading ? (
        <BoardLoader showTopPathwayLoader={false} />
      ) : (
        <Slider variant={'horizontal'} className="p-3 h-full gap-4">
          {patientPathway.pathwayStages.map(stage => (
            <div
              key={stage.publicId}
              onDragOver={handleDragOver}
              onDrop={e => handleDrop(e, stage)}
            >
              <StageColum
                stage={stage}
                events={filteredEvents}
                setEventToUpdateId={setEventToUpdateId}
                removeArchivedEvent={removeArchivedEvent}
                showPatient={showPatient}
                handleAddUnarchiveEvent={handleAddUnarchiveEvent}
                showNoOfEvents={showNoOfEvents}
                enableAddToPathway={
                  stage.stageIndex === 0 && enableAddToPathway
                }
                addToPathway={addToPathway}
                pathway={patientPathway}
              />
            </div>
          ))}
        </Slider>
      )}
    </>
  );
};

export default PathStages;
