import React, { ChangeEvent, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  Button,
  DialogFooter,
  Label,
  Input,
  Slider,
  Checkbox,
} from '../../../../UI';
import { CrossIcon } from '../../../../../assets/icons';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { preLoginCheck, adminResetPassword } from '../../../../../services/api';
import { notify } from '../../../../../components/common';

const ResetPasswordModal = ({
  open,
  onClose,
  staffUsername,
}: ResetPasswordModalProps) => {
  const client = useSelector<RootState, SwivoClient>(state => state.client);
  const user = useSelector<RootState, UserState>(state => state.user);
  const [passwordData, setPasswordData] = useState({
    adminPassword: '',
    newPassword: '',
    confirmPassword: '',
    disableTwoFactor: false,
    twoFactorReset: false,
  });
  const [isLoading, setIsLoading] = useState<boolean>();
  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setPasswordData(prev => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const onSubmit = async () => {
    if (
      passwordData.adminPassword === '' ||
      passwordData.newPassword === '' ||
      passwordData.confirmPassword === ''
    ) {
      return;
    }
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      notify.error({
        title: 'Password does not match',
        message: 'Please enter the same password',
      });
      return;
    }
    setIsLoading(true);
    const preLogonData: LoginUser = {
      clientId: client.client.clientId!,
      username: user.user.username,
      password: passwordData.adminPassword,
    };
    const [, err] = await preLoginCheck(preLogonData);
    if (err) {
      notify.error({
        title: 'Incorrect admin password',
        message: err.data,
      });
      setIsLoading(false);
      return;
    }
    if (!staffUsername) {
      setIsLoading(false);
      notify.error({
        title: 'Staff username not found',
        message: 'Unable to get staff username. Please reload the page',
      });
      return;
    }
    const passwordDetails: AdminResetPassword = {
      username: staffUsername,
      newPassword: passwordData.newPassword,
      twoFactorReset: passwordData.twoFactorReset,
      disableTwoFactor: passwordData.disableTwoFactor,
    };
    // API call
    const [, error] = await adminResetPassword(passwordDetails);
    if (error) {
      setIsLoading(false);
      notify.error({
        title: 'Unable to update password',
        message: error.data,
      });
      return;
    }
    notify.success({
      title: 'Password reset successful',
      message: `Password updated for ${staffUsername}`,
    });
    setIsLoading(false);
    onClose();
  };
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="p-0  tablet:w-[400px] md:w-[533px] flex flex-col gap-0 border border-neutral-100 max-h-[70%]">
        <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
          <h1 className="text-[14px] text-neutral-900 font-semibold">
            Reset password
          </h1>
          <Button variant={'link'} size={'xmall'} onClick={onClose}>
            <CrossIcon />
          </Button>
        </DialogHeader>
        <Slider className="flex-1 p-4 space-y-3" variant={'vertical'}>
          <div className="space-y-1">
            <Label htmlFor="adminPassword">Enter your password</Label>
            <Input
              placeholder="Enter your password"
              id="adminPassword"
              name="adminPassword"
              type="password"
              onChange={handleInput}
              value={passwordData.adminPassword}
            />
          </div>
          <div className="space-y-1">
            <Label htmlFor="newPassword">New password</Label>
            <Input
              placeholder="Enter password"
              id="newPassword"
              name="newPassword"
              type="password"
              onChange={handleInput}
              value={passwordData.newPassword}
            />
          </div>
          <div className="space-y-1">
            <Label htmlFor="confirmPassword">Confirm password</Label>
            <Input
              placeholder="Conform password"
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              onChange={handleInput}
              value={passwordData.confirmPassword}
            />
          </div>
          <div className="space-y-1">
            <div>
              <Checkbox
                onCheckedChange={checked =>
                  setPasswordData(prev => ({
                    ...prev,
                    twoFactorReset: !!checked,
                  }))
                }
              />{' '}
              Reset two factor authentication
            </div>
            <div>
              <Checkbox
                onCheckedChange={checked =>
                  setPasswordData(prev => ({
                    ...prev,
                    disableTwoFactor: !!checked,
                  }))
                }
              />{' '}
              Disable two factor authentication
            </div>
          </div>
        </Slider>
        <DialogFooter>
          <Button
            variant={'link'}
            size={'small'}
            className="w-full"
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            size={'small'}
            onClick={onSubmit}
            className="w-full"
            isLoading={isLoading}
            variant={'primary'}
            disabled={
              passwordData.adminPassword === '' ||
              passwordData.newPassword === '' ||
              passwordData.confirmPassword === '' || isLoading
            }
          >
            Reset password
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ResetPasswordModal;
