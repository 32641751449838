export const THEME = {
  // Neutral Colors
  NEUTRAL_50: '#FCFCFC',
  NEUTRAL_100: '#D5D5D5',
  NEUTRAL_200: '#C0C0C0',
  NEUTRAL_300: '#AAAAAA',
  NEUTRAL_400: '#959595',
  NEUTRAL_500: '#808080',
  NEUTRAL_600: '#505050',
  NEUTRAL_700: '#303030',
  NEUTRAL_800: '#101010',
  NEUTRAL_900: '#0A0A0A',

  // Primary Colors
  PRIMARY_50: '#F4EFF7',
  PRIMARY_100: '#DDCFEB',
  PRIMARY_200: '#D2C0E0',
  PRIMARY_300: '#C7B0D8',
  PRIMARY_400: '#B090C9',
  PRIMARY_500: '#A580C1',
  PRIMARY_600: '#8D5EB0',
  PRIMARY_700: '#764A97',
  PRIMARY_800: '#583771',
  PRIMARY_900: '#110816',

  // Success Colors
  SUCCESS_50: '#F0FDF4',
  SUCCESS_100: '#DCFCE7',
  SUCCESS_200: '#BBF7D0',
  SUCCESS_300: '#86EFAC',
  SUCCESS_400: '#4ADE80',
  SUCCESS_500: '#22C55E',
  SUCCESS_600: '#16A34A',
  SUCCESS_700: '#15803D',
  SUCCESS_800: '#166534',
  SUCCESS_900: '#14532D',

  // Warning Colors
  WARNING_50: '#FFFBEB',
  WARNING_100: '#FEF3C7',
  WARNING_200: '#FDE68A',
  WARNING_300: '#FCD34D',
  WARNING_400: '#FCD34D',
  WARNING_500: '#F59E0B',
  WARNING_600: '#D97706',
  WARNING_700: '#B45309',
  WARNING_800: '#92400E',
  WARNING_900: '#78350F',

  // Destructive Colors
  DESTRUCTIVE_50: '#FEF2F2',
  DESTRUCTIVE_100: '#FEE2E2',
  DESTRUCTIVE_200: '#FECACA',
  DESTRUCTIVE_300: '#FCA5A5',
  DESTRUCTIVE_400: '#FCA5A5',
  DESTRUCTIVE_500: '#EF4444',
  DESTRUCTIVE_600: '#DC2626',
  DESTRUCTIVE_700: '#B91C1C',
  DESTRUCTIVE_800: '#991B1B',
  DESTRUCTIVE_900: '#7F1D1D',

  // Info Colors
  INFO_50: '#E3F2FD',
  INFO_100: '#BBDEFB',
  INFO_200: '#90CAF9',
  INFO_300: '#64B5F6',
  INFO_400: '#42A5F5',
  INFO_500: '#2196F3', // Main blue shade
  INFO_600: '#1E88E5',
  INFO_700: '#1976D2',
  INFO_800: '#1565C0',
  INFO_900: '#0D47A1',

  // Shades
  SHADE_0: '#FFFFFF',
  SHADE_100: '#000000',
  SHADE_200: '#110B16',
};
