import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: UserState = {
  user: {
    publicId: '',
    username: '',
    email: '',
    roles: [],
    staff: {
      publicId: '',
      username: '',
      title: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneNo: '',
      signature: '',
      createdBy: '',
      whenCreated: '',
      updatedBy: '',
      whenLastUpdated: '',
      siteExternalId: '',
      type: '',
    },
    createdBy: '',
    whenCreated: '',
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addUserCredentials: (
      state: UserState,
      action: PayloadAction<UserEntities>
    ) => {
      state.user = action.payload;
    },
    getUserCredentials: (state: UserState) => {
      return state;
    },
    updateUserStaffDetails: (state: UserState,action: PayloadAction<StaffInformation>) =>  {
      state.user.staff = action.payload;
    }
  },
});

export default userSlice.reducer;
export const { addUserCredentials, getUserCredentials, updateUserStaffDetails } = userSlice.actions;

export const getCurrentUser = (state: UserState) => state.user;
