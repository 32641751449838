import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { uploadFile } from '../../../../services/api';
import {
  notify,
  UploadProfilePictureCamera,
} from '../../../../components/common';
import { updatePatient } from '../../../../services/api/endPoints/patients';
import '../../Scanner/constants/cropper.css';
const ProfilePicCamera = () => {
  const navigate = useNavigate();
  const [loading, isLoading] = useState<boolean>(false);
  const { id } = useParams();

  const onUpload = async (file: File) => {
    const profilePictureFileData = new FormData();
    profilePictureFileData.append('containerName', 'patient_profile_pictures');
    profilePictureFileData.append(
      'fileName',
      `${id}.${file.name.split('.').pop()}`
    );
    profilePictureFileData.append('file', file);
    isLoading(true);
    const [res, error] = await uploadFile(profilePictureFileData);
    if (error) {
      notify.error({
        title: 'Unable to upload the image',
        message: error.data,
      });
      isLoading(false);
      return;
    }
    const profileImageUploadData = {
      publicId: id,
      profilePictureUrl: res,
    };
    const [, errorImageChange] = await updatePatient(profileImageUploadData);
    if (errorImageChange) {
      notify.error({
        title: 'Unable to upload the image',
        message: errorImageChange.data,
      });
      isLoading(false);
      return;
    }
    notify.success({
      title: 'Successfully updated patient profile picture',
    });
    isLoading(false);
    navigate(-1);
  };

  return (
    <UploadProfilePictureCamera
      onUpload={onUpload}
      loading={loading}
      backHandler={() => navigate(-1)}
      heading="Capture patient profile picture"
    />
  );
};

export default ProfilePicCamera;
