import {
  Button,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Slider,
} from '../../../UI';
import { RootState } from '../../../../redux/store/store';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { DataTable, notify } from '../../../../components/common';
import {
  DeleteIcon,
  DownArrowIcon,
  OutlinedAddIcon,
  PenIcon,
} from '../../../../assets/icons';
import { ColumnDef } from '@tanstack/react-table';
import AddEditWorkingHoursModal from './AddEditWorkingHoursModal';
import { getLocationResources } from '../../../../services/api/endPoints/resources';
import {
  getSchedule,
  updateSchedule,
  deleteSchedule,
} from '../../../../services/api/endPoints/schedule';
import {
  addResourceSchedules,
  deleteResourceSchedule,
  updateResourceSchedule,
} from '../../../../redux/slice/resourceScheduleSlice';
import { useDispatch } from 'react-redux';
import { weekDays } from '../../../../constants/constants';
import { THEME } from '../../../../constants/ColorConstants';

const ResourceSchedule = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openAddWorkingHoursModal, setOpenAddWorkingHoursModal] =
    useState<boolean>(false);
  const [openEditWorkingHoursModal, setEditOpenWorkingHoursModal] =
    useState<boolean>(false);
  const [resources, setResources] = useState<Resource[]>([]);
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [selectedSchedule, setSelectedSchedule] =
    useState<SingleSchedule | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<string | undefined>(
    ''
  );
  const [selectedResource, setSelectedResource] = useState<string | null>(null);
  const [selectedResourceName, setSelectedResourceName] = useState<string>('');

  const locations = useSelector<RootState, Location[]>(
    state => state.locations.locations
  );

  const fetchResources = async (locationId: string) => {
    setIsLoading(true);
    const [data, error] = await getLocationResources('', locationId);
    setIsLoading(false);
    if (data) {
      const resourceInfo: Resource[] = data.map((resource: any) => ({
        publicId: resource.publicId,
        name: resource.name,
      }));
      setResources(resourceInfo);
      if (data.length === 0) {
        setSchedules([]);
        setSelectedResourceName('');
        notify.error({
          title: 'No resources found for the selected location',
          message: 'Please try selecting a different location',
        });
      }
    } else {
      notify.error({ title: 'Error fetching resources', message: error });
    }
  };

  const schedule = useSelector<RootState, SingleSchedule[]>(
    state => state.resourceSchedule.schedule
  );

  const fetchSchedule = async (resourceId: string) => {
    setIsLoading(true);
    const [data, error] = await getSchedule('', resourceId, '');
    setIsLoading(false);
    if (data) {
      dispatch(addResourceSchedules(data));
    } else {
      notify.error({ title: 'Error fetching schedule', message: error });
    }
  };

  const tranformSchedule = (data: SingleSchedule[]) => {
    const dayMap: Record<string, Schedule[]> = weekDays.reduce(
      (acc, day) => {
        acc[day] = [];
        return acc;
      },
      {} as Record<string, Schedule[]>
    );

    const convertTo12HourFormat = (time: string): string => {
      const [hours, minutes] = time.split(':');
      const hour = parseInt(hours, 10);
      const period = hour >= 12 ? 'PM' : 'AM';
      const hour12 = hour % 12 || 12;
      return `${hour12}:${minutes} ${period}`;
    };

    data.forEach((entry: SingleSchedule) => {
      entry.days.forEach((day: string) => {
        const formattedDay = day.charAt(0) + day.slice(1).toLowerCase();
        const scheduleEntry: Schedule = {
          fullEntry: entry,
          day: formattedDay,
          egDay: day,
          hours:
            entry.timeStart && entry.timeEnd
              ? `${convertTo12HourFormat(entry.timeStart)} - ${convertTo12HourFormat(entry.timeEnd)}`
              : '',
          services:
            entry.services && entry.services.length > 0
              ? entry.services.map((service: any) => service.name).join(', ')
              : '',
          repeats: entry.repeatWeeks ? (entry.repeatWeeks === 1 ? 'Weekly' : `Every ${entry.repeatWeeks} weeks`) : '',
          publicId: entry.publicId,
        };
        dayMap[formattedDay].push(scheduleEntry);
      });
    });

    weekDays.forEach(day => {
      if (dayMap[day].length > 1) {
        dayMap[day][0].day = day;
        for (let i = 1; i < dayMap[day].length; i++) {
          dayMap[day][i].day = '';
        }
      } else if (dayMap[day].length === 1) {
        dayMap[day][0].day = day;
      } else {
        dayMap[day].push({
          day,
          egDay: '',
          hours: 'Not working',
          services: '-',
          repeats: '-',
          publicId: '',
          fullEntry: {} as SingleSchedule,
        });
      }
    });

    const scheduleList: Schedule[] = [];
    for (const day in dayMap) {
      scheduleList.push(...dayMap[day]);
    }

    const transformedSchedule = scheduleList.map((entry, index, array) => {
      if (index > 0 && entry.day === array[index - 1].day) {
        return { ...entry, day: '' };
      }
      return entry;
    });

    setSchedules(transformedSchedule);
  };

  //eslint-disable-next-line
  const deleteScheduleData = async (data: SingleSchedule, day: string) => {
    const scheduleData: CreateScheduleRequest = {
      publicId: data?.publicId,
      days: data.days.filter(d => d !== day),
    };
    if (data.days.length === 1) {
      try {
        //eslint-disable-next-line
        const [response, error] = await deleteSchedule(data.publicId);
        if (response) {
          dispatch(deleteResourceSchedule({ publicId: data.publicId }));
          notify.success({
            title: 'Working hours deleted successfully',
          });
        } else {
          notify.error({
            title: 'Unknown error occurred. Please try again after sometime',
          });
          setIsLoading(false);
        }
      } catch (error) {
        notify.error({
          title:
            'Failed to delete working hours. Please try again after sometime',
        });
        setIsLoading(false);
      }
    } else {
      try {
        console.log('scheduleData', scheduleData);
        //eslint-disable-next-line
        const [response, error] = await updateSchedule(scheduleData);
        if (response) {
          dispatch(updateResourceSchedule(response));
          notify.success({
            title: 'Working hours deleted successfully',
          });
        } else {
          notify.error({
            title: 'Unknown error occured. Please try again after sometime.',
          });
          setIsLoading(false);
        }
      } catch (error) {
        notify.error({
          title:
            'Failed to delete working hours. Please try again after sometime.',
        });
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const findFirstLocationWithResources = async () => {
      for (const location of locations) {
        const [data] = await getLocationResources('', location.publicId);
        if (data && data.length > 0) {
          setSelectedLocation(location.publicId);
          break;
        }
      }
    };

    if (locations.length > 0) {
      findFirstLocationWithResources();
    }
  }, [locations]);

  useEffect(() => {
    if (selectedLocation) {
      fetchResources(selectedLocation);
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (selectedResource) {
      fetchSchedule(selectedResource);
    }
  }, [selectedResource]);

  useEffect(() => {
    if (schedule.length >= 0 && selectedResource) {
      tranformSchedule(schedule);
    }
  }, [schedule]);

  const scheduleColumns: ColumnDef<Schedule>[] = useMemo(
    () => [
      {
        accessorKey: 'day',
        header: 'Day',
        cell: ({ row }) => {
          const isNotWorking = row.original.hours === 'Not working';
          return (
            <span className={isNotWorking ? 'text-gray-400' : ''}>
              {row.original.day}
            </span>
          );
        },
      },
      {
        accessorKey: 'hours',
        header: 'Hours',
        cell: ({ row }) => {
          const isNotWorking = row.original.hours === 'Not working';
          return (
            <span className={isNotWorking ? 'text-gray-400' : ''}>
              {isNotWorking ? 'Not Working' : row.original.hours}
            </span>
          );
        },
      },
      {
        accessorKey: 'services',
        header: 'Services',
        cell: ({ row }) => {
          const isNotWorking = row.original.hours === 'Not working';
          return (
            <span className={isNotWorking ? 'text-gray-400' : ''}>
              {row.original.services}
            </span>
          );
        },
      },
      {
        accessorKey: 'repeats',
        header: 'Repeats',
        cell: ({ row }) => {
          const isNotWorking = row.original.hours === 'Not working';
          return (
            <div>
              <span className={isNotWorking ? 'text-gray-400' : ''}>
                {isNotWorking ? '-' : row.original.repeats}
              </span>
            </div>
          );
        },
      },
      {
        id: 'actions',
        cell: ({ row }) => {
          const isNotWorking = row.original.hours === 'Not working';
          return (
            <div className="flex justify-end">
              <div className="flex gap-2">
                {!isNotWorking && (
                  <Button
                    onClick={() => {
                      setEditOpenWorkingHoursModal(true);
                      setSelectedSchedule(row.original.fullEntry);
                    }}
                    className="h-[30px] border-none"
                  >
                    <PenIcon stroke={THEME.SHADE_100} />
                  </Button>
                )}
                {!isNotWorking && (
                  <Button
                    onClick={() => {
                      deleteScheduleData(
                        row.original.fullEntry,
                        row.original.egDay
                      );
                    }}
                    className="h-[30px] border-none"
                  >
                    <DeleteIcon stroke={THEME.SHADE_100} />
                  </Button>
                )}
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div>
      <div className="custom-height flex flex-col">
        <div className="flex-0 h-1/8">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <Label className="pb-1">Location</Label>
              <Select
                onValueChange={value => setSelectedLocation(value)}
                value={selectedLocation}
              >
                <SelectTrigger
                  className="w-full"
                  selectIcon={<DownArrowIcon />}
                >
                  <SelectValue placeholder="Select Location" />
                </SelectTrigger>
                <SelectContent className="bg-shades-0 z-[999999]">
                  {locations.map(location => (
                    <SelectItem
                      key={location.publicId}
                      value={location.publicId}
                    >
                      {location.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            {resources.length > 0 && (
              <div>
                <Label>Resource</Label>
                <Select
                  onValueChange={value => {
                    setSelectedResource(value);
                    const selectedResource = resources.find(
                      resource => resource.publicId === value
                    );
                    setSelectedResourceName(
                      selectedResource ? selectedResource.name : ''
                    );
                  }}
                >
                  <SelectTrigger
                    className="w-full"
                    selectIcon={<DownArrowIcon />}
                  >
                    <SelectValue placeholder="Select Resource" />
                  </SelectTrigger>
                  <SelectContent className="bg-shades-0 z-[999999]">
                    {resources.map(resource => (
                      <SelectItem
                        key={resource.publicId}
                        value={resource.publicId}
                      >
                        {resource.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            )}
          </div>
        </div>
        <div className="flex-1 h-7/8">
          <div className="mt-[10px] border border-neutral-100 rounded-lg h-full">
            <div className="flex flex-row items-center justify-between p-3">
              <div className="flex flex-row gap-[50px]">
                <div>
                  <h1 className="text-neutral-800 font-semibold text-[14px]">
                    Resource
                  </h1>
                </div>
                <div>
                  <h1 className="text-neutral-800 font-semibold text-[14px]">
                    {selectedResourceName || 'Select a Resource'}
                  </h1>
                </div>
              </div>
              {selectedResource && (
                <Button
                  variant={'secondary'}
                  size={'xmall'}
                  trailingIcon={<OutlinedAddIcon stroke={THEME.PRIMARY_600} />}
                  onClick={() => setOpenAddWorkingHoursModal(true)}
                >
                  Add Working Hours
                </Button>
              )}
            </div>
            <Slider variant={'vertical'} className="flex-1">
              <DataTable
                columns={scheduleColumns}
                data={schedules}
                isLoading={isLoading}
              />
            </Slider>
          </div>
        </div>
      </div>

      {openAddWorkingHoursModal && (
        <AddEditWorkingHoursModal
          open={openAddWorkingHoursModal}
          onClose={() => setOpenAddWorkingHoursModal(false)}
          type="Create"
          selectedResourceId={selectedResource}
          selectedLocationId={selectedLocation}
          selectedResourceName={selectedResourceName}
        />
      )}
      {openEditWorkingHoursModal && (
        <AddEditWorkingHoursModal
          open={openEditWorkingHoursModal}
          onClose={() => setEditOpenWorkingHoursModal(false)}
          type="Edit"
          selectedSchedule={selectedSchedule}
          selectedResourceId={selectedResource}
          selectedLocationId={selectedLocation}
          selectedResourceName={selectedResourceName}
        />
      )}
    </div>
  );
};

export default ResourceSchedule;
