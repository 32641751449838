import React, { useEffect, useState } from 'react';
import defaultImage from '../../../../assets/images/360_F_118019822_6CKXP6rXmVhDOzbXZlLqEM2ya4HhYzSV.jpg';
import ViewProfilePicModal from './ViewProfilePicModal';
import { downloadFileByUrl } from '../../../../services/api/endPoints/files.api';
import UploadProfilePicModal from './UploadProfilePicModal';
import Skeleton from 'react-loading-skeleton';
const PatientProfileImage = ({
  patient,
  publicId,
  ...props
}: {
  patient?: PatientInformation;
  publicId: string;
} & (
  | {
      type: 'partner';
    }
  | { type: 'patient'; onChangeProfilePicture: (imageUrl: string) => void }
)) => {
  const [viewImageModal, setViewImageModal] = useState<boolean>(false);
  const [uploadImageModal, setUploadImageModal] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  useEffect(() => {
    const getProfileImage = async () => {
      if (!patient || !patient.profilePictureUrl) return;
      setIsLoading(true);
      const [res] = await downloadFileByUrl({ url: patient.profilePictureUrl });
      if (res) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImageUrl(reader.result as string);
        };
        reader.readAsDataURL(res);
      }
      setIsLoading(false);
    };

    getProfileImage();
  }, [patient]);
  const editHandler = () => {
    setViewImageModal(false);
    setUploadImageModal(true);
  };
  return (
    <>
      {props.type === 'patient' && viewImageModal && (
        <ViewProfilePicModal
          open={viewImageModal}
          onClose={() => setViewImageModal(false)}
          imageUrl={imageUrl}
          onEdit={editHandler}
        />
      )}
      {props.type === 'patient' && uploadImageModal && (
        <UploadProfilePicModal
          open={uploadImageModal}
          onClose={() => setUploadImageModal(false)}
          onChangeProfilePicture={props.onChangeProfilePicture}
          publicId={publicId}
        />
      )}
      <div
        className="relative cursor-pointer"
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        onClick={() => setIsVisible(!isVisible)}
      >
        {!isLoading ? (
          <img
            src={imageUrl ? imageUrl : defaultImage}
            alt="patient-profile"
            className="rounded-full h-[100px] w-[100px] object-fit"
          />
        ) : (
          <Skeleton height={100} width={100} circle={true} />
        )}
        {props.type === 'patient' && !isLoading && isVisible && (
          <div className="absolute top-[70%] left-[70%] z-[9999] p-2 space-y-2 rounded border border-neutral-100 bg-shades-0">
            {(patient?.profilePictureUrl ||
              patient?.profilePictureUrl !== '') && (
              <p
                className="cursor-pointer text-neutral-900 font-semibold text-[14px] text-nowrap"
                onClick={() => setViewImageModal(true)}
              >
                View image
              </p>
            )}
            <p
              className="cursor-pointer text-neutral-900 font-semibold text-[14px] text-nowrap"
              onClick={() => setUploadImageModal(true)}
            >
              Upload image
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default PatientProfileImage;
