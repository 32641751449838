/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosRequestConfig } from 'axios';
import { URL } from '../requestConstructor';

export const loginUser = async (
  data: LoginUser,
  twoFactorAuthCode?: string
) => {
  try {
    const headers: AxiosRequestConfig['headers'] = {};
    headers['Two-Factor-Auth-Code'] = twoFactorAuthCode;

    const response = await URL.post('/user/auth/token', data, {
      headers,
    });

    if (response?.status >= 200 && response.status < 300) {
      return [response.data, null];
    } else if (response?.status >= 400 && response.status < 600) {
      return [null, response.data];
    } else {
      return [null, response];
    }
  } catch (error: any) {
    return [null, error.response.data];
  }
};

export const getUser = async () => {
  try {
    const response = await URL.get('/user/get');
    return response;
  } catch (error: any) {
    return error.response;
  }
};

export const preLoginCheck = async (data: LoginUser) => {
  try {
    const response = await URL.post('/user/preLoginCheck', data);

    return [response.data, null];
  } catch (error: any) {
    if (error?.status >= 400 && error?.status < 500) {
      return [null, error.response];
    } else {
      return [null, error.response];
    }
  }
};

export const firstPasswordReset = async (data: FirstPasswordReset) => {
  try {
    const response = await URL.put('/user/resetAuth', data);
    return [response, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const adminResetPassword = async (data: AdminResetPassword) => {
  try {
    const response = await URL.put('/user/admin/resetAuth', data);
    return [response, null];
  } catch (error: any) {
    return [null, error.response];
  }
};
