import { THEME } from '../../constants/ColorConstants';

const DownArrow = ({
  stroke = THEME.SHADE_0,
  width = '16',
  height = '17',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 6.5L8 10.5L12 6.5"
      stroke={stroke}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DownArrow;
