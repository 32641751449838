/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { FilterIcon } from '../../assets/icons';
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoperClose,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../UI';

interface FilterProps {
  filters: SessionFilterType[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setAllFilters: any;
  initialData: SelectedDatesAppointment;
  returnFunction: (data: SelectedDatesAppointment, value: boolean) => void;
}

const Filter: React.FC<FilterProps> = React.memo(
  ({ filters, setAllFilters, initialData, returnFunction }) => {
    const [selectedFilters, setFilterData] = useState<{
      [key: string]: string;
    }>(() => {
      const savedFilters = localStorage.getItem('selectedFilters');
      return savedFilters ? JSON.parse(savedFilters) : {};
    });

    const onSelect = (type: SessionFilterType, value: string) => {
      setFilterData(prev => {
        const updatedFilters = { ...prev, [type.label]: value };
        const updatedOptions = filters.map(filter => {
          return {
            ...filter,
            options: filter.options.map(option => {
              return {
                ...option,
                isSelected: option.value === value,
              };
            }),
          };
        });
        setAllFilters(updatedOptions);
        filters = updatedOptions;
        localStorage.setItem('selectedFilters', JSON.stringify(updatedOptions));
        return updatedFilters;
      });
    };

    const [showCancelled, setShowCancelled] = useState<boolean>(false);

    const applyFilters = () => {
      returnFunction(appointmentFiltering(), showCancelled);
    };

    const appointmentFiltering = () => {
      const filteredAppointments = initialData.appointments.filter(
        appointment => {
          const value: boolean[] = [];
          filters.forEach(filter => {
            const selectedValue = selectedFilters[filter.label];
            if (selectedValue) {
              if (filter.label === 'Location') {
                value.push(appointment.location.publicId === selectedValue);
              }
              if (filter.label === 'Service') {
                value.push(appointment.service.publicId === selectedValue);
              }
              if (filter.label === 'Consultant') {
                value.push(appointment.staff.publicId === selectedValue);
              }
              if (filter.label === 'Session') {
                if (selectedValue === 'all') {
                  value.push(true);
                  setShowCancelled(false);
                } else {
                  setShowCancelled(JSON.parse(selectedValue));
                  value.push(
                    appointment.cancelled === JSON.parse(selectedValue)
                  );
                }
              }
            }
          });
          return areAllTrue(value);
        }
      );
      return {
        date: initialData.date,
        appointments: filteredAppointments,
      };
    };

    const areAllTrue = (booleanList: boolean[]): boolean => {
      return booleanList.every(value => value === true);
    };

    return (
      <Popover>
        <PopoverTrigger asChild>
          <div>
            <Button
              variant={'link'}
              size={'xmall'}
              leadingIcon={<FilterIcon />}
              onClick={() => setFilterData({})}
            >
              <span className="sr-only">Open menu</span>
              Filter
            </Button>
          </div>
        </PopoverTrigger>
        <PopoverContent
          align="center"
          className="min-w-[310px] bg-shades-0 space-y-3"
        >
          <div className="flex flex-col gap-3">
            {filters.map(filter => (
              <div
                className="flex justify-between items-center"
                key={filter.label}
              >
                <p className="text-[14px] text-neutral-900">{filter.label}</p>

                <Select onValueChange={value => onSelect(filter, value)}>
                  <SelectTrigger className="w-[12rem]">
                    <SelectValue placeholder={filter.placeholder} />
                  </SelectTrigger>
                  <SelectContent className="bg-shades-0">
                    {filter.options.map(option => (
                      <SelectItem
                        key={option.value.toString()}
                        value={option.value}
                        className="w-[200px]"
                        selected={option.isSelected}
                      >
                        {option.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            ))}
          </div>
          {/* Action Menu Item */}
          <div className="flex gap-3 w-full">
            <PopoperClose className="w-full">
              <Button
                variant={'link'}
                size={'large'}
                onClick={() => returnFunction(initialData, false)}
              >
                clear
              </Button>
            </PopoperClose>
            <PopoperClose className="w-full">
              <Button variant={'primary'} size={'large'} onClick={applyFilters}>
                Apply
              </Button>
            </PopoperClose>
          </div>
        </PopoverContent>
      </Popover>
    );
  }
);

export { Filter };
