import { formatToDateTimeCalendar } from '../../utils/date.utl';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SelectedDatesAppointment {
  date: string;
  appointments: Appointment[];
}

interface AllAppointmentState {
  allAppointments: SelectedDatesAppointment[];
}

const initialState: AllAppointmentState = {
  allAppointments: [],
};

const appointmentSlice = createSlice({
  name: 'allAppointments',
  initialState,
  reducers: {
    setAppointments: (
      state,
      action: PayloadAction<SelectedDatesAppointment>
    ) => {
      const { date, appointments } = action.payload;
      const existingIndex = state.allAppointments.findIndex(
        item => item.date === date
      );

      if (existingIndex !== -1) {
        state.allAppointments[existingIndex].appointments = appointments;
      } else {
        state.allAppointments.push({ date, appointments });
      }
    },
    updateAppointment: (state, action: PayloadAction<Appointment>) => {
      let appt = action.payload;

      const appointmentStartTime = new Date(appt.appointmentTime);
      const durationMinutes = appt.service.duration
        ? Number(appt.service.duration)
        : 15;
      const appointmentEndTime = appt.appointmentEndTime
        ? new Date(appt.appointmentEndTime)
        : new Date(appointmentStartTime.getTime() + durationMinutes * 60000);

      appt = {
        ...appt,
        fullName: appt.patient.fullName,
        serviceName: appt.service.name,
        startTime: formatToDateTimeCalendar(appointmentStartTime),
        endTime: formatToDateTimeCalendar(appointmentEndTime),
        appointmentTime: appointmentStartTime,
        appointmentEndTime: appointmentEndTime,
      };

      const date = new Date(appt.appointmentTime).toISOString().split('T')[0];

      const existingDateIndex = state.allAppointments.findIndex(
        item => item.date === date
      );

      if (existingDateIndex !== -1) {
        const existingAppointmentIndex = state.allAppointments[
          existingDateIndex
        ].appointments.findIndex(
          appointment => appointment.publicId === appt.publicId
        );

        if (existingAppointmentIndex !== -1) {
          state.allAppointments[existingDateIndex].appointments[
            existingAppointmentIndex
          ] = appt;
        }
      }
    },
  },
});

export const { setAppointments, updateAppointment } = appointmentSlice.actions;

export default appointmentSlice.reducer;
