/* eslint-disable @typescript-eslint/no-explicit-any */
import { URL } from '../requestConstructor';

export const registerToPushNotification = async (token: string) => {
  try {
    const response = await URL.post(`/notification/register?token=${token}`);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const sendNotification = async (data: string) => {
  console.log(data);
};

export const getNotifications = async (formData: {
  publicId?: string;
  from?: string | '';
  to?: string | '';
}) => {
  try {
    const response = await URL.get('/notification/get', { params: formData });
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const getLastWeekNotifications = async () => {
  try {
    const formData = {
      from: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
      to: new Date(Date.now() +  24 * 60 * 60 * 1000).toISOString().split('T')[0],
    };

    const response = await URL.get('/notification/get', { params: formData });
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const markNotificationAsRead = async (notificationId: string) => {
  try {
    const body : NotificationUpdateRequest = {
      publicIds: [notificationId],
      hasRead: true,
    };

    const response = await URL.put('/notification/update', body);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};


export const markAllNotificationsAsRead = async () => {
  try {
    const body : NotificationUpdateRequest = {
      hasRead: true,
      all: true,
    };
    const response = await URL.put('/notification/update', body);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const markNotificationAsOpened = async (notificationId: string) => {
  try {
    const body : NotificationUpdateRequest = {
      publicIds: [notificationId],
      hasOpened: true,
    };

    const response = await URL.put('/notification/update', body);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const markAllNotificationsAsOpened = async () => {
  try {
    const body : NotificationUpdateRequest = {
      hasOpened: true,
      all: true,
    };
    const response = await URL.put('/notification/update', body);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};
