import { Button } from '../../UI';
import React, { ForwardedRef, useState, useLayoutEffect } from 'react';
import Webcam from 'react-webcam';
import { SwitchCameraIcon } from '../../../assets/icons';
import { notify } from '../../common';

const Camera = React.forwardRef((_, ref: ForwardedRef<Webcam>) => {
  const [hasBothCamera, setHasBothCamera] = useState<boolean>(false);
  const [facingMode, setFacingMode] = useState<'user' | 'environment'>('user');
  useLayoutEffect(() => {
    const back_constraints = {
      video: { facingMode: { exact: 'environment' } }, // 'environment' for back camera, 'user' for front camera
    };
    const front_constraints = {
      video: { facingMode: { exact: 'user' } }, // 'environment' for back camera, 'user' for front camera
    };
    // if the mobile device then we will find whether the device has back camera and front camera
    const setCameraConfig = async () => {
      try {
        const hasBothCamera =
          (await navigator.mediaDevices.getUserMedia(front_constraints)) &&
          (await navigator.mediaDevices.getUserMedia(back_constraints));

        if (hasBothCamera) {
          // setting default camera as back camera
          setFacingMode('environment');
          setHasBothCamera(true);
        }
      } catch (error) {
        // let see whether has back camera or
        try {
          const backStream =
            await navigator.mediaDevices.getUserMedia(back_constraints);
          if (backStream) {
            setFacingMode('environment');
          }
        } catch (error) {
          try {
            const frontStream =
              await navigator.mediaDevices.getUserMedia(front_constraints);
            if (frontStream) {
              setFacingMode('user');
            }
          } catch (error) {
            notify.error({
              title: 'Camera not found',
              message: 'Please check your camera and try again',
            });
          }
        }
      }
    };
    setCameraConfig();
  }, []);
  const changeCameraView = () => {
    if (hasBothCamera) {
      if (facingMode === 'environment') {
        setFacingMode('user');
      } else {
        setFacingMode('environment');
      }
    }
  };
  return (
    <div className="relative">
      <Webcam
        audio={false}
        ref={ref}
        screenshotFormat="image/jpeg"
        screenshotQuality={1}
        videoConstraints={{ facingMode: { exact: facingMode } }}
        height={window.innerHeight - 0.5 * window.innerHeight}
        width={window.innerWidth - 0.25 * window.innerWidth}
        // forceScreenshotSourceSize={true}
        // className="w-[100svw] h-[100svw]"
      />
      {hasBothCamera && (
        <Button
          variant={'primary'}
          size={'xmall'}
          className="rounded-full absolute right-5 bottom-[15px]"
          onClick={changeCameraView}
        >
          <SwitchCameraIcon />
        </Button>
      )}
    </div>
  );
});

export default Camera;
