import { THEME } from '../../constants/ColorConstants';
import React, { ReactNode } from 'react';

const ClinicManagerSidebarIcon = ({ isActive }: { isActive: Boolean }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 13.0811C2.00007 12.3061 2.18301 11.5421 2.53394 10.8512C2.88487 10.1602 3.39389 9.5619 4.01958 9.10483C4.64528 8.64776 5.37 8.34486 6.13479 8.22077M6.92308 8.15545L5.40426 13.0144C5.3926 13.0532 5.4077 13.0952 5.44148 13.1177L6.79189 13.936C6.8229 13.9566 6.86329 13.9566 6.8943 13.936L8.09702 13.1177C8.1308 13.0952 8.1459 13.0532 8.13423 13.0144L6.92308 8.15545ZM6.92308 8.15545C8.62241 8.15545 10 6.77751 10 5.07773C10 3.37794 8.62241 2 6.92308 2C5.22374 2 3.84615 3.37794 3.84615 5.07773C3.84615 6.77751 5.22374 8.15545 6.92308 8.15545Z"
        stroke={isActive ? THEME.SHADE_200 : THEME.SHADE_0}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.4444 9H10.5556C10.2487 9 10 9.24873 10 9.55556V13.4444C10 13.7513 10.2487 14 10.5556 14H14.4444C14.7513 14 15 13.7513 15 13.4444V9.55556C15 9.24873 14.7513 9 14.4444 9Z"
        stroke={isActive ? THEME.SHADE_200 : THEME.SHADE_0}
        stroke-width="1.3"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ClinicManagerSidebarIcon;
