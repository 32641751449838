import React from 'react';

const ThemeInput = ({
  value,
  inputHandler,
  label,
}: ThemeInputProps) => {
  return (
    <div className="flex justify-between items-center">
      <div className="space-y-1">
        <p className="font-semibold text-[16px]">{label}</p>
        <p className="text-[14px] text-neutral-500">
          Select your application color
        </p>
      </div>
      <div className="border border-neutral-100 py-2 px-3 flex items-center gap-2 w-[274px] rounded-lg">
        <input
          className="w-[27px] h-[27px] rounded-full"
          type="color"
          value={value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            inputHandler(event.target.value)
          }
        />
        <p className="text-[14px] text-neutral-600">{value}</p>
      </div>
    </div>
  );
};

export default ThemeInput;
