import React from 'react';
import { THEME } from '../../constants/ColorConstants';

interface FileIconProps {
  stroke?: string;
}

const FileIcon: React.FC<FileIconProps> = ({ stroke = THEME.SHADE_100 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
  >
    <path
      d="M9.66675 1.83398H4.00008C3.64646 1.83398 3.30732 1.97446 3.05727 2.22451C2.80722 2.47456 2.66675 2.8137 2.66675 3.16732V13.834C2.66675 14.1876 2.80722 14.5267 3.05727 14.7768C3.30732 15.0268 3.64646 15.1673 4.00008 15.1673H12.0001C12.3537 15.1673 12.6928 15.0268 12.9429 14.7768C13.1929 14.5267 13.3334 14.1876 13.3334 13.834V5.50065L9.66675 1.83398Z"
      stroke={stroke}
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.33325 1.83398V5.83398H13.3333"
      stroke={stroke}
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default FileIcon;
