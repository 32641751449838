import React, { useMemo, useState } from 'react';
import { Input, Popover, PopoverContent, PopoverTrigger, Slider } from '../UI';
import { RightArrow } from '../../assets/icons';
import { Check, X } from 'lucide-react';
import { cn } from '../../utils';

const SelectWithInputAndAddOption = ({
  options,
  inputPlaceholder = 'Enter option or add option',
  selectPlaceHolder = 'Select',
  isLoading = false,
  error,
  setOptions,
  hasError,
  ...props
}: SelectWithInputAndAddOptionsType) => {
  const [open, setOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>();
  const addOption = () => {
    if (!inputValue || inputValue.length == 0) return;
    setOptions((prev: string[]) => [...prev!, inputValue]);
    if (props.type === 'single') {
      props.setValue(inputValue);
    } else {
      props.setValues([...props.values, inputValue]);
    }

    setInputValue('');
    setOpen(false);
  };
  const removeValue = (valueToBeRemoved: string) => {
    if (props.type == 'multi') {
      const filteredValues = props.values.filter(
        val => val !== valueToBeRemoved
      );
      props.setValues(filteredValues);
    }
  };
  const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };
  const filteredList = useMemo(() => {
    if (!options || options.length === 0 || error || isLoading) return [];
    if (!inputValue || inputValue === '') return [...options];
    return options.filter(option =>
      option.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())
    );
  }, [inputValue, options, error, isLoading]);

  const addValueHandler = (option: string) => {
    if (props.type === 'single') {
      props.setValue(props.value === option ? '' : option);
    } else {
      if (props.type === 'multi') {
        if (props.values.includes(option)) return;
        props.setValues([...props.values, option]);
      }
    }
    setOpen(false);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger className="w-full" asChild>
        {(props.type == 'multi' && props.values.length === 0) ||
        (props.type == 'single' && (!props.value || props.value === '')) ? (
            <div
              className={cn(
                'flex h-auto w-full items-center justify-between whitespace-nowrap rounded-md border border-input bg-transparent px-3 py-2 text-sm shadow-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-1 focus:ring-ring disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 cursor-pointer',
                hasError ? 'border-destructive-300' : ''
              )}
            >
              <span>{selectPlaceHolder}</span>
              <div className="rotate-90">
                <RightArrow />
              </div>
            </div>
          ) : props.type === 'multi' ? (
            <div
              className={cn(
                'flex h-auto w-full items-center justify-between whitespace-nowrap rounded-md border border-input bg-transparent px-3 py-2 text-sm shadow-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-1 focus:ring-ring disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 cursor-pointer',
                hasError ? 'border-destructive-300' : ''
              )}
            >
              <div className="flex gap-1 flex-wrap">
                {props.values.map((val, idx) => (
                  <div
                    key={`${idx}/${val}`}
                    className="p-1 border border-neutral-100 rounded flex gap-2 z"
                  >
                    <span>{val}</span>
                    <button
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        removeValue(val);
                      }}
                    >
                      <X size={10} />
                    </button>
                  </div>
                ))}
              </div>
              <div className="rotate-90">
                <RightArrow />
              </div>
            </div>
          ) : (
            <div
              className={cn(
                'flex h-auto w-full items-center justify-between whitespace-nowrap rounded-md border border-input bg-transparent px-3 py-2 text-sm shadow-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-1 focus:ring-ring disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 cursor-pointer',
                hasError ? 'border-destructive-300' : ''
              )}
            >
              <span>{props.value}</span>
              <div className="rotate-90">
                <RightArrow />
              </div>
            </div>
          )}
      </PopoverTrigger>
      <PopoverContent className="w-[--radix-popover-trigger-width] max-h-[--radix-popover-content-available-height] z-[9999999] bg-white overflow-scroll p-0">
        {!isLoading &&
          (!error ? (
            <>
              <div className="w-full h-full space-y-2 p-3">
                <Input
                  placeholder={inputPlaceholder}
                  onChange={inputHandler}
                  inputSize={'medium'}
                />
                <Slider variant={'vertical'} className="h-full">
                  {filteredList.length === 0 ? (
                    inputValue && inputValue.length > 0 ? (
                      <p
                        onClick={addOption}
                        className="cursor-pointer py-2 text-center text-sm"
                      >
                        No option found. Click to add - {inputValue}
                      </p>
                    ) : (
                      <p className="py-2 text-center text-sm">
                        No option found.
                      </p>
                    )
                  ) : (
                    <ul className="space-y-2">
                      {filteredList.map(option => (
                        <li
                          key={option}
                          value={option}
                          onClick={() => addValueHandler(option)}
                          className="flex justify-between py-0 cursor-pointer items-center"
                        >
                          {option}
                          <Check
                            size={15}
                            style={{
                              opacity:
                                props.type === 'multi'
                                  ? props.values.includes(option)
                                    ? 1
                                    : 0
                                  : props.value === option
                                    ? 1
                                    : 0,
                            }}
                          />
                        </li>
                      ))}
                      {inputValue && inputValue.length > 0 && (
                        <p
                          onClick={addOption}
                          className="cursor-pointer  text-center text-sm"
                        >
                          Click to add - {inputValue}
                        </p>
                      )}
                    </ul>
                  )}
                </Slider>
              </div>
            </>
          ) : (
            <p className="p-4 text-center">
              {error ? error : 'Unable to load the options. Please try again.'}
            </p>
          ))}
      </PopoverContent>
    </Popover>
  );
};

export default SelectWithInputAndAddOption;
