import React from 'react';
import { THEME } from '../../constants/ColorConstants';

interface FolderIconProps {
  stroke?: string;
}

const FolderIcon: React.FC<FolderIconProps> = ({ stroke = THEME.SHADE_100 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
  >
    <path
      d="M13.3333 13.8333C13.6869 13.8333 14.026 13.6929 14.2761 13.4428C14.5261 13.1928 14.6666 12.8536 14.6666 12.5V5.83333C14.6666 5.47971 14.5261 5.14057 14.2761 4.89052C14.026 4.64048 13.6869 4.5 13.3333 4.5H8.06659C7.84359 4.50219 7.62362 4.44841 7.42679 4.34359C7.22995 4.23877 7.06256 4.08625 6.93992 3.9L6.39992 3.1C6.27851 2.91565 6.11323 2.76432 5.91892 2.6596C5.7246 2.55488 5.50732 2.50004 5.28658 2.5H2.66659C2.31296 2.5 1.97382 2.64048 1.72378 2.89052C1.47373 3.14057 1.33325 3.47971 1.33325 3.83333V12.5C1.33325 12.8536 1.47373 13.1928 1.72378 13.4428C1.97382 13.6929 2.31296 13.8333 2.66659 13.8333H13.3333Z"
      stroke={stroke}
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default FolderIcon;
