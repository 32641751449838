import React, { ReactNode, useEffect, useState } from 'react';
import { CrossIcon, DownArrowIcon } from '../../../assets/icons';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  Button,
  DialogFooter,
  Label,
  Slider,
  Checkbox,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  TooltipProvider,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../../UI';
import { DatePicker, notify } from '../../../components/common';
import DebouncedSearchInput from '../../../components/UI/DebouncedSearchInput';
import { searchPatient } from '../../../services/api/endPoints/patients';
import { getFullName } from '../../../utils/Helper';
import AutoComplete from '../../../components/UI/AutoComplete';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { ERROR_TITLE } from '../../../constants/NotificationConstants';
import { getLocationResources } from '../../../services/api/endPoints/resources';
import { createNewBooking } from '../../../services/api/endPoints/bookings';
import { InfoIcon } from 'lucide-react';
import { useDispatch } from 'react-redux';
import { addResourceBooking } from '../../../redux/slice/resourceBookingSlice';


const BookResourceModal: React.FC<Modal> = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null | string>(null);
  const [endDate, setEndDate] = useState<Date | null | string>(null);
  const [selectedPatient, setSelectedPatient] =
    useState<PatientInformation | null>(null);
  const [locationErrorMessage, setLocationErrorMessage] = useState<string>('');
  const staffs = useSelector<RootState, StaffInformation[]>(
    state => state.staffs.staffs
  );
  const user = useSelector<RootState, UserEntities>(state => state.user.user);
  const [selectedStaff, setSelectedStaff] = useState<StaffInformation>(
    user.staff
  );
  const [selectedService, setSelectedService] = useState<Option | null>(null);
  const services = useSelector<RootState, Service[]>(
    state => state.services.services
  );
  const [selectedLocation, setSelectedLocation] = useState<string | undefined>(
    ''
  );
  const locations = useSelector<RootState, Location[]>(
    state => state.locations.locations
  );
  const [resources, setResources] = useState<Resource[]>([]);
  const [selectedResource, setSelectedResource] = useState<string | null>(null);
  const [forceBooking, setForceBooking] = useState<boolean>(false);
  const [showForceBookingTooltip, setShowForceBookingTooltip] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleDateChange = (newDate: Date | null, type: 'start' | 'end') => {
    if (newDate) {
      if (type === 'start') {
        setStartDate(newDate);
      } else {
        setEndDate(newDate);
      }
    }
  };

  const onSearch = async (search: string): Promise<PatientLite[]> => {
    try {
      const res = await searchPatient(search);
      const data: PatientLite[] = res.data.map((row: PatientLite) => ({
        ...row,
        fullName: getFullName(row),
        phoneNo: `${row.phoneNo ? row.phoneNo : ''}`,
      }));
      return data;
    } catch (err) {
      notify.error({
        title: ERROR_TITLE.GENERIC_PATIENT_SEARCH,
      });
      return [];
    }
  };

  const mapToOption = <
    T extends { publicId: string; name: string; duration?: string },
  >(item: T | null): Option | null => {
    if (!item) {
      return null;
    }

    const option: Option = {
      label: item.name,
      value: item.publicId,
      duration: '',
    };

    if (item.duration) {
      option.duration = item.duration;
    }

    return option;
  };

  const fetchResources = async (locationId: string) => {
    setIsLoading(true);
    try {
      const [data, error] = await getLocationResources('', locationId);
      setIsLoading(false);

      if (error) {
        setLocationErrorMessage('Failed to fetch resources. Please try again.');
        setResources([]);
        return;
      }

      if (!data || !Array.isArray(data)) {
        setLocationErrorMessage('Received unexpected data format from server.');
        setResources([]);
        return;
      }

      setLocationErrorMessage('');
      const resourceInfo: Resource[] = data.map((resource: any) => ({
        publicId: resource.publicId,
        name: resource.name,
        description: resource.description || '',
        location: resource.location || null,
        createdBy: resource.createdBy || null,
        whenCreated: resource.whenCreated || null,
      }));
      setResources(resourceInfo);

      if (resourceInfo.length === 0) {
        setLocationErrorMessage('No resources found for the selected location');
      }
    } catch (err) {
      setIsLoading(false);
      setLocationErrorMessage(
        'An unexpected error occurred. Please try again.'
      );
      setResources([]);
    }
  };

  useEffect(() => {
    if (selectedLocation) {
      fetchResources(selectedLocation);
    }
  }, [selectedLocation]);

  const handleBooking = async () => {
    setIsLoading(true);

    const bookingData = {
      patientPublicId: selectedPatient?.publicId || '',
      staffPublicId: selectedStaff?.publicId || '',
      resourcePublicId: selectedResource || '',
      locationPublicId: selectedLocation || '',
      servicePublicId: selectedService?.value || '',
      appointmentTime: startDate,
      appointmentEndTime: endDate,
      forceBooking: forceBooking,
      autoBookResource: true,
    };

    const [response, error] = await createNewBooking(bookingData);
    setIsLoading(false);

    if (response) {
      dispatch(addResourceBooking(response));
      notify.success({ title: 'Booking Successful!' });
      onClose();
    } else {
      setErrorMessage(error || 'An unknown error occurred.');
    }
  };

  const setSelectedServiceOption = (values: Option[]) => {
    if (values.length > 0) {
      const selectedOption = values[0];
      const selectedService = services.find(
        service => service.publicId === selectedOption.value
      );

      if (selectedService && selectedService.duration && startDate) {
        // Calculate the end date based on the service duration
        const durationHours = parseInt(
          selectedService.duration.split(':')[0],
          10
        );
        const durationMinutes = parseInt(
          selectedService.duration.split(':')[1],
          10
        );

        const endDateObj = new Date(startDate);
        endDateObj.setHours(endDateObj.getHours() + durationHours);
        endDateObj.setMinutes(endDateObj.getMinutes() + durationMinutes);

        setEndDate(endDateObj);
      }

      setSelectedService(selectedOption);
    } else {
      setSelectedService(null);
    }
  };

  const getForceBookingTooltip = (trigger: ReactNode) => {
    return (
      <TooltipProvider>
        <Tooltip
          open={showForceBookingTooltip}
          onOpenChange={setShowForceBookingTooltip}
        >
          <TooltipTrigger asChild>{trigger}</TooltipTrigger>
          <TooltipContent>
            <p>
              Check this box to override availability and ensure the resource is
              booked regardless of conflicts.
            </p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  };

  return (
    <div>
      <Dialog open={open} onOpenChange={onClose}>
        <DialogContent className="p-0 tablet:w-[40rem] md:w-[40rem] flex flex-col gap-0 border border-neutral-100 max-h-[80%]">
          <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
            <h1 className="text-[14px] text-neutral-900 font-semibold">
              Book a resource
            </h1>
            <Button variant={'link'} size={'xmall'} onClick={onClose}>
              <CrossIcon />
            </Button>
          </DialogHeader>
          <Slider className="flex-1" variant={'vertical'}>
            <div className="p-[1rem]">
              <div>
                <DebouncedSearchInput
                  label={'Patient'}
                  onSearch={onSearch}
                  onSelect={option =>
                    setSelectedPatient(option as PatientInformation)
                  }
                />
              </div>
              <div className="pt-2">
                <Label className="pb-1">Service</Label>
                <AutoComplete
                  setSelectedValue={setSelectedServiceOption}
                  selectedValue={selectedService ? [selectedService] : []}
                  options={services
                    .map(mapToOption)
                    .filter((o): o is Option => o !== null)}
                  labelField="label"
                  valueField="value"
                  placeholder="Select Service"
                />
              </div>
              <div className="pt-2">
                <Label className="pb-1">Consultant</Label>
                <AutoComplete
                  setSelectedValue={values => setSelectedStaff(values[0] || {})}
                  selectedValue={[selectedStaff]}
                  options={staffs}
                  labelField={'fullName'}
                  valueField={'publicId'}
                  placeholder={'Select Staff'}
                />
              </div>
              <div className="pt-2">
                <Label className="pb-1">Location</Label>
                <Select
                  value={selectedLocation}
                  onValueChange={value => setSelectedLocation(value)}
                >
                  <SelectTrigger
                    className="w-full"
                    selectIcon={<DownArrowIcon />}
                  >
                    <SelectValue placeholder="Select Location" />
                  </SelectTrigger>
                  <SelectContent className="bg-shades-0 z-[999999]">
                    {locations.map(location => (
                      <SelectItem
                        key={location.publicId}
                        value={location.publicId}
                      >
                        {location.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {locationErrorMessage && (
                  <div className="mt-2 text-red-500 text-sm text-left">
                    {locationErrorMessage}
                  </div>
                )}
              </div>
              {resources.length > 0 && (
                <div className="mt-2">
                  <Label>Resource</Label>
                  <Select
                    onValueChange={value => {
                      setSelectedResource(value);
                    }}
                  >
                    <SelectTrigger
                      className="w-full"
                      selectIcon={<DownArrowIcon />}
                    >
                      <SelectValue placeholder="Select Resource" />
                    </SelectTrigger>
                    <SelectContent className="bg-shades-0 z-[999999]">
                      {resources.map(resource => (
                        <SelectItem
                          key={resource.publicId}
                          value={resource.publicId}
                        >
                          {resource.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              )}
              <div className="pt-2 pb-2 flex flex-col gap-2">
                <div>
                  <Label>Start Date & Time</Label>
                  <DatePicker
                    variant="dateTime"
                    handleDateChange={date => handleDateChange(date, 'start')}
                    initialValue={startDate}
                  />
                </div>
                <div>
                  <Label>End Date & Time</Label>
                  <DatePicker
                    initialValue={endDate}
                    variant="dateTime"
                    handleDateChange={date => handleDateChange(date, 'end')}
                  />
                </div>
              </div>
              <div className="pt-2 flex flex-col gap-4">
                <div className="flex flex-row items-center gap-[2.9rem]">
                  {getForceBookingTooltip(
                    <div className="flex flex-row items-center gap-2">
                      <Label>Force Booking</Label>
                      <InfoIcon
                        size="1rem"
                        onClick={() =>
                          setShowForceBookingTooltip(prev => !prev)
                        }
                      />
                    </div>
                  )}
                  <Checkbox
                    onCheckedChange={() => {
                      setForceBooking(prev => !prev);
                    }}
                    checked={forceBooking}
                  />
                </div>
              </div>
              {errorMessage && (
                <div className="mt-2 text-red-500 text-sm text-left">{errorMessage}</div>
              )}
            </div>
          </Slider>
          <DialogFooter className="flex justify-end gap-2 p-4">
            <Button
              onClick={handleBooking}
              variant={'primary'}
              disabled={isLoading || !startDate || !endDate || !selectedPatient || !selectedService || !selectedStaff || !selectedLocation || !selectedResource}
              isLoading={isLoading}
            >
              {isLoading ? 'Booking...' : 'Book'}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default BookResourceModal;
