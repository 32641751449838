import { URL } from '../requestConstructor';

export const getAllTreatmentCyclesByPatientPublicId = async (
  patientPublicId: string
) => {
  try {
    const response = await URL.get(
      `/treatmentCycle/get?patientPublicId=${patientPublicId}`
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, error?.response];
  }
};

export const getTreatmentCycle = async (treatmentCyclePublicId: string) => {
  try {
    const response = await URL.get(
      `/treatmentCycle/get?publicId=${treatmentCyclePublicId}`
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, error?.response];
  }
};
export const createTreatmentCycle = async (data: any) => {
  try {
    const response = await URL.post('/treatmentCycle/create', data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error?.response];
  }
};

export const updateTreatmentCycle = async (data: any) => {
  try {
    const response = await URL.put('/treatmentCycle/update', data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error?.response];
  }
};

export const deleteTreatmentCycle = async (treatmentCyclePublicId: any) => {
  try {
    const response = await URL.delete(
      `/treatmentCycle/delete?publicId=${treatmentCyclePublicId}`
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, error?.response];
  }
};

export const cancelTreatmentCycle = async (treatmentCyclePublicId: string) => {
  try {
    const response = await URL.put(
      `/treatmentCycle/cancel?publicId=${treatmentCyclePublicId}`
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, error?.response];
  }
};

export const restartTreatmentCycle = async (treatmentCyclePublicId: string) => {
  try {
    const response = await URL.put(
      `/treatmentCycle/restart?publicId=${treatmentCyclePublicId}`
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, error?.response];
  }
};

export const getAllDonors = async () => {
  try {
    const response = await URL.get('/treatmentCycle/donor/get');
    return [response.data, null];
  } catch (error: any) {
    return [null, error?.response];
  }
};
