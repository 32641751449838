import React from 'react';

type GentlemanIconProps = {
  stroke?: string;
};

const GentlemanIcon: React.FC<GentlemanIconProps> = ({ stroke = "currentColor" }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 13.9447C2.00009 13.0394 2.22304 12.1469 2.65074 11.3397C3.07844 10.5326 3.6988 9.83363 4.46136 9.29969C5.22393 8.76575 6.10718 8.41192 7.03927 8.26695C7.97136 8.12199 8.79495 8.12179 9.82549 8.46545C10.953 8.84143 11.75 9.63136 12.3125 10.1694C12.875 10.7074 13.1087 11.096 13.4375 11.7867C13.8136 12.5766 14 13.9438 14 13.9438M7.99999 8.19065L6.14894 13.8667C6.13472 13.9121 6.15313 13.9611 6.19429 13.9875L7.87519 15C7.91298 15.0242 7.96221 15.0242 8 15L9.43073 13.9875C9.47189 13.9611 9.4903 13.9121 9.47608 13.8667L7.99999 8.19065ZM7.99999 8.19065C10.0711 8.19065 11.75 6.58097 11.75 4.59533C11.75 2.60968 10.0711 1 7.99999 1C5.92892 1 4.25 2.60968 4.25 4.59533C4.25 6.58097 5.92892 8.19065 7.99999 8.19065Z"
      stroke={stroke}
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default GentlemanIcon;
