/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable quotes */
import { ColumnDef } from '@tanstack/react-table';
import React, { useEffect, useState } from 'react';
import {
  DirectedArrowIcon,
  SearchIcon,
  DownloadIcon,
  OutlinedAddIcon,
  Dot,
} from '../../../../../assets/icons';
import { Button, Input, Slider } from '../../../../UI';
import { useNavigate } from 'react-router-dom';
import { getFullName } from '../../../../../utils/Helper';
import { getReadableDate } from '../../../../../utils/date.utl';
import { ROUTES } from '../../../../../constants/NavigationConstants';
import { Eye } from 'lucide-react';
import { getAllTDLTestRequests } from '../../../../../services/api/endPoints/labs';
import { DataTable } from '../../../../../components/common';
import { useResultDownload, useDownloadFile } from '../../../../../hooks';
import { RESULT_STATUS } from '../../../../../constants/constants';
import LabsFilter from './LabsFilter';
import { DateRange } from 'react-day-picker';
import { notify } from '../../../../../components/common';
import { THEME } from '../../../../../constants/ColorConstants';

const RequestedTests = () => {
  const navigate = useNavigate();
  const { downloadFile } = useDownloadFile();
  const { downloadTDLResult } = useResultDownload();
  const [dateRange, setDateRange] = useState<DateRange>({
    from: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000), // Subtract 30 days in milliseconds
    to: new Date(),
  });

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>('');
  const [, setValidationError] = useState<string>('');
  const [requestedTests, setRequestedTests] = useState<
    TDLRequestedTestResponse[]
  >([]);
  const [filteredData, setFilteredData] = useState<TDLRequestedTestResponse[]>(
    []
  );
  const [btnLoading, setBtnLoading] = useState<Map<string, boolean>>(new Map());

  const toggleBtnLoading = (id: string, loading: boolean) => {
    const formBtnLoading = new Map(btnLoading);
    formBtnLoading.set(id, loading);
    setBtnLoading(formBtnLoading);
  };

  const patientColumn: ColumnDef<TDLRequestedTestResponse>[] = [
    {
      accessorKey: 'FullName',
      header: ({ column }) => {
        return (
          <button
            className="flex gap-1 items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Patient Name
            {column.getIsSorted() === 'asc' ? (
              <div className="rotate-180">
                <DirectedArrowIcon fill={THEME.NEUTRAL_500} />{' '}
              </div>
            ) : (
              <DirectedArrowIcon fill={THEME.NEUTRAL_500} />
            )}
          </button>
        );
      },
      cell: ({ row }) => {
        return (
          // <Link to={`${ROUTES.LABS}/${row.original.publicId}`}>
          row.original.FullName
          // </Link>
        );
      },
    },

    {
      accessorKey: 'whenCreated',
      header: ({ column }) => {
        return (
          <button
            className="flex gap-1 items-center"
            onClick={() =>
              column.toggleSorting(column.getIsSorted() === 'desc')
            }
          >
            Requested Date
            {column.getIsSorted() === 'desc' ? (
              <div className="rotate-180">
                <DirectedArrowIcon fill="#808080" />{' '}
              </div>
            ) : (
              <DirectedArrowIcon fill="#808080" />
            )}
          </button>
        );
      },
      cell: ({ row }) => {
        return getReadableDate(row.original.whenCreated);
      },
    },
    {
      accessorKey: 'RequestedTests',
      id: 'RequestedTests',
      header: 'test Name',
      cell: ({ row }) => {
        return (
          <div className="flex flex-wrap gap-1 min-w-[150px] max-w-[250px] ">
            {row.original.RequestedTests?.map((test, index) => (
              <p
                key={index}
                className="px-2 py-1 rounded border border-neutral-500 text-neutral-600"
              >
                {test}
              </p>
            ))}
          </div>
        );
      },
    },
    {
      accessorKey: 'staff.fullName',
      header: 'Created By',
    },
    {
      accessorKey: 'ResultStatus',
      header: 'Result Status',

      cell: ({ row }) => {
        return (
          <p
            className={
              'py-1 px-2 flex items-center gap-1 rounded border w-[fit-content] ' +
              (row.original.ResultStatus === RESULT_STATUS.PENDING
                ? 'border-warning-500 bg-warning-50'
                : 'border-success-500 bg-success-50')
            }
          >
            <Dot
              fill={
                row.original.ResultStatus === RESULT_STATUS.PENDING
                  ? THEME.WARNING_500
                  : THEME.SUCCESS_500
              }
            />
            <span>
              {row.original.ResultStatus === RESULT_STATUS.PENDING
                ? RESULT_STATUS.PENDING
                : RESULT_STATUS.RESULT_AVAILABLE}
            </span>
          </p>
        );
      },
    },
    {
      id: 'actions',
      cell: ({ row }) => {
        return (
          <div className="flex justify-end">
            <div className="flex gap-2">
              <div className="flex-1">
                <Button
                  disabled={row.original.ResultStatus === RESULT_STATUS.PENDING}
                  className="h-[30px] w-full"
                  onClick={() => {
                    navigate('view-result', {
                      state: { selectedTest: row.original },
                    });
                  }}
                  trailingIcon={<Eye height={16} width={16} />}
                >
                  View
                </Button>
              </div>
              <div className="flex-1">
                {row.original.ResultStatus === RESULT_STATUS.PENDING ? (
                  <Button
                    className="h-[30px] w-full"
                    onClick={() => handleDownloadForm(row.original)}
                    disabled={
                      !row.original.formUrl ||
                      btnLoading.get('form_' + row.original.publicId)
                    }
                    trailingIcon={<DownloadIcon />}
                    isLoading={btnLoading.get('form_' + row.original.publicId)}
                  >
                    &nbsp;Form&nbsp;
                  </Button>
                ) : (
                  <Button
                    className="h-[30px] w-full"
                    onClick={() => handleDownloadResult(row.original)}
                    disabled={
                      !row.original.formUrl ||
                      btnLoading.get('result_' + row.original.publicId)
                    }
                    trailingIcon={<DownloadIcon />}
                    isLoading={btnLoading.get(
                      'result_' + row.original.publicId
                    )}
                  >
                    Result
                  </Button>
                )}
              </div>
              {/* <div className="flex-1">
                <Button
                  className="h-[30px] w-full"
                  disabled={row.original.ResultStatus === RESULT_STATUS.PENDING}
                  trailingIcon={<ForwardIcon height={16} width={16} />}
                >
                  Send
                </Button>
              </div> */}
            </div>
          </div>
        );
      },
    },
  ];

  const handleDownloadResult = async (row: TDLRequestedTestResponse) => {
    toggleBtnLoading('result_' + row.publicId, true);
    await downloadTDLResult(row);
    toggleBtnLoading('result_' + row.publicId, false);
  };

  const handleSearch = () => {
    const filtered = requestedTests.filter((row: TDLRequestedTestResponse) =>
      Object.values(row).some((value: any) =>
        String(value).toLowerCase().includes(search.toLowerCase())
      )
    );
    setFilteredData(filtered);
  };

  const handleDownloadForm = async (row: TDLRequestedTestResponse) => {
    toggleBtnLoading('form_' + row.publicId, true);
    await downloadFile(row.formUrl);
    toggleBtnLoading('form_' + row.publicId, false);
  };

  const handleButtonClick = () => {
    navigate(ROUTES.LABS.CREATE);
  };

  const getAllTests = async () => {
    setIsLoading(true);

    const fromDate = dateRange?.from;
    let toDate = new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000);
    if (dateRange && dateRange.to) {
      toDate = new Date(dateRange.to.getTime() + 2 * 24 * 60 * 60 * 1000);
    }

    const [response, error] = await getAllTDLTestRequests({
      fromDate: fromDate?.toISOString().split('T')[0],
      toDate: toDate.toISOString().split('T')[0],
    });

    if (response) {
      setRequestedTests(
        response.map((test: TDLRequestedTestResponse) => {
          return {
            ...test,
            FullName: getFullName(test.patient),
            RequestedTests: test.testCode.split(','),
            ResultStatus:
              (test.testResult?.Tests?.length || 0) > 0
                ? RESULT_STATUS.RESULT_AVAILABLE
                : RESULT_STATUS.PENDING,
          };
        })
      );
    } else {
      notify.error({
        title: 'Failed to get requested tests.',
        message: 'Please try again after some time',
      });
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (dateRange) getAllTests();
  }, []);

  useEffect(() => {
    setFilteredData(requestedTests);
  }, [requestedTests]);
  return (
    <>
      <div className="custom-height w-full rounded-md border border-neutral-100 flex flex-col">
        <div className="flex-none px-[12px] py-[16px] w-full flex justify-between items-center ">
          <Input
            leadingIcon={<SearchIcon />}
            className="w-[400px] focus:border-neutral-100"
            placeholder="Search with patient, tests, staff"
            value={search}
            onChange={e => {
              setSearch(e.target.value);
              if (e.target.value.length < 3) {
                setValidationError('Search must be at least 3 characters long');
              } else {
                setValidationError('');
              }
              handleSearch();
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
          />
          <div className="flex gap-3 items-center justify-center">
            <LabsFilter
              setDateRange={setDateRange}
              dateRange={dateRange}
              returnFunction={() => {
                getAllTests();
              }}
            />
            <Button
              variant={'secondary'}
              trailingIcon={<OutlinedAddIcon stroke={THEME.PRIMARY_600} />}
              onClick={handleButtonClick}
            >
              Book a test
            </Button>
          </div>
        </div>
        <Slider variant={'vertical'} className="flex-auto">
          <DataTable
            columns={patientColumn}
            data={filteredData}
            tableBodyRowClass={'border-b'}
            isLoading={isLoading}
            tableOptions={{
              isSortable: true,
              sortOrder: 'desc',
              sortOnKey: 'whenCreated',
            }}
          />
        </Slider>
      </div>
    </>
  );
};

export default RequestedTests;
