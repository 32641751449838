import { Edit2 } from 'lucide-react';
import {
  Button,
  Input,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../components/UI';
import React, { useEffect, useState } from 'react';
import { DownArrowIcon } from '../assets/icons';
import {
  // TextEditor,
  notify,
} from '../components/common';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { getFullName } from '../utils/Helper';
import { getClient, updateClient } from '../services/api/endPoints/client';
import {
  // downloadFileByUrl,
  uploadFile,
} from '../services/api/endPoints/files.api';
import { LogoInput, ThemeInput } from '../components/sections';

const Settings = () => {
  const [data, setData] = useState<ClientFormData>({
    publicId: '',
    name: '',
    smallLogoUrl: '',
    largeLogoUrl: '',
    pdfHeaderFormatUrl: '',
    pdfFooterFormatUrl: '',
    headStaffPublicId: '',
    superadminPublicId: '',
    mainLocationPublicId: '',
    primaryColor: '',
    secondaryColor: '',
    tertiaryColor: '',
  });
  // const [pdfHeaderValue, setPdfHeaderValue] = useState<string>('');
  // const [pdfFooterValue, setPdfFooterValue] = useState<string>('');
  const [primaryLogoBlob, setPrimaryLogoBlob] = useState<File>();
  const [secondaryLogoBlob, setSecondaryLogoBlob] = useState<File>();
  const staffs = useSelector<RootState, StaffInformation[]>(
    state => state.staffs.staffs
  );
  const { locations } = useSelector<RootState, LocationState>(
    state => state.locations
  );
  const [enableNameEditMode, setEnableNameEditMode] = useState<boolean>(false);
  // const [isPDFFooterChanged,setIsPDFFooterChanged] = useState<boolean>(false);
  // const [isPDFHeaderChanged,setIsPDFHeaderChanged] = useState<boolean>(false);
  const [hasChangedAnything, setHasChangedAnything] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const COLOR_OPTIONS = [
    {
      label: 'Brand primary',
      color: 'primaryColor',
    },
    {
      label: 'Brand secondary',
      color: 'secondaryColor',
    },
    {
      label: 'Brand tertiary',
      color: 'tertiaryColor',
    },
  ];
  // async function readBlobAsText(blob: Blob) {
  //   try {
  //     const text = await blob.text();
  //     return text;
  //   } catch (error) {
  //     console.error('Error reading blob as text:', error);
  //   }
  // }
  useEffect(() => {
    const loadData = async () => {
      const [res, error] = await getClient();
      if (res) {
        const formData: ClientFormData = {
          publicId: res.publicId!,
          name: res.name ? res.name : '',
          smallLogoUrl: res.smallLogoUrl ? res.smallLogoUrl : '',
          largeLogoUrl: res.largeLogoUrl ? res.largeLogoUrl : '',
          pdfHeaderFormatUrl: res.pdfHeaderFormatUrl
            ? res.pdfHeaderFormatUrl
            : '',
          pdfFooterFormatUrl: res.pdfFooterFormatUrl
            ? res.pdfFooterFormatUrl
            : '',
          headStaffPublicId: res.headStaff ? res.headStaff.publicId : '',
          superadminPublicId: res.superadmin ? res.superadmin.publicId : '',
          mainLocationPublicId: res.mainLocation
            ? res.mainLocation.publicIc
            : '',
          primaryColor: res.primaryColor,
          secondaryColor: res.secondaryColor,
          tertiaryColor: res.tertiaryColor,
        };
        // if (formData.pdfFooterFormatUrl !== '') {
        //   const [res] = await downloadFileByUrl({
        //     url: formData.pdfFooterFormatUrl,
        //   });
        //   if (res) {
        //     const text = await readBlobAsText(res);
        //     setPdfFooterValue(text ? text : '');
        //   }
        // }
        // if (formData.pdfHeaderFormatUrl !== '') {
        //   const [res] = await downloadFileByUrl({
        //     url: formData.pdfHeaderFormatUrl,
        //   });
        //   if (res) {
        //     const text = await readBlobAsText(res);
        //     setPdfHeaderValue(text ? text : '');
        //   }
        // }
        setData(formData);
      } else {
        notify.error({
          title: 'Failed to get the client information',
          message: error.data,
        });
      }
    };
    loadData();
  }, []);
  const nameEditModeHandler = () => {
    setEnableNameEditMode(prev => !prev);
  };
  // const handleHeaderChange = (value: string) => {
  //   if (!hasChangedAnything) setHasChangedAnything(true);
  //   if (!isPDFHeaderChanged) setIsPDFHeaderChanged(true);
  //   setPdfHeaderValue(value);
  // };
  // const handleFooterChange = (value: string) => {
  //   if (!hasChangedAnything) setHasChangedAnything(true);
  //   if (!isPDFFooterChanged) setIsPDFFooterChanged(true);
  //   setPdfFooterValue(value);
  // };
  const inputHandler = (key: keyof ClientFormData, value: string) => {
    if (!hasChangedAnything) setHasChangedAnything(true);
    setData(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const onPrimaryLogoChange = (file: File) => {
    if (!hasChangedAnything) setHasChangedAnything(true);
    setPrimaryLogoBlob(file);
  };

  const onSecondaryLogoChange = (file: File) => {
    if (!hasChangedAnything) setHasChangedAnything(true);

    setSecondaryLogoBlob(file);
  };

  const saveHandler = async () => {
    setIsSaving(true);
    const uploadData = {
      ...data,
    };
    // let primaryLogoUrl = ''
    if (primaryLogoBlob) {
      const formData = new FormData();
      formData.append('containerName', 'static');
      formData.append(
        'fileName',
        `primary-logo.${primaryLogoBlob.name.split('.').pop()}`
      );
      formData.append('file', primaryLogoBlob);
      const [res, error] = await uploadFile(formData);
      if (res) {
        uploadData.largeLogoUrl = res;
      } else {
        notify.error({
          title: 'Failed to upload primary logo',
          message: error.data,
        });
      }
    }
    // let secondaryLogoUrl = ''
    if (secondaryLogoBlob) {
      const formData = new FormData();
      formData.append('containerName', 'static');
      formData.append(
        'fileName',
        `secondary-logo.${secondaryLogoBlob.name.split('.').pop()}`
      );
      formData.append('file', secondaryLogoBlob);
      const [res, error] = await uploadFile(formData);
      if (res) {
        uploadData.smallLogoUrl = res;
      } else {
        notify.error({
          title: 'Failed to upload secondary logo',
          message: error.data,
        });
      }
    }
    // if (isPDFHeaderChanged) {
    //   const headerFile = new Blob([pdfHeaderValue], { type: 'text/html' });
    //   const formData = new FormData();
    //   formData.append('containerName', 'pdf-formats');
    //   formData.append('fileName', 'header.html');
    //   formData.append('file', headerFile);
    //   const [res, error] = await uploadFile(formData);
    //   if (res) {
    //     uploadData.pdfHeaderFormatUrl = res;
    //   } else {
    //     notify.error({
    //       title: 'Failed to upload PDF header',
    //       message: error.data,
    //     });
    //   }
    // }
    // if (isPDFFooterChanged) {
    //   const headerFile = new Blob([pdfFooterValue], { type: 'text/html' });
    //   const formData = new FormData();
    //   formData.append('containerName', 'pdf-formats');
    //   formData.append('fileName', 'footer.html');
    //   formData.append('file', headerFile);
    //   const [res, error] = await uploadFile(formData);
    //   if (res) {
    //     uploadData.pdfFooterFormatUrl = res;
    //   } else {
    //     notify.error({
    //       title: 'Failed to upload PDF footer',
    //       message: error.data,
    //     });
    //   }
    // }
    const [res, error] = await updateClient(uploadData);
    if (res) {
      notify.success({
        title: 'Settings updated successfully',
      });
    } else {
      notify.error({
        title: 'Failed to update settings',
        message: error.data,
      });
    }
    setEnableNameEditMode(false);
    setIsSaving(false);
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <p className="text-[16px] font-semibold text-neutral-900">Name</p>
          {!enableNameEditMode && (
            <p className="text-[14px] text-neutral-600">{data.name}</p>
          )}
        </div>
        <div className="flex items-center gap-2">
          {enableNameEditMode && (
            <Input
              value={data.name}
              placeholder="Enter name"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                inputHandler('name', event.target.value)
              }
              inputSize={'small'}
            />
          )}
          {!enableNameEditMode && (
            <Button
              size={'small'}
              variant={'outlined'}
              onClick={nameEditModeHandler}
            >
              <Edit2 size={15} />
            </Button>
          )}
        </div>
      </div>
      <div className="mt-[20px] mb-[20px] h-[1px] w-full bg-neutral-100" />
      <div className="space-y-3">
        <div className="space-y-1">
          <p className="font-semibold text-[16px]">Theme</p>
          <p className="text-[14px] text-neutral-500 w-[50%]">
            Personalize your visual experience by selecting your preferred theme
            settings, and uploading logos for a customized look and feel.
          </p>
        </div>
        <div className="space-y-2">
          {COLOR_OPTIONS.map((brandColor, index) => (
            <ThemeInput
              key={`${index}-${brandColor.label}/${brandColor.color}`}
              value={data[brandColor.color as keyof ClientFormData]}
              label={brandColor.label}
              inputHandler={(value: string) =>
                inputHandler(brandColor.color as keyof ClientFormData, value)
              }
            />
          ))}
        </div>
        <div className="flex gap-3">
          <LogoInput
            logoHeading="Primary logo"
            logoHeadingDescription="A logo which is wordmark + logomark suitable for the sidebar
              section of the dashboard"
            initialLogoUrl={data.largeLogoUrl}
            onLogoAdd={onPrimaryLogoChange}
          />
          <LogoInput
            logoHeading="Secondary logo"
            logoHeadingDescription="A logo which is wordmark + logomark suitable for the sidebar
              section of the dashboard"
            initialLogoUrl={data.smallLogoUrl}
            onLogoAdd={onSecondaryLogoChange}
          />
        </div>
      </div>

      <div className="mt-[40px] mb-[40px] h-[1px] w-full bg-neutral-100" />

      {/* <div className="space-y-3">
        <div className="space-y-1">
          <p className="text-[16px] font-semibold text-neutral-900">
            Header and footer
          </p>
          <p className="text-[14px] text-neutral-500">
            Add header and footer for your pdf docs
          </p>
        </div>
        <div className="flex flex-col gap-5">
          <div className="space-y-1">
            <p className="text-[16px] font-semibold text-neutral-900">
              PDF header
            </p>

            <TextEditor
              value={pdfHeaderValue}
              onChange={handleHeaderChange}
              placeholder="Enter your text here..."
            />
          </div>
          <div className="space-y-1">
            <p className="text-[16px] font-semibold text-neutral-900">
              PDF footer
            </p>
            <TextEditor
              value={pdfFooterValue}
              onChange={handleFooterChange}
              placeholder="Enter your text here..."
            />
          </div>
        </div>
      </div> */}

      <div className="mt-[40px] mb-[40px] h-[1px] w-full bg-neutral-100" />

      <div className="grid grid-cols-2 gap-3">
        <div className="space-y-1">
          <Label htmlFor="headStaff">Head staff</Label>
          <Select
            required
            name="headStaff"
            onValueChange={value => inputHandler('headStaffPublicId', value)}
            value={data.headStaffPublicId}
          >
            <SelectTrigger className="w-full" selectIcon={<DownArrowIcon />}>
              <SelectValue placeholder="Select" />
            </SelectTrigger>
            <SelectContent className="bg-shades-0 z-[999999]">
              {staffs.map(staff => (
                <SelectItem key={staff.publicId} value={staff.publicId}>
                  {getFullName(staff)}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="space-y-1">
          <Label htmlFor="superadmin">Super admin</Label>
          <Select
            required
            name="superadmin"
            onValueChange={value => inputHandler('superadminPublicId', value)}
            value={data.superadminPublicId}
          >
            <SelectTrigger className="w-full" selectIcon={<DownArrowIcon />}>
              <SelectValue placeholder="Select" />
            </SelectTrigger>
            <SelectContent className="bg-shades-0 z-[999999]">
              {staffs
                .filter(staff => staff.type === 'Admin')
                .map(staff => (
                  <SelectItem key={staff.publicId} value={staff.publicId}>
                    {getFullName(staff)}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
        </div>
        <div className="space-y-1">
          <Label htmlFor="Location">Location</Label>
          <Select
            required
            name="Location"
            onValueChange={value => inputHandler('mainLocationPublicId', value)}
            value={data.mainLocationPublicId}
          >
            <SelectTrigger className="w-full" selectIcon={<DownArrowIcon />}>
              <SelectValue placeholder="Select" />
            </SelectTrigger>
            <SelectContent className="bg-shades-0 z-[999999]">
              {locations.map(location => (
                <SelectItem key={location.publicId} value={location.publicId}>
                  {location.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>

      <div className="mt-[50px] mb-[40px] h-[1px] w-full bg-neutral-100" />
      <div className="w-full flex justify-end items-center mb-[30px]">
        <Button
          disabled={isSaving || !hasChangedAnything}
          isLoading={isSaving}
          size={'medium'}
          onClick={saveHandler}
          variant={'primary'}
          className="w-[108px]"
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default Settings;
