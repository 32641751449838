import React from 'react';
import './LibraryCalendar.css';

interface CustomEventProps {
  event: CustomEvent;
}

const CustomEvent: React.FC<CustomEventProps> = ({ event }) => {
  const eventStyle = event.cancelled ? { textDecoration: 'line-through' } : {};
  const isBlocked = event.blocked;

  return (
    <div>
      <span style={eventStyle}>
        {isBlocked && <span style={{ fontWeight: 700 }}>Blocked Time - </span>}
        {event.title}
      </span>
    </div>
  );
};

export default CustomEvent;
