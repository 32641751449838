import { Loader } from '../../../../components/UI';
import { downloadFileByUrl } from '../../../../services/api/endPoints/files.api';
import { Upload } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';

const LogoInput = ({
  initialLogoUrl,
  logoHeading,
  logoHeadingDescription,
  onLogoAdd,
}: LogoInputProps) => {
  const logoInputRef = useRef<HTMLInputElement>(null);
  const [logoDisplayBase64url, setLogoDisplayBase64url] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const logoUploadHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoDisplayBase64url(reader.result as string);
      };
      reader.readAsDataURL(selectedFile);
      onLogoAdd(selectedFile);
    }
  };
  useEffect(() => {
    const getLogo = async () => {
      if (initialLogoUrl !== '') {
        setIsLoading(true);
        const [res] = await downloadFileByUrl({
          url: initialLogoUrl,
        });
        if (res) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setLogoDisplayBase64url(reader.result as string);
          };
          reader.readAsDataURL(res);
        }
      }
      setIsLoading(false);
    };
    getLogo();
  }, [initialLogoUrl]);
  return (
    <div className="space-y-2 w-full">
      <p className="font-semibold text-[16px]">{logoHeading}</p>
      <p className="text-[14px] text-neutral-500">{logoHeadingDescription}</p>
      <div className="w-full h-[250px] rounded-lg flex flex-col border border-neutral-100">
        <div className="flex-1 flex justify-center items-center overflow-x-auto">
          {isLoading ? (
            <Loader />
          ) : logoDisplayBase64url ? (
            <img
              src={logoDisplayBase64url}
              alt="primary-logo"
              className="w-[200px]"
            />
          ) : (
            <p>No primary logo uploaded</p>
          )}
        </div>
        <div
          className="border-t border-neutral-100 px-[2.5] py-3 flex justify-center items-center cursor-pointer gap-2"
          onClick={() => logoInputRef?.current?.click()}
        >
          <input
            type="file"
            accept="image/*"
            hidden
            ref={logoInputRef}
            onChange={logoUploadHandler}
          />
          <p className="text-[14px] font-semibold">Change</p>
          <Upload size={15} />
        </div>
      </div>
    </div>
  );
};

export default LogoInput;
