/* eslint-disable @typescript-eslint/no-explicit-any */
import { URL } from '../requestConstructor';

export const getAllStaff = async () => {
  try {
    const response = await URL.get('/staff/get/all');
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const getStaff = async (publicId: string) => {
  try {
    const response = await URL.get(`/staff/get?publicId=${publicId}`);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const deleteStaff = async (id: string) => {
  try {
    const response = await URL.delete(`/staff/delete?publicId=${id}`);
    return [response, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const createStaff = async (staffData: any) => {
  try {
    const response = await URL.post('/staff/create', staffData);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const updateStaff = async (data: any) => {
  try {
    const response = await URL.put('/staff/update', data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const getStaffDetailsById = async (id: string) => {
  try {
    const response = await URL.get(`/staff/get?publicId=${id}`);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};
