import { ColumnDef } from '@tanstack/react-table';
import {
  Dot,
  DownloadIcon,
  OpenEyeIcon2,
  OutlinedAddIcon,
} from '../../../../../assets/icons';
import { Button, Slider, buttonVariants } from '../../../../UI';
import React, { useEffect, useState } from 'react';
import { DataTable, notify } from '../../../../common';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getAllTDLTestRequests } from '../../../../../services/api/endPoints/labs';
import { capitalizeFirstLetter } from '../../../../../utils/data.helper';
import { getReadableDate } from '../../../../../utils/date.utl';
import { useDownloadFile, useResultDownload } from '../../../../../hooks';
import { RESULT_STATUS } from '../../../../../constants/constants';
import { THEME } from '../../../../../constants/ColorConstants';

const Labs = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [btnLoading, setBtnLoading] = useState<Map<string, boolean>>(new Map());
  const { downloadFile } = useDownloadFile();
  const { downloadTDLResult } = useResultDownload();
  const [requestedTests, setRequestedTests] = useState<
    TDLRequestedTestResponse[]
  >([]);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (id) {
        setIsLoading(true);
        const [response] = await getAllTDLTestRequests({
          patientPublicId: id,
        });
        if (isMounted) {
          if (response) {
            setRequestedTests(
              response.map((test: any) => ({
                ...test,
                FullName: `${capitalizeFirstLetter(
                  test.patient.title ?? ''
                )} ${capitalizeFirstLetter(
                  test.patient.firstName ?? ''
                )} ${capitalizeFirstLetter(test.patient.lastName ?? '')}`,
                RequestedTests: test.testCode.split(','),
                ResultStatus:
                  (test.testResult?.Tests?.length || 0) > 0
                    ? RESULT_STATUS.RESULT_AVAILABLE
                    : RESULT_STATUS.PENDING,
              }))
            );
          } else {
            notify.error({
              title: 'Failed to get requested tests.',
              message: 'Please try again after some time',
            });
          }
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [id]);

  const toggleBtnLoading = (id: string, loading: boolean) => {
    const formBtnLoading = new Map(btnLoading);
    formBtnLoading.set(id, loading);
    setBtnLoading(formBtnLoading);
  };

  const handleDownload = async (row: TDLRequestedTestResponse) => {
    toggleBtnLoading('form_' + row.publicId, true);
    await downloadFile(row.formUrl);
    toggleBtnLoading('form_' + row.publicId, false);
  };

  const handleDownloadResult = async (row: TDLRequestedTestResponse) => {
    toggleBtnLoading('result_' + row.publicId, true);
    await downloadTDLResult(row);
    toggleBtnLoading('result_' + row.publicId, false);
  };

  const labsColumnDefn: ColumnDef<TDLRequestedTestResponse>[] = [
    {
      accessorKey: 'whenCreated',
      header: 'Date Created',
      cell: ({ row }) => {
        return (
          <div className="flex flex-wrap gap-1 min-w-[150px] max-w-[250px]">
            {getReadableDate(row.original.whenCreated)}
          </div>
        );
      },
    },
    {
      accessorKey: 'RequestedTests',
      id: 'RequestedTests',
      header: 'test Name',
      cell: ({ row }) => {
        return (
          <div className="flex flex-wrap gap-1 min-w-[150px] max-w-[250px]">
            {row.original.RequestedTests?.map((test, index) => (
              <p
                key={index}
                className="px-2 py-1 rounded border border-neutral-500 text-neutral-600"
              >
                {test}
              </p>
            ))}
          </div>
        );
      },
    },
    {
      accessorKey: 'staff.fullName',
      header: 'Created By',
    },
    {
      accessorKey: 'ResultStatus',
      header: 'Result Status',

      cell: ({ row }) => {
        return (
          <p
            className={
              'py-1 px-2 flex items-center gap-1 rounded border w-[fit-content] ' +
              (row.original.ResultStatus === RESULT_STATUS.PENDING
                ? 'border-warning-500 bg-warning-50'
                : 'border-success-500 bg-success-50')
            }
          >
            <Dot
              fill={
                row.original.ResultStatus === RESULT_STATUS.PENDING
                  ? THEME.WARNING_500
                  : THEME.SUCCESS_500
              }
            />
            <span>
              {row.original.ResultStatus === RESULT_STATUS.PENDING
                ? RESULT_STATUS.PENDING
                : RESULT_STATUS.RESULT_AVAILABLE}
            </span>
          </p>
        );
      },
    },
    {
      id: 'actions',
      cell: ({ row }) => {
        return (
          <div className="flex justify-end">
            <div className="flex gap-8">
              <div className="flex gap-2">
                <Button
                  disabled={row.original.ResultStatus === RESULT_STATUS.PENDING}
                  className="h-[30px]"
                  onClick={() => {
                    navigate('view-result', {
                      state: { selectedTest: row.original },
                    });
                  }}
                  trailingIcon={<OpenEyeIcon2 />}
                >
                  View
                </Button>
                {row.original.ResultStatus === RESULT_STATUS.PENDING ? (
                  <Button
                    className="h-[30px]"
                    onClick={() => handleDownload(row.original)}
                    disabled={
                      !row.original.formUrl ||
                      btnLoading.get('form_' + row.original.publicId)
                    }
                    trailingIcon={<DownloadIcon />}
                    isLoading={btnLoading.get('form_' + row.original.publicId)}
                  >
                    &nbsp;Form&nbsp;
                  </Button>
                ) : (
                  <Button
                    className="h-[30px]"
                    onClick={() => handleDownloadResult(row.original)}
                    disabled={
                      !row.original.formUrl ||
                      btnLoading.get('result_' + row.original.publicId)
                    }
                    trailingIcon={<DownloadIcon />}
                    isLoading={btnLoading.get(
                      'result_' + row.original.publicId
                    )}
                  >
                    Result
                  </Button>
                )}
                {/* <Button
                  className="h-[30px]"
                  disabled={row.original.ResultStatus === RESULT_STATUS.PENDING}
                  trailingIcon={<SendIcon />}
                >
                  Send
                </Button> */}
              </div>
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div>
      <div className="p-3 flex justify-between items-center">
        <h1 className="text-neutral-900 font-medium whitespace-nowrap">Labs</h1>
        <div className="flex gap-3">
          {/* <Button
            className="inline-flex"
            variant={'link'}
            trailingIcon={<FilterIcon />}
          >
            Filter by
          </Button> */}
          {/* <LabsFilter
            filters={[]}
            setAllFilters={undefined}
            initialData={[]}
            returnFunction={function (
              data: SelectedDatesAppointment,
              value: boolean
            ): void {
              throw new Error('Function not implemented.');
            }}
          /> */}
          <Link
            className={buttonVariants({ variant: 'primary', size: 'xmall' })}
            to="add-test"
          >
            Book a Test <OutlinedAddIcon />
          </Link>
        </div>
      </div>
      <Slider variant={'vertical'} className="h-[500px]">
        <DataTable
          columns={labsColumnDefn}
          data={requestedTests}
          isLoading={isLoading}
          tableOptions={{
            isSortable: true,
            sortOrder: 'desc',
            sortOnKey: 'whenCreated',
          }}
        />
      </Slider>
    </div>
  );
};

export default Labs;
