import { THEME } from '../../constants/ColorConstants';
import React from 'react';

const BoardSidebarIcon = ({ isActive }: { isActive: boolean }) => {
  const strokeColor = isActive
    ? THEME.SHADE_200
    : THEME.SHADE_0;
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6667 2H3.33333C2.59695 2 2 2.59695 2 3.33333V12.6667C2 13.403 2.59695 14 3.33333 14H12.6667C13.403 14 14 13.403 14 12.6667V3.33333C14 2.59695 13.403 2 12.6667 2Z"
        stroke={strokeColor}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.3335 4.66675V9.33341"
        stroke={strokeColor}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 4.66675V7.33341"
        stroke={strokeColor}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.6665 4.66675V10.6667"
        stroke={strokeColor}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default BoardSidebarIcon;
