/* eslint-disable @typescript-eslint/no-unused-vars */

import { Field, Section } from 'src/types/form/type';

const calculateAge = (dateOfBirth: Date): number => {
  const today = new Date();
  const birthDate = new Date(dateOfBirth);

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
};

const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const generateRandomString = (length: number) => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const removeEmptyValues = (obj: any) => {
  const newObj: any = {};
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') {
      const innerObj = removeEmptyValues(obj[key]);
      if (Object.keys(innerObj).length > 0) {
        newObj[key] = innerObj;
      }
    } else if (obj[key] !== undefined && obj[key] !== null && obj[key] !== '') {
      newObj[key] = obj[key];
    }
  });
  return newObj;
};

const createFormFillFields = (data: Record<string, any>) => {
  const formFieldValues = [];
  for (const formFormatFieldPublicId in data) {
    formFieldValues.push({
      formFormatFieldPublicId: formFormatFieldPublicId,
      fieldKey: formFormatFieldPublicId,
      fieldValue: data[formFormatFieldPublicId],
    });
  }
  return formFieldValues;
};

const createErrorMessage = (
  errors: Record<string, any>,
  patientFormFormat: any
) => {
  // eslint-disable-next-line no-debugger
  // debugger;
  const missingFields: any[] = [];

  // Iterate through each section to find matching fields
  patientFormFormat.sections.forEach((section: Section) => {
    section.fields.forEach((field: Field) => {
      if (errors[field.publicId]) {
        missingFields.push(field.label);
      }
    });
  });

  if (missingFields.length > 0) {
    return {
      title: 'Required fields missing',
      message: `The following fields are required: ${missingFields.join(', ')}`,
    };
  } else {
    return {
      title: '',
      message: '',
    };
  }
};

const setNestedState = (state: any, path: string, value: any): any => {
  const keys = path.split('.');
  const lastKey = keys.pop();
  const lastObj = keys.reduce((obj, key) => (obj[key] = obj[key] || {}), state);
  lastObj[lastKey!] = value;
  return { ...state };
};
export {
  calculateAge,
  capitalizeFirstLetter,
  generateRandomString,
  removeEmptyValues,
  createFormFillFields,
  setNestedState,
  createErrorMessage,
};
