import { URL } from '../requestConstructor';

export const getAllResources = async () => {
  try {
    const response = await URL.get('/location/resource/get/all');
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const createNewResource = async (data: CreateNewResourceRequest) => {
  try {
    const response = await URL.post('/location/resource/create', data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const updateResource = async (data: UpdateResourceRequest) => {
  try {
    const response = await URL.put('/location/resource/update', data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const deleteResource = async (id: string) => {
  try {
    const response = await URL.delete(
      `/location/resource/delete?publicId=${id}`
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};
export const getResource = async (data: any) => {
  try {
    const response = await URL.get('/location/resource/get', data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};
