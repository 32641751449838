/* eslint-disable */
import React, { useState } from 'react';
import { updateTreatmentCycle } from '../../../../../services/api';
import { updateTreatmentCycle as updateTreatmentCycleRedux } from '../../../../../redux/slice';
import {
  Button,
  Label,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  Slider,
  Textarea,
} from '../../../../UI';
import { CrossIcon } from '../../../../../assets/icons';
import { notify } from '../../../../common/Alert';
import {
  ERROR_MESSAGE,
  ERROR_TITLE,
  SUCCESS_TITLE,
} from '../../../../../constants/NotificationConstants';
import { useDispatch } from 'react-redux';
import { DatePicker } from '../../../../common';
import { useParams } from 'react-router-dom';
const EndTreatmentCycleModal = ({
  open,
  onClose,
  treatmentCycle,
}: EndTreatmentCycleModalProps) => {
  const [formData, setFormData] = useState<EndTreatmentCycleForm>({
    startDate: new Date(treatmentCycle.startDate),
    endDate: treatmentCycle.endDate
      ? new Date(treatmentCycle.endDate)
      : new Date(),
    reasonForEnding: treatmentCycle.reasonForEnding ?? '',
  });
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const dispatch = useDispatch();
  const inputHandler = (name: keyof EndTreatmentCycleForm, value: any) => {
    const errors = { ...formErrors };
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
    if (errors[name]) {
      delete errors[name];
    }
    setFormErrors(errors);
  };
  const isValidInput = () => {
    const errors = { ...formErrors };
    if (!formData.endDate) {
      errors.startDate = 'End date is required';
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const submitHandler = async () => {
    if (!isValidInput()) return;
    const data = {
      publicId: treatmentCycle.publicId,
      patientPublicId: id,
      ...formData,
    };
    setIsLoading(true);
    const [res, error] = await updateTreatmentCycle(data);
    if (res) {
      notify.success({
        title: SUCCESS_TITLE.GENERIC_END_TREATMENT_CYCLE,
        message: `Treatment cycle '${res.treatment.name}' has been ended successfully`,
      });
      dispatch(updateTreatmentCycleRedux(res));
      onClose();
    } else {
      notify.error({
        title: ERROR_TITLE.GENERIC_END_TREATMENT_CYCLE,
        message: error?.data || ERROR_MESSAGE.GENERIC_TRY_AGAIN,
      });
    }
    setIsLoading(false);
  };
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="p-0 w-[65vw] md:w-[533px] flex flex-col gap-0 border border-neutral-100 max-h-[80%]">
        <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
          <h1 className="text-[14px] text-neutral-900 font-semibold">
            End treatment cycle {treatmentCycle.treatment.name}
          </h1>
          <Button variant={'link'} size={'xmall'} onClick={onClose}>
            <CrossIcon />
          </Button>
        </DialogHeader>
        <Slider className="flex-1" variant={'vertical'}>
          <div className="p-4 space-y-4">
            <p>
              You can no longer make changes to closed cycle. Are you sure you
              want to end cycle?
            </p>

            <div className="flex w-full gap-2">
              <div className="w-full space-y-1">
                <Label>Start date</Label>
                <DatePicker
                  variant="fullDate"
                  handleDateChange={value => inputHandler('startDate', value)}
                  initialValue={formData.startDate}
                />
              </div>
              <div className="w-full space-y-1">
                <Label>End date</Label>
                <DatePicker
                  variant="fullDate"
                  handleDateChange={value => inputHandler('endDate', value)}
                  initialValue={formData.endDate}
                  hasError={!!formErrors.endDate}
                />
                {formErrors.endDate && (
                  <small className="text-destructive-500">
                    {formErrors.endDate}
                  </small>
                )}
              </div>
            </div>
            <div className="w-full space-y-1">
              <Label>Reason for ending </Label>
              <Textarea
                name="reasonForEnding"
                value={formData.reasonForEnding}
                onChange={e => inputHandler('reasonForEnding', e.target.value)}
              />
            </div>
          </div>
        </Slider>
        <DialogFooter>
          <Button
            variant={'link'}
            size={'small'}
            className="w-full"
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            size={'small'}
            variant={'primary'}
            onClick={submitHandler}
            className="w-full"
            isLoading={isLoading}
            disabled={isLoading}
          >
            End treatment cycle
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default EndTreatmentCycleModal;
