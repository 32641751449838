/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { REDUCER_ACTIONS } from '../../constants/actions';
import { logout, openTokenExpiredModal } from '../../redux/slice/authSlice';
import { store } from '../../redux/store/store';
import axios from 'axios';

let failedQueue: any[] = [];
const URLS = {
  API_URL: process.env.REACT_APP_API_URL,
};
const WHITELIST_APIS = [
  '/user/auth/refreshToken',
  '/client/get/public',
  '/user/preLoginCheck',
  '/user/auth/token',
];

export const URL = axios.create({
  withCredentials: true,
  baseURL: URLS.API_URL,
});

URL.interceptors.request.use(async (config: any) => {
  if (config.url && isWhitelisted(config.url)) {
    return config;
  }
  // if (isTokenExpired && token) {
  //   if (!isRefreshing) {
  //     isRefreshing = true;
  //     {
  //       try {
  //         await refreshAuthToken(token, refreshToken);
  //         // store.dispatch(updateTokens(newTokens));
  //         isRefreshing = false;
  //         // processQueue(null, `${newTokens.tokenType} ${newTokens.token}`);
  //       } catch (error) {
  //         isRefreshing = false;
  //         processQueue(error, null);
  //         throw error;
  //       }
  //     }
  //   }
  //   return new Promise((resolve, reject) => {
  //     failedQueue.push({ resolve, reject });
  //   })
  //     .then(token => {
  //       // if (token) {
  //       //   config.headers.Authorization = token;
  //       // }
  //       return config;
  //     })
  //     .catch(err => {
  //       return Promise.reject(err);
  //     });
  // } else {
  //   // config.headers.Authorization = `${tokenType} ${token}`;
  // }

  return config;
});

let isTokenExpiredAlertShown = true;
URL.interceptors.response.use(
  response => response,
  error => {
    const { response } = error;
    if (!isTokenExpiredAlertShown && response && response.status === 440) {
      isTokenExpiredAlertShown = true;

      alert('Access Token expired. Please login again.');
      store.dispatch({ type: REDUCER_ACTIONS.RESET_STORE });
      window.localStorage.clear();

      store.dispatch(logout());
      store.dispatch(openTokenExpiredModal());
      window.location.assign('/signIn');
    }

    return Promise.reject(error);
  }
);

const isWhitelisted = (url: string | undefined): boolean => {
  if (!url) return false;
  const basePath = url.split('?')[0];
  return WHITELIST_APIS.some(api => basePath.includes(api));
};

const checkTokenExpiry = (whenExpires: string): boolean => {
  if (whenExpires.length) {
    const expirationDate = new Date(whenExpires).getTime();
    const currentTime = Date.now();
    return expirationDate < currentTime;
  }
  return true;
};
const refreshAuthToken = async (
  token: string,
  refreshToken: string
): Promise<AuthEntities> => {
  const response = await axios.post(`${URLS.API_URL}/user/auth/refreshToken`);
  isTokenExpiredAlertShown = false;
  return response.data;
};

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// Set the maximum number of API calls
const MAX_API_CALLS = 20;

// Keep track of the last five API calls

// Add a request interceptor
URL.interceptors.request.use(function (config) {
  const lastFiveAPICalls: (string | undefined)[] = [];
  // Check if the last five API calls are the same
  if (
    lastFiveAPICalls.length === MAX_API_CALLS &&
    lastFiveAPICalls.every(call => call === config.url)
  ) {
    return Promise.reject(new Error('All five API calls are the same.'));
  }

  // Remove the oldest API call if the maximum number of API calls has been reached
  if (lastFiveAPICalls.length === MAX_API_CALLS) {
    lastFiveAPICalls.shift();
  }

  // Add the current API call to the last five API calls
  lastFiveAPICalls.push(config.url);

  return config;
});
