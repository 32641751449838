import { DirectedArrowIcon, RightArrow } from '../../../assets/icons';
import React from 'react';
import { Slider } from '../../UI';
import { THEME } from '../../../constants/ColorConstants';

// TODO: Once API is integrated according to that use data type that will be passed and move the type to interface to Types folder
interface AnlyticsPropsType {
  role: 'admin' | 'consultantantManager' | 'consultant';
}
const Analytics: React.FC<AnlyticsPropsType> = ({ role }) => {
  // Just dummy data later will be removed
  // For Consultant Manager and Consultant
  const BASEANALYTIC_DATA = {
    id: 1,
    label: 'Session Label',
    totalResult: 54,
    growthRate: 23,
    lastResult: 16,
  };
  const BASEANALYTIC_DATA2 = {
    id: 1,
    label: 'Staff',
    count: 54,
  };
  return (
    <div className="w-full rounded-md border border-neutral-100">
      <div className="w-full flex justify-between py-[16px] px-[14px]">
        <h2 className="font-semibold text-[16px] text-neutral-900">
          Analytics
        </h2>
        <div className="flex">
          {/* TODO: Need to imporve the code if it dynamic */}
          <p className="font-semibold text-[16px] text-neutral-900">
            Your Active Sessions for{' '}
            <span className="font-semibold text-[16px] text-primary-500">
              Today
            </span>
          </p>

          <div className="rotate-90">
            <RightArrow stroke={THEME.PRIMARY_500} />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        {/* For Consultant Manager and Consultant */}
        {(role === 'consultantantManager' || role === 'consultant') && (
          <>
            {/* Genral Info: first analytic card */}
            <div className="flex px-[12px] py-[12px] border-t border-b border-neutral-100 gap-5">
              <AnalyticCard
                label="Total Sessions Booked"
                totalResult={24}
                growthRate={20}
                lastResult={21}
              />
              <div className="h-[70px] w-[2px] bg-neutral-100 ml-3"></div>
              <Slider variant={'horizontal'}>
                {Array(20)
                  .fill(BASEANALYTIC_DATA)
                  .map((obj, index) => ({ ...obj, id: index + 1 }))
                  .map(item => (
                    <AnalyticCard
                      key={item.id}
                      label={item.label}
                      totalResult={item.totalResult}
                      growthRate={item.growthRate}
                      lastResult={item.lastResult}
                    />
                  ))}
              </Slider>
            </div>
            {/* Genral Info: first analytic card */}
            <div className="flex px-[12px] py-[12px] border-t border-b border-netral-100 gap-5">
              <AnalyticCard
                label="Total Sessions Booked"
                totalResult={24}
                growthRate={20}
                lastResult={21}
              />
              <div className="h-[70px] w-[2px] bg-neutral-100 ml-3"></div>
              <Slider variant={'horizontal'}>
                {Array(20)
                  .fill(BASEANALYTIC_DATA)
                  .map((obj, index) => ({ ...obj, id: index + 1 }))
                  .map(item => (
                    <AnalyticCard
                      key={item.id}
                      label={item.label}
                      totalResult={item.totalResult}
                      growthRate={item.growthRate}
                      lastResult={item.lastResult}
                    />
                  ))}
              </Slider>
            </div>
          </>
        )}
        {/* For Admin */}
        {role === 'admin' && (
          <Slider className="border-t border-netral-100">
            {Array(20)
              .fill(BASEANALYTIC_DATA2)
              .map((obj, index) => ({ ...obj, id: index + 1 }))
              .map((item, index) => (
                <div
                  key={item.id + index}
                  className="p-[12px] border-r border-netral-100 min-w-[170px]"
                >
                  <AnalyticCard2 label={item.label} count={item.count} />
                </div>
              ))}
          </Slider>
        )}
      </div>
    </div>
  );
};

const AnalyticCard: React.FC<{
  label: string;
  totalResult: number;
  growthRate: number;
  lastResult: number;
}> = ({ label, totalResult, growthRate, lastResult }) => {
  return (
    <div className="group flex flex-col min-w-[180px] text-[14px]">
      <div className="flex gap-1 items-center">
        <p className="text-neutral-500 group-hover:text-neutral-900 transition-all delay-300 font-medium">
          {label}
        </p>
        <div className="rotate-45 border border-neutral-200 rounded-full opacity-0 group-hover:opacity-100 transition-all delay-300 cursor-pointer">
          <DirectedArrowIcon />
        </div>
      </div>
      <div className="flex gap-2 items-end">
        <p className="text-[23px] font-medium">{totalResult}</p>
        <p className="text-success-500 flex items-center">
          <DirectedArrowIcon fill={THEME.SUCCESS_500} />
          {growthRate}%
        </p>
      </div>
      <p className="text-neutral-500">vs last yesterday : {lastResult}</p>
    </div>
  );
};

// For admin
const AnalyticCard2: React.FC<{
  label: string;
  count: number;
}> = ({ label, count }) => {
  return (
    <div className="w-full text-[14px]">
      <p className="font-medium text-neutral-500">{label}</p>
      <div className="flex justify-between items-center">
        <p className="text-[24px] text-neural-900 font-medium justify-between">
          {count}
        </p>
        <div className="rotate-45">
          <DirectedArrowIcon />
        </div>
      </div>
    </div>
  );
};

export default Analytics;
