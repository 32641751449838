import { Button, Input, Label } from '../../UI';
import React, { useState } from 'react';
import RightArrow from '../../../assets/icons/RightArrow';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUserDetails, loginUser } from '../../../services/api';
import { useDispatch } from 'react-redux';
import { addUserCredentials } from '../../../redux/slice';
import { ROUTES } from '../../../constants/NavigationConstants';
import { getClient } from '../../../services/api/endPoints/client';
import { clientDetails } from '../../../redux/slice/clientSlice';
import { useDownloadFile } from '../../../hooks/useDownloadFile';

const ValidateTwoFactorAuth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { getFileSrc } = useDownloadFile();
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [codeError, setCodeError] = useState<string | null>(null);
  const username = location.state && location.state.username;
  const password = location.state && location.state.password;
  const clientId = location.state && location.state.clientId;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    if (verificationCode.length === 6) {
      setIsLoading(true);
      if (username && password) {
        const [response, error] = await loginUser(
          { username, password, clientId },
          verificationCode
        );
        if (response) {
          const { data } = await getUserDetails();

          try {
            const [response] = await getClient();
            const [res] = await getFileSrc(response.smallLogoUrl);
            response.smallLogoUrlBlob = res as unknown as Blob;
            dispatch(clientDetails(response));
          } catch (error) {
            console.error('Error fetching data:', error);
          }

          const userRoles: string[] = [];
          for (const role of data.roles) {
            userRoles.push(role.name);
          }
          data.roles = userRoles;
          dispatch(addUserCredentials(data));
          setVerificationCode('');
          setIsLoading(false);
          navigate(ROUTES.ROUTE_TO_OVERVIEW, { replace: true });
        } else {
          setIsLoading(false);
          setCodeError(error);
        }
      }
    }
  };
  return (
    <div className="space-y-5 w-full">
      <div className="w-full flex flex-col gap-1">
        <Label htmlFor="verification-code">Enter Verification Code</Label>
        <Input
          id="verification-code"
          type="text"
          pattern="[0-9]*"
          inputMode="numeric"
          value={verificationCode}
          maxLength={6}
          hasError={!!codeError}
          onChange={e => {
            const inputText = e.target.value.replace(/\D/g, '');
            setVerificationCode(inputText.substring(0, 6));
            setCodeError(null);
          }}
          placeholder="6-digit code"
        />

        {codeError && verificationCode.length > 0 && (
          <p className="text-sm text-destructive-500">{codeError}</p>
        )}
      </div>
      <Button
        style={{ width: '100%' }}
        type="submit"
        variant="primary"
        size="medium"
        isLoading={isLoading}
        onClick={handleSubmit}
        trailingIcon={<RightArrow />}
      >
        Continue
      </Button>
      <p className="text-sm text-neutral-400">
        Not working?{' '}
        <a href="mailto:admin@swivo.io">
          <span className="cursor-pointer text-neutral-600">Contact admin</span>
        </a>
      </p>
    </div>
  );
};

export default ValidateTwoFactorAuth;
