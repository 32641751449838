import React, { useEffect, useState, useCallback } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import * as dateFns from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './LibraryCalendar.css';
import CalenderEventModal from './CalenderEventModal';
import { getResourceBookings } from '../../../services/api/endPoints/bookings';
import { addResourceBookings } from '../../../redux/slice/resourceBookingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store/store';
import { notify } from '../../../components/common';
import CustomEvent from './CustomEvent';
import { useDebounce } from '../../../hooks';
import { CONSTANT } from '../../../constants/constants';

const locales = {
  // 'en-US': require('date-fns/locale/en-US'),
};

const localizer = dateFnsLocalizer({
  format: dateFns.format,
  parse: dateFns.parse,
  startOfWeek: dateFns.startOfWeek,
  getDay: dateFns.getDay,
  locales,
});

const MyCalendar: React.FC = () => {
  const dispatch = useDispatch();
  const [allEvents, setAllEvents] = useState<CustomEvent[]>([]);
  //eslint-disable-next-line
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<CustomEvent | null>(null);
  const [openEventModal, setOpenEventModal] = useState<boolean>(false);
  const [calendarHeight, setCalendarHeight] = useState<number>(
    window.innerHeight
  );
  const [currentDate, setCurrentDate] = useState<Date>(new Date());

  const debouncedDate = useDebounce(currentDate, 300);

  const handleCloseModal = () => {
    setSelectedEvent(null);
    setOpenEventModal(false);
  };

  const bookings = useSelector<RootState, ResourceBooking[]>(
    state => state.resourceBooking.filteredBookings
  );

  const fetchSchedule = async (startDate?: Date, endDate?: Date) => {
    setIsLoading(true);
    const [scheduleData, blockData] = await Promise.all([
      getResourceBookings({
        resourceOnly: true,
        fromDate: startDate,
        toDate: endDate,
      }),
      getResourceBookings({
        blocked: true,
        fromDate: startDate,
        toDate: endDate,
      }),
    ]);

    if (scheduleData[0] && blockData[0]) {
      setIsLoading(false);
      const combinedData = [...scheduleData[0], ...blockData[0]];
      dispatch(addResourceBookings(combinedData));
    } else {
      setIsLoading(false);
      notify.error({
        title: 'Error fetching schedule',
        message: 'Server Error',
      });
    }
  };

  const formattedBookings = (data: any) => {
    const formattedEvents: CustomEvent[] = data.map((booking: any) => {
      const start = new Date(new Date(booking.appointmentTime).getTime());
      const end = new Date(new Date(booking.appointmentEndTime).getTime());
      if (booking.blocked) {
        return {
          publicId: booking.publicId,
          title: booking.title || CONSTANT.BLOCKED,
          description: booking.description,
          location: booking.location.name,
          locationPublicId: booking.location.publicId,
          resourcePublicId: booking.resource.publicId,
          resource: booking.resource.name,
          cancelled: booking.cancelled,
          start,
          end,
          blocked: true,
        };
      }
      return {
        publicId: booking.publicId,
        serviceName: booking.service.name,
        title: `${booking.service.name} - ${booking.resource.name}`,
        patient: `${booking.patient.firstName} ${booking.patient.lastName}`,
        resourcePublicId: booking.resource.publicId,
        resource: booking.resource.name,
        location: booking.location.name,
        locationPublicId: booking.location.publicId,
        cancelled: booking.cancelled,
        blocked: booking.blocked,
        paymentStatus: booking?.paymentStatus,
        start,
        end,
      };
    });

    setAllEvents(formattedEvents);
  };

  useEffect(() => {
    const today = new Date();
    const startDate = dateFns.startOfMonth(today);
    const endDate = dateFns.endOfMonth(today);
    fetchSchedule(startDate, endDate);
  }, []);

  useEffect(() => {
    if (bookings.length > 0) {
      formattedBookings(bookings);
    }
  }, [bookings]);

  useEffect(() => {
    const startDate = dateFns.startOfMonth(debouncedDate);
    const endDate = dateFns.endOfMonth(debouncedDate);
    fetchSchedule(startDate, endDate);
  }, [debouncedDate]);

  const handleNavigate = useCallback((date: Date) => {
    setCurrentDate(date);
  }, []);

  const updateCalendarHeight = () => {
    setCalendarHeight(window.innerHeight - 285);
  };

  useEffect(() => {
    updateCalendarHeight();
    window.addEventListener('resize', updateCalendarHeight);
    return () => {
      window.removeEventListener('resize', updateCalendarHeight);
    };
  }, []);

  return (
    <div>
      <Calendar
        localizer={localizer}
        events={allEvents}
        startAccessor="start"
        endAccessor="end"
        style={{ height: calendarHeight }}
        onSelectEvent={event => {
          setSelectedEvent(event as CustomEvent);
          setOpenEventModal(true);
        }}
        onNavigate={handleNavigate}
        components={{
          event: CustomEvent,
        }}
      />
      <CalenderEventModal
        event={selectedEvent}
        open={openEventModal}
        onClose={handleCloseModal}
      />
    </div>
  );
};

export default MyCalendar;
